import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch, batch } from 'react-redux'
import { string, node, array, element, object, oneOfType, arrayOf, bool } from 'prop-types'

// Components
import { ErrorState } from '@mlcl-digital/mlcl-design'
import WithLoader from '../WithLoader'

// Actions
import { getPolicySummary } from '../../../actions/creators/customerPolicies'
import { shouldCallPolicyDetails } from '../../../selectors/customerPolicyDetails'
import { actionCreators } from '../../../actions'
// Constants
import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_FAILED,
} from '../../../constants/apis'
import { NAVIGATION_MAIN, FOOTER_MAIN } from '../../../constants/navigation'
// Utils
import { getBancsCustomerNumber } from '../../../utils/cookieUtils'

import { resetNavigationType, resetFooterType } from '../../../actions/creators/navigation'

const { getMasterList, getCustomerDetails, fetchPolicySelfAndSupportingData } = actionCreators

const ErrorStateContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '500px',
})

export const CustomerPortalLifeCycleComponent = ({
  children,
  loadingConstraints,
  failureConstraints,
  bancsPolicyNo,
  errorMessage,
  checkNavigation,
}) => {
  const dispatch = useDispatch()

  const pageIsLoading = useSelector(
    ({ customerPolicySummaryStatus: { isLoading } }) =>
      isLoading === EXP_CUSTOMER_STATUS_LOADING || loadingConstraints.some(Boolean)
  )
  const lifeCycleHasFailed = useSelector(
    ({ customerPolicySummaryStatus: { isLoading } }) =>
      isLoading === EXP_CUSTOMER_STATUS_FAILED || failureConstraints.some(Boolean)
  )
  const shouldLoadSummary = useSelector(
    ({ customerPolicySummaryStatus: { isLoading } }) => isLoading === EXP_CUSTOMER_STATUS_UNLOADED
  )
  const shouldHandleGetPolicy = useSelector(state =>
    shouldCallPolicyDetails({ ...state, policyId: bancsPolicyNo })
  )
  const handleGetPolicySummary = () => {
    dispatch(getPolicySummary(getBancsCustomerNumber()))
  }

  const footerType = useSelector(state => state.navigation.footerType)
  const navigationType = useSelector(state => state.navigation.navigationType)

  useEffect(() => {
    if (shouldLoadSummary) {
      dispatch(getMasterList(true))
      dispatch(getCustomerDetails())
      handleGetPolicySummary()
    }
  }, [shouldLoadSummary])

  useEffect(() => {
    if (checkNavigation) {
      batch(() => {
        if (footerType !== FOOTER_MAIN) dispatch(resetFooterType())
        if (navigationType !== NAVIGATION_MAIN) dispatch(resetNavigationType())
      })

      // FIXME: Ask business if we still want to hide beneficiaries in menu on v2 API. MM
      // if (isPoliciesLoaded) {
      //   dispatch(
      //     showBeneficiariesBasedOnAllPolicies(policies)
      //       ? resetNavigationType()
      //       : changeNavigationType(NAVIGATION_CUSTOMER)
      //   )
      // } else {
      //   dispatch(resetNavigationType())
      // }
    }
  }, [checkNavigation])

  useEffect(() => {
    if (bancsPolicyNo && shouldHandleGetPolicy) {
      dispatch(fetchPolicySelfAndSupportingData(bancsPolicyNo))
    }
  }, [shouldHandleGetPolicy])

  return (
    <WithLoader isLoading={pageIsLoading} overlay>
      {lifeCycleHasFailed ? (
        <ErrorStateContainer>
          <ErrorState
            message={errorMessage}
            showBackground={false}
            retryFunction={handleGetPolicySummary}
          />
        </ErrorStateContainer>
      ) : (
        <>{children}</>
      )}
    </WithLoader>
  )
}

CustomerPortalLifeCycleComponent.defaultProps = {
  loadingConstraints: [],
  failureConstraints: [],
  bancsPolicyNo: undefined,
  errorMessage: undefined,
  checkNavigation: true,
}

CustomerPortalLifeCycleComponent.propTypes = {
  children: oneOfType([string, node, array, element, object]).isRequired,
  loadingConstraints: arrayOf(bool),
  failureConstraints: arrayOf(bool),
  bancsPolicyNo: string,
  errorMessage: string,
  checkNavigation: bool,
}

export default CustomerPortalLifeCycleComponent
