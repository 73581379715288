import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontSize } = Variables as VariablesType

const styles = {
  optionsWrapper: {
    border: 0,
    margin: 0,
    padding: 0,
    label: {
      fontSize: fontSize.xsm,
    },
    'input:checked:not(:disabled) + label': {
      '&:before': {
        borderColor: colours.mlcAquaAccessible,
      },
      svg: {
        color: colours.mlcAquaAccessible,
      },
    },
    '> div': {
      display: 'block',
      marginBottom: space(1),
    },
  },
  value: {
    fontSize: fontSize.xsm,
  },
  optionModalBtnContainer: {
    button: {
      '&:first-of-type(1)': {
        marginRight: space(3),
      },
    },
  },
  label: {
    fontWeight: fontWeights.semiBold,
    marginBottom: space(3),
    display: 'block',
  },
}

export default styles
