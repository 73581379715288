// @flow
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Button, Heading, Variables } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { objectOf, shape, string } from 'prop-types'
import { createEvent } from '../../../utils/telemetry'

// selectors
import {
  getCertificateOfCurrencyTiles,
  getCertificateOfCurrencyRequestPayload,
} from '../../../selectors/customerDocuments'

// utils.
import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { generateCorrelationID } from '../../../utils/commonUtils'

// components
import Modal from '../Modal'
import { DOC_TYPE_CERT_CURR } from '../../../constants/documentTypes'

// styles
import styles from './CustomerCertificateOfCurrency.styles'

const { space, mediaQueries } = Variables

const CardSection = styled('div')(styles.cardSection)
const HeadCardSection = styled('div')(styles.headCardSection)
const CardWrapper = styled(Card)(styles.cardWrapper)
const ModalContent = styled('div')(styles.modalContent)

const StyledHeading = styled(Heading)({
  margin: 0,
})

const StyledWrapper = styled.div({
  display: 'grid',
  gridGap: space(2),
  gridTemplateColumns: 'repeat(1, 1fr)',
  [mediaQueries.sm]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [mediaQueries.md]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})
// certificate of currency component
export const CertificateOfCurrency = props => {
  const [showModal, setShowModal] = useState(false)
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const {
    fields: {
      downloadDocumentCertificateOfCurrencyText,
      downloadDocumentCertificateOfCurrencyCardBody,
      downloadDocumentCertificateOfCurrencyDownloadText,
      downloadCurrencySelectPolicyText,
      downloadCurrencyModalPolicyText,
      downloadCurrencyModalLifeInsuredText,
    },
    analytics,
  } = props

  const requestPayloads = useSelector(getCertificateOfCurrencyRequestPayload)
  const policies = useSelector(getCertificateOfCurrencyTiles)
  const config = useSelector(state => state.config)

  // function to call download document
  const downloadCertificateOfCurrency = bancsPolicyNo => {
    const event = createEvent({
      GA: analytics,
      Splunk: {
        attributes: {
          'workflow.name': 'download CoC',
        },
      },
    })
    event.end()

    setDownloadInProgress(true)
    setShowModal(false)
    const downloadCompletedCallback = () => {
      setDownloadInProgress(false)
    }

    const requestPayload = {
      docType: DOC_TYPE_CERT_CURR,
      correlationID: generateCorrelationID(),
      policy: {
        ...requestPayloads[bancsPolicyNo],
      },
    }
    downloadDocument(requestPayload, null, null, config, downloadCompletedCallback)
  }

  const action = () => {
    if (policies.length === 1) {
      downloadCertificateOfCurrency(policies[0].bancsPolicyNo)
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      {Boolean(policies.length > 1) && (
        <Modal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false)
          }}
          title={downloadCurrencySelectPolicyText}
          isDynamic
        >
          <ModalContent>
            {policies.map(policy => (
              <CardWrapper
                onClick={() => downloadCertificateOfCurrency(policy.bancsPolicyNo)}
                styleOverrides={{
                  body: {
                    padding: `${space(3, true)} 0`,
                  },
                }}
                key={policy.bancsPolicyNo}
              >
                <HeadCardSection>
                  {downloadCurrencyModalPolicyText}:{` ${policy.policyNo}`}
                </HeadCardSection>
                <CardSection>
                  {downloadCurrencyModalLifeInsuredText}:
                  {policy.lifeAssured.map(lifeAssured => (
                    <div key={lifeAssured}>{lifeAssured}</div>
                  ))}
                </CardSection>
              </CardWrapper>
            ))}
          </ModalContent>
        </Modal>
      )}
      <StyledWrapper>
        <Card
          header={
            <StyledHeading variant="h3">{downloadDocumentCertificateOfCurrencyText}</StyledHeading>
          }
          footer={
            <Button variant="secondary" onClick={action} isLoading={downloadInProgress}>
              {downloadDocumentCertificateOfCurrencyDownloadText}
            </Button>
          }
          styleOverrides={{
            footer: {
              display: 'flex',
              justifyContent: 'flex-end',
            },
          }}
        >
          {downloadDocumentCertificateOfCurrencyCardBody}
        </Card>
      </StyledWrapper>
    </>
  )
}

CertificateOfCurrency.propTypes = {
  fields: objectOf(string),
  analytics: shape({
    action: string,
    event: string,
  }),
}

export default CertificateOfCurrency
