// @flow
import { Variables } from '@mlcl-digital/mlcl-design'
import { fontSize } from '../../../styles'

const { space, colours, fontWeights, fontFamily } = Variables

export const getColorVariation = (variant: string) => {
  const variants = {
    dark: {
      color: colours.tertiarySix,
      background: colours.mlcAquaDark,
    },
    light: {
      color: colours.tertiarySix,
      background: colours.mlcAquaAccessible,
    },
    white: {
      background: colours.tertiarySix,
      color: colours.mlcAquaDark,
    },
    offWhite: {
      background: colours.offWhite,
      color: colours.darkestGrey,
    },
  }
  return variants[variant]
}

export const getAlignmentVariation = (variant: string) => {
  const variants = {
    center: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    left: {
      textAlign: 'left',
      justifyContent: 'left',
    },
  }
  return variants[variant]
}

export const getAlignItemsVariation = (variant: string) => {
  const variants = {
    center: {
      alignItems: 'center',
    },
    left: {
      alignItems: 'left',
    },
  }
  return variants[variant]
}

export const getPaddingVariation = (variant: string) => {
  const variants = {
    center: {
      padding: `0 ${space(11, true)}`,
    },
    left: {
      padding: '0',
    },
  }
  return variants[variant]
}

const styles = {
  sectionWrap: (bgVariation: string, alignVariation: string) => ({
    ...getColorVariation(bgVariation),
    display: 'flex',
    padding: `${space(6, true)} 0`,
    ...getAlignmentVariation(alignVariation),
    '& h2': {
      fontFamily: fontFamily.charlie,
      fontWeight: fontWeights.bold,
      ...getColorVariation(bgVariation),
    },
    '& > div > div > p': {
      fontSize: fontSize.md,
      ...getPaddingVariation(alignVariation),
    },
    '& > div > h3': {
      ...getColorVariation(bgVariation),
      margin: `${space(3, true)} 0`,
      fontFamily: fontFamily.charlie,
    },
  }),
  tileContainer: (alignVariation: string, shouldHide: boolean) => ({
    margin: `${shouldHide ? `${space(4, true)} 0` : 0}`,
    display: 'flex',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    borderRadius: '5px',
    '& > div': {
      ...getAlignItemsVariation(alignVariation),
      borderRadius: '5px',
      flexDirection: 'column',
      flexFlow: 'column',
      '& > h3': {
        flex: '1 0 auto',
      },
      '& > div': {
        flex: '2 0 auto',
      },
      '& > a, & > button': {
        width: 'fit-content',
      },
    },
  }),
  pageWrap: {
    width: '100%',
  },
}

export default styles
