import { fontSize, space, colours, fontFamily, fontWeights } from '../../../../../styles'

const styles = {
  main: {
    textAlign: 'center',
  },
  completedIcon: {
    minHeight: space(25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  caption: {
    fontSize: fontSize.md,
  },
  message: {
    marginBottom: space(4),
    marginTop: space(3),
  },
  exploreButton: () => ({
    height: 48,
    padding: `${space(1.5, true)} ${space(3, true)}`,
    textAlign: 'center',
    border: 'none',

    justifyContent: 'center',
    display: 'inline-flex',
    alignItems: 'center',

    backgroundColor: colours.mediumGreen,
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    fontFamily: fontFamily.charlie,
    fontWeight: fontWeights.normal,
    '&:hover, &:focus': {
      backgroundColor: colours.darkGreen,
    },
    '> a': {
      textDecoration: 'none',
      color: colours.white,
    },
  }),
}
export default styles
