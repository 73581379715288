import { setColumns, fontSize, fontWeights, colours, space } from '../../../styles'

const styles = {
  main: {
    color: colours.black,
  },
  base: {
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
    '& input': {
      width: space(38),
    },
    '& span': {
      marginLeft: 0,
      fontSize: fontSize.xs,
      color: colours.black,
      paddingTop: space(0.5),
    },
    '& div': {
      flexBasis: '100%',
    },
  },
  caption: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.normal,
  },
  message: {
    fontSize: fontSize.xs,
    marginBottom: space(4),
  },
  resendLink: {
    fontWeight: fontWeights.bold,
    color: colours.lightGreen,
    paddingRight: space(0.5),
    cursor: 'pointer',

    '&:hover': {
      color: colours.darkGreen,
    },
  },
}
export default styles
