// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { A11yLabel } from '@mlcl-digital/mlcl-design'

import { get } from 'lodash'
import Input from '../../../../../../../atoms/Input'
import {
  IconCreditCard,
  IconVisa,
  IconMasterCard,
  IconExclaimation16,
} from '../../../../../../../atoms/Icons'

// constants
import { MASTERCARD, VISA } from '../../../../../../../../constants/policies'

import { actionCreators } from '../../../../../../../../actions'
import { errorCheck } from '../../../../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../../../../utils/sitecoreUtils'
import custCreditCardSchema, { FORM_ID } from './creditCard.schema'
import styles from './creditCard.styles'
import { getCreditType } from '../../../../../../../../utils/creditTypeUtils'

const CreditCardContainer = styled('div')(styles.creditCardContainer)
const CreditCardWrap = styled('div')(styles.creditCardWrap)
const CreditCardNumber = styled(Input)(styles.creditCardNumber)
const FullName = styled(Input)(styles.fullName)
const DrawDate = styled(Input)(styles.drawDate)
const ExpiryDate = styled(Input)(styles.expiryDate)
const Wrapper = styled('div')(styles.wrapper)
const CreditCardIcon = styled('div')(styles.creditCardIcon)
const Row = styled('div')(styles.row)
const ErrorWrap = styled('div')(styles.errorWrap)
const ErrorMessage = styled('p')(styles.error)
const ExclaimationIcon = styled(IconExclaimation16)(styles.icon)

type Props = {
  actions: Object,
  fields: Object,
  form: Object,
  nextButton: Object,
  fileUploadInfo: { isModalOpen: Boolean },
  paymentInstruction: Object,
}
type State = {
  paymentDrawDayOptions: Array<Object>,
  SCHEMA: Object,
}

export class CreditCard extends Component<Props, State> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit, formResetField },
      fields,
      form,
    } = props
    const SCHEMA = custCreditCardSchema({ fields })
    this.state = {
      SCHEMA,
    }
    // initializing form
    if (!form) {
      formInit(FORM_ID, SCHEMA)
    }
    formResetField(FORM_ID, ['cardHolderName', 'cardNumber', 'expiryDate'])
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props

    const { SCHEMA } = this.state
    const data = {
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)

    if (name === 'cardNumber') {
      formUpdateField(FORM_ID, 'cardType', { value: this.getCardType(value), error: false })
    }
  }

  getCardType = value => {
    let cardType = ''
    if (getCreditType(value).isVisa) {
      cardType = VISA
    } else {
      cardType = MASTERCARD
    }
    this.setState({ isCardType: cardType })
    return cardType
  }

  handleDisplayErrorMessage() {
    const { form, fields } = this.props
    const { cardNumber, expiryDate } = form.fields
    const SCHEMA = custCreditCardSchema({ fields })
    const { cpEditPaymentCreditCardAccountNumberErrorMsg } = reduceAuthorableFields(fields)
    if (cardNumber.error.error && expiryDate.error.error) {
      return (
        <ErrorWrap>
          <Row>
            <ExclaimationIcon />
            <ErrorMessage>{cpEditPaymentCreditCardAccountNumberErrorMsg}</ErrorMessage>
          </Row>
        </ErrorWrap>
      )
    }
    return (
      <ErrorWrap>
        {cardNumber.error.error && (
          <Row>
            <ExclaimationIcon />
            <ErrorMessage>
              {cardNumber.error.error ? cardNumber.error.errorMsg : SCHEMA.cardNumber.tooltip}
            </ErrorMessage>
          </Row>
        )}
        {expiryDate.error.error && (
          <Row>
            <ExclaimationIcon />
            <ErrorMessage>
              {expiryDate.error.error ? expiryDate.error.errorMsg : SCHEMA.expiryDate.tooltip}
            </ErrorMessage>
          </Row>
        )}
      </ErrorWrap>
    )
  }

  renderCardIcon() {
    const { isCardType } = this.state
    switch (isCardType) {
      case VISA:
        return <IconVisa />
      case MASTERCARD:
        return <IconMasterCard />
      default:
        return <IconCreditCard />
    }
  }

  render() {
    const { form, fields, nextButton, paymentInstruction } = this.props
    const { SCHEMA } = this.state
    const {
      cpEditPaymentCreditCardAccountNameLabel,
      cpEditPaymentCreditCardAccountNamePlaceholder,
      cpEditPaymentCreditCardNumberPlaceholder,
      cpEditPaymentCreditCardExpiryDatePlaceholder,
    } = reduceAuthorableFields(fields)
    if (!form) {
      return null
    }
    const { collectionDay } = paymentInstruction
    const { cardNumber, cardHolderName, expiryDate } = form.fields
    return (
      <div>
        <A11yLabel id="creditCardForm">
          <Text field={get(fields, 'cpEditPaymentCreditCardFormLabel', {})} />
        </A11yLabel>

        <form aria-labelledby="creditCardForm">
          <CreditCardContainer>
            <Wrapper role="group">
              {<CreditCardIcon>{this.renderCardIcon()}</CreditCardIcon>}
              <CreditCardNumber
                noBorder
                htmlFor={SCHEMA.cardNumber}
                name="cardNumber"
                placeholder={cpEditPaymentCreditCardNumberPlaceholder}
                changeHandler={this.handleChange}
                value={cardNumber.value}
                error={cardNumber.error.error}
              />
              <ExpiryDate
                noBorder
                htmlFor="expiryDate"
                name="expiryDate"
                options={{ date: true, pattern: 'MM/YY' }}
                placeholder={cpEditPaymentCreditCardExpiryDatePlaceholder}
                changeHandler={this.handleChange}
                value={expiryDate.value}
                error={expiryDate.error.error}
              />
            </Wrapper>
            {this.handleDisplayErrorMessage()}
          </CreditCardContainer>
          <CreditCardWrap>
            <FullName
              htmlFor="cardHolderName"
              label={cpEditPaymentCreditCardAccountNameLabel}
              placeholder={cpEditPaymentCreditCardAccountNamePlaceholder}
              name="cardHolderName"
              changeHandler={this.handleChange}
              error={cardHolderName.error.error}
              caption={
                cardHolderName.error.error
                  ? cardHolderName.error.errorMsg
                  : SCHEMA.cardHolderName.tooltip
              }
              value={cardHolderName.value}
            />
            <DrawDate
              htmlFor="paymentDrawDate"
              name="paymentDrawDate"
              value={collectionDay}
              disabled
            />
          </CreditCardWrap>
          {nextButton}
        </form>
      </div>
    )
  }
}

export const mapStateToProps = ({ forms, captureCreditCard }) => ({
  form: forms[FORM_ID],
  captureCreditCard,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)
