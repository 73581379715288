import { createSelector } from 'reselect'
import moment from 'moment'
import get from 'lodash/get'
import { getCustomerPoliciesCardData } from './customerPolicies'
import { getMasterList } from './common'
// @ts-expect-error file not in typescript
import { getCollectionFrequency } from '../utils/paymentUtils'
// @ts-expect-error file not in typescript
import { getCreditType } from '../utils/creditTypeUtils'
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
import {
  isPolicyLapseDateOverThanCertainDays,
  checkIfPaymentMethodValid,
  getInvalidReason,
  getPaymentStatus,
  // @ts-expect-error file not in typescript
} from '../utils/customerOneTimePaymentUtils'
import { POLICY_LAPSE_STATUS, POLICY_OUT_OF_FORCE } from '../constants/payments'

// @ts-expect-error file not in typescript
import { FORM_ID } from '../components/organisms/CustomerOneOffPayment/components/PaymentDetails/paymentForm.schema'

// types
import { FormObject, Store } from '../types/store'
import { CustomerPolicyCardData, CustomerPolicyPaymentDetail } from '../types/ClientPolicies'
import { Rules } from '../types/alterations'
import { NabPayment } from '../types/nabPayment'

export const getPolicyPaymentDetails = createSelector(
  [getCustomerPoliciesCardData, getMasterList],
  (policiesData: CustomerPolicyCardData[], { policyStatus }) =>
    policiesData.map(
      ({
        policyType,
        subHeadingText,
        mainHeadingText,
        policyPersonnel,
        policyPremiumValue,
        nextPremiumDueDate,
        outstandingBalance,
        policyPremiumFrequency,
        status,
        paymentInstrument,
        paymentMethod,
        policyBenefitStatuses,
        policyBenefitAmounts,
        bancsPolicyNo,
        nextAnniversaryDate,
        futurePremium,
      }) => {
        const hasPolicyLapsedOverGracePeriod = isPolicyLapseDateOverThanCertainDays(
          policyBenefitStatuses,
          30
        )

        return {
          bancsPolicyNo,
          policyType,
          policyId: subHeadingText,
          policyName: mainHeadingText,
          lifeAssured: policyPersonnel,
          status,
          benefits: policyBenefitAmounts
            .map(
              benefit =>
                `${benefit.benefitName}${
                  policyBenefitAmounts.filter(b => b.benefitName === benefit.benefitName).length >
                    1 && benefit.instanceNo
                    ? ` ${benefit.instanceNo}`
                    : ''
                }`
            )
            .sort(),
          premium: futurePremium
            ? dollarAmountWithCommasAndTwoDecimal(futurePremium)
            : policyPremiumValue,
          outstandingBalance: outstandingBalance && outstandingBalance > 0 ? outstandingBalance : 0,
          nextPremiumDueDate: getPaymentStatus(status, hasPolicyLapsedOverGracePeriod)
            ? policyBenefitStatuses?.[0]?.statusDate
            : nextPremiumDueDate || 'N/A',
          policyPremiumFrequency: getCollectionFrequency(policyPremiumFrequency) as string,
          inValidReason: getInvalidReason(
            status,
            policyStatus,
            checkIfPaymentMethodValid(paymentInstrument, paymentMethod),
            hasPolicyLapsedOverGracePeriod
          ),
          nextAnniversaryDate,
        }
      }
    )
)

const getRules = (state: Store) => state.alterations.rules

export const getAnniversaryCalls = createSelector(
  [getRules, getPolicyPaymentDetails],
  (rules: Rules, policyData: CustomerPolicyPaymentDetail[]) => {
    if (!rules) {
      return []
    }
    const anniversaryPeriods = rules.businessData.policies.reduce(
      (map: { [key: string]: { anniversary: string } }, policy) => {
        map[policy.bancsPolicyNo] = {
          anniversary: policy.assesment.decreaseIncrease_policyAnniversaryPeriod,
        }
        return map
      },
      {}
    )
    return policyData.map(policy => ({
      bancsPolicyNo: policy.bancsPolicyNo,
      anniversary: get(anniversaryPeriods[policy.bancsPolicyNo], 'anniversary'),
      effectiveDate: policy.nextAnniversaryDate,
      isPolicyCancelled:
        policy.inValidReason === POLICY_OUT_OF_FORCE || policy.status === POLICY_LAPSE_STATUS,
    }))
  }
)

const getNabPayment = (state: Store) => state.nabPayment
const getOneTimePaymentForm = (state: Store) => state.forms[FORM_ID]

export const getReceiptDetails = createSelector(
  [getPolicyPaymentDetails, getNabPayment, getOneTimePaymentForm],
  (policies: CustomerPolicyPaymentDetail[], nabPayment: NabPayment, form: FormObject) => {
    const {
      nabData: { txnid, expirydate, pan },
    } = nabPayment
    const {
      fields: {
        amount: { value: amount },
        selectedPolicy: { value: selectedPolicy },
        selectedPolicyBancsPolicyId: { value: selectedPolicyBancsPolicyId },
      },
    } = form
    const panSplit = pan.split('.')
    const cardType = getCreditType(panSplit[0])
    const { policyType, outstandingBalance, status } =
      policies.find(p => p.bancsPolicyNo === selectedPolicy) || {}
    const shouldShowReinstatementMessage =
      !!outstandingBalance &&
      parseFloat(amount) >= outstandingBalance &&
      status === POLICY_LAPSE_STATUS
    const shouldShowPolicyNotFullyPaid =
      !!outstandingBalance &&
      parseFloat(amount) < outstandingBalance &&
      status === POLICY_LAPSE_STATUS
    return {
      policyType,
      txnid,
      expirydate:
        expirydate.length === 3
          ? `0${expirydate.substring(0, 1)}/${expirydate.substring(1)}`
          : `${expirydate.substring(0, 2)}/ ${expirydate.substring(2)}`,
      pan: `XXXX XXXX XXXX X${panSplit[panSplit.length - 1]}`,
      selectedPolicyBancsPolicyId,
      amount,
      date: moment().format('Do MMM YYYY'),
      cardType: cardType.isVisa ? 'VI' : 'MC',
      shouldShowReinstatementMessage,
      shouldShowPolicyNotFullyPaid,
    }
  }
)
