import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, pxToRem, colours, fontSize, fontWeights } = Variables as VariablesType

const anchorStyles = {
  color: colours.mlcAquaAccessible,
  textDecoration: 'none',
}
const styles = {
  backToHome: {
    margin: `${space(5, true)} 0 ${space(5, true)} 0`,
    '> a': {
      ...anchorStyles,
      fontSize: fontSize.xs,
      paddingLeft: 0,
      letterSpacing: 0,
      '& svg': {
        paddingRight: space(2),
      },
    },
  },
  errorMessage: {
    display: 'flex',
    marginBottom: space(5, true),
    '> div': {
      background: colours.functionalDangerLight,
      color: colours.functionalDangerDark,
      padding: space(2),
      fontWeight: fontWeights.semiBold,
      svg: {
        marginRight: space(1.5),
        paddingTop: space(0.5),
      },
    },
  },
  descriptionText: {
    color: colours.tertiaryOne,
    margin: `${space(3, true)} 0 ${space(6, true)}`,
  },
  sidePanelContent: {
    padding: `${space(2, true)} ${space(3, true)}`,
    '& a': {
      ...anchorStyles,
    },
  },
  disclaimer: {
    fontSize: pxToRem(14),
    color: colours.tertiaryTwo,
    margin: `${space(5, true)} 0 ${space(20, true)} 0`,
    '> div:first-of-type': {
      fontWeight: fontWeights.semiBold,
      marginBottom: space(2),
    },
    '& a': {
      ...anchorStyles,
    },
  },
  buttonContainer: {
    display: 'flex',
    marginTop: space(3),
    'a:first-of-type': {
      marginRight: space(2),
      lineHeight: pxToRem(18),
    },
  },
  stepDescription: {
    color: colours.tertiaryOne,
    margin: `${space(3, true)} 0`,
  },
}

export default styles
