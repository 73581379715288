// @flow
import {
  CUSTOMER_POLICY_BENEFICIARY_DETAILS,
  CUSTOMER_POLICY_BENEFICIARY_DETAILS_INIT,
  CUSTOMER_POLICY_BENEFICIARY_DETAILS_SUCCESS,
  CUSTOMER_POLICY_BENEFICIARY_DETAILS_ERROR,
  CUSTOMER_POLICY_BENEFICIARY_DETAILS_FAILURE,
  CUSTOMER_POLICY_BENEFICIARY_DETAILS_DONT_RETRY,
  BENEFICIARY_INFO_MODAL,
  RELATIONSHIPS_UPDATE,
  BENEFICIARY_UPDATE_INIT,
  BENEFICIARY_UPDATE_SUCCESS,
  BENEFICIARY_UPDATE_FAILURE,
} from '../actions/types/customerBeneficiary'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  policies: [],
  isLoading: false,
  isSubmitting: false,
  hasFetchError: false,
  isModalOpen: false,
  relationships: [],
  beneficiariesForFormUpdate: [],
  isPercentageAllocationOpen: false,
  currentBancsPolicyNoForUpdate: null,
  addingNewBeneficiary: false,
  currentProductIdForFileUpload: null,
  currentBancsPolicyNoForFileUpload: null,
  showBeneficiaryForm: false,
  currentDataForInputForBeneficiary: null,
  currentBeneficiariesForInput: null,
  isBeneficiaryFormAdd: null,
  currentBancsPolicyNoForBenForm: null,
  lifeAssuredDetailsForFormEdit: null,
}

const customerBeneficiary = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case 'BENEFICIARY_FORM_UPDATE': {
      const { beneficiaries, bancsPolicyNo, toAdd } = action.payload
      return {
        ...state,
        beneficiariesForFormUpdate: beneficiaries,
        currentBancsPolicyNoForUpdate: bancsPolicyNo,
        addingNewBeneficiary: toAdd,
      }
    }

    case 'SHOW_BENEFICIARY_FORM': {
      if (action.payload.showBeneficiaryForm) {
        const {
          showBeneficiaryForm,
          dataForInput,
          lifeAssured,
          bancsPolicyNo,
          toAdd,
          beneficiaries,
        } = action.payload
        return {
          ...state,
          showBeneficiaryForm,
          currentDataForInputForBeneficiary: dataForInput,
          lifeAssuredDetailsForFormEdit: lifeAssured,
          currentBancsPolicyNoForBenForm: bancsPolicyNo,
          isBeneficiaryFormAdd: toAdd,
          currentBeneficiariesForInput: beneficiaries,
        }
      }
      return {
        ...state,
        showBeneficiaryForm: false,
      }
    }

    case 'FILE_UPLOAD_MODAL_TOGGLE': {
      const { additionalData } = action.payload
      if (!additionalData.length) {
        return state
      }
      const [bancsPolicyNo, productId] = additionalData
      return {
        ...state,
        currentProductIdForFileUpload: productId,
        currentBancsPolicyNoForFileUpload: bancsPolicyNo,
      }
    }

    case 'BENEFICIARY_SHOW_ADJUSTMENT_MODAL': {
      const isPercentageAllocationOpen = action.payload
      return {
        ...state,
        isPercentageAllocationOpen,
      }
    }

    case 'BENEFICIARY_FORM_RESET': {
      return {
        ...state,
        beneficiariesForFormUpdate: [],
        currentBancsPolicyNoForUpdate: null,
        addingNewBeneficiary: false,
      }
    }

    case BENEFICIARY_INFO_MODAL: {
      const { showModal } = action.payload
      return {
        ...state,
        isModalOpen: showModal,
      }
    }

    case CUSTOMER_POLICY_BENEFICIARY_DETAILS: {
      return {
        ...state,
        isSubmitting: false,
        isLoading: true,
        hasFetchError: false,
      }
    }
    case CUSTOMER_POLICY_BENEFICIARY_DETAILS_INIT: {
      return {
        ...state,
        isSubmitting: false,
        isLoading: true,
        hasFetchError: false,
      }
    }
    case CUSTOMER_POLICY_BENEFICIARY_DETAILS_DONT_RETRY: {
      return {
        ...state,
        hasFetchError: false,
      }
    }
    case CUSTOMER_POLICY_BENEFICIARY_DETAILS_SUCCESS: {
      return {
        ...state,
        policies: action.payload.businessData,
        isSubmitting: false,
        isLoading: false,
      }
    }
    case CUSTOMER_POLICY_BENEFICIARY_DETAILS_ERROR:
    case CUSTOMER_POLICY_BENEFICIARY_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasFetchError: true,
      }
    }
    case RELATIONSHIPS_UPDATE:
      return {
        ...state,
        relationships: action.payload.data,
      }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    case BENEFICIARY_UPDATE_INIT: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case BENEFICIARY_UPDATE_FAILURE:
    case BENEFICIARY_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default customerBeneficiary
