// @flow
import get from 'lodash/get'
import {
  OPEN_UW_METHOD_CHANGE_MODAL,
  CLOSE_UW_METHOD_CHANGE_MODAL,
  CHANGE_PERSONAL_STATEMENT_COMPLETION_STATUS,
  RESET_CUSTOMER_MYLINK_AUTH_DETAIL,
} from '../actions/types/myLink'
import {
  MYLINK_OTP_VERIFY_REQUEST_SUCCESS,
  MYLINK_OTP_VERIFY_REQUEST_FAILURE,
  MYLINK_OTP_VERIFY_REQUEST_ERROR,
  MYLINK_OTP_VERIFY_SET_ERROR,
  MYLINK_OTP_SUBMIT_REQUEST_FAILURE,
  MYLINK_OTP_SUBMIT_REQUEST_SUCCESS,
  MYLINK_OTP_RESEND_REQUEST_SUCCESS,
  MYLINK_PRIMARY_AUTH_INIT,
  MYLINK_OTP_VERIFY_REQUEST_INIT,
  MYLINK_OTP_SUBMIT_REQUEST_INIT,
  MYLINK_OTP_RESEND_REQUEST_INIT,
  MYLINK_OTP_RESEND_REQUEST_FAILURE,
  MYLINK_OTP_GRANT_ACCESS,
  MYLINK_OTP_SUBMIT_REQUEST_ERROR,
  MYLINK_PRIMARY_AUTH_SUCCESS,
  MYLINK_PRIMARY_AUTH_ERROR,
  MYLINK_PRIMARY_AUTH_FAILURE,
  MYLINK_OTP_RESEND_REQUEST_ERROR,
} from '../actions/types/okta'
import {
  FETCH_QUOTE_COLLECTION_INIT,
  FETCH_QUOTE_COLLECTION_SUCCESS,
} from '../actions/types/createQuote'
import { INCORRECT_OTP_ERROR_CODE, OTP_ERROR_E0000068 } from '../constants/ure'

export const initialState = {
  showModal: false,
  hasCompleted: false,
  stateToken: '',
  factorId: '',
  authError: '',
  hasAuthenticated: false,
  hasAuthorised: null,
  user: {},
  contactIdentity: '',
  reSendSuccess: false,
  otpExpiresAt: '',
  isQuoteCollectionLoading: false,
  quoteCollectionId: '',
  status: '',
  authLoading: false,
  hasFactorMissing: false,
}

const myLink = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case OPEN_UW_METHOD_CHANGE_MODAL: {
      return {
        ...state,
        showModal: true,
      }
    }
    case CLOSE_UW_METHOD_CHANGE_MODAL: {
      return {
        ...state,
        showModal: false,
      }
    }
    case CHANGE_PERSONAL_STATEMENT_COMPLETION_STATUS: {
      const {
        payload: { hasCompleted, status },
      } = action
      return {
        ...state,
        hasCompleted,
        ...(status && { status }),
      }
    }
    case MYLINK_OTP_VERIFY_REQUEST_SUCCESS: {
      const {
        payload: { status, data, error },
      } = action

      const phoneNumber = get(data, '_embedded.factor.profile.phoneNumber', '')

      if (status === 200 && !error) {
        return {
          ...state,
          stateToken: data.stateToken,
          factorId: get(data, '_embedded.factor.id', ''),
          hasAuthenticated: true,
          user: data.user,
          otpExpiresAt: data.expiresAt,
          contactIdentity: phoneNumber.substr(phoneNumber.length - 3),
          authLoading: false,
        }
      }
      if (error) {
        return {
          ...state,
          authError: error,
          authLoading: false,
        }
      }

      return {
        ...state,
      }
    }
    case MYLINK_OTP_SUBMIT_REQUEST_SUCCESS: {
      const {
        payload: { status, error },
      } = action

      if (status === 200 && !error) {
        return { ...state }
      }
      if (error) {
        return {
          ...state,
          authError: error,
          authLoading: false,
        }
      }
      return {
        ...state,
      }
    }
    case MYLINK_OTP_SUBMIT_REQUEST_ERROR:
    case MYLINK_OTP_SUBMIT_REQUEST_FAILURE:
    case MYLINK_OTP_VERIFY_REQUEST_ERROR:
    case MYLINK_OTP_VERIFY_REQUEST_FAILURE:
    case MYLINK_OTP_RESEND_REQUEST_ERROR:
    case MYLINK_OTP_RESEND_REQUEST_FAILURE: {
      const {
        payload: { data },
      } = action
      return {
        ...state,
        authError:
          data.errorCode === INCORRECT_OTP_ERROR_CODE ? OTP_ERROR_E0000068 : data.errorSummary,
        authLoading: false,
      }
    }
    case MYLINK_OTP_VERIFY_SET_ERROR: {
      const {
        payload: { error },
      } = action

      return {
        ...state,
        authError: error,
        reSendSuccess: false,
        authLoading: false,
      }
    }
    case MYLINK_OTP_RESEND_REQUEST_SUCCESS: {
      return {
        ...state,
        reSendSuccess: true,
      }
    }
    case MYLINK_PRIMARY_AUTH_INIT:
    case MYLINK_OTP_VERIFY_REQUEST_INIT:
    case MYLINK_OTP_SUBMIT_REQUEST_INIT: {
      return {
        ...state,
        ...initialState,
        reSendSuccess: false,
        authError: '',
        authLoading: true,
        hasFactorMissing: false,
      }
    }
    case MYLINK_OTP_RESEND_REQUEST_INIT: {
      return {
        ...state,
        reSendSuccess: false,
        authError: '',
        hasFactorMissing: false,
      }
    }
    case RESET_CUSTOMER_MYLINK_AUTH_DETAIL: {
      return initialState
    }
    case FETCH_QUOTE_COLLECTION_INIT: {
      return {
        ...state,
        isQuoteCollectionLoading: true,
      }
    }
    case FETCH_QUOTE_COLLECTION_SUCCESS: {
      return {
        ...state,
        isQuoteCollectionLoading: false,
      }
    }
    case MYLINK_OTP_GRANT_ACCESS: {
      const { quoteCollectionId } = action.payload
      return {
        ...state,
        hasAuthorised: true,
        quoteCollectionId,
      }
    }
    case MYLINK_PRIMARY_AUTH_SUCCESS: {
      const {
        payload: { data },
      } = action
      const { stateToken, _embedded } = data
      const factorId = _embedded.factors.find(factor => factor.factorType.toLowerCase() === 'sms')

      return {
        ...state,
        stateToken,
        factorId: (factorId && factorId.id) || '',
        otpExpiresAt: data.expiresAt,
      }
    }

    case MYLINK_PRIMARY_AUTH_ERROR:
    case MYLINK_PRIMARY_AUTH_FAILURE: {
      const {
        payload: {
          error,
          data: { hasFactorMissing },
        },
      } = action

      return {
        ...state,
        authError: error,
        authLoading: false,
        hasFactorMissing: hasFactorMissing || false,
      }
    }

    default: {
      return state
    }
  }
}

export default myLink
