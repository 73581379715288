// @flow
import React from 'react'
import CustomerPaymentDetails from '../../../organisms/CustomerPaymentDetails'

type CustomerPaymentProps = {
  fields: Object,
}

const CustomerDetail = ({ fields }: CustomerPaymentProps) => (
  <CustomerPaymentDetails fields={fields} />
)

export default CustomerDetail
