// @flow
import React from 'react'
import { Card, Button, Variables } from '@mlcl-digital/mlcl-design'
import { IconPlus32 } from '../../../atoms/Icons'

// components.
// import { ADD_BENEFICARY } from '../beneficiaries.locators' // FIXME: What is this
const { space } = Variables

type AddBeneficiaryProps = {
  // Add beneficiary label
  addBeneficiaryLabel: string,
  // Add beneficiary button click handler
  handleClick: Function,
}
const AddBeneficiary = ({ addBeneficiaryLabel, handleClick }: AddBeneficiaryProps) => (
  <Card
    styleOverrides={{
      base: {
        marginBottom: space(4),
      },
      body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        '& > *': {
          margin: space(1),
        },
      },
    }}
  >
    <IconPlus32 onClick={handleClick} />
    <Button variant="secondary" size="medium" onClick={handleClick}>
      {addBeneficiaryLabel}
    </Button>
  </Card>
)

export default AddBeneficiary
