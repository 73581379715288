import { space, colours, fontSize, fontWeights, fontFamily } from '../../../../styles'

const styles = {
  confirmChangeBtn: {
    margin: `${space(3, true)} 0`,
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  flexItem: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${space(3, true)} ${space(2, true)} 0 0`,
  },
  beneficiaryName: {
    color: colours.black,
    fontWeight: fontWeights.normal,
    fontSize: fontSize.sm,
  },
  percentageInput: {
    marginTop: `${space(2, true)}`,
  },
  notification: {
    marginTop: space(2, true),
    marginBottom: 0,
  },
  browseLink: {
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontSize.semiBold,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
    padding: space(0.5, true),
  },
}

export default styles
