import { Variables } from '@mlcl-digital/mlcl-design'

const { fontSize, fontWeights, colours, space } = Variables

const styles = {
  declarationModalBody: {
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
  },
  nextButton: {
    background: colours.white,
    width: space(12.5),
    padding: `${space(1, true)} 0`,
    marginTop: space(4),
    color: colours.mediumGreen,
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    border: `2px solid ${colours.mediumGreen}`,
    '&:hover, &:focus': {
      outline: 'none',
    },
  },
  rolloverValidation: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    span: {
      justifyContent: 'normal',
    },
  },
}
export default styles
