import { space, colours, fontSize } from '../../../../styles'

const styles = {
  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
    fontSize: fontSize.xs,
  },
  confirmBenificiaryRemovalBtn: {
    margin: `${space(3, true)} 0`,
    color: colours.red,
    borderColor: colours.red,
    '&:hover, &:focus': {
      color: colours.lightRed,
      borderColor: colours.red,
    },
  },
}

export default styles
