// helpers.
import {
  required,
  numeric,
  cvcNumber,
  numberWithTwoDecimal,
  validateCreditCardNumber,
  validateMasterOrVisaNumber,
  firstNameLastName,
  validateFutureDate,
  isNotFormattedDate,
  optional,
} from '../../../../../utils/formUtils'
import { ONE_OFF_PAYMENT } from '../../../../../constants/payments'

export const FORM_ID = 'oneOffPayment'

export const defaults = (customerEmail, policyId, total, existingCardDetails, bancsPolicyNo) => ({
  transactionType: ONE_OFF_PAYMENT,
  selectedPolicyBancsPolicyId: policyId,
  selectedPolicy: bancsPolicyNo,
  fullName: '',
  amount: total,
  cardNumber: '',
  expiryDate: '',
  cvcNumber: '',
  email: customerEmail,
  existingCardDetails,
})

const schema = (fields, isUsingNewCard) => ({
  transactionType: {
    condition: required,
  },
  selectedPolicy: {
    condition: [{ condition: required }],
  },
  selectedPolicyBancsPolicyId: {
    condition: [{ condition: required }],
  },
  fullName: {
    condition: isUsingNewCard
      ? [{ condition: required }, { condition: firstNameLastName }]
      : {
          condition: optional,
        },
  },
  amount: {
    condition: [{ condition: required }, { condition: numberWithTwoDecimal }],
  },
  cardNumber: {
    condition: isUsingNewCard
      ? [
          {
            condition: required,
            errorMsg: 'Please enter a valid credit card number',
          },
          {
            condition: numeric,
            errorMsg: 'Please enter a valid credit card number',
          },
          {
            condition: validateCreditCardNumber,
            errorMsg: 'Please enter a valid credit card number',
          },
          {
            condition: validateMasterOrVisaNumber,
            errorMsg: 'Please enter a valid credit card number',
          },
        ]
      : {
          condition: optional,
        },
    tooltip: '',
  },
  expiryDate: {
    condition: isUsingNewCard
      ? [
          {
            condition: required,
            errorMsg: 'Please enter a valid expiry date',
          },
          {
            condition: isNotFormattedDate,
            errorMsg: 'Please enter a valid expiry date',
          },
          {
            condition: validateFutureDate(),
            errorMsg: 'Please enter a valid expiry date',
          },
        ]
      : {
          condition: optional,
        },
    tooltip: '',
  },
  cvcNumber: {
    condition: isUsingNewCard
      ? [{ condition: required }, { condition: cvcNumber }]
      : {
          condition: optional,
        },
  },
  email: {
    condition: optional,
  },
  existingCardDetails: {
    condition: required,
  },
  isAuthorisedAccountHolder: {
    condition: required,
  },
})

export default schema
