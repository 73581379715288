// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { objectOf, object, string, shape, func, bool, array, arrayOf } from 'prop-types'
import { PageWrap } from '@mlcl-digital/mlcl-design'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// Selectors
import {
  getActivePolicy,
  getPaymentHistory,
  shouldPaymentDetailsPageShowLoader,
  checkIfPaymentHistoryFailed,
  checkIfEveryPaymentInstrumentFailed,
} from '../../../selectors/customerPaymentDetails'
import { checkIfAllPoliciesLoaded, getCustomerPolicies } from '../../../selectors/common'
import { checkIfEveryPolicyFailed } from '../../../selectors/customerPolicies'
// Atoms
import Header from '../../molecules/PageHeader'
// Molecules
import { CustomerPortalLifeCycleComponent } from '../../molecules/CustomerPortalLifeCycle'

// Child components
import PaymentDetailsComponent from './components/PaymentDetails'
import PaymentHistory from './components/PaymentHistory'
import PolicyPayerModal from './components/modals/PolicyPayerModal'
import PaymentDetailsModal from './components/modals/PaymentDetailsModal'
import DeclarationModal from './components/modals/DeclarationsModal'
import FileUploadModal from './components/modals/FileUploadModal'

// Styles.
import styles from './customerPaymentDetails.styles'

// Helpers.
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'

// Constants
import { THEME_LIGHT } from '../../../constants/themes'
import {
  TOAST_TITLE_CREDITCARD_PAYMENT_ERROR,
  TOAST_TITLE_SAVE_CARD_SUCCESS,
} from '../../../constants/toast'

// Schema
import schema, { FORM_ID } from './components/PaymentDetails/paymentDetails.schema'

const Section = styled('div')(styles.section)
const Wrap = styled(PageWrap)(styles.wrap)

export class CustomerPaymentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editPaymentMethodRequest: {},
      fileUploadMeta: {},
      modals: {
        payer: false,
        payment: false,
        declaration: false,
      },
    }
    this.handleFormModalToggle = this.handleFormModalToggle.bind(this)
  }

  componentWillMount() {
    const {
      actions: { formInit, fetchBatchRelatedParty },
      haveAllPoliciesLoaded,
    } = this.props
    formInit(FORM_ID, schema)
    if (haveAllPoliciesLoaded) {
      fetchBatchRelatedParty('payers')
    }
  }

  componentDidUpdate(prevProps) {
    const { haveAllPoliciesLoaded: prevHaveAllPoliciesLoaded } = prevProps
    const {
      haveAllPoliciesLoaded,
      actions: { fetchBatchRelatedParty },
    } = this.props
    if (prevHaveAllPoliciesLoaded !== haveAllPoliciesLoaded && haveAllPoliciesLoaded) {
      fetchBatchRelatedParty('payers')
    }
  }

  apiCallback = error => {
    const {
      actions: { fetchPolicySupportingData },
      activeBancsPolicyNo,
    } = this.props
    if (error) {
      toast(TOAST_TITLE_CREDITCARD_PAYMENT_ERROR, {
        toastId: TOAST_TITLE_CREDITCARD_PAYMENT_ERROR,
        type: toast.TYPE.ERROR,
      })
    } else {
      toast(TOAST_TITLE_SAVE_CARD_SUCCESS, {
        toastId: TOAST_TITLE_SAVE_CARD_SUCCESS,
        type: toast.TYPE.SUCCESS,
      })
      fetchPolicySupportingData(activeBancsPolicyNo, ['payment-instrument'])
    }
  }

  handleFileModalToggle = (meta = {}) => {
    const {
      actions: { toggleFileUploadModal },
    } = this.props
    const tagEvent = createEvent({
      GA: {
        category: 'Payment detail edit modal',
        action: 'CP - edit payment details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Payment detail edit modal - No',
        },
      },
    })
    tagEvent.end()
    this.setState({ fileUploadMeta: meta }, () => toggleFileUploadModal(true))
  }

  handleFormModalToggle() {
    const { modals } = this.state
    // eslint-disable-next-line prefer-rest-params
    const params = [...arguments]
    params.forEach(modal => {
      const tagEvent = createEvent({
        GA: {
          category: `Policy ${modal} Modal`,
          action: 'Yes',
        },
        Splunk: {
          attributes: {
            'workflow.name': `Policy ${modal} Modal`,
          },
        },
      })
      tagEvent.end()
    })
    const modalsToToggle = params.reduce(
      (state, modal) => ({
        ...state,
        [modal]: !modals[modal],
      }),
      {}
    )
    this.setState({
      modals: {
        ...modals,
        ...modalsToToggle,
      },
    })
  }

  render() {
    const {
      fields,
      fileUploadInfo: { isModalOpen },
      paymentHistory,
      isLoading,
      isPaymentDetailsAPILoading,
      hasPaymentHistoryFailed,
      allPaymentInstrumentsFailed,
      haveAllPolciesFailed,
      fileUploadInfo,
      activeBancsPolicyNo,
    } = this.props
    const { heading, content } = fields
    const hasFileUpdaload = fileUploadInfo.workItemInfo.some(
      item => item.bancsPolicyNo === activeBancsPolicyNo
    )

    const { cpPaymentDetailsBannerImageUrl, cpPaymentDetailsBannerImageAlt } =
      reduceAuthorableFields(fields)

    const {
      modals: { payer, payment, declaration },
      editPaymentMethodRequest,
      fileUploadMeta,
    } = this.state
    return (
      <Fragment>
        <Header
          theme={THEME_LIGHT}
          heading={heading}
          subHeading={content}
          imageSrc={cpPaymentDetailsBannerImageUrl}
          imageAlt={cpPaymentDetailsBannerImageAlt}
        />
        <Wrap>
          <Section>
            <CustomerPortalLifeCycleComponent
              loadingConstraints={[isLoading, isPaymentDetailsAPILoading]}
              failureConstraints={[allPaymentInstrumentsFailed, haveAllPolciesFailed]}
            >
              <PaymentDetailsComponent
                fields={fields}
                handleFileModalToggle={() => this.handleFileModalToggle()}
                handlePayerModalToggle={() => this.handleFormModalToggle('payer')}
              />
              <PaymentHistory
                fields={fields}
                paymentHistory={paymentHistory}
                hasPaymentHistoryFailed={hasPaymentHistoryFailed}
              />
            </CustomerPortalLifeCycleComponent>
          </Section>
        </Wrap>

        {isModalOpen && <FileUploadModal fields={fields} fileUploadMeta={fileUploadMeta} />}
        {payer && (
          <PolicyPayerModal
            fields={fields}
            isModalOpen={payer}
            closeModal={() => this.handleFormModalToggle('payer')}
            handleAction={isPayer => {
              if (isPayer) {
                this.handleFormModalToggle('payer', 'payment')
              } else {
                this.handleFormModalToggle('payer')
                this.handleFileModalToggle()
              }
            }}
          />
        )}
        {payment && (
          <PaymentDetailsModal
            fields={fields}
            isModalOpen={payment}
            closeModal={() => this.handleFormModalToggle('payment')}
            handleFileUploadModalToggle={() => this.handleFileModalToggle()}
            handleAction={meta => {
              this.setState(
                {
                  editPaymentMethodRequest: meta,
                },
                () => this.handleFormModalToggle('payment', 'declaration')
              )
            }}
          />
        )}
        {declaration && (
          <DeclarationModal
            fields={fields}
            isModalOpen={declaration}
            closeModal={() => this.handleFormModalToggle('declaration')}
            callBack={this.apiCallback}
            handleAction={() => {
              this.handleFormModalToggle('declaration')
              if (!hasFileUpdaload) this.handleFileModalToggle(editPaymentMethodRequest)
            }}
          />
        )}
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => {
  const { fileUploadInfo } = state
  return {
    activeBancsPolicyNo: getActivePolicy(state),
    fileUploadInfo,
    customerPolicies: getCustomerPolicies(state),
    paymentHistory: getPaymentHistory(state),
    isLoading: shouldPaymentDetailsPageShowLoader(state),
    haveAllPoliciesLoaded: checkIfAllPoliciesLoaded(state),
    isPaymentDetailsAPILoading: state.paymentUpdateIsLoading,
    hasPaymentHistoryFailed: checkIfPaymentHistoryFailed(state),
    allPaymentInstrumentsFailed: checkIfEveryPaymentInstrumentFailed(state),
    haveAllPolciesFailed: checkIfEveryPolicyFailed(state),
  }
}

CustomerPaymentDetails.defaultProps = {
  activeBancsPolicyNo: null,
  isLoading: false,
}

CustomerPaymentDetails.propTypes = {
  fileUploadInfo: shape({
    fileUploadError: bool,
    files: array,
    workItemInfo: array,
    workItemError: bool,
    isModalOpen: bool,
    disableConfirmButton: bool,
    fileListUploaded: bool,
    isLoading: bool,
  }).isRequired,
  activeBancsPolicyNo: string,
  isLoading: bool,
  fields: objectOf(object).isRequired,
  actions: shape({
    formInit: func,
    toggleFileUploadModal: func,
    fetchPolicySupportingData: func,
  }).isRequired,
  paymentHistory: arrayOf(object).isRequired,
  haveAllPoliciesLoaded: bool.isRequired,
  isPaymentDetailsAPILoading: bool.isRequired,
  hasPaymentHistoryFailed: bool.isRequired,
  allPaymentInstrumentsFailed: bool.isRequired,
  haveAllPolciesFailed: bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentDetails)
