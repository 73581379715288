// @flow

export const initialState = false

const paymentUpdateIsLoading = (
  state: Boolean = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case 'PAYMENT_UPDATE_INIT': {
      return true
    }
    case 'PAYMENT_UPDATE_FAILURE':
    case 'PAYMENT_UPDATE_SUCCESS': {
      return false
    }
    default: {
      return state
    }
  }
}

export default paymentUpdateIsLoading
