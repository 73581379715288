import { Variables } from '@mlcl-digital/mlcl-design'

const { fontSize, fontWeights, colours, space, mediaQueries } = Variables

const styles = {
  paymentType: {
    background: colours.white,
    width: '100%',
    [mediaQueries.sm]: {
      width: '50%',
    },
    nextButton: {
      background: colours.white,
      width: space(12.5),
      padding: `${space(1, true)} 0`,
      marginTop: space(4),
      color: colours.mediumGreen,
      fontSize: fontSize.sm,
      fontWeight: fontWeights.semiBold,
      border: `2px solid ${colours.mediumGreen}`,
      '&:hover, &:focus': {
        outline: 'none',
      },
    },
    spinner: {
      minHeight: 0,
    },
    noteLink: {
      color: colours.mediumGreen,
      textDecoration: 'none',
      marginLeft: space(1),
    },
  },
}
export default styles
