// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Heading, Button, Select, Loader, Input } from '@mlcl-digital/mlcl-design'
import { toast } from 'react-toastify'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../utils/telemetry'
import { actionCreators } from '../../../../actions'

// selectors
import {
  getCurrentContactDetails,
  getContactDetailsFormattedForForm,
  formatBusinessDetailsRequestPayload,
  getClientBusinessDetails,
} from '../../../../selectors/personalDetails'

// utils
import {
  errorCheck,
  validateAll,
  getValue,
  generateFieldsFromData,
} from '../../../../utils/formUtils'
import { getBancsCustomerNumber } from '../../../../utils/cookieUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import history from '../../../../utils/browserHistory'
import AddressLookupComponent from '../../../molecules/AddressLookUp'
import FileUpload from '../../../molecules/FileUpload'
import InputPhone from '../../../molecules/InputPhone'

// constants
import { DOC_TYPE_DOC } from '../../../../constants/documentTypes'
import { WORK_TYPE_CHNGDETAIL } from '../../../../constants/workItems'
import { DASHBOARD_ROUTE } from '../../../../constants/routes'
import {
  TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
  TOAST_ID_UPDATE_CONTACT_ERROR,
  TOAST_ID_UPDATE_CONTACT_SUCCESS,
} from '../../../../constants/toast'
import {
  PREFERRED_METHOD_OF_COMMS,
  PREFERRED_METHOD_OF_COMMS_EVENT_MAP,
} from '../../../../constants/forms'

// styles
import styles from './businessDetails.styles'

// schema.
import SCHEMA, { FORM_ID } from './businessDetails.schema'

// components
const InputComponent = Input

const BusinessDetailsCard = styled('div')(styles.businessDetailsCard)
const Wrapper = styled('div')(styles.wrapper)
const KeyValueContainer = styled('div')(styles.keyValueContainer)
const Key = styled('div')(styles.key)
const Value = styled('div')(styles.value)
export const EditButton = styled(Button)(styles.editButton)
const Form = styled('form')(styles.form)
const FormInput = styled(InputComponent)(styles.input)
const FormAddressLookup = styled(AddressLookupComponent)(styles.input)
const Header = styled('div')(styles.header)
const SelectComponent = styled(Select)(styles.title)
const Spinner = styled(Loader)(styles.spinner)

export const RESIDENTIAL_ADDRESS = ['address', 'houseNo', 'street', 'locality', 'state', 'postCode']

type BusinessDetailsComponentProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // action creators
  actions: Object,
  // file uplaod info
  fileUploadInfo: Object<Object>,
  // form object
  form: Object,
  // isManualResidential
  isManualResidential: Boolean,
  // clientDetails object
  businessDetails: Object,
  // details
  contactDetailsRequestPayload: Object,
  // master list entoty
  businessContactDetails: Object,
  hasCompletedWelcomeJourney: Boolean,
  contactDetailsFormattedForForm: Object,
}

export class BusinessDetailsComponent extends Component<BusinessDetailsComponentProps> {
  schemaWithAuthorableFields = SCHEMA()

  constructor() {
    super()
    this.state = {
      editContactDetails: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.initContactDetailsForm()
  }

  initContactDetailsForm = () => {
    const {
      isManualResidential,
      fields,
      actions: { formInit },
      hasCompletedWelcomeJourney,
      contactDetailsFormattedForForm,
    } = this.props
    const schema = this.schemaWithAuthorableFields({ isManualResidential }, fields)
    formInit(FORM_ID, schema, contactDetailsFormattedForForm)
    if (!hasCompletedWelcomeJourney) {
      this.setState({ editContactDetails: true })
    }
  }

  openEditContactDetails = () => {
    this.initContactDetailsForm()
    this.setState({
      editContactDetails: true,
    })
  }

  closeEditContactDetails = () => {
    this.setState({
      editContactDetails: false,
    })
  }

  cancelEditContactDetails = () => {
    this.initContactDetailsForm()
    this.closeEditContactDetails()
  }

  handleFileUploadModal = () => {
    const {
      actions: { toggleFileUploadModal },
    } = this.props
    toggleFileUploadModal(true)
  }

  addressToggleHandler = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props

    if (isManual) {
      formResetField(FORM_ID, RESIDENTIAL_ADDRESS)
    }
  }

  addressChange = ({ name, data, value }) => {
    const {
      actions: { formUpdateField, formUpdate, formValidate },
      isManualResidential,
      fields,
    } = this.props
    const schema = this.schemaWithAuthorableFields({ isManualResidential }, fields)
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      isManualResidential,
      fields,
    } = this.props
    const schema = this.schemaWithAuthorableFields({ isManualResidential }, fields)
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
    if (name === PREFERRED_METHOD_OF_COMMS) {
      const tagEvent = createEvent({
        GA: {
          category: 'Contact details edit modal',
          action: PREFERRED_METHOD_OF_COMMS_EVENT_MAP[value.value],
        },
        Splunk: {
          attributes: {
            'workflow.name': `Contact details edit modal - ${
              PREFERRED_METHOD_OF_COMMS_EVENT_MAP[value.value]
            }`,
          },
        },
      })
      tagEvent.end()
    }
  }

  onSubmit = () => {
    const {
      actions: { formSubmit, formSubmitComplete, updateContactDetails, formUpdateValidations },
      isManualResidential,
      fields,
      form,
      hasCompletedWelcomeJourney,
      contactDetailsRequestPayload,
    } = this.props
    const schema = this.schemaWithAuthorableFields({ isManualResidential }, fields)
    formUpdateValidations(FORM_ID, schema)

    if (validateAll(schema, form.fields)) {
      if (form.isDirty) {
        formSubmit(FORM_ID, schema, () => {
          this.setState({ isLoading: true })

          updateContactDetails(contactDetailsRequestPayload, (data, err) => {
            this.setState({ isLoading: false })
            if (err) {
              toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
                toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
                type: toast.TYPE.ERROR,
              })
            } else {
              toast(TOAST_ID_UPDATE_CONTACT_SUCCESS, {
                toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
                type: toast.TYPE.SUCCESS,
              })
              this.closeEditContactDetails()
              if (!hasCompletedWelcomeJourney) {
                history.push(DASHBOARD_ROUTE)
              }
            }
          })
        })
        formSubmitComplete(FORM_ID)
      } else if (!hasCompletedWelcomeJourney) {
        history.push(DASHBOARD_ROUTE)
      }
    }
  }

  renderContactDetails = () => {
    const {
      fields: {
        cpCustomerDetailsBusinessContactDetailsMobileNumberHeading,
        cpCustomerDetailsBusinessContactDetailsEmailHeading,
        cpCustomerDetailsBusinessContactDetailsAddressHeading,
        cpCustomerDetailsBusinessContactDetailsEditText,
        customerPreferredMethodOfComms,
      },
      businessContactDetails,
    } = this.props

    const { emailAddress, telePhoneNumber, address, preferredMethodOfComms } =
      businessContactDetails

    return (
      <Fragment>
        <Wrapper>
          <KeyValueContainer>
            <Key>{renderTextField(cpCustomerDetailsBusinessContactDetailsMobileNumberHeading)}</Key>
            <Value>{telePhoneNumber}</Value>
          </KeyValueContainer>
          <KeyValueContainer>
            <Key>{renderTextField(cpCustomerDetailsBusinessContactDetailsEmailHeading)}</Key>
            <Value>{emailAddress}</Value>
          </KeyValueContainer>
          <KeyValueContainer>
            <Key>{renderTextField(cpCustomerDetailsBusinessContactDetailsAddressHeading)}</Key>
            <Value>{address}</Value>
          </KeyValueContainer>
          <KeyValueContainer>
            <Key style={{ whiteSpace: 'nowrap' }}>
              {renderTextField(customerPreferredMethodOfComms)}
            </Key>
            <Value>{preferredMethodOfComms}</Value>
          </KeyValueContainer>
        </Wrapper>
        <EditButton variant="secondary" size="small" onClick={this.openEditContactDetails}>
          {renderTextField(cpCustomerDetailsBusinessContactDetailsEditText)}
        </EditButton>
      </Fragment>
    )
  }

  renderBusinessDetailsForm = () => {
    const { fields, form, isManualResidential, hasCompletedWelcomeJourney } = this.props
    if (!form) return null
    const {
      cpCustomerDetailsBusinessEditContactDetailsEmailLabel,
      cpCustomerDetailsBusinessEditContactDetailsEmailPlaceholder,
      cpCustomerDetailsBusinessContactDetailsAddressHeading,
      ContactDetailsFormResidentialAddressFieldPlaceholder,
      ContactDetailsFormResidentialAddressToggleToAutoText,
      ContactDetailsFormResidentialAddressFieldError,
      ContactDetailsFormResidentialAddressStreetFieldLabel,
      ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
      ContactDetailsFormResidentialAddressHouseNoFieldLabel,
      ContactDetailsFormResidentialAddressHouseNoFieldPlaceholder,
      ContactDetailsFormResidentialAddressLocalityFieldLabel,
      ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
      ContactDetailsFormResidentialAddressStateFieldLabel,
      ContactDetailsFormResidentialAddressStateFieldPlaceholder,
      ContactDetailsFormResidentialAddressCountryFieldLabel,
      ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
      ContactDetailsFormResidentialAddressPostCodeFieldLabel,
      ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
      ContactDetailsCantFindAddressLabel,
      updateCustomerDetailsBusinessDetailsFormConfirm,
      updateCustomerDetailsBusinessDetailsFormCancel,
      customerPreferredMethodOfComms,
    } = reduceAuthorableFields(fields)
    const { phoneCodeLabel } = fields
    const {
      phones,
      emails,
      address,
      addressHouseNo,
      addressStreet,
      addressLocality,
      addressState,
      addressCountry,
      addressPostCode,
      phoneCode,
      preferredMethodOfComms,
    } = form.fields
    const schema = this.schemaWithAuthorableFields({ isManualResidential }, fields)
    const selectPhoneCode = {
      label: phoneCodeLabel,
      value: phoneCode.value,
      name: 'phoneCode',
      id: 'phoneCode',
      changeHandler: this.handleChange,
    }
    const inputPhoneNumber = {
      htmlFor: 'phone',
      type: 'tel',
      label: fields.phoneLabel,
      name: 'phones',
      placeholder: fields.phonePlaceholder,
      changeHandler: this.handleChange,
      error: phones.error.error,
      value: phones.value,
      caption: phones.error.error && fields.phoneRequiredErrorMessage,
    }
    const { isLoading } = this.state
    return (
      <Fragment>
        <Wrapper>
          <Form id={FORM_ID} aria-labelledby="application-overview">
            <InputPhone selectPhoneCode={selectPhoneCode} inputPhoneNumber={inputPhoneNumber} />
            <FormInput
              htmlFor="emails"
              label={cpCustomerDetailsBusinessEditContactDetailsEmailLabel}
              name="emails"
              placeholder={cpCustomerDetailsBusinessEditContactDetailsEmailPlaceholder}
              changeHandler={this.handleChange}
              error={emails.error.error}
              value={emails.value}
              caption={emails.error.error && emails.error.errorMsg}
            />
            <FormAddressLookup
              toggleHandler={this.addressToggleHandler}
              name="businessDetailsAddress"
              auto={{
                label: cpCustomerDetailsBusinessContactDetailsAddressHeading,
                placeholder: ContactDetailsFormResidentialAddressFieldPlaceholder,
                toggleLabel: ContactDetailsFormResidentialAddressToggleToAutoText,
                value: address.value,
                selectChangeHandler: this.addressChange,
                addressError: address.error.error,
                addressErrorMessage:
                  address.error.error && ContactDetailsFormResidentialAddressFieldError,
              }}
              manual={{
                streetLabel: ContactDetailsFormResidentialAddressStreetFieldLabel,
                streetPlaceholder: ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
                streetValue: addressStreet.value,
                streetRequiredErrorMessage: addressStreet.error.errorMsg,
                streetError: addressStreet.error.error,
                streetName: 'addressStreet',
                houseNoName: 'addressHouseNo',
                localityName: 'addressLocality',
                stateName: 'addressState',
                countryName: 'addressCountry',
                postCodeName: 'addressPostCode',
                houseNoLabel: ContactDetailsFormResidentialAddressHouseNoFieldLabel,
                houseNoPlaceholder: ContactDetailsFormResidentialAddressHouseNoFieldPlaceholder,
                houseNoValue: addressHouseNo.value,
                houseNoError: addressHouseNo.error.error,
                houseNoRequiredErrorMessage: addressHouseNo.error.errorMsg,
                localityLabel: ContactDetailsFormResidentialAddressLocalityFieldLabel,
                localityPlaceholder: ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
                localityLabelValue: addressLocality.value,
                localityError: addressLocality.error.error,
                localityRequiredErrorMessage: addressLocality.error.errorMsg,
                stateLabel: ContactDetailsFormResidentialAddressStateFieldLabel,
                statePlaceholder: ContactDetailsFormResidentialAddressStateFieldPlaceholder,
                stateOptions: schema.addressState.options,
                stateValue: addressState.value,
                stateError: addressState.error.error,
                stateRequiredErrorMessage: addressState.error.errorMsg,
                countryLabel: ContactDetailsFormResidentialAddressCountryFieldLabel,
                countryPlaceholder: ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
                countryValue: addressCountry.value,
                countryError: addressCountry.error.error,
                countryRequiredErrorMessage: addressCountry.error.errorMsg,
                postCodeLabel: ContactDetailsFormResidentialAddressPostCodeFieldLabel,
                postCodePlaceholder: ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
                postCodeValue: addressPostCode.value,
                postCodeError: addressPostCode.error.error,
                postCodeRequiredErrorMessage: addressPostCode.error.errorMsg,
                manualToggleLabel: ContactDetailsCantFindAddressLabel,
                inputEntryHandler: this.handleChange,
              }}
            />
            <SelectComponent
              label={customerPreferredMethodOfComms}
              placeholder="None"
              value={preferredMethodOfComms.value}
              name={PREFERRED_METHOD_OF_COMMS}
              id={PREFERRED_METHOD_OF_COMMS}
              changeHandler={this.handleChange}
              options={schema.preferredMethodOfComms.options}
              error={preferredMethodOfComms.error.error}
              caption={
                preferredMethodOfComms.error.error && preferredMethodOfComms.error.errorMsg.value
              }
            />
          </Form>
        </Wrapper>
        <EditButton variant="secondary" size="small" onClick={this.onSubmit}>
          {isLoading ? (
            <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
          ) : (
            updateCustomerDetailsBusinessDetailsFormConfirm
          )}
        </EditButton>
        {hasCompletedWelcomeJourney && (
          <EditButton variant="tertiary" size="small" onClick={this.cancelEditContactDetails}>
            {updateCustomerDetailsBusinessDetailsFormCancel}
          </EditButton>
        )}
      </Fragment>
    )
  }

  renderBusinessDetailsInfo = () => {
    const {
      fields: {
        cpBusinessDetailsCompanyNameHeading,
        cpCompanyDetailsSuperannuationFundHeading,
        cpBusinessDetailsABNHeading,
        cpBusinessDetailsDirectorsHeading,
        cpCustomerDetailsBusinessContactDetailsEditText,
      },
      businessDetails,
    } = this.props
    const { abn, isSMSF, businessName, directors } = businessDetails
    return (
      <Fragment>
        <Wrapper>
          <KeyValueContainer>
            <Key>
              {isSMSF
                ? renderTextField(cpCompanyDetailsSuperannuationFundHeading)
                : renderTextField(cpBusinessDetailsCompanyNameHeading)}
            </Key>
            <Value>{businessName}</Value>
          </KeyValueContainer>
          <KeyValueContainer>
            <Key>{renderTextField(cpBusinessDetailsABNHeading)}</Key>
            <Value>{abn}</Value>
          </KeyValueContainer>
          <KeyValueContainer>
            <Key>{renderTextField(cpBusinessDetailsDirectorsHeading)}</Key>
            {directors && directors.map(director => <Value key={director}>{director}</Value>)}
          </KeyValueContainer>
        </Wrapper>
        <EditButton variant="secondary" size="small" onClick={this.handleFileUploadModal}>
          {renderTextField(cpCustomerDetailsBusinessContactDetailsEditText)}
        </EditButton>
      </Fragment>
    )
  }

  render() {
    const {
      fileUploadInfo: { isModalOpen },
      hasCompletedWelcomeJourney,
      fields,
    } = this.props
    const { editContactDetails } = this.state
    const {
      cpCustomerDetailsBusinessDetailsHeading,
      cpCustomerDetailsBusinessContactDetailsHeading,
      updateCustomerDetailsBusinessDetailsFormName,
      updateCustomerDetailsBusinessDetailsFormPath,
      updateCustomerDetailsBusinessDetailsDocumentCode,
      updateCustomerDetailsBusinessDetailsModalHeading,
      updateCustomerDetailsBusinessDetailsModalSubHeading,
      updateCustomerDetailsBusinessDetailsModalConfirm,
      updateCustomerDetailsBusinessDetailsMaxFileUploadCount,
      updateCustomerDetailsBusinessDetailsMaxFileSize,
      updateCustomerDetailsBusinessDetailsMaxFileSizeError,
      updateCustomerDetailsBusinessDetailsWrongMimeType,
      updateCustomerDetailsBusinessDetailsFileUploading,
      updateCustomerDetailsBusinessDetailsUploadSuccess,
      updateCustomerDetailsBusinessDetailsUploadFailure,
      updateCustomerDetailsBusinessDetailsWorkItemFailure,
      updateCustomerDetailsBusinessDetailsWorkItemSuccess,
      updateCustomerDetailsBusinessDetailsFileRemove,
      updateCustomerDetailsBusinessDetailsNoFileError,
      updateCustomerDetailsBusinessDetailsWrongFileNameError,
    } = reduceAuthorableFields(fields)

    return (
      <BusinessDetailsCard>
        {hasCompletedWelcomeJourney && (
          <Fragment>
            <Header>
              <Heading size="large" variant="h2" styleOverrides={{ marginBottom: '6px' }}>
                {cpCustomerDetailsBusinessDetailsHeading}
              </Heading>
              <p>{renderTextField(fields.customerBusinessDetailsSubtitle)}</p>
            </Header>
            <Heading size="small" variant="h3" styleOverrides={styles.subHeading}>
              {renderTextField(fields.customerBusinessDetailsSubHeading)}
            </Heading>
            {this.renderBusinessDetailsInfo()}
            {isModalOpen && (
              <FileUpload
                formDownloadMeta={{
                  documentName: updateCustomerDetailsBusinessDetailsFormName,
                  documentPath: { value: updateCustomerDetailsBusinessDetailsFormPath },
                  docSubTypeCode: updateCustomerDetailsBusinessDetailsDocumentCode,
                }}
                modalMeta={{
                  modalHeading: updateCustomerDetailsBusinessDetailsModalHeading,
                  modalSubHeading: updateCustomerDetailsBusinessDetailsModalSubHeading,
                  modalConfirmButton: updateCustomerDetailsBusinessDetailsModalConfirm,
                }}
                dropzoneMeta={{
                  maxFileNumber: updateCustomerDetailsBusinessDetailsMaxFileUploadCount,
                  maxFileSize: updateCustomerDetailsBusinessDetailsMaxFileSize,
                }}
                fileUploadMeta={{
                  maxFileSizeError: updateCustomerDetailsBusinessDetailsMaxFileSizeError,
                  wrongFileType: updateCustomerDetailsBusinessDetailsWrongMimeType,
                  fileUploading: updateCustomerDetailsBusinessDetailsFileUploading,
                  fileUploadSuccess: updateCustomerDetailsBusinessDetailsUploadSuccess,
                  fileUploadFailure: updateCustomerDetailsBusinessDetailsUploadFailure,
                  createWorkItemError: updateCustomerDetailsBusinessDetailsWorkItemFailure,
                  createWorkItemSuccess: updateCustomerDetailsBusinessDetailsWorkItemSuccess,
                  fileRemoveButtonLabel: updateCustomerDetailsBusinessDetailsFileRemove,
                  noFileUploadedError: updateCustomerDetailsBusinessDetailsNoFileError,
                  wrongFileNameError: updateCustomerDetailsBusinessDetailsWrongFileNameError,
                }}
                createWorkItemRequest={{
                  workTypeCode: WORK_TYPE_CHNGDETAIL,
                  bancsPolicyNo: getBancsCustomerNumber,
                }}
                uploadDocumentRequest={{
                  docSubTypeCode: updateCustomerDetailsBusinessDetailsDocumentCode,
                  docType: DOC_TYPE_DOC,
                  workType: WORK_TYPE_CHNGDETAIL,
                }}
              />
            )}
          </Fragment>
        )}
        <Header>
          <Heading size="large" variant="h2" styleOverrides={{ marginBottom: '6px' }}>
            {cpCustomerDetailsBusinessContactDetailsHeading}
          </Heading>
          <p>{renderTextField(fields.customerContactDetailsSubtitle)}</p>
        </Header>
        {!editContactDetails && (
          <Fragment>
            <Heading size="small" variant="h3" styleOverrides={styles.subHeading}>
              {renderTextField(fields.customerContactDetailsSubHeading)}
            </Heading>
            {this.renderContactDetails()}
          </Fragment>
        )}
        {editContactDetails && <Fragment>{this.renderBusinessDetailsForm()}</Fragment>}
      </BusinessDetailsCard>
    )
  }
}

export const mapStateToProps = state => {
  const { forms, addressLookup } = state
  return {
    form: forms[FORM_ID],
    isManualResidential:
      addressLookup.businessDetailsAddress && addressLookup.businessDetailsAddress.isManual,
    businessContactDetails: getCurrentContactDetails(state),
    contactDetailsFormattedForForm: getContactDetailsFormattedForForm(state),
    contactDetailsRequestPayload: formatBusinessDetailsRequestPayload(state),
    businessDetails: getClientBusinessDetails(state),
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetailsComponent)
