// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { actionCreators } from '../../../../actions'

// components.
import history from '../../../../utils/browserHistory'
import Checkbox from '../../../atoms/Checkbox'
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { getFullUrePayload } from '../../../../utils/ureUtils'

// data locators
import {
  LOCATOR_DOD_DISCLOSURE,
  LOCATOR_DOD_PRIVACY,
} from '../../../organisms/PersonalStatement/personalStatement.locators'
import PersonalStatementAccordion from '../../../organisms/PersonalStatement/components/Accordion'
import PersonalStatementLayout from '../../../molecules/PersonalStatementLayout'
import PSChangeModalComponent from '../../../organisms/CompletionMethod/components/PSChangeModal'

// style
import styles from '../PersonalStatement/personalStatement.styles'

//  constants
import { NAVIGATION_CUSTOMER_PERSONAL_STATEMENT } from '../../../../constants/navigation'
import {
  CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE,
  CUSTOMER_PERSONAL_STATEMENT_URE_PAGE,
  CUSTOMER_TELE_UNDERWRITER_URE_PAGE,
} from '../../../../constants/personalStatement'
import { URE_BY_ADVISER } from '../../../../constants/application'

// utils
import { getScope } from '../../../../utils/cookieUtils'
import { SCOPE_TYPE_TELE } from '../../../../constants/okta'
import { getUREAssignedParty, handleUREByOtherParty } from '../../../../utils/quoteUtils'
import EnquiryIdExpiredModal from '../../../organisms/EnquiryIdExpiredModal'

const ErrorMessage = styled('p')(styles.errorMessage)
const PrivacyPolicy = styled('div')(styles.policy)

type UnderwritingProps = {
  actions: {
    formInit: Function,
    formUpdateField: Function,
    formValidate: Function,
  },
  fields: Object<Object>,
  isOpenUWChangeModal: Boolean,
  targetedIndex: String,
  createQuote: Object<Object>,
  ure: Object<Object>,
  // config object for all the constant
  config: Object,
}

export class Underwriting extends Component<UnderwritingProps> {
  constructor(props) {
    super(props)

    const isDodCompleted = false

    this.state = {
      isRoleTele: getScope() === SCOPE_TYPE_TELE,
      isDodCompleted,
      showError: false,
      openSections: {
        dod: !isDodCompleted,
      },
      isDisclosureConfirmed: false,
      isPolicyConfirmed: false,
    }
  }

  componentWillMount() {
    const {
      actions: { changeNavigationType, updateDutyOfDisclosureConsents },
      targetedIndex,
    } = this.props
    changeNavigationType(NAVIGATION_CUSTOMER_PERSONAL_STATEMENT)

    updateDutyOfDisclosureConsents({
      isDutyOfDisclosureChecked: false,
      isDutyOfDisclosurePrivacyChecked: false,
      targetedIndex,
    })
  }

  componentDidMount() {
    const {
      actions,
      createQuote,
      ure: { initialData },
    } = this.props
    const { ureInitalData } = actions
    // init ure data
    if (isEmpty(initialData)) ureInitalData()

    // show app has beeen assigned to other party
    handleUREByOtherParty(createQuote)
  }

  componentDidUpdate(prevProps) {
    const {
      ure: { initialData },
      actions: { updateEnquiryId },
    } = this.props

    if (initialData !== prevProps.ure.initialData && !isEmpty(initialData)) {
      updateEnquiryId({
        isPreassessment: false,
        payload: getFullUrePayload(),
      })
    }
  }

  renderErrorMessage = errorMessage => <ErrorMessage>{errorMessage}</ErrorMessage>

  handleToggle = labelKey => openState => {
    this.setState({
      openSections: {
        [labelKey]: openState,
      },
    })
  }

  handleUWCloseModal = () => {
    const {
      actions: { closeUWMethodChangeModal, updateEnquiryId },
    } = this.props

    updateEnquiryId({
      isPreassessment: false,
      payload: getFullUrePayload(),
    })

    closeUWMethodChangeModal()
  }

  changeMethodHandler = () => {
    const {
      actions: { openUWMethodChangeModal },
    } = this.props
    openUWMethodChangeModal()
  }

  handleExpiredEnquiryId = () => {
    const {
      actions: { createEnquiryId, showEnquiryIdExpiredModal },
    } = this.props

    createEnquiryId(() => {}, getFullUrePayload(), false, true)
    showEnquiryIdExpiredModal(false)
  }

  handleConfirmDisclosure() {
    const { isDisclosureConfirmed } = this.state
    this.setState({
      isDisclosureConfirmed: !isDisclosureConfirmed,
    })
  }

  handleConfirmPolicy() {
    const { isPolicyConfirmed } = this.state
    this.setState({
      isPolicyConfirmed: !isPolicyConfirmed,
    })
  }

  handleContinueBtnCP() {
    const {
      actions: { updateDutyOfDisclosureConsents, saveQuote, checkQuoteActionStatus },
      targetedIndex,
      createQuote: { quoteCollectionId },
    } = this.props
    const { isDisclosureConfirmed, isPolicyConfirmed, isRoleTele } = this.state

    if (isDisclosureConfirmed && isPolicyConfirmed) {
      this.setState({ isDodCompleted: true, showError: false })
      updateDutyOfDisclosureConsents({
        isDutyOfDisclosureChecked: true,
        isDutyOfDisclosurePrivacyChecked: true,
        targetedIndex,
      })
      checkQuoteActionStatus(quoteCollectionId, (error, data) => {
        if (!(getUREAssignedParty(data.businessData) === URE_BY_ADVISER)) {
          saveQuote(err => {
            if (!err) {
              if (isRoleTele) {
                history.push(CUSTOMER_TELE_UNDERWRITER_URE_PAGE)
              } else {
                history.push(CUSTOMER_PERSONAL_STATEMENT_URE_PAGE)
              }
            }
          })
        } else {
          history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
        }
      })
    } else {
      this.setState({ isDodCompleted: false, showError: true })
    }
  }

  renderDutyofClosure() {
    const { fields } = this.props
    const {
      isDodCompleted,
      isDisclosureConfirmed,
      isPolicyConfirmed,
      showError,
      openSections: { dod },
    } = this.state

    const {
      personalStatementConfirmDisclosureInsuredLabel,
      personalStatementConfirmPrivacyInsuredLink,
      personalStatementDisclosureContent,
      personalStatementDisclosureHeading,
      personalStatementPrivacyContent,
      personalStatementDoDError,
    } = fields

    return (
      <PersonalStatementAccordion
        checked={isDodCompleted}
        label={renderTextField(personalStatementDisclosureHeading)}
        isOpen={dod}
        fields={fields}
        continueHandler={() => this.handleContinueBtnCP()}
        labelKey="dod"
        disableScrollContent={false}
        toggleListener={this.handleToggle('dod')}
      >
        {renderTextField(personalStatementDisclosureContent, true)}
        <Checkbox
          htmlFor="confirmDisclosure"
          text={renderTextField(personalStatementConfirmDisclosureInsuredLabel, true)}
          name="confirmDisclosure"
          value={0}
          onChangeHandler={() => this.handleConfirmDisclosure()}
          checked={isDisclosureConfirmed}
          dataLocator={LOCATOR_DOD_DISCLOSURE}
        />
        {showError &&
          !isDisclosureConfirmed &&
          this.renderErrorMessage(renderTextField(personalStatementDoDError))}

        <PrivacyPolicy>{renderTextField(personalStatementPrivacyContent, true)}</PrivacyPolicy>

        <Checkbox
          htmlFor="confirmPolicy"
          text={renderTextField(personalStatementConfirmPrivacyInsuredLink, true)}
          name="confirmPolicy"
          onChangeHandler={() => this.handleConfirmPolicy()}
          checked={isPolicyConfirmed}
          dataLocator={LOCATOR_DOD_PRIVACY}
        />
        {showError &&
          !isPolicyConfirmed &&
          this.renderErrorMessage(renderTextField(personalStatementDoDError))}
      </PersonalStatementAccordion>
    )
  }

  render() {
    const { isOpenUWChangeModal, fields, config } = this.props
    const {
      heading,
      content,
      personalStatementEnquiryIdModalTitle,
      personalStatementEnquiryIdModalContent,
      personalStatementEnquiryIdModalButton,
    } = fields

    return (
      <Fragment>
        <PersonalStatementLayout
          fields={fields}
          pageTitle={heading}
          pageSubTitle={content}
          fillChildBackground={false}
          config={config}
        >
          {this.renderDutyofClosure()}
          {isOpenUWChangeModal && (
            <PSChangeModalComponent
              fields={reduceAuthorableFields(fields)}
              handleUWCloseModal={this.handleUWCloseModal}
            />
          )}
          <EnquiryIdExpiredModal
            enquiryIdModalTitle={renderTextField(personalStatementEnquiryIdModalTitle)}
            enquiryIdModalContent={renderTextField(personalStatementEnquiryIdModalContent, true)}
            enquiryIdModalButton={renderTextField(personalStatementEnquiryIdModalButton)}
            callback={this.handleExpiredEnquiryId}
            onClose={this.handleExpiredEnquiryId}
            payload={getFullUrePayload()}
            isPreassessment={false}
          />
        </PersonalStatementLayout>
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ createQuote, myLink: { showModal }, ure, config }) => {
  let targetedQuoteIndex = createQuote.quotes.findIndex(quote => quote.type === 'DRAFT_APPLN')
  targetedQuoteIndex = targetedQuoteIndex === -1 ? createQuote.activeIndex : targetedQuoteIndex
  return {
    createQuote,
    ure,
    targetedIndex: targetedQuoteIndex,
    isOpenUWChangeModal: showModal,
    config,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Underwriting)
