// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { get, capitalize, cloneDeep } from 'lodash'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../utils/telemetry'

// actions
import { actionCreators } from '../../../../actions'

// components.
import Layout from '../../../molecules/PersonalStatementLayout'
import CheckboxGroupComponent from '../../../molecules/CheckboxGroup'
import ToggleGroup from '../../../molecules/ToggleGroup'
import Button from '../../../atoms/Button'
import Select from '../../../atoms/Select'
import Heading from '../../../atoms/Heading'

// schema.
import SCHEMA, {
  FORM_ID,
  completionMethodOptions,
  initialContactOptions,
  selectTimeOptions,
} from './personalStatementMethod.schema'
// utils.
import { renderTextField } from '../../../../utils/sitecoreUtils'

import { BIG_T_BOOK, BIG_T_INTERVIEW } from '../../../../constants/workItems'
// styles.
import styles from './personalStatementMethod.styles'
import { NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION } from '../../../../constants/navigation'
import history from '../../../../utils/browserHistory'

// constants
import { TELE, WIP_MYLINK, MYLINK, URE_BY_ADVISER } from '../../../../constants/application'
import { QUOTE_STATUS_APPLICATION_STAGE } from '../../../../constants/policies'
import {
  CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE,
  CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE,
} from '../../../../constants/personalStatement'

import {
  getUWMethodFromQuote,
  isTeleUnderwriter,
  handleUREByOtherParty,
  getUREAssignedParty,
} from '../../../../utils/quoteUtils'
import { isFeatureEnabledForCP } from '../../../../utils/commonUtils'

const ContactRadioGroup = styled.div(({ isSingleCard }) => styles.contactRadioGroup(isSingleCard))
const ContactWrapper = styled.div(styles.contactWrapper)
const SelectWrap = styled(Select)(styles.select)
const InitialContactDayWrap = styled('div')(styles.initialContactDaysWrap)
const InitialContactTimeWrap = styled('div')(styles.intialContatcTimeWrap)
const FormCaption = styled(Heading)(styles.caption)
const Message = styled('p')(styles.message)
const DisclaimerText = styled('p')(styles.disclaimerText)
const ErrorMessage = styled('p')(styles.errorMessage)

type PSMethodProps = {
  // Redux actions
  actions: {
    formInit: Function,
    formUpdateField: Function,
    formValidate: Function,
    changeNavigationType: Function,
    saveQuote: Function,
    updateWorkTypeHistory: Function,
    updateQuoteStatus: Function,
    getMasterList: Function,
  },
  // Redux form
  form: object,
  // Sitecore fields
  fields: object,
  createQuote: object,
  // config object for all constants
  config: Object,
  featureControlSwitch: Array<Object>,
}

export class PSMethod extends Component<PSMethodProps> {
  state = {
    showTeleError: false,
  }

  componentWillMount() {
    const {
      actions: { formInit, getMasterList },
      createQuote,
    } = this.props

    const { activeIndex, quotes } = createQuote
    const method = get(quotes, `[${activeIndex}].underwritingDetails.underwritingMethod`, '')
    const completionMethodValue = method === TELE ? BIG_T_BOOK : BIG_T_INTERVIEW
    const workTypeHistory =
      get(createQuote, 'workTypeHistory', []).find(work => work.type === completionMethodValue) ||
      {}
    const { preferredCallAfterTime, preferredCallBeforeTime, preferredDays } = workTypeHistory
    const data = {
      completionMethod: {
        value: completionMethodValue,
      },
      ...(method === TELE && {
        preferredCallAfterTime: {
          value: preferredCallAfterTime,
        },
        preferredCallBeforeTime: {
          value: preferredCallBeforeTime,
        },
        preferredDays: {
          value: preferredDays,
        },
      }),
    }
    formInit(FORM_ID, SCHEMA, {}, data)
    getMasterList()
  }

  componentDidMount() {
    const {
      actions: { changeNavigationType },
      createQuote,
    } = this.props
    changeNavigationType(NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION)
    // show app has beeen assigned to adviser if it is already with adviser
    if (getUREAssignedParty(createQuote) === URE_BY_ADVISER) {
      handleUREByOtherParty(createQuote)
    }
  }

  // eslint-disable-next-line react/sort-comp
  changeHandler = ({ name, value }) => {
    const { actions } = this.props
    const { formUpdateField, formValidate, formRemoveFields } = actions
    this.removeTeleError()

    formUpdateField(FORM_ID, name, {
      value,
    })
    if (value === BIG_T_BOOK) {
      formUpdateField(FORM_ID, 'preferredDays', {
        value: [],
      })
      formUpdateField(FORM_ID, 'preferredCallAfterTime', {
        value: '',
      })
      formUpdateField(FORM_ID, 'preferredCallBeforeTime', {
        value: '',
      })
      const tagEvent = createEvent({
        GA: {
          category: 'Customer chooses method',
          action: 'Customer selects tele',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Customer chooses method - Customer selects tele',
          },
        },
      })
      tagEvent.end()
    } else {
      const tagEvent = createEvent({
        GA: {
          category: 'Customer chooses method',
          action: 'Customer selects online',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Customer chooses method - Customer selects online',
          },
        },
      })
      tagEvent.end()
      formRemoveFields(FORM_ID, 'preferredDays')
      formRemoveFields(FORM_ID, 'preferredCallAfterTime')
      formRemoveFields(FORM_ID, 'preferredCallBeforeTime')
    }

    formValidate(FORM_ID, SCHEMA)
  }

  daysChangeHandler = ({ name, value }) => {
    const { actions, form } = this.props
    const { formUpdateField, formValidate } = actions

    let preferredDays = [...get(form, 'fields.preferredDays.value', [])]

    if (value && preferredDays.indexOf(capitalize(name)) === -1) {
      preferredDays.push(capitalize(name))
    } else if (!value && preferredDays.indexOf(capitalize(name)) !== -1) {
      preferredDays = preferredDays.filter(elem => elem !== capitalize(name))
    }

    formUpdateField(FORM_ID, 'preferredDays', {
      value: preferredDays,
    })
    formValidate(FORM_ID, SCHEMA)
  }

  // eslint-disable-next-line no-unused-vars
  timeChangeHandler = ({ name, value }) => {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions

    const { callAfterTime, callBeforeTime } = value

    formUpdateField(FORM_ID, 'preferredCallAfterTime', {
      value: callAfterTime,
    })
    formUpdateField(FORM_ID, 'preferredCallBeforeTime', {
      value: callBeforeTime,
    })
    formValidate(FORM_ID, SCHEMA)
  }

  initStatus = () => {
    const { createQuote } = this.props
    const method = getUWMethodFromQuote(createQuote)

    if (isTeleUnderwriter(method)) {
      history.push(CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE)
    } else {
      history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
    }
  }

  pushFormUpdateToRedux = data => {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions
    formUpdateField(FORM_ID, 'preferredDays', { value: data })
    formValidate(FORM_ID, SCHEMA)
  }

  displayTeleError() {
    this.setState(() => ({
      showTeleError: true,
    }))
  }

  removeTeleError() {
    this.setState(() => ({
      showTeleError: false,
    }))
  }

  getTeleDetail() {
    const { form } = this.props
    const callBeforeTime = get(form, 'fields.preferredCallBeforeTime.value', '')
    const callAfterTime = get(form, 'fields.preferredCallAfterTime.value', '')
    const selectedDay = get(form, 'fields.preferredDays.value', [])

    let selectedTime = ''
    if (callBeforeTime && callAfterTime) {
      selectedTime = selectTimeOptions.find(
        slot => slot.callAfterTime === callAfterTime && slot.callBeforeTime === callBeforeTime
      ).value
    }

    return [selectedDay, selectedTime]
  }

  handleSubmit = () => {
    const {
      actions: { checkQuoteActionStatus },
      createQuote: { quoteCollectionId },
    } = this.props

    checkQuoteActionStatus(quoteCollectionId, (error, data) => {
      if (!(getUREAssignedParty(data.businessData) === URE_BY_ADVISER)) {
        this.submitMethod()
      } else {
        history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
      }
    })
  }

  submitMethod = () => {
    const {
      form,
      createQuote,
      actions: { saveQuote, updateWorkTypeHistory, updateQuoteStatus },
      config,
    } = this.props
    const completionMethod = get(form, 'fields.completionMethod.value', '')
    if (completionMethod === BIG_T_BOOK) {
      // save data to quote entity
      const [selectedDay, selectedTime: {}] = this.getTeleDetail()

      if (selectedDay.length && selectedTime.length) {
        this.removeTeleError()
        const quoteStatus = {
          actionName: TELE,
          quoteType: QUOTE_STATUS_APPLICATION_STAGE,
          underwritingMethod: TELE,
        }
        updateQuoteStatus(quoteStatus)
        updateWorkTypeHistory({
          type: BIG_T_BOOK,
          preferredDays: selectedDay,
          preferredCallAfterTime: get(form, 'fields.preferredCallAfterTime.value'),
          preferredCallBeforeTime: get(form, 'fields.preferredCallBeforeTime.value'),
          teleUWURL: `${config.MLCL_TELE_OKTA_REDIRECT_URI}?quoteCollectionId=${createQuote.quoteCollectionId}`,
        })
        saveQuote()
        this.initStatus()
        this.getDaysOptionData()
      } else {
        this.displayTeleError()
      }
    } else {
      const quoteStatus = {
        actionName: WIP_MYLINK,
        quoteType: QUOTE_STATUS_APPLICATION_STAGE,
        underwritingMethod: MYLINK,
      }
      updateQuoteStatus(quoteStatus)
      this.initStatus()
      saveQuote()
    }
  }

  getDaysOptionData = () => {
    const [selectedDay] = this.getTeleDetail()
    if (selectedDay.length > 4) {
      return initialContactOptions
    }
    const options = cloneDeep(initialContactOptions)
    options.map(opt => {
      selectedDay.forEach(day => {
        if (opt.label === day) {
          // eslint-disable-next-line no-param-reassign
          opt.checked = true
        }
      })

      return opt
    })
    return options
  }

  renderOnlineOptions() {
    const {
      fields: {
        personalStatementMethodOnlineDisplayText,
        personalStatementMethodOnlineDetailText,
        personalStatementMethodOnlineDisclaimerText,
      },
    } = this.props
    return (
      <Fragment>
        <FormCaption size={3}>
          {renderTextField(personalStatementMethodOnlineDisplayText)}
        </FormCaption>
        {renderTextField(personalStatementMethodOnlineDetailText, true)}
        <DisclaimerText>
          {renderTextField(personalStatementMethodOnlineDisclaimerText, true)}
        </DisclaimerText>
      </Fragment>
    )
  }

  renderTeleoptions() {
    const {
      fields: {
        initialContactHeading,
        initialContactTimeHeading,
        selectAll,
        selectTime: selectTimePlaceholder,
        selectDayError,
        selectTimeError,
      },
    } = this.props

    const { showTeleError } = this.state
    const [selectedDay, selectedTime] = this.getTeleDetail()

    return (
      <Fragment>
        <InitialContactDayWrap>
          <FormCaption size={3}>{renderTextField(initialContactHeading, true)}</FormCaption>
          <CheckboxGroupComponent
            optionsData={this.getDaysOptionData()}
            handleCheckBoxChange={this.daysChangeHandler}
            formId={FORM_ID}
            updateReduxForm={this.pushFormUpdateToRedux}
            hasSelectAll
            selectAllLabel={selectAll && selectAll.value}
            hasSelectedAll={selectedDay.length > 4}
          />
          {showTeleError && !selectedDay.length && (
            <ErrorMessage>{renderTextField(selectDayError)}</ErrorMessage>
          )}
        </InitialContactDayWrap>

        <InitialContactTimeWrap>
          <FormCaption size={3}>{renderTextField(initialContactTimeHeading, true)}</FormCaption>
          <SelectWrap
            value={selectedTime}
            name="selectTime"
            id="selectTime"
            changeHandler={this.timeChangeHandler}
            options={selectTimeOptions}
            placeholder={selectTimePlaceholder && selectTimePlaceholder.value}
          />
          {showTeleError && !selectedTime && (
            <ErrorMessage>{renderTextField(selectTimeError)}</ErrorMessage>
          )}
        </InitialContactTimeWrap>
      </Fragment>
    )
  }

  renderOptions() {
    const { form } = this.props
    const completionMethod = get(form, 'fields.completionMethod.value', '')

    switch (completionMethod) {
      case BIG_T_BOOK: {
        return this.renderTeleoptions()
      }
      case BIG_T_INTERVIEW: {
        return this.renderOnlineOptions()
      }
      default: {
        return null
      }
    }
  }

  render() {
    const {
      form,
      fields: { methodCompletionHeading, methodCompletionSubHeading, confirmButton, ...restFields },
      featureControlSwitch,
    } = this.props
    if (!form) return null

    const completionMethod = get(form, 'fields.completionMethod.value', '')
    const isOverthePhoneEnabled = isFeatureEnabledForCP(
      featureControlSwitch,
      'enableTeleUnderwritingForCustomerPersonalStatement'
    )

    const availableOptions = isOverthePhoneEnabled
      ? completionMethodOptions(restFields)
      : completionMethodOptions(restFields).filter(field => field.value === BIG_T_INTERVIEW)

    return (
      <Layout fields={restFields}>
        <ContactWrapper>
          <FormCaption size={3}>{renderTextField(methodCompletionHeading, true)}</FormCaption>
          <Message>{renderTextField(methodCompletionSubHeading, true)}</Message>

          <ContactRadioGroup isSingleCard={!isOverthePhoneEnabled}>
            <ToggleGroup
              htmlFor="completionMethod"
              label=""
              name="completionMethod"
              labelledby="completionMethod"
              className="itemWidth"
              options={availableOptions}
              handleChange={this.changeHandler}
              value={completionMethod}
              variant="mobileFullWidth"
            />
          </ContactRadioGroup>
        </ContactWrapper>

        {this.renderOptions()}

        {completionMethod && (
          <div>
            <Button onClick={this.handleSubmit}>{renderTextField(confirmButton)}</Button>
          </div>
        )}
      </Layout>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms, createQuote, config, masterList }) => ({
  form: forms[FORM_ID],
  createQuote,
  config,
  featureControlSwitch: get(masterList, 'data.featureControlSwitch', []),
})

export default connect(mapStateToProps, mapDispatchToProps)(PSMethod)
