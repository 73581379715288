import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bool, func, objectOf, object } from 'prop-types'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../../../utils/telemetry'

// Redux
import { actionCreators } from '../../../../../../actions'

// Selectors
import {
  getPaymentDetailFormType,
  getActivePolicy,
  getUpdatedPaymentInstrument,
} from '../../../../../../selectors/customerPaymentDetails'

// Utils
import { reduceAuthorableFields, renderTextField } from '../../../../../../utils/sitecoreUtils'

// Constants
import {
  CC,
  DD,
  ROLLOVER_FROM_EXTERNAL_FUND,
  ROLLOVER_DISCLAIEMER_NAME,
} from '../../../../../../constants/customerPaymentDetails'
import {
  MASTER_KEY_ACCOUNT_ID,
  WRAPPER_NAVIGATOR_ID,
  ROLL_OVER_ID,
} from '../../../../../../constants/policies'

import Checkbox from '../../../../../atoms/Checkbox'
import Modal from '../../../../../molecules/Modal'
import styles from './declarationsModal.styles'

const DeclarationModalBody = styled('div')(styles.declarationModalBody)
const NextButton = styled(Button)(styles.nextButton)
const RolloverValidation = styled(Checkbox)(styles.rolloverValidation)

const getDeclarationCopy = fields => {
  const {
    cpEditPolicyPaymentDetailRolloverDeclarationTitle,
    cpEditPolicyPaymentDetailRolloverDeclaration,
    cpEditPolicyPaymentDetailCreditCardDeclarationTitle,
    cpEditPolicyPaymentDetailCreditCardDeclaration,
    cpEditPolicyPaymentDetailDirectDebitDeclarationTitle,
    cpEditPolicyPaymentDetailDirectDebitDeclaration,
    cpEditPolicyPaymentDetailMasterKeyDeclarationTitle,
    cpEditPolicyPaymentDetailMasterKeyDeclaration,
    cpEditPolicyPaymentDetailNavigatorDeclarationTitle,
    cpEditPolicyPaymentDetailNavigatorDeclaration,
  } = reduceAuthorableFields(fields)

  const declarationCopyMap = {
    [CC]: {
      modalTitle: cpEditPolicyPaymentDetailCreditCardDeclarationTitle,
      modalBodyText: cpEditPolicyPaymentDetailCreditCardDeclaration,
    },
    [DD]: {
      modalTitle: cpEditPolicyPaymentDetailDirectDebitDeclarationTitle,
      modalBodyText: cpEditPolicyPaymentDetailDirectDebitDeclaration,
    },
    [ROLLOVER_FROM_EXTERNAL_FUND]: {
      modalTitle: cpEditPolicyPaymentDetailRolloverDeclarationTitle,
      modalBodyText: cpEditPolicyPaymentDetailRolloverDeclaration,
    },
    [MASTER_KEY_ACCOUNT_ID]: {
      modalTitle: cpEditPolicyPaymentDetailMasterKeyDeclarationTitle,
      modalBodyText: cpEditPolicyPaymentDetailMasterKeyDeclaration,
    },
    [WRAPPER_NAVIGATOR_ID]: {
      modalTitle: cpEditPolicyPaymentDetailNavigatorDeclarationTitle,
      modalBodyText: cpEditPolicyPaymentDetailNavigatorDeclaration,
    },
  }
  return declarationCopyMap
}

const DeclarationsModal = ({ isModalOpen, closeModal, fields, callBack, handleAction }) => {
  // Redux
  const dispatch = useDispatch()
  const formType = useSelector(getPaymentDetailFormType)
  const bancsPolicyNo = useSelector(getActivePolicy)
  const updatedPaymentInstrument = useSelector(getUpdatedPaymentInstrument)

  const [isCheckbox, setCheckbox] = useState(false)
  const [isValidated, setValidated] = useState(false)

  const {
    cpEditPolicyPaymentDetailModalConfirm,
    cpEditPolicyPaymentDetailRolloverAuthorityDisclaimerErrorMsg,
  } = reduceAuthorableFields(fields)

  const copyMap = getDeclarationCopy(fields)
  const { modalTitle, modalBodyText } = copyMap[formType] || {}

  const editPaymentMethod = useCallback(() => {
    const { updatePaymentInstructions } = actionCreators
    const tagEvent = createEvent({
      GA: {
        category: 'Payment detail edit modal',
        action: 'CP - update payment details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Payment detail edit modal - Confirm',
        },
      },
    })
    tagEvent.end()

    closeModal()
    if (bancsPolicyNo) {
      dispatch(updatePaymentInstructions(updatedPaymentInstrument, bancsPolicyNo, callBack))
    }
  }, [updatedPaymentInstrument, bancsPolicyNo])

  const handleIsRolloverDeclarationAccepted = useCallback(
    (isCheckRequired = false) => {
      const tagEvent = createEvent({
        GA: {
          category: 'Payment detail edit modal',
          action: 'CP - edit payment details confirm',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Payment detail edit modal - Confirm',
          },
        },
      })
      tagEvent.end()

      if (isCheckbox || isCheckRequired) {
        closeModal()
        handleAction()
      }
      setValidated(true)
    },
    [isCheckbox]
  )

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} title={modalTitle}>
      <DeclarationModalBody dangerouslySetInnerHTML={{ __html: modalBodyText }} />
      {formType === CC || formType === DD ? (
        <NextButton variant="secondary" onClick={() => editPaymentMethod()}>
          {cpEditPolicyPaymentDetailModalConfirm}
        </NextButton>
      ) : (
        <div>
          {formType === ROLL_OVER_ID ? (
            <div>
              <RolloverValidation
                text={renderTextField(
                  fields.cpEditPolicyPaymentDetailRolloverAuthorityDisclaimer,
                  true
                )}
                onChangeHandler={() => setCheckbox(!isCheckbox)}
                checked={isCheckbox}
                error={isValidated || !isCheckbox}
                caption={
                  !isCheckbox &&
                  isValidated &&
                  cpEditPolicyPaymentDetailRolloverAuthorityDisclaimerErrorMsg
                }
                htmlFor={ROLLOVER_DISCLAIEMER_NAME}
                name={ROLLOVER_DISCLAIEMER_NAME}
              />
              <NextButton variant="secondary" onClick={handleIsRolloverDeclarationAccepted}>
                {cpEditPolicyPaymentDetailModalConfirm}
              </NextButton>
            </div>
          ) : (
            <NextButton
              variant="secondary"
              onClick={() => handleIsRolloverDeclarationAccepted(true)}
            >
              {cpEditPolicyPaymentDetailModalConfirm}
            </NextButton>
          )}
        </div>
      )}
    </Modal>
  )
}

DeclarationsModal.propTypes = {
  isModalOpen: func.isRequired,
  closeModal: bool.isRequired,
  fields: objectOf(object).isRequired,
  callBack: func.isRequired,
  handleAction: func.isRequired,
}

export default DeclarationsModal
