import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { fontSize, fontWeights, space, colours, fontFamily } = Variables as VariablesType

const styles = {
  policyContainer: {
    marginBottom: space(6),
    border: `1px solid ${colours.tertiaryFour}`,
  },
  coverSection: ({ isPolicyAltered }: { isPolicyAltered?: boolean }) => ({
    display: 'flex',
    '> div': {
      flexBasis: isPolicyAltered ? '100%' : '50%',
      padding: space(3),
      '&:last-of-type': {
        borderLeft: `1px solid ${colours.tertiaryFour}`,
      },
      h3: {
        fontFamily: fontFamily.sourceSans,
        marginBottom: space(3),
      },
    },
  }),
  policySection: {
    background: colours.tertiaryFive,
    color: colours.tertiaryOne,
    display: 'flex',
    '> div': {
      flexBasis: '100%',
      padding: space(3),
      '&:last-of-type': {
        borderLeft: `1px solid ${colours.tertiaryFour}`,
      },
      dd: {
        fontSize: fontSize.sm,
        fontWeight: fontWeights.bold,
        marginBottom: space(3),
        display: 'flex',
        alignItems: 'center',
        span: {
          marginRight: space(1),
        },
        '&:last-of-type': {
          fontSize: fontSize.md,
          marginBottom: space(1),
        },
      },
    },
  },
  premiumSaved: {
    background: colours.mlcAquaAccessible,
    color: colours.tertiarySix,
    padding: space(3),
    '& >div:last-of-type': {
      fontSize: fontSize.md,
    },
  },
  tooltipContainer: {
    display: 'inline-block',
    marginLeft: space(0.5),
    '> div': {
      maxWidth: space(32, true),
    },
  },
  heading: {
    padding: space(3),
    border: `1px solid ${colours.tertiaryFour}`,
    h3: {
      display: 'flex',
      fontFamily: fontFamily.sourceSans,
      borderTop: 0,
      marginBottom: 0,
      div: {
        fontWeight: fontWeights.light,
        margin: `${space(0.5, true)} ${space(1, true)}`,
        alignItems: 'center',
      },
    },
  },
  paymentDetails: {
    marginRight: space(5),
    span: {
      color: colours.tertiaryOne,
      '&:first-of-type': {
        fontWeight: fontWeights.semiBold,
        marginRight: space(1, true),
      },
    },
  },
  paymentFrequency: {
    padding: space(3),
    borderBottom: `1px solid ${colours.tertiaryFour}`,
    span: {
      color: colours.tertiaryOne,
      '&:first-of-type': {
        marginRight: space(1, true),
        fontWeight: fontWeights.semiBold,
      },
    },
  },
  noAdjustmentContainer: {
    padding: space(2),
    fontWeight: fontWeights.semiBold,
    svg: {
      marginRight: space(1),
    },
    color: colours.functionalWarningDark,
    background: colours.functionalWarningLight,
  },
  errorMessage: {
    display: 'flex',
    background: colours.functionalDangerLight,
    color: colours.functionalDangerDark,
    padding: space(2),
    fontWeight: fontWeights.semiBold,
    svg: {
      marginRight: space(1.5),
      paddingTop: space(0.5),
    },
  },
  subHeading: {
    display: 'flex',
    marginTop: space(1.5),
  },
  waiverWrapper: {
    display: 'flex',
    flex: '0 0 32%',
    p: {
      color: colours.tertiaryOne,
      fontWeight: fontWeights.semiBold,
      margin: `0 ${space(1, true)} 0 0}`,
    },
  },
  radioGroup: {
    base: {
      margin: 0,
      '> div': {
        paddingRight: space(1),
      },
    },
  },
}

export default styles
