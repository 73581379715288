import 'core-js/es7/array'
import { createSelector } from 'reselect'
// @ts-expect-error file not in typescript
import { checkIfValidBenefitStatusCode } from '../utils/policyUtils'
import {
  getMasterList,
  getInforceCustomerPolicies,
  getCustomerPolicies,
  checkIfAllPoliciesLoaded,
  checkIfAllRelationshipsHaveRelatedParty,
} from './common'

// types
import { Store } from '../types/store'
import { CustomerPolicy } from '../types/ClientPolicies'
import { CustomerRelationship, FormattedCustomerRelationship } from '../types/customerRelationship'

const getCustomerRelationships = (state: Store) => Object.values(state.customerRelationships)

export const getCustomerBenefits = createSelector(
  [getMasterList, getInforceCustomerPolicies, getCustomerRelationships],
  (
    { benefitDescriptions, benefitStatus },
    customerPolicies: CustomerPolicy[],
    customerRelationships
  ) => {
    const benefits = customerPolicies.flatMap(policy => {
      const validBenefits = policy.benefits.filter(benefit =>
        checkIfValidBenefitStatusCode(benefit.status, benefitStatus)
      )
      return validBenefits.map(benefit => ({
        ...benefit,
        policyId: policy.policyId,
        description: benefitDescriptions.find(benefitDesc => benefitDesc.id === benefit.type),
        benefitAssured: benefit.benefitAssured.map(BA => {
          const lifeAssured = customerRelationships.find(LA => BA === LA.bancsCustomerNo)
          return lifeAssured ? `${lifeAssured.firstName} ${lifeAssured.lastName}` : ''
        }),
      }))
    })
    return benefits
  }
)

const rolesMap: Record<string, string> = {
  SA: 'Agent',
  LA: 'Life Assured',
  PYR: 'Payer',
  OWR: 'Policy Owner',
  IET: 'IET',
  LB: 'Life beneficiary',
}

/**
 * This selector needs to be used only to create a v1 version of PolicyEntity
 * Currently being used only for Digital Alterations flow
 */
export const getPolicies = createSelector(
  [
    checkIfAllPoliciesLoaded,
    getCustomerPolicies,
    checkIfAllRelationshipsHaveRelatedParty,
    getCustomerRelationships,
  ],
  (
    haveAllPoliciesLoaded,
    policyStore: CustomerPolicy[],
    allRelationshipsHaveRelatedParty,
    customerRelationshipsStore
  ) =>
    haveAllPoliciesLoaded && allRelationshipsHaveRelatedParty
      ? Object.values(policyStore).map(policy => {
          const relationshipsBancsCustomerNos: string[] = [
            ...new Set([
              ...(policy.lifeAssured || []),
              ...(policy.payers || []),
              ...(policy.policyOwners || []),
              ...(policy.agents || []),
              ...(policy.iet || []),
              ...(policy.beneficiaries || []),
            ]),
          ]
          const relationshipsInPolicy: CustomerRelationship[] = customerRelationshipsStore.filter(
            relationship => relationshipsBancsCustomerNos.includes(relationship.bancsCustomerNo)
          )
          const relationships = relationshipsInPolicy.reduce(
            (formattedArray: FormattedCustomerRelationship[], relationship) => {
              const policyObject = relationship.policyRolesMap[policy.policyNo] || {}
              const array = [...new Set(policyObject.roles)].map(role => ({
                bancsCustomerNo: relationship.bancsCustomerNo,
                expiryDate: policyObject?.policyMeta?.expiryDate,
                preferredTelephoneRef: policyObject?.policyMeta?.preferredTelephoneRef,
                startDate: policyObject?.policyMeta?.startDate,
                status: policyObject?.policyMeta?.status,
                policyState: policyObject?.policyMeta?.policyState,
                role: rolesMap[role],
                roleCode: role,
                ...(policyObject.policyMeta.isPrimaryLifeAssured && {
                  isPrimaryLifeAssured: policyObject.policyMeta.isPrimaryLifeAssured,
                }),
                ...(policyObject.policyMeta.isPrimaryPolicyOwner && {
                  isPrimaryPolicyOwner: policyObject.policyMeta.isPrimaryPolicyOwner,
                }),
                relatedParty: {
                  identifiers: [
                    { type: 'BANCS_CUSTOMER_NO', value: relationship.bancsCustomerNo },
                    { type: 'PARTY_NO', value: relationship.partyNo },
                  ],
                  contactMethods: relationship.contactMethods,
                  title: relationship.title,
                  firstName: relationship.firstName,
                  lastName: relationship.lastName,
                  middleName: relationship.middleName,
                  isSmoker: relationship.smokerStatus,
                  gender: relationship.gender,
                  businessName: relationship.businessName,
                  partyType: relationship.partyType,
                  dateOfBirth: relationship.dateOfBirth,
                  abnNumber: relationship.abnNumber,
                  companyIdentifier: relationship.companyIdentifier,
                  initial: relationship.initial,
                  isTFNProvided: relationship.isTFNProvided,
                  maritalStatus: relationship.maritalStatus,
                  mlVerificationStatus: relationship.mlVerificationStatus,
                  preferredCommChannel: relationship.preferredCommChannel,
                  specialCorroRequirement: relationship.specialCorroRequirement,
                  fatcacrsentity: relationship.fatcacrsentity,
                  employment: relationship.employment,
                  lobPartyDetails: relationship.lobPartyDetails,
                },
              }))
              return formattedArray.concat(array)
            },
            []
          )

          const v1PolicyObj = {
            bancsPolicyNo: policy.policyNo,
            policy: {
              ...policy,
              identifiers: [
                { type: 'POLICY_ID', value: policy.policyId },
                { type: 'BANCS_POLICY_NO', value: policy.policyNo },
              ],
              paymentDetails: { ...policy.paymentInstrument },
              serviceHistory: { ...policy.workItems },
              benefits: policy.benefits.map(eachBenefit => {
                const transformedBenefit = {
                  ...eachBenefit,
                  benefitAssured: eachBenefit.benefitAssuredLegacy,
                }
                delete transformedBenefit.benefitAssuredLegacy

                return transformedBenefit
              }),
              relationships,
            },
          }

          delete v1PolicyObj.policy.iet
          delete v1PolicyObj.policy.lifeAssured
          delete v1PolicyObj.policy.payers
          delete v1PolicyObj.policy.policyOwners
          delete v1PolicyObj.policy.agents
          delete v1PolicyObj.policy.beneficiaries
          delete v1PolicyObj.policy.paymentInstrument
          delete v1PolicyObj.policy.workItems

          return v1PolicyObj
        })
      : []
)
