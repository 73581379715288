import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { colours, space, fontWeights, pxToRem, mediaQueries } = Variables as VariablesType

export default {
  subHeading: {
    color: colours.tertiaryTwo,
    marginBottom: space(5.6),
    fontWeight: fontWeights.semiBold,
    fontSize: pxToRem(20),
  },
  card: {
    width: '100%',
    marginTop: space(2.5),
    border: `solid 1px ${colours.tertiaryThree}`,
    padding: space(2),
    [mediaQueries.md]: {
      padding: space(5),
    },
    '& div>div': {
      marginBottom: space(3),
      marginRight: space(5),
    },
    '& > button': {
      paddingLeft: 0,
    },
  },
  heading: {
    fontSize: pxToRem(24),
  },
  container: {
    [mediaQueries.lg]: {
      '& span:nth-of-type(3)': {
        overflowWrap: 'anywhere' as const,
      },
    },
  },
}
