import { space, colours, mediaQueries } from '../../../../../../../../styles'

const HALF_WIDTH = {
  width: '50%',
}

const FULL_WIDTH = {
  width: '100%',
}

const styles = {
  root: {
    position: 'relative',
  },
  membershipNumber: {
    ...FULL_WIDTH,
    [mediaQueries.sm]: {
      ...HALF_WIDTH,
    },
  },
  paymentMethodWrapper: {
    marginBottom: space(3, true),
  },
  paymentMethod: {
    display: 'block',
    padding: `${space(1, true)} ${space(1, true)} ${space(1, true)} 0`,
    color: colours.darkGrey,
  },
  fundType: {
    display: 'inline-block',
    ...HALF_WIDTH,
  },
  noResults: {
    padding: space(2),
  },
  halfWidth: {
    ...FULL_WIDTH,
    [mediaQueries.sm]: {
      width: '48%',
    },
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [mediaQueries.sm]: {
      flexDirection: 'row',
    },
  },
}

export default styles
