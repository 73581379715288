import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontSize } = Variables
const styles = {
  container: ({ smallBorder }) => ({
    maxWidth: space(47.75),
    margin: '0 auto',
    marginTop: smallBorder ? '1px' : space(7),
    background: colours.tertiaryFive,
    padding: space(3),
    textAlign: 'center',
  }),
  text: ({ noMargin }) => ({
    marginBottom: noMargin ? space(1) : space(3),
  }),
  bold: {
    color: colours.tertiaryOne,
    fontSize: fontSize.sm,
    fontWeight: 600,
  },
  methodContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `solid 1px ${colours.tertiaryOne}`,
    borderRadius: '5px',
    padding: space(3.5),
    alignItems: 'center',
    marginBottom: space(2),
  },
  downloadIcon: {
    marginRight: space(2),
    color: colours.tertiaryOne,
  },
  downloadReceipt: {
    maxWidth: space(47.75),
    margin: '0 auto',
    '& > p': {
      fontSize: fontSize.xxs,
      textAlign: 'center',
    },
  },
}

export default styles
