// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Variables, Label } from '@mlcl-digital/mlcl-design'
import { Text, Link } from '@sitecore-jss/sitecore-jss-react'
import { toast } from 'react-toastify'

// components.
import Modal from '../../../molecules/Modal'
import AdviserInfoCard from '../AdviserInfoCard'
import Heading from '../../../atoms/Heading'
import Button from '../../../atoms/Button'

// styles.
import styles from './yourAdviserDetails.styles'

// utils.
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

// const
import {
  ERROR_GET_ADV_DETAILS,
  TOAST_ID_GET_ADV_DETAILS,
  ERROR_DELETE_ADVISER,
  TOAST_ID_DELETE_ADVISER,
  SUCCESS_CP_DELETE_ADVISER,
  TOAST_ID_SUCCESS_DELETE_ADVISER,
} from '../../../../constants/adviser'

const AdviserDetailsWrapper = styled('div')(styles.wrapper)
const InnerWrap = styled('div')(styles.innerWrapper)
export const NotFoundLabel = styled(Label)(styles.label)
const FindAdviserInnerWrapper = styled('div')(styles.findAdviserInnerWrapper)
const NoAdviserDetailsWrapper = styled('div')(styles.findAdviserwrapper)
const SubHeading = styled(Heading)(styles.subHeading)
const RemoveButton = styled(Button)(styles.confirmAdviserRemovalBtn)

const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'inline',
  padding: 0,
  color: Variables.colours.mlcAquaCustomer,
})

type AdviserDetailsPropTypes = {
  fields: Object<Object>,
  // customer relationships
  customerRelationships: Array,
  actions: {
    cpRemoveAdvisor: (workflow: Object) => void,
    cpGetAdviserDetails: (payload: Object) => void,
  },
  adviserList: Array,
  isRemoveAdviserButtonRendered: Boolean,
}

export class AdviserDetails extends Component<AdviserDetailsPropTypes> {
  constructor() {
    super()
    this.state = {
      showRemoveAdviserModal: false,
      adviserIdToRemove: '',
      policiesToDelink: [],
    }
  }

  showRemoveModal = (adviserId, policyIds) => {
    this.setState({
      showRemoveAdviserModal: true,
      adviserIdToRemove: adviserId,
      policiesToDelink: policyIds,
    })
  }

  handleCloseModal = () => {
    this.setState({ showRemoveAdviserModal: false, adviserIdToRemove: '', policiesToDelink: [] })
  }

  handleRemoveBtn = () => {
    const {
      actions: { cpRemoveAdvisor, cpGetAdviserDetails },
      fields,
    } = this.props

    const { adviserWorkitemCommentPart1, adviserWorkitemCommentPart2 } =
      reduceAuthorableFields(fields)

    const { adviserIdToRemove, policiesToDelink } = this.state

    cpGetAdviserDetails({ adviserId: adviserIdToRemove }, (err, adviserDetails) => {
      if (err) {
        toast(ERROR_GET_ADV_DETAILS, {
          toastId: TOAST_ID_GET_ADV_DETAILS,
          type: toast.TYPE.ERROR,
        })
      } else {
        cpRemoveAdvisor(
          {
            workTypeCode: 'AGENCYTRANSFER',
            bancsAgencyCode: adviserDetails.bancsAgencyCode,
            comment: `${adviserWorkitemCommentPart1} ${policiesToDelink}. ${adviserWorkitemCommentPart2}`,
          },
          delErr => {
            if (delErr) {
              toast(ERROR_DELETE_ADVISER, {
                toastId: TOAST_ID_DELETE_ADVISER,
                type: toast.TYPE.ERROR,
              })
            } else {
              toast(SUCCESS_CP_DELETE_ADVISER, {
                toastId: TOAST_ID_SUCCESS_DELETE_ADVISER,
                type: toast.TYPE.SUCCESS,
              })
            }
          }
        )
      }
    })

    this.setState({ showRemoveAdviserModal: false })
  }

  renderNoAdviserAssociated = fields => {
    const { adviserPolicyNotHaveAdviser, findAdvisorLink } = fields
    return (
      <NoAdviserDetailsWrapper data-testid="advisor-details-empty">
        <FindAdviserInnerWrapper>
          <NotFoundLabel>
            <Text field={adviserPolicyNotHaveAdviser} />
          </NotFoundLabel>
          <StyledLink field={findAdvisorLink} />
        </FindAdviserInnerWrapper>
      </NoAdviserDetailsWrapper>
    )
  }

  renderAdvisorDetails = (
    customerRelationships,
    filteredadviserArray,
    fields,
    isRemoveAdviserButtonRendered
  ) => (
    <Fragment>
      {filteredadviserArray.map(
        adviser =>
          customerRelationships[adviser] && (
            <AdviserDetailsWrapper data-testid="advisor-details" key={adviser}>
              <InnerWrap>
                <AdviserInfoCard
                  advisor={customerRelationships[adviser]}
                  fields={fields}
                  showRemoveModal={this.showRemoveModal}
                  isRemoveAdviserButtonRendered={isRemoveAdviserButtonRendered}
                />
              </InnerWrap>
            </AdviserDetailsWrapper>
          )
      )}
    </Fragment>
  )

  render() {
    const { fields, customerRelationships, adviserList, isRemoveAdviserButtonRendered } = this.props
    const { showRemoveAdviserModal } = this.state

    const { adviserRemoveModalHeaderLabel, adviserRemoveModalContent, adviserRemoveButtonLabel } =
      fields
    return adviserList.length ? (
      <Fragment>
        {this.renderAdvisorDetails(
          customerRelationships,
          adviserList,
          fields,
          isRemoveAdviserButtonRendered
        )}
        <Modal
          isOpen={showRemoveAdviserModal}
          title={adviserRemoveModalHeaderLabel}
          onClose={this.handleCloseModal}
        >
          <SubHeading size="6">
            <Text field={adviserRemoveModalContent} />
          </SubHeading>
          <RemoveButton type="secondary" onClick={this.handleRemoveBtn}>
            <Text field={adviserRemoveButtonLabel} />
          </RemoveButton>
        </Modal>
      </Fragment>
    ) : (
      this.renderNoAdviserAssociated(fields)
    )
  }
}

export default AdviserDetails
