// @flow
import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { object, arrayOf, shape, string, oneOfType } from 'prop-types'

// components.
import { Heading, PageWrap } from '@mlcl-digital/mlcl-design'
import Tile from './components/Tile'

// styles.
import styles from './contentCard.styles'

// utils
import { checkIfValidBenefitStatusCode } from '../../../utils/policyUtils'

const SectionWrap = styled('div')(({ bgVariation, alignVariation }) =>
  styles.sectionWrap(bgVariation, alignVariation)
)
export const TileContainer = styled('div')(({ alignVariation, shouldHide }) =>
  styles.tileContainer(alignVariation, shouldHide)
)
const PageWrapper = styled(PageWrap)(styles.pageWrap)
const DEFAULT_TILES_PER_ROW = 4

/** Function to return valid benefits of all policies */
const getBenefitData = ({ masterData, policies }) => {
  const { benefitDescriptions, benefitStatus } = masterData
  const policiesData = Object.values(policies)
  const benefits =
    policiesData &&
    policiesData.flatMap(policy => {
      const benefitsArray = get(policy, 'benefits', [])
      return benefitsArray
        .filter(benefit => checkIfValidBenefitStatusCode(benefit.benefitStatusCode, benefitStatus))
        .map(benefit => {
          const benefitDescription = benefitDescriptions.find(
            benefitDesc => benefitDesc.id === benefit.type
          )
          return {
            TileText: benefitDescription && benefitDescription.benefitHeading,
            TileDesc: benefitDescription && benefitDescription.description,
            benefitType: benefit.type,
          }
        })
    })
  return uniqBy(benefits, 'benefitType')
}

const getTileWidth = (HasOtherTiles, tilesCount) => {
  const defaultWidth = 100 / DEFAULT_TILES_PER_ROW
  if (HasOtherTiles) return defaultWidth
  return Math.max(100 / tilesCount, 100 / DEFAULT_TILES_PER_ROW)
}

const ContentCard = ({ fields, params }) => {
  const { BgColor, Alignment, HasOtherTiles, TileAlignment, TileBgColor, MaxWidth } = params
  const { SubsectionHeader, SectionHeader, Tiles = [], SectionDesc, SubsectionDesc } = fields
  const benefitTiles = useSelector(state =>
    getBenefitData({
      masterData: state.masterList.data,
      policies: state.customerPolicies,
    })
  )
  return (
    <SectionWrap alignVariation={Alignment.toLowerCase()} bgVariation={BgColor.toLowerCase()}>
      <PageWrapper>
        <Heading variant="h2" size="xlarge">
          <Text field={SectionHeader} />
        </Heading>
        {SectionDesc && <RichText field={SectionDesc} />}
        {SubsectionHeader && (
          <Heading data-testid="sub-section" variant="h3" size="medium">
            <Text field={SubsectionHeader} />
          </Heading>
        )}
        {SubsectionDesc && <RichText field={SubsectionDesc} />}
        <TileContainer alignVariation={Alignment.toLowerCase()} shouldHide={!!Tiles.length}>
          {HasOtherTiles === 'true' &&
            benefitTiles &&
            benefitTiles.length > 0 &&
            benefitTiles.map(tileData => (
              <Tile
                tileWidth={100 / DEFAULT_TILES_PER_ROW}
                isBenefitData
                key={`${BgColor}${Alignment}-${get(tileData, 'TileText')}`}
                fields={tileData}
                alignVariation="center"
                bgVariation="light"
              />
            ))}
          {Tiles.length > 0 &&
            Tiles.map(tileData => (
              <Tile
                tileWidth={
                  (MaxWidth && Number(MaxWidth)) || getTileWidth(HasOtherTiles, Tiles.length)
                }
                key={`${BgColor}${Alignment}-${get(
                  tileData,
                  'fields.TileText.value'
                )}${TileBgColor.toLowerCase()}`}
                isBenefitData={false}
                fields={tileData.fields}
                alignVariation={TileAlignment.toLowerCase()}
                bgVariation={TileBgColor.toLowerCase()}
              />
            ))}
        </TileContainer>
      </PageWrapper>
    </SectionWrap>
  )
}

ContentCard.propTypes = {
  fields: shape({
    SubsectionHeader: object,
    SectionHeader: object,
    Tiles: oneOfType([arrayOf(object), object]),
  }).isRequired,
  params: shape({
    BgColor: string.isRequired,
    Alignment: string.isRequired,
    HasOtherTiles: string,
    TileAlignment: string.isRequired,
    TileBgColor: string.isRequired,
    MaxWidth: string,
  }).isRequired,
}

export default ContentCard
