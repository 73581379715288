import { createSelector } from 'reselect'
import PropTypes, { number, string } from 'prop-types'
import pick from 'lodash/pick'
import moment from 'moment'

import { getPolicyStatuses } from './customerPolicies'
import {
  showBeneficiariesBasedOnProductClassAndLifeRelatedBenefitsStatus,
  getBindingTypeText,
  getBenefeciaryName,
  PLACEHOLDER_BENEFICIARY_BLACKLIST,
  checkIfPlaceholderOrCorruptBeneficiaries,
  filterOutIOOFOwnedPolicies,
  // @ts-expect-error file not in typescript
} from '../utils/policyUtils'

import {
  BENEFICIARY_TYPE_BINDING_CODE,
  SUPER_ESTATE_OPTIMISER_RELATIONSHIP_BENEFICIARY_MAPPING,
  SEO_BINDING_LABEL,
  BENEFICIARY_TYPE_LAPSING_BINDING_CODE,
  BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE,
  BENEFICIARY_TYPE_NON_BINDING_CODE,
  RELATIONSHIP_MAPPING,
} from '../constants/forms'

import {
  MINIMUM_AMOUNT_LB_TO_SHOW_SPLIT_MODAL,
  RELATIONSHIP_LEGAL_REP,
} from '../constants/customerBeneficiary'

import { getFullAddressWithState, isPartyTypeOrg } from '../utils/contactUtils'
import { Store } from '../types/store'
import { CustomerRelationship, PolicyRoleMap, Beneficiary } from '../types/customerRelationship'
import { AttributeValue } from '../types/ClientPolicies'

const getCustomerRelationships = (state: Store) => state.customerRelationships

const getMasterData = (state: Store) => state.masterList.data
const getCustomerPolicies = (state: Store) => state.customerPolicies
const getCustomerPoliciesList = (state: Store) => Object.values(state.customerPolicies)

// FIXME: Move to using policies instead of status. Also move action from component.
export const hasAllPoliciesLoaded = createSelector(
  [getPolicyStatuses, getCustomerPoliciesList],
  (policyStatuses, policies) =>
    policyStatuses.length > 0 &&
    policyStatuses.every(item => ['LOADED', 'FAILED'].includes(item.loaded)) &&
    policyStatuses.length === policies.length
)

const POLICY_STATUS_NA = 'NA'
const inForcePoliciesList = createSelector(
  [getCustomerPoliciesList, getMasterData],
  (policies = [], masterData) => {
    const { policyStatus } = masterData
    return policies.filter(policy => {
      const match = policyStatus.find(p => p.status.toUpperCase() === policy.status.toUpperCase())
      const currentStatus = match?.value || 'POLICY_STATUS_NA'
      return (
        currentStatus.toUpperCase() === POLICY_STATUS_NA &&
        showBeneficiariesBasedOnProductClassAndLifeRelatedBenefitsStatus(policy)
      )
    })
  }
)

export const getFailedPoliciesLoaded = createSelector(
  [getPolicyStatuses, getCustomerPolicies],
  (policyStatuses, policies) =>
    policyStatuses
      .filter(item => item.loaded === 'FAILED')
      .map(item => ({ policyNo: item.policyNo, policyId: policies[item.policyNo].policyId }))
)

export const hasAllBeneficiariesRelatedPartyLoaded = createSelector(
  [hasAllPoliciesLoaded, inForcePoliciesList, getCustomerRelationships],
  (allPoliciesLoaded, policies, customerRelationships) => {
    const beneficiariesBancsCustomerNos = [
      ...new Set(policies.flatMap(policy => policy.beneficiaries)),
    ]
    return (
      allPoliciesLoaded &&
      beneficiariesBancsCustomerNos.every(
        customerNo => customerNo && !customerRelationships[customerNo].hasRelatedParty
      )
    )
  }
)

const addressLookupState = (state: Store) => state.addressLookup
export const isManualResidential = createSelector(addressLookupState, addressLookup =>
  Boolean(
    addressLookup.beneficiaryFormResidentialAddress &&
      addressLookup.beneficiaryFormResidentialAddress.isManual
  )
)

const MAX_BENEFICIARIES_PER_POLICY = 6
export const getInForcePoliciesBeneficariesMapping = createSelector(
  [inForcePoliciesList, getCustomerRelationships],
  (inForcePolicies = [], customerRelationships = {}) => {
    const beneficiariesBancsCustomerNos = [
      ...new Set(inForcePolicies.flatMap(policy => policy.beneficiaries)),
    ] as string[]
    const beneficiariesRelationships = pick(
      customerRelationships,
      beneficiariesBancsCustomerNos
    ) as Record<string, CustomerRelationship>

    return inForcePolicies
      .filter(showBeneficiariesBasedOnProductClassAndLifeRelatedBenefitsStatus)
      .filter(filterOutIOOFOwnedPolicies)
      .map(policy => {
        const beneficiariesData = (policy.beneficiaries || [])
          .filter(customerNo => beneficiariesRelationships[customerNo])
          .map(
            customerNo =>
              [
                customerNo,
                beneficiariesRelationships[customerNo],
                beneficiariesRelationships[customerNo].policyRolesMap[policy.policyNo],
              ] as [
                bancsCustomerNo: string,
                customerData: CustomerRelationship,
                policyRolesMap: PolicyRoleMap
              ]
          )
          .map(
            ([bancsCustomerNo, { policyRolesMap, ...otherCustomerData }, { policyMeta = {} }]) => ({
              bancsCustomerNo,
              percentageAllocationValue: `${policyMeta?.percentageAllocation || 0}%`,
              percentageAllocation: policyMeta.percentageAllocation,
              bindingType: policyMeta.bindingType,
              bindingTypeValue: getBindingTypeText(policyMeta.bindingType),
              beneficiaryName: getBenefeciaryName(otherCustomerData) as string,
              relationshipTypeLabel:
                policyMeta.beneficiaryRelationshipType &&
                RELATIONSHIP_MAPPING[policyMeta?.beneficiaryRelationshipType],
              relationshipType: policyMeta.beneficiaryRelationshipType,
              roleCode: 'LB',
              role: 'Life Beneficiary',
              isLegalRepresentive:
                policyMeta.beneficiaryRelationshipType === RELATIONSHIP_LEGAL_REP,
              canShowAddress: !PLACEHOLDER_BENEFICIARY_BLACKLIST.includes(bancsCustomerNo),
              dateOfBirth:
                !isPartyTypeOrg(otherCustomerData.partyType) &&
                otherCustomerData.dateOfBirth &&
                moment(otherCustomerData.dateOfBirth).format('DD/MM/YYYY'),
              address: otherCustomerData.contactMethods
                ? getFullAddressWithState(otherCustomerData.contactMethods.addresses)
                : policyMeta.beneficiaryRelationshipType !== RELATIONSHIP_LEGAL_REP && ' ',
              customerData: otherCustomerData,
              loadingState: otherCustomerData.loadingState,
              partyType: otherCustomerData.partyType || 'PER',
              hasRelatedParty: otherCustomerData.hasRelatedParty,
              startDate: policyRolesMap[policy.policyNo].policyMeta.startDate,
              expiryDate: policyRolesMap[policy.policyNo].policyMeta.expiryDate,
              beneficiaryRelationshipType:
                policyRolesMap[policy.policyNo].policyMeta.beneficiaryRelationshipType,
              status: policyRolesMap[policy.policyNo].policyMeta.status,
              relatedPartyStillPending: otherCustomerData.relatedPartyStillPending,
            })
          )
        const seoBeneficiariesData = (
          policy.seoBeneficiaryAttributes.includes('NA') ? [] : policy.seoBeneficiaryAttributes
        ).map((attribute: AttributeValue) => ({
          bindingType: BENEFICIARY_TYPE_BINDING_CODE,
          bindingTypeValue: SEO_BINDING_LABEL,
          beneficiaryName: SUPER_ESTATE_OPTIMISER_RELATIONSHIP_BENEFICIARY_MAPPING[attribute].label,
          percentageAllocationValue: '100%',
          canShowAddress: false,
          relationshipTypeLabel:
            SUPER_ESTATE_OPTIMISER_RELATIONSHIP_BENEFICIARY_MAPPING[attribute].relationshipType,
        }))

        const isDataCorrupted = Boolean(
          checkIfPlaceholderOrCorruptBeneficiaries(beneficiariesData) ||
            (beneficiariesData.length && seoBeneficiariesData.length)
        )

        const everyBeneficiaryIsNonBinding =
          policy.beneficiaries &&
          policy.beneficiaries.length > 0 &&
          policy.beneficiaries.every(bancsCustomerNo =>
            [
              BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE,
              BENEFICIARY_TYPE_LAPSING_BINDING_CODE,
            ].includes(
              beneficiariesRelationships[bancsCustomerNo].policyRolesMap[policy.policyNo].policyMeta
                .bindingType
            )
          )
        const isPolicyBinding =
          everyBeneficiaryIsNonBinding ||
          (policy.beneficiaries &&
            policy.beneficiaries.length === 0 &&
            seoBeneficiariesData.length > 0)
        const isInsideSuper = policy.productClass === 'Super'
        const allBeneficiaries = (beneficiariesData as Beneficiary[]).concat(
          seoBeneficiariesData as Beneficiary[]
        )

        let formType = isDataCorrupted || isPolicyBinding ? 'binding' : 'nonbinding'
        if (isInsideSuper && allBeneficiaries.length === 0) {
          formType = 'both'
        }
        return {
          isDataCorrupted,
          formType,
          bancsPolicyNo: policy.policyNo,
          authorisedToEditBeneficiary:
            policy.policyOwners &&
            policy.policyOwners.length === 1 &&
            (formType === 'nonbinding' || formType === 'both'),
          /** FIXME: This needs to be followed up/corrected.
          /* LA should ideally be same bancsCustomerNo as LB? MM
           */
          lifeAssuredDetails: policy?.lifeAssured && customerRelationships[policy.lifeAssured[0]],
          policyId: policy.policyId,
          productId: policy.productId,
          policyName: policy.policyName,
          beneficiariesData: (beneficiariesData as Beneficiary[]).concat(
            seoBeneficiariesData as Beneficiary[]
          ),
          canAddBeneficiary: allBeneficiaries.length < MAX_BENEFICIARIES_PER_POLICY,
        }
      })
  }
)

/**
 * Check for issues in SEO attributes,
 * this is a temp work error scenario to a current issue in the seo beneficiary bancs behaviour
 *  If there is a attribute of 'NA" in the list and
 * there's more than one attribute then an error has occured
 */
export const checkForErrorForCorruptedSeoAttributes = createSelector(
  [inForcePoliciesList],
  policies =>
    policies
      .filter(showBeneficiariesBasedOnProductClassAndLifeRelatedBenefitsStatus)
      .some(
        policy =>
          policy.seoBeneficiaryAttributes &&
          policy.seoBeneficiaryAttributes.includes('NA') &&
          policy.seoBeneficiaryAttributes.length > 1
      )
)

export const getNonBindingPoliciesBeneficariesMapping = createSelector(
  getInForcePoliciesBeneficariesMapping,
  policies =>
    policies.filter(
      policy =>
        policy.beneficiariesData.length > MINIMUM_AMOUNT_LB_TO_SHOW_SPLIT_MODAL &&
        policy.beneficiariesData.every(
          benefeciary => benefeciary.bindingType === BENEFICIARY_TYPE_NON_BINDING_CODE
        )
    )
)

export const getCustomerBeneficiaryOptions = createSelector(getMasterData, masterData =>
  (masterData?.relationshipBeneficiaryForm || []).map(option => ({
    value: option.code,
    label: option.value,
  }))
)

export const BeneficiaryType = PropTypes.shape({
  bindingType: PropTypes.string,
  bindingTypeValue: PropTypes.string,
  beneficiaryName: PropTypes.string,
  percentageAllocationValue: PropTypes.string,
  relationshipType: PropTypes.string,
  canShowAddress: PropTypes.bool,
  address: PropTypes.string,
  percentageAllocation: PropTypes.oneOfType([number, string]),
  dateOfBirth: PropTypes.string,
  customerData: PropTypes.object,
  partyType: PropTypes.string,
  hasRelatedParty: PropTypes.bool,
})

export const PolicyType = PropTypes.shape({
  isDataCorrupted: PropTypes.bool.isRequired,
  formType: PropTypes.oneOf(['binding', 'nonbinding', 'both']).isRequired,
  bancsPolicyNo: PropTypes.string.isRequired,
  authorisedToEditBeneficiary: PropTypes.bool.isRequired,
  canAddBeneficiary: PropTypes.bool.isRequired,
  lifeAssuredDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  policyId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  policyName: PropTypes.string.isRequired,
  beneficiariesData: PropTypes.arrayOf(BeneficiaryType).isRequired,
})
