// @flow
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { createEvent } from '../../../../utils/telemetry'

// styles
import styles from './deleteBeneficiary.styles'

// components
import Heading from '../../../atoms/Heading'
import Button from '../../../atoms/Button'
import Modal from '../../../molecules/Modal'

// utils
import { formatBeneficiaryForm } from '../../../../utils/customerBeneficiaryUtils'
import { BeneficiaryType } from '../../../../selectors/customerBeneficiaries'

// Constants
import { ACTION_REMOVE } from '../../../../constants/customerBeneficiary'

const SubHeading = styled(Heading)(styles.subHeading)
const RemoveButton = styled(Button)(styles.confirmBenificiaryRemovalBtn)

const DeleteBeneficiariesModel = ({
  fields,
  actions,
  beneficiaries,
  beneficiaryToRemove,
  bancsPolicyNo,
  displayRemoveModal,
  authorisedToEditBeneficiary,
  removeQuoteModalOpen,
  productId,
}) => {
  const {
    updateBeneficiariesAction,
    updateRelationshipsForCustomerBeneficiaries,
    updateBeneficiariesFormForUpdate,
    showPercentageAdjustmentModal,
    toggleFileUploadModal,
  } = actions
  // Make deep copy of object passed from parent

  const handleRemoveConfirmationModalClose = () => {
    displayRemoveModal(false)
  }

  const handleRemoveBtn = () => {
    displayRemoveModal(false)
    if (authorisedToEditBeneficiary) {
      const dataToSend = formatBeneficiaryForm(
        null,
        beneficiaryToRemove.bancsCustomerNo,
        beneficiaries,
        ACTION_REMOVE
      )

      updateRelationshipsForCustomerBeneficiaries(dataToSend)
      const tagEvent = createEvent({
        GA: {
          category: 'Customer select Beneficiary Remove beneficiary remove',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Customer select Beneficiary Remove beneficiary remove',
          },
        },
      })
      tagEvent.end()

      if (dataToSend.length <= 1) {
        updateBeneficiariesAction({ benificiaryEntities: dataToSend, bancsPolicyNo })
      } else {
        updateBeneficiariesFormForUpdate({ benificiaryEntities: dataToSend, bancsPolicyNo })
        showPercentageAdjustmentModal(true)
      }
    } else {
      toggleFileUploadModal(true, bancsPolicyNo, productId)
    }
  }

  return (
    <Fragment>
      {removeQuoteModalOpen ? (
        <Modal
          isOpen={removeQuoteModalOpen}
          title={fields.cpClientPolicyDetailsBeneficiaryRemoveLabel}
          onClose={handleRemoveConfirmationModalClose}
        >
          <SubHeading size="6">
            <Text field={fields.cpClientPolicyDetailsBeneficiaryRemoveMsg} />
          </SubHeading>
          <RemoveButton type="secondary" onClick={handleRemoveBtn}>
            <Text field={fields.cpClientPolicyDetailsBeneficiaryRemoveButtonLabel} />
          </RemoveButton>
        </Modal>
      ) : null}
    </Fragment>
  )
}

DeleteBeneficiariesModel.propTypes = {
  fields: PropTypes.shape({
    cpViewPoliciesButtonLabel: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryRemoveMsg: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryRemoveButtonLabel: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryConfirmButtonLabel: PropTypes.object,
  }).isRequired,
  actions: PropTypes.shape({
    updateBeneficiariesAction: PropTypes.func,
    updateRelationshipsForCustomerBeneficiaries: PropTypes.func,
    updateBeneficiariesFormForUpdate: PropTypes.func,
    showPercentageAdjustmentModal: PropTypes.func,
    toggleFileUploadModal: PropTypes.func,
  }).isRequired,
  authorisedToEditBeneficiary: PropTypes.bool.isRequired,
  beneficiaries: PropTypes.arrayOf(BeneficiaryType).isRequired,
  beneficiaryToRemove: BeneficiaryType.isRequired,
  productId: PropTypes.string.isRequired,
  bancsPolicyNo: PropTypes.string.isRequired,
  displayRemoveModal: PropTypes.func.isRequired,
  removeQuoteModalOpen: PropTypes.bool.isRequired,
}

export default DeleteBeneficiariesModel
