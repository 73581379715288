// @flow
import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from '@emotion/styled'
import { Table, ErrorState, Label, Loader, SectionHeader } from '@mlcl-digital/mlcl-design'
import propTypes from 'prop-types'
import get from 'lodash/get'

// redux.
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

import { renderTextField } from '../../../utils/sitecoreUtils'
import styles from './correspondenceHistory.styles'
import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { sortDate } from '../../../utils/sortingUtils'

const Wrapper = styled('div')(styles.wrapper)
const TableWrapper = styled('div')(styles.table)
const DownloadLink = styled('a')(styles.anchor)
const ActivityMessage1Label = styled(Label)(styles.label)
const CorrespondenceHeading = styled(SectionHeader)(styles.sectionHeader)
const columns = ({
  correspondenceHistoryHeaderDate,
  correspondenceHistoryHeaderPolicyNumber,
  correspondenceHistoryHeaderDescription,
}) => [
  {
    Header: correspondenceHistoryHeaderDate,
    accessor: 'date',
    sortType: sortDate('date', 'policyNumber'),
    Cell: ({ cell: { value } }) => moment(value, 'DD/MM/YYYY').format('DD-MM-YYYY'),
  },
  {
    Header: correspondenceHistoryHeaderPolicyNumber,
    accessor: 'policyNumber',
    disableSortBy: true,
  },
  {
    Header: correspondenceHistoryHeaderDescription,
    accessor: 'description',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'downloadLink',
    disableSortBy: true,
  },
]

export const CorrespondenceHistory = (props: CorrespondenceHistoryProps) => {
  const { fields, correspondenceHistory, isError, config, analytics, pageSize } = props
  const [loadingRowState, setLoadingRowState] = useState([])
  const [lastClickedRow, setLastClickedRow] = useState(0)

  const handleDownloadClick = useCallback(
    (index, individualData) => {
      if (analytics) {
        const event = createEvent({
          GA: {
            category: analytics.category.replace(
              new RegExp('\\{docSubType\\}', 'g'),
              individualData.docSubType
            ),
            action: analytics.action.replace(
              new RegExp('\\{docSubType\\}', 'g'),
              individualData.docSubType
            ),
          },
          Splunk: {
            attributes: {
              'workflow.name': analytics.category.replace(
                new RegExp('\\{docSubType\\}', 'g'),
                individualData.docSubType
              ),
            },
          },
        })
        event.end()
      }
      setLoadingRowState([...loadingRowState, index])
      setLastClickedRow(index)
      downloadDocument({}, 'GET', `/documents/${individualData.externalId}`, config, () => {
        setLoadingRowState(loadingRowState.filter(i => i !== index))
      })
    },
    [loadingRowState]
  )

  const formatResults = useCallback(
    (data = {}) =>
      get(data, 'documents', []).map((individualData, index) => ({
        date: moment(individualData.createdDate),
        policyNumber: individualData.externalPolicyNumber || '',
        description: individualData.docSubType,
        downloadLink: (
          <DownloadLink onClick={() => handleDownloadClick(index, individualData)}>
            {loadingRowState.includes(index) ? (
              <Loader borderSize={2} spinnerSize={16} />
            ) : (
              'Download'
            )}
          </DownloadLink>
        ),
      })),
    [loadingRowState]
  )
  const results = formatResults(correspondenceHistory)
  const {
    correspondenceHistoryHeading,
    correspondenceHistoryNoRecordMessage,
    AppActivityMessage1,
  } = fields

  const COLUMNS = columns(fields)
  return (
    <Wrapper>
      <CorrespondenceHeading
        heading={correspondenceHistoryHeading}
        SectionMarginBottom={results.length === 0 || isError}
      />
      {isError && <ErrorState showBackground={false} />}
      {results.length > 0 && !isError && (
        <>
          <ActivityMessage1Label size="lg">
            {renderTextField(AppActivityMessage1, true)}
          </ActivityMessage1Label>
          <TableWrapper>
            <Table
              currentIndex={Math.floor(lastClickedRow / pageSize)}
              pageSize={pageSize}
              data={results}
              columns={COLUMNS}
              styleOverrides={{
                footer: styles.tableFooter,
              }}
            />
          </TableWrapper>
        </>
      )}
      {!isError && results.length === 0 && (
        <ErrorState
          emptyState
          message={renderTextField({ value: correspondenceHistoryNoRecordMessage })}
          showBackground={false}
        />
      )}
    </Wrapper>
  )
}

CorrespondenceHistory.defaultProps = {
  isError: false,
  correspondenceHistory: {
    data: [],
  },
  config: {
    MLCL_UPLOAD_FILE_API_INITIAL_ROLE: '',
  },
  analytics: {
    category: 'Downloading past correspondence - documents tab - {docSubType}',
    action: 'Downloading past correspondence - documents tab - {docSubType}',
  },
  pageSize: 1000,
}

const { bool, string, shape, array, number } = propTypes
CorrespondenceHistory.propTypes = {
  // fields from sitecore
  fields: shape({
    correspondenceHistoryHeading: string,
    correspondenceHistoryNoRecordMessage: string,
    correspondenceHistoryHeaderDate: string,
    correspondenceHistoryHeaderPolicyNumber: string,
    correspondenceHistoryHeaderDescription: string,
    AppActivityMessage1: string,
    AppActivityMessage2: string,
  }).isRequired,
  correspondenceHistory: shape({
    data: array,
  }),
  // error flag for get policy api
  isError: bool,
  // env constants config
  config: shape({
    MLCL_UPLOAD_FILE_API_INITIAL_ROLE: string,
  }),
  analytics: shape({
    category: string,
    action: string,
  }),
  pageSize: number,
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ correspondenceHistory: { businessData, isError }, config }) => ({
  correspondenceHistory: businessData,
  isError,
  config,
})

export default connect(mapStateToProps, mapDispatchToProps)(CorrespondenceHistory)
