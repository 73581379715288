// @flow
import React from 'react'
import { object, shape, oneOfType, bool, string } from 'prop-types'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Loader } from '@mlcl-digital/mlcl-design'

// constants.
import {
  Card,
  CardDivide,
  CardGridItem,
  CardGrid,
  CardCell,
  CardTable,
} from '../../../molecules/CardChildren'

export const PolicyDetailsComponent = ({ fields, policy, policyOwner }) => {
  const {
    cpCustomerPolicyDetailsPaymentTypeHeading,
    cpCustomerPolicyDetailsNextPaymentDueHeading,
    cpCustomerPolicyDetailsPolicyOwnerHeading,
    cpCustomerPolicyDetailsPolicyStartDateHeading,
    cpCustomerPolicyDetailsBPAYDetalsHeading,
    cpCustomerPolicyDetailsReferencenumberHeading,
    cpCustomerPolicyDetailsBillerCodeHeading,
    cpCustomerPolicyDetailsBillerCodeValue,
  } = fields
  const {
    startDate,
    policyPremium,
    bPayPayInReference,
    nextCollectionDate,
    paymentCollectionMethod,
    shouldShowBpay,
    collectionFrequency,
  } = policy

  return (
    <Card data-testid="policy-details-overview">
      <CardDivide large data-testid="policy-details-overview-payment-summary">
        <div>
          <Text field={cpCustomerPolicyDetailsPolicyOwnerHeading} />
          <br />
          {policyOwner || <Loader />}
        </div>
        <div>
          <Text field={cpCustomerPolicyDetailsNextPaymentDueHeading} />
          <br />
          {nextCollectionDate}
        </div>
        <div>
          <Text field={cpCustomerPolicyDetailsPaymentTypeHeading} />
          <br />
          {paymentCollectionMethod || <Loader />}
        </div>
      </CardDivide>
      <CardGrid large>
        <CardGridItem>
          <CardTable data-testid="policy-details-overview-policy-dates-premium">
            <CardCell bold>
              <Text field={cpCustomerPolicyDetailsPolicyStartDateHeading} />
            </CardCell>
            <CardCell>{startDate}</CardCell>
            <CardCell bold>{collectionFrequency} premium</CardCell>
            <CardCell>{policyPremium}</CardCell>
          </CardTable>
        </CardGridItem>
        {shouldShowBpay && (
          <CardGridItem>
            <CardTable data-testid="policy-details-overview-policy-payment-details">
              <CardCell bold>
                <Text field={cpCustomerPolicyDetailsBPAYDetalsHeading} />
              </CardCell>
              <CardCell>
                <Text field={cpCustomerPolicyDetailsBillerCodeHeading} />:{' '}
                <Text field={cpCustomerPolicyDetailsBillerCodeValue} />
                <br />
                <Text field={cpCustomerPolicyDetailsReferencenumberHeading} />: {bPayPayInReference}
              </CardCell>
            </CardTable>
          </CardGridItem>
        )}
      </CardGrid>
    </Card>
  )
}

PolicyDetailsComponent.propTypes = {
  fields: shape({
    cpCustomerPolicyDetailsPaymentTypeHeading: object,
    cpCustomerPolicyDetailsNextPaymentDueHeading: object,
    cpCustomerPolicyDetailsPolicyOwnerHeading: object,
    cpCustomerPolicyDetailsPolicyStartDateHeading: object,
    cpCustomerPolicyDetailsBPAYDetalsHeading: object,
    cpCustomerPolicyDetailsReferencenumberHeading: object,
    cpCustomerPolicyDetailsBillerCodeHeading: object,
    cpCustomerPolicyDetailsBillerCodeValue: object,
  }).isRequired,
  policy: oneOfType([bool, object]).isRequired,
  policyOwner: string,
}

PolicyDetailsComponent.defaultProps = {
  policyOwner: undefined,
}

export default PolicyDetailsComponent
