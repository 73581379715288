// @flow
import { createEvent } from '../../utils/telemetry'

import {
  OPEN_UW_METHOD_CHANGE_MODAL,
  CLOSE_UW_METHOD_CHANGE_MODAL,
  CHANGE_PERSONAL_STATEMENT_COMPLETION_STATUS,
  RESET_CUSTOMER_MYLINK_AUTH_DETAIL,
} from '../types/myLink'
import { MYLINK_OTP_VERIFY_SET_ERROR, OKTA_AUTHORIZATION_INIT } from '../types/okta'
import { RESET_CREATE_QUOTE } from '../types/createQuote'
import { removeAllCookies } from '../../utils/cookieUtils'

export const openUWMethodChangeModal = () => {
  const event = createEvent({
    GA: {
      category: 'Customer selects help',
      action: 'Customer selects help icon in URE',
    },
    Splunk: {
      attributes: {
        'workflow.name': 'Customer selects help icon in URE',
      },
    },
  })
  event.end()
  return {
    type: OPEN_UW_METHOD_CHANGE_MODAL,
  }
}

export const closeUWMethodChangeModal = () => ({
  type: CLOSE_UW_METHOD_CHANGE_MODAL,
})

export const changePersonalStatementCompletionStatus = (hasCompleted, status) => ({
  type: CHANGE_PERSONAL_STATEMENT_COMPLETION_STATUS,
  payload: {
    hasCompleted,
    ...(status && { status }),
  },
})

export const setMylinkError = error => ({
  type: MYLINK_OTP_VERIFY_SET_ERROR,
  payload: {
    error,
  },
})

export const resetMylinkAuthDetail =
  (removeCookies = false) =>
  dispatch => {
    if (removeCookies) {
      removeAllCookies()
    }
    dispatch({
      type: OKTA_AUTHORIZATION_INIT,
    })
    dispatch({
      type: RESET_CUSTOMER_MYLINK_AUTH_DETAIL,
    })
    dispatch({
      type: RESET_CREATE_QUOTE,
    })
  }
