// @flow
import React from 'react'
import CustomerPolicyDetailsContainer from '../../../organisms/CustomerPolicyDetails'

type CustomerPolicyDetailsProps = {
  // Sitecore authorable fields.
  fields: Object,
}

const CustomerPolicyDetails = ({ fields }: CustomerPolicyDetailsProps) => (
  <CustomerPolicyDetailsContainer fields={fields} />
)

export default CustomerPolicyDetails
