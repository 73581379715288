import React from 'react'
import styled from '@emotion/styled'
import { objectOf, object, func, bool } from 'prop-types'
import Modal from '../../../../../molecules/Modal'
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { IS_POLICY_PAYER_NO_LABEL } from '../../../../../../constants/customerPaymentDetails'

import styles from './policyPayerModal.styles'

const ModalSubHeading = styled('h6')(styles.modalSubHeading)
const PolicyPayerWrap = styled('div')(styles.policyPayerWrap)
const PolicyPayerOption = styled('button')(styles.policyPayerOption)

const PolicyPayerModal = ({ fields, isModalOpen, closeModal, handleAction }) => {
  const {
    customerEditPaymentDetailsHeadingLabel,
    cpEditPolicyPaymentDetailModalSubHeading,
    cpEditPaymentIsPolicyPayerYesLabel,
    cpEditPaymentIsPolicyPayerNoLabel,
  } = reduceAuthorableFields(fields)
  const isPolicyPayerOrNot = [
    {
      label: cpEditPaymentIsPolicyPayerYesLabel,
      value: cpEditPaymentIsPolicyPayerYesLabel.toLowerCase(),
    },
    {
      label: cpEditPaymentIsPolicyPayerNoLabel,
      value: cpEditPaymentIsPolicyPayerNoLabel.toLowerCase(),
    },
  ]
  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} title={customerEditPaymentDetailsHeadingLabel}>
      <ModalSubHeading>{cpEditPolicyPaymentDetailModalSubHeading}</ModalSubHeading>
      <PolicyPayerWrap>
        {isPolicyPayerOrNot.map(item => (
          <PolicyPayerOption
            key={item.label}
            onClick={() => {
              handleAction(item.value !== IS_POLICY_PAYER_NO_LABEL.toLowerCase())
            }}
          >
            {item.label}
          </PolicyPayerOption>
        ))}
      </PolicyPayerWrap>
    </Modal>
  )
}
PolicyPayerModal.propTypes = {
  fields: objectOf(object).isRequired,
  isModalOpen: bool.isRequired,
  closeModal: func.isRequired,
  handleAction: func.isRequired,
}
export default PolicyPayerModal
