import { createSelector } from 'reselect'
import moment from 'moment'
import {
  getFullName,
  getEmail,
  getPhoneNumber,
  getAddressByAddressType,
  constructAddress,
  getPhoneNumberCode,
} from '../utils/contactUtils'
import { FormObject, Store } from '../types/store'
import { CONTACT_TYPES } from '../constants/forms'
import {
  API_DATE_OF_BIRTH_FORMAT,
  DATE_OF_BIRTH_FORMAT,
  LOA_DURATION_INDEFINITE,
  LOA_TYPE_COMPANY,
  LOA_TYPE_INDIVIDUAL,
} from '../constants/contactDetails'
import {
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
  ADDRESS_TYPE_WORK,
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../constants/policies'

const getAddAuthorityForm = (state: Store) => state.forms.addAuthority
const getSelectedAuthorityForm = (state: Store) => state.forms.selectedAuthority
const getAuthorityData = (state: Store) => state.letterOfAuthority.data

const makeAuthorityName = (formData: FormObject) => {
  if (!formData) return null
  const { fields } = formData
  const { companyName, firstName, lastName, businessName } = fields
  if (companyName || businessName) {
    return companyName?.value || businessName?.value
  }
  return getFullName({ firstName: firstName?.value, lastName: lastName?.value }, false)
}

export const getAddedAuthorityName = createSelector([getAddAuthorityForm], formData =>
  makeAuthorityName(formData)
)

export const getRemovedAuthorityName = createSelector([getSelectedAuthorityForm], formData =>
  makeAuthorityName(formData)
)

export const getListOfActiveAuthorities = createSelector([getAuthorityData], data => {
  const loa = data?.businessData?.partyDetails ?? []
  return loa
    .filter(authourity => moment(authourity.loaEndDate, API_DATE_OF_BIRTH_FORMAT).isAfter(moment()))
    .map(authority => ({
      ...(authority.partyType === POLICY_PARTY_TYPE_INDIVIDUAL && {
        name: getFullName({ ...authority.name }),
        type: LOA_TYPE_INDIVIDUAL,
        dateOfBirth: moment(authority.dob, API_DATE_OF_BIRTH_FORMAT).format(DATE_OF_BIRTH_FORMAT),
      }),
      ...(authority.partyType === POLICY_PARTY_TYPE_BUSINESS && {
        name: authority?.name?.businessName,
        type: LOA_TYPE_COMPANY,
        abn: authority.abnNumber,
      }),
      email: getEmail(authority?.contacts?.emails ?? []),
      phone: `+${getPhoneNumberCode(
        authority?.contacts?.phones ?? [],
        CONTACT_TYPES.TEL
      )} ${getPhoneNumber(authority?.contacts?.phones ?? [], CONTACT_TYPES.TEL)}`,
      address: constructAddress(
        getAddressByAddressType(
          authority?.contacts?.postals ?? [],
          authority.partyType === POLICY_PARTY_TYPE_INDIVIDUAL
            ? ADDRESS_TYPE_HOME
            : ADDRESS_TYPE_WORK
        )
      ),
      postalAddress: constructAddress(
        getAddressByAddressType(authority?.contacts?.postals ?? [], ADDRESS_TYPE_STATEMENT)
      ),
      duration: authority.loaEndDate.includes('9999')
        ? LOA_DURATION_INDEFINITE
        : moment(authority.loaEndDate, API_DATE_OF_BIRTH_FORMAT).format(DATE_OF_BIRTH_FORMAT),
      bancsCustomerNo: authority.bancsPartyNo,
      nameObject: authority.name as { [key: string]: string },
    }))
})
