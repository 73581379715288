import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, colours } = Variables as VariablesType

const styles = {
  wrapper: {
    marginTop: space(5),
    display: 'flex',
    flexDirection: 'row' as const,
    '> div:first-of-type': {
      paddingRight: space(2.5),
      flexBasis: '68.75%',
    },
    '> div:last-of-type': {
      display: 'flex',
      flexBasis: '31.25%',
      flexDirection: 'column' as const,
      alignSelf: 'flex-start',
      '> div': {
        border: `solid 1px ${colours.tertiaryThree}`,
        '&:not(:last-of-type)': {
          marginBottom: space(2),
        },
      },
    },
  },
}

export default styles
