import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Modal, Button } from '@mlcl-digital/mlcl-design'

// types
import { AltsPremiumCalculatorFields } from '../../../../../types/components/PremiumCalculator'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../utils/sitecoreUtils'

// selectors
import { getCreateQuote } from '../../../../../selectors/common.selectors'

// constants
import { MAX_LIMIT_ERROR_CODE } from '../../../../../constants/premiumCalculator'

const SubmitMaxLimitModal = ({ fields }: { fields: AltsPremiumCalculatorFields }) => {
  const dispatch = useDispatch()
  const { isSaveQuoteErrorModal, saveQuoteError } = useSelector(getCreateQuote)

  // modal close(X) button
  const handleClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.showSaveQuoteErrorModal(false))
  }

  return (
    <Modal
      isOpen={
        isSaveQuoteErrorModal &&
        (
          saveQuoteError as {
            dataReceived: { status: { type: string; code: string; description: string } }
          }
        )?.dataReceived?.status?.code === MAX_LIMIT_ERROR_CODE
      }
      onClose={handleClose}
      title={fields.MaxLimitModalHeading}
      footer={
        <Button variant="linkUnderlineOnHover" onClick={handleClose}>
          {renderTextField(fields.Close)}
        </Button>
      }
    >
      {renderTextField(fields.SubmitLimitModalDescription)}
    </Modal>
  )
}

export default SubmitMaxLimitModal
