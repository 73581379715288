// @flow
import React, { type Node, Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import debounce from 'lodash/debounce'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components.
import Button from '../../../../atoms/Button'
import { IconUser16 } from '../../../../atoms/Icons'
import Logo from '../../../../atoms/Logo'
import NavDropdown from '../../../../molecules/NavDropdown'
import NavItem from '../../../../atoms/NavItem'

// styles.
import styles from './customerNavigation.styles'
import { themeConsumer } from '../../../../../styles/ThemeContext'

// utils.
import { getScope } from '../../../../../utils/cookieUtils'
import { renderTextField, reduceContentListFields } from '../../../../../utils/sitecoreUtils'
import { getProfileName } from '../../../../../utils/profileUtils'

// constants
import { SCOPE_TYPE_TELE } from '../../../../../constants/okta'
import { DASHBOARD_ROUTE } from '../../../../../constants/routes'

type CustomerNavigationProps = {
  // redux actions
  actions: Object,
  showQuickLinkAction: boolean,
  fields: Object,
  advisor: Object,
  customerPersonalDetails: Object,
  navigation: Object,
  disableGoToDashboard: Boolean,
  // sitecore rendering
  rendering: Object,
}

type CustomerNavigationState = {
  // Sets the dropdown compoment toggle to open
  dropdown: Object,
  // Sets scrolling state for the header
  header: {
    isScrolling: boolean,
  },
}

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)

const Nav = styled('nav')(styles.nav)
const Dropdown = styled(NavDropdown)()

const List = styled('ul')(({ theme }) => styles.list(theme, NavItem))
const Item = styled('li')(({ theme }) => styles.item(theme))
const ItemLink = styled(Item.withComponent(NavItem))(styles.itemLink)
const ItemLinkShort = styled(ItemLink.withComponent(NavItem))(styles.itemLinkShort)

const NavSubSection = styled('div')(styles.navSubSection)
const ProfileDropdown = styled(Dropdown)(styles.profileDropdpwn)
const PrimaryDropdown = styled(Dropdown)(styles.primaryDropdown)
const Overlay = styled('div')(styles.overlay)

const QuickAction = styled(Item.withComponent('li'))(styles.quickAction)

const ProfileIcon = styled(IconUser16)(styles.profileIcon)
const ProfileNameText = styled('span')(styles.profileNameText)

const QuickActionButton = styled(Button)(styles.quickActionButton)
class CustomerNavigation extends Component<CustomerNavigationProps, CustomerNavigationState> {
  constructor() {
    super()

    this.state = {
      dropdown: {},
      header: {
        isScrolling: false,
      },
    }

    this.handleScroll = debounce(this.handleScroll.bind(this), 5)
    this.handleOnClickMenu = this.handleOnClickMenu.bind(this)
    this.dropdownClose = this.dropdownClose.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.handleScroll.cancel()
  }

  setScrollingState(isScrolling) {
    this.setState(prevState => ({
      header: {
        ...prevState.header,
        isScrolling,
      },
    }))
  }

  handleScroll() {
    const {
      header: { isScrolling },
    } = this.state

    if (window.scrollY === 0 && isScrolling === true) {
      this.setScrollingState(false)
    } else if (window.scrollY !== 0 && isScrolling !== true) {
      this.setScrollingState(true)
    }
  }

  handleOnClickMenu() {
    const {
      actions: { toggleBurgerMenu },
      navigation: { isOpen: menuIsOpen },
    } = this.props

    toggleBurgerMenu(!menuIsOpen)
  }

  dropdownClose() {
    this.setState(prevState => ({
      ...prevState,
      dropdown: {},
    }))
  }

  render() {
    const {
      header: { isScrolling },
      dropdown,
    } = this.state

    const {
      actions,
      fields,
      showQuickLinkAction,
      advisor,
      customerPersonalDetails,
      navigation: { isOpen: menuIsOpen },
      disableGoToDashboard,
      rendering,
    } = this.props
    const dropdownOpen = Object.keys(dropdown).length !== 0

    const {
      logoHorizontal,
      logoVertical,
      profile,
      primary,
      viewQuoteLink,
      changeMethod,
      needHelp,
    } = fields

    const quicklinkAction = getScope() === SCOPE_TYPE_TELE ? changeMethod : needHelp

    const profileDisplayName = getProfileName(
      advisor,
      customerPersonalDetails,
      get(profile, 'fields.text.value', 'Account')
    )

    const ProfileName = (
      <ProfileNameText>
        <ProfileIcon /> {profileDisplayName}
      </ProfileNameText>
    )

    return (
      <Fragment>
        {dropdownOpen && <Overlay onClick={this.dropdownClose} />}
        {rendering && rendering.placeholders && (
          <Placeholder name="notification-banner" rendering={rendering} />
        )}
        <Header data-testid="main-navigation" isScrolling={isScrolling}>
          <LogoContainer>
            <Logo
              href={disableGoToDashboard ? '' : DASHBOARD_ROUTE}
              horizontalSrc={get(logoHorizontal, 'value', '')}
              verticalSrc={get(logoVertical, 'value', '')}
              alt="MLC"
            />
            <BurgerMenu isOpen={menuIsOpen} onClick={this.handleOnClickMenu} />
          </LogoContainer>
          <Nav isOpen={menuIsOpen}>
            <List>
              {profile &&
                profile.fields &&
                !!profile.fields.length &&
                !!Object.keys(profile.fields).length && (
                  <Item showOnSmall>
                    <Dropdown
                      buttonText={profileDisplayName}
                      items={reduceContentListFields(get(profile, 'fields.secondary', []))}
                    />
                  </Item>
                )}

              {primary &&
                primary.map((nav: Object): Node =>
                  get(nav, 'fields.secondary.length', 0) ? (
                    <Item key={get(nav, 'fields.text.value', '')}>
                      <PrimaryDropdown
                        buttonText={renderTextField(get(nav, 'fields.text', ''))}
                        items={reduceContentListFields(get(nav, 'fields.secondary', []))}
                      />
                    </Item>
                  ) : (
                    <ItemLink
                      key={get(nav, 'fields.text.value', '')}
                      href={get(nav, 'fields.href.value', '')}
                    >
                      {renderTextField(get(nav, 'fields.text', ''))}
                    </ItemLink>
                  )
                )}

              {viewQuoteLink &&
                viewQuoteLink.fields &&
                !!Object.keys(viewQuoteLink.fields).length && (
                  <ItemLink
                    onClick={() => actions.openSidebar('quoteTool', 0, { fields }, 0)}
                    showOnSmall
                  >
                    {renderTextField(get(viewQuoteLink, 'fields.text', ''))}
                  </ItemLink>
                )}
            </List>
          </Nav>
          <NavSubSection>
            <List>
              {showQuickLinkAction && quicklinkAction && quicklinkAction.fields && (
                <QuickAction>
                  <QuickActionButton
                    type="secondary"
                    onClick={() => {
                      if (
                        get(quicklinkAction, 'fields.id.value', '') === 'openUWMethodChangeModal'
                      ) {
                        actions.openUWMethodChangeModal()
                      }
                    }}
                  >
                    {renderTextField(get(quicklinkAction, 'fields.text', ''))}
                  </QuickActionButton>
                </QuickAction>
              )}

              {viewQuoteLink &&
                viewQuoteLink.fields &&
                !!Object.keys(viewQuoteLink.fields).length && (
                  <ItemLinkShort onClick={() => actions.openSidebar('quoteTool', 0, { fields }, 0)}>
                    {renderTextField(get(viewQuoteLink, 'fields.text', ''))}
                  </ItemLinkShort>
                )}
            </List>

            {profile && profile.fields && !!Object.keys(profile.fields).length && (
              <ProfileDropdown
                buttonText={ProfileName}
                items={reduceContentListFields(get(profile, 'fields.secondary', []))}
                variant="secondary"
              />
            )}
          </NavSubSection>
        </Header>
      </Fragment>
    )
  }
}

CustomerNavigation.defaultProps = {
  showQuickLinkAction: true,
  disableGoToDashboard: false,
}

const mapStateToProps = ({ advisor, customerPersonalDetails, navigation }) => ({
  advisor,
  customerPersonalDetails,
  navigation,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const Navigation = CustomerNavigation // Exported for testing.

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(themeConsumer(CustomerNavigation, 'navigation'))
