// @flow
import React from 'react'
import AltsPremiumCalculatorComponent from '../../../organisms/PremiumCalculator/AltsPremiumCalculator'
import { isBrowser } from '../../../../utils/browserUtils'

type AltsPremiumCalculatorProps = {
  fields: Object,
  rendering: Object,
}

const AltsPremiumCalculator = ({ fields, rendering }: AltsPremiumCalculatorProps) =>
  isBrowser() && <AltsPremiumCalculatorComponent fields={fields} rendering={rendering} />

export default AltsPremiumCalculator
