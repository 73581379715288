import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontSize, fontWeights, mediaQueries } = Variables
const style = {
  businessDetailsCard: {
    [mediaQueries.lg]: {
      maxWidth: space(74),
    },
    marginBottom: space(8, true),
  },
  businessDetailsHeader: {
    fontStyle: 'normal',
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.lg,
    lineHeight: space(5, true),
  },
  businessContactDetailsHeader: {
    marginTop: space(8, true),
  },
  businessDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapper: {
    width: '100%',
    color: colours.mediumDarkGrey,
  },
  keyValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: space(2),

    [mediaQueries.md]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: space(65),
    },
    '& > div:first-child': {
      paddingLeft: space(3),
    },
    '& > div:last-child': {
      paddingRight: space(3),
    },
  },
  key: {
    flexBasis: '25%',
    fontSize: fontSize.xxs,
    color: colours.tertiaryOne,
    [mediaQueries.md]: {
      fontSize: fontSize.xs,
    },
  },
  value: {
    fontSize: fontSize.xs,
    color: colours.tertiaryOne,
    margin: 0,
    [mediaQueries.lg]: {
      maxWidth: space(38),
    },
  },
  editButton: {
    margin: `${space(3, true)} 0 ${space(7, true)} 0`,
  },
  form: {
    paddingTop: space(3, true),
  },
  header: {
    marginBottom: space(3, true),
    color: colours.tertiaryOne,
  },
  subHeading: {
    padding: `${space(2, true)} ${space(3, true)}`,
    borderBottom: `solid 1px ${colours.tertiaryThree}`,
  },
  spinner: {
    minHeight: 0,
  },
}

export default style
