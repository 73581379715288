import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { fontFamily, space, colours } = Variables as VariablesType

const styles = {
  heading: {
    margin: 0,
    fontFamily: fontFamily.sourceSans,
    svg: {
      marginRight: space(1),
    },
  },
  coverHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${space(1.5, true)} ${space(3, true)}`,
    background: colours.mlcAquaLight,
    borderBottom: `1px solid ${colours.lightestGrey}`,
  },
}

export default styles
