import { space, colours, fontSize, fontWeights, pxToRem, mediaQueries } from '../../../styles'

const styles = {
  modalContent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [mediaQueries.md]: {
      flexDirection: 'row',
      minWidth: pxToRem(520),
    },
  },
  actionCardWrap: {
    background: colours.red,
  },
  cardWrapper: {
    minWidth: pxToRem(241),
    maxWidth: 'none',
    marginRight: space(0),
    marginBottom: space(2),
    padding: `${space(4, true)} 0`,
    cursor: 'pointer',
    [mediaQueries.md]: {
      maxWidth: pxToRem(241),
      marginRight: space(2),
      padding: `${space(3, true)} 0`,
    },
  },
  cardSection: {
    display: 'block',
    textAlign: 'center',
  },
  headCardSection: {
    textAlign: 'center',
    display: 'block',
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
  },
}

export default styles
