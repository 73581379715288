import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, colours, fontSize } = Variables as VariablesType

const styles = {
  sideContainerHeading: {
    color: colours.tertiarySix,
    backgroundColor: colours.tertiaryTwo,
    padding: `${space(2, true)} ${space(3, true)} 0`,
    '& h2': {
      color: colours.tertiarySix,
    },
    '& svg': {
      margin: `${space(0.25, true)} ${space(1, true)} 0 0`,
      fontSize: fontSize.lg,
    },
    display: 'flex',
  },
}

export default styles
