// @flow
import React, { Fragment, useEffect } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import propTypes from 'prop-types'
import { actionCreators } from '../../../actions'

// components.
import Header from '../../molecules/PageHeader'
import { CustomerPortalLifeCycleComponent } from '../../molecules/CustomerPortalLifeCycle'
import PersonalDetailsForm from './PersonalDetailsForm'
import LetterOfAuthoritySuccessBanner from './LetterOfAuthoritySuccessBanner'

// selectors
import { checkIfAllPoliciesLoaded } from '../../../selectors/common'

// constants.
import { POLICY_PARTY_TYPE_BUSINESS } from '../../../constants/policies'
import { THEME_LIGHT } from '../../../constants/themes'
import { NAVIGATION_ONLY_LOGO, FOOTER_NO_LINKS } from '../../../constants/navigation'
import { EXP_CUSTOMER_STATUS_LOADED } from '../../../constants/apis'

// utils
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { isFeatureEnabled } from '../../../utils/featureToggling'

// styles.
import styles from './customerPersonalDetails.styles'

const Wrap = styled(PageWrap)(styles.offset)
const BackgroundColourOffset = styled('div')(styles.backgroundOffset)

export const CustomerPersonalDetails = props => {
  const {
    actions: { changeNavigationType, changeFooterType, fetchRelatedParty },
    hasCompletedWelcomeJourney,
    customerRelationships,
    details,
    fields,
    hasSummaryLoaded,
    rendering,
    isLetterOfAuthorityEnabled,
  } = props

  const allPoliciesLoaded = useSelector(checkIfAllPoliciesLoaded)

  const bancsCustomerNo = get(details, 'identifiers[0].value', '')
  const partyType = get(details, 'partyType', '')
  const isBusiness = partyType === POLICY_PARTY_TYPE_BUSINESS
  const waitForAllPolicies =
    isBusiness &&
    hasSummaryLoaded &&
    allPoliciesLoaded &&
    !get(customerRelationships[bancsCustomerNo], 'hasOdsLoaded', false)
  const loadingConstraint = isBusiness && !allPoliciesLoaded

  useEffect(() => {
    if (!hasCompletedWelcomeJourney) {
      changeNavigationType(NAVIGATION_ONLY_LOGO)
      changeFooterType(FOOTER_NO_LINKS)
    }
  }, [hasCompletedWelcomeJourney])

  useEffect(() => {
    if (waitForAllPolicies) {
      fetchRelatedParty(bancsCustomerNo)
    }
  }, [waitForAllPolicies])

  const {
    heading,
    content,
    businessDetailsHeading,
    businessDetailsContentSubHeading,
    firstTimeCustomerDetailsHeading,
    firstTimeCustomerDetailsContent,
    cpPersonalDetailsBannerImageUrl,
    cpPersonalDetailsBannerImageAlt,
  } = reduceAuthorableFields(fields)
  const headingValue = isBusiness ? businessDetailsHeading : heading
  const subHeading = isBusiness ? businessDetailsContentSubHeading : content
  return (
    <Fragment>
      <Header
        theme={THEME_LIGHT}
        heading={{
          value: `${hasCompletedWelcomeJourney ? headingValue : firstTimeCustomerDetailsHeading}`,
        }}
        subHeading={{
          value: `${hasCompletedWelcomeJourney ? subHeading : firstTimeCustomerDetailsContent}`,
        }}
        imageSrc={cpPersonalDetailsBannerImageUrl}
        imageAlt={cpPersonalDetailsBannerImageAlt}
      />
      <CustomerPortalLifeCycleComponent
        checkNavigation={false}
        loadingConstraints={[loadingConstraint]}
      >
        <BackgroundColourOffset>
          <Wrap>
            {isLetterOfAuthorityEnabled && <LetterOfAuthoritySuccessBanner />}
            <PersonalDetailsForm fields={fields} isBusiness={isBusiness} rendering={rendering} />
          </Wrap>
        </BackgroundColourOffset>
      </CustomerPortalLifeCycleComponent>
    </Fragment>
  )
}

const { oneOfType, objectOf, object, bool, func, shape, array, string } = propTypes
CustomerPersonalDetails.propTypes = {
  // An object containing action creator functions.
  actions: shape({
    changeNavigationType: func,
    changeFooterType: func,
    fetchRelatedParty: func,
  }).isRequired,
  // Sitecore authorable fields.
  fields: objectOf(object).isRequired,
  // customer relationships data
  customerRelationships: oneOfType([objectOf(object), array]).isRequired,
  // customerDetails object
  details: objectOf(oneOfType([object, array, string])).isRequired,
  // welcome journey flag
  hasCompletedWelcomeJourney: bool.isRequired,
  hasSummaryLoaded: bool.isRequired,
  rendering: objectOf(oneOfType([object, string])).isRequired,
  isLetterOfAuthorityEnabled: bool.isRequired,
}

export const mapStateToProps = ({
  customerPersonalDetails: { details },
  customerRelationships,
  authentication: { hasCompletedWelcomeJourney },
  customerPolicySummaryStatus: { isLoading },
  config,
}) => ({
  details,
  customerRelationships,
  hasCompletedWelcomeJourney,
  hasSummaryLoaded: isLoading === EXP_CUSTOMER_STATUS_LOADED,
  isLetterOfAuthorityEnabled: isFeatureEnabled('LetterOfAuthority', config.FEATURES),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPersonalDetails)
