import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space } = Variables

const styles = {
  offset: {
    position: 'relative',
    marginTop: space(9),
  },
  backgroundOffset: {
    overflow: 'auto',
    backgroundColor: colours.tertiarySix,
  },
}
export default styles
