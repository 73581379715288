import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import FileUpload from '../../../molecules/FileUpload'

import { getProductIdConfig } from '../../../../constants/forms'
import { DOC_TYPE_DOC } from '../../../../constants/documentTypes'

import { getDocumentTypeDetails } from '../../../../utils/fileUpload'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { browserOrigin } from '../../../../utils/browserUtils'

export const BenefeciaryFileUpload = props => {
  const isFileUploadModalOpen = useSelector(state => state.fileUploadInfo.isModalOpen)

  const bancsPolicyNumber = useSelector(
    state => state.customerBeneficiary.currentBancsPolicyNoForFileUpload
  )
  const productId = useSelector(state => state.customerBeneficiary.currentProductIdForFileUpload)
  const { documentTypes } = useSelector(state => state.masterList.data)

  const { fields } = props
  const {
    cpEditBeneficiaryModalHeadingLabel,
    cpAddBeneficiaryModalSubHeadingLabel,
    cpAddBeneficiaryModalConfirmButton,
    cpUploadBeneficiaryFormMaxFileCount,
    cpUploadBeneficiaryFormMaxFileSize,
    cpUploadBeneficiaryFormMaxFileSizeError,
    cpUploadBeneficiaryFormWrongMimeType,
    cpUploadBeneficiaryFormUploading,
    cpUploadBeneficiaryFormUploadSuccess,
    cpUploadBeneficiaryFormUploadFailure,
    cpUploadBeneficiaryFormWorkItemFailure,
    cpUploadBeneficiaryFormWorkItemSuccess,
    cpUploadBeneficiaryFormFileRemoveLabel,
    cpEditNonBindingBeneficairyModalTitle,
    cpUpdateClientDetailsNoFileError,
    cpUpdateClientDetailsWrongFileNameError,
    ...otherFields
  } = reduceAuthorableFields(fields)

  const productTypeDocumentLabel = getProductIdConfig(productId).customerBeneficiary
  const documentEntity = getDocumentTypeDetails(
    otherFields[productTypeDocumentLabel],
    documentTypes
  )
  const downloadLink = `<a href="${
    browserOrigin() + get(documentEntity, '[0]link', '')
  }" target=_blank>Download</a>`

  return (
    isFileUploadModalOpen && (
      <FileUpload
        formDownloadMeta={{
          documentName: get(
            documentEntity,
            '[0]displayName',
            get(documentEntity, '[0]documentTypeName', '')
          ),
          documentPath: { value: downloadLink },
          docSubTypeCode: get(documentEntity, '[0]documentCode', ''),
        }}
        modalMeta={{
          modalHeading: cpEditBeneficiaryModalHeadingLabel,
          modalSubHeading: cpAddBeneficiaryModalSubHeadingLabel,
          modalConfirmButton: cpAddBeneficiaryModalConfirmButton,
        }}
        dropzoneMeta={{
          maxFileNumber: cpUploadBeneficiaryFormMaxFileCount,
          maxFileSize: cpUploadBeneficiaryFormMaxFileSize,
        }}
        fileUploadMeta={{
          maxFileSizeError: cpUploadBeneficiaryFormMaxFileSizeError,
          wrongFileType: cpUploadBeneficiaryFormWrongMimeType,
          fileUploading: cpUploadBeneficiaryFormUploading,
          fileUploadSuccess: cpUploadBeneficiaryFormUploadSuccess,
          fileUploadFailure: cpUploadBeneficiaryFormUploadFailure,
          createWorkItemError: cpUploadBeneficiaryFormWorkItemFailure,
          createWorkItemSuccess: cpUploadBeneficiaryFormWorkItemSuccess,
          fileRemoveButtonLabel: cpUploadBeneficiaryFormFileRemoveLabel,
          noFileUploadedError: cpUpdateClientDetailsNoFileError,
          wrongFileNameError: cpUpdateClientDetailsWrongFileNameError,
        }}
        createWorkItemRequest={{
          workTypeCode: get(documentEntity, '[0]workType', ''),
          bancsPolicyNo: bancsPolicyNumber,
        }}
        uploadDocumentRequest={{
          docSubTypeCode: get(documentEntity, '[0]documentCode', ''),
          docType: DOC_TYPE_DOC,
          workType: get(documentEntity, '[0]workType', ''),
        }}
      />
    )
  )
}
