import {
  setColumns,
  fontSize,
  fontWeights,
  colours,
  space,
  mediaQueries,
} from '../../../../../styles'

const styles = {
  main: {
    color: colours.black,
  },
  base: {
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
    '& span': {
      marginLeft: 0,
      fontSize: fontSize.xs,
      color: colours.black,
      paddingTop: space(0.5),
    },
    '& div': {
      flexBasis: '100%',
      marginBottom: 0,
    },

    [mediaQueries.md]: {
      '& input': {
        width: space(38),
      },
    },
  },
  caption: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.normal,
  },
  message: {
    fontSize: fontSize.xs,
    marginBottom: space(4),
  },
  resendOTPContainer: {
    paddingTop: space(1),
    paddingBottom: space(2),

    '& p': {
      margin: 0,
      display: 'inline-block',
    },
  },
  resendLink: {
    fontWeight: fontWeights.bold,
    color: colours.lightGreen,
    paddingRight: space(0.5),
    cursor: 'pointer',

    '&:hover': {
      color: colours.darkGreen,
    },
  },
  error: {
    color: colours.red,
    margin: 0,
  },
  otpConfirmation: {
    color: colours.mediumGreen,
    fontWeight: 'bold',
  },
  spinner: {
    minHeight: 0,
  },
}
export default styles
