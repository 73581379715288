/* eslint-disable no-useless-escape */
// @flow
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import { pathOr } from 'lodash/fp'
import { toast } from 'react-toastify'

import { browserOrigin } from '../../utils/browserUtils'
import { getCreditType } from '../../utils/creditTypeUtils'

// constants.
import { EXPERIENCE_API } from '../types/experienceApi'
import { SITECORE_API } from '../types/sitecoreApi'
import {
  SITECORE_QUOTEAPPLY_BASE,
  EXPERIENCE_API_TRANSACT_BASE,
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_SAVE_CARD_TRANSACT_BASE,
} from '../../constants/apis'
import {
  FETCH_NAB_CONFIG,
  INITIATE_NAB_TRANSACT,
  INITIATE_NAB_TRANSACT_SUCCESS,
  INITIATE_NAB_TRANSACT_ERROR,
  UPDATE_NAB_TRANSACT,
  UPDATE_SAVE_CARD_TRANSACT,
  RESET_NAB_DATA,
} from '../types/nabPayment'
import {
  TOAST_ID_CAPTURE_CREDITCARD,
  TOAST_TITLE_CREDITCARD_PAYMENT_SUCCESS,
  TOAST_TITLE_CREDITCARD_PAYMENT_ERROR,
  TOAST_TITLE_CREDITCARD_PAYMENT_TRIGGERED,
  TOAST_TITLE_SAVE_CARD_ERROR,
  TOAST_TITLE_SAVE_CARD_SUCCESS,
  TOAST_TITLE_SAVE_CARD_TRIGGERED,
} from '../../constants/toast'
import { ONE_OFF_PAYMENT, CP_ONE_OFF_PAYMENT } from '../../constants/payments'

export const checkIsNabResponseValid = (
  nabDataField: string,
  nabQueryData: Object,
  formData: Object
): boolean => {
  let isNabResponseValid = true

  // handle incorrect timestamp form data
  if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_TIMESTAMP') !== -1) {
    isNabResponseValid = false
  }
  // handle incorrect fingerprint form data
  else if (
    typeof nabDataField === 'string' &&
    (nabDataField.indexOf('EPS_FINGERPRINT') !== -1 || nabDataField.indexOf('fingerprint') !== -1)
  ) {
    isNabResponseValid = false
  }
  // handle incorrect card number form data
  else if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_CARDNUMBER') !== -1) {
    isNabResponseValid = false
  }
  // handle incorrect expiry month form data
  else if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_EXPIRYMONTH') !== -1) {
    isNabResponseValid = false
  }
  // handle incorrect expiry year form data
  else if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_EXPIRYYEAR') !== -1) {
    isNabResponseValid = false
  }
  // handle actual nab response whether the transaction is success or not
  else if (
    nabQueryData &&
    formData.transactionType === 'oneOffPayment' &&
    !(
      nabQueryData.rescode === '00' ||
      nabQueryData.rescode === '08' ||
      nabQueryData.rescode === '11'
    )
  ) {
    isNabResponseValid = false
  }
  // handle actual nab response whether the token is generated or not
  else if (
    nabQueryData &&
    formData.transactionType !== 'oneOffPayment' &&
    nabQueryData.strescode !== '00'
  ) {
    isNabResponseValid = false
  }

  return isNabResponseValid
}

const createApiFormData = (nabData: Object, formData: Object) => {
  const formId = formData.id
  const getExpiryMonth = (
    moment(pathOr(-1, 'expiryDate', formData), 'MM/YY').month() + 1
  ).toString()
  const getExpiryYear = moment(pathOr(-1, 'expiryDate', formData), 'MM/YY')
    .year()
    .toString()
    .substr(-2)
  const getCardNumber = pathOr('', 'cardNumber', formData).replace(/\s/g, '')
  const getCvc = pathOr('', 'cvcNumber', formData).replace(/\s/g, '')
  const getAmount = Number(pathOr(0, 'amount', formData)).toFixed(2).toString()
  const getEmail = pathOr('', 'email', formData)
  const getZipCode = pathOr('', 'residentialPostCode', formData)
  const getRefId = pathOr('', 'referenceId', nabData)

  const relativeUrl = pathOr('', 'resultUrl', nabData).replace(/^(?:\/\/|[^\/]+)*\//, '')
  const urlOrigin = browserOrigin()

  const name = pathOr('', 'fullName', formData).split(' ')
  const firstName = name.slice(0, name.length - 1).join(' ')
  const lastName = name[name.length - 1]

  const newFormData = new FormData()
  newFormData.append('EPS_MERCHANT', pathOr('', 'merchantId', nabData))
  newFormData.append('EPS_TXNTYPE', pathOr('', 'transactType', nabData))
  newFormData.append('EPS_REFERENCEID', getRefId)
  newFormData.append('EPS_TIMESTAMP', pathOr('', 'timestamp', nabData))
  newFormData.append('EPS_FINGERPRINT', pathOr('', 'fingerprint', nabData))
  newFormData.append('EPS_RESULTURL', `${urlOrigin}/${relativeUrl}`)
  // add query data to nab call for cutomer portal one off payment
  if (formId && formId === CP_ONE_OFF_PAYMENT) {
    const callbackUrl = pathOr('', 'callbackUrl', nabData).replace(/^(?:\/\/|[^\/]+)*\//, '')
    newFormData.append('EPS_CALLBACKURL', `${urlOrigin}/${callbackUrl}`)
    newFormData.append('EPS_CALLBACKPARAMS', pathOr('', 'callbackParams', nabData))
    newFormData.append('policyId', pathOr('', 'selectedPolicy', formData))
  }

  newFormData.append('EPS_REDIRECT', pathOr('', 'redirect', nabData))
  newFormData.append('EPS_RESULTPARAMS', pathOr('', 'resultParams', nabData))
  newFormData.append('EPS_CARDNUMBER', getCardNumber)
  newFormData.append('EPS_EXPIRYMONTH', getExpiryMonth)
  newFormData.append('EPS_EXPIRYYEAR', getExpiryYear)

  if (formData.transactionType === 'oneOffPayment') {
    newFormData.append('EPS_CCV', getCvc)
    newFormData.append('EPS_AMOUNT', getAmount)
  } else {
    newFormData.append('EPS_STORE', pathOr('', 'store', nabData))
    newFormData.append('EPS_STORETYPE', pathOr('', 'storeType', nabData))
  }

  // other optional form data
  newFormData.append('EPS_FIRSTNAME', firstName)
  newFormData.append('EPS_LASTNAME', lastName)
  newFormData.append('EPS_EMAILADDRESS', getEmail)
  if ((formId && formId !== CP_ONE_OFF_PAYMENT) || !formId) {
    newFormData.append('EPS_ZIPCODE', getZipCode)
  }

  return newFormData
}

export const initiateNabSuccess = (data: Object) => (dispatch: Function) => {
  dispatch({
    type: INITIATE_NAB_TRANSACT_SUCCESS,
    payload: data,
  })
}

export const initiateNabError = (data: Object) => (dispatch: Function) => {
  dispatch({
    type: INITIATE_NAB_TRANSACT_ERROR,
    payload: data || '',
  })
}

export const resetNabData = () => dispatch => {
  dispatch({
    type: RESET_NAB_DATA,
  })
}

export const initiateNabTransact = (nabData: Object, formData: Object) => (dispatch: Function) => {
  dispatch({
    type: INITIATE_NAB_TRANSACT,
  })

  // form post url
  const transactUrl = pathOr('', 'transacUrl', nabData)

  // form post data
  const bodyFormData = createApiFormData(nabData, formData)

  // form post config
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    },
  }

  const axiosPromise = axios
    .post(transactUrl, bodyFormData, config)
    .then(result => {
      const nabResponseUrl = pathOr('', 'request.responseURL', result)
      const responseData = pathOr(null, 'data', result)

      // actual nab response will return as query parameter in the url
      const parsedNabResponseUrl = queryString.parse(nabResponseUrl.split('?')[1])

      // handle NAB response here and check for valid nab response
      if (
        !checkIsNabResponseValid(responseData, parsedNabResponseUrl, formData) ||
        nabResponseUrl === transactUrl
      ) {
        dispatch(initiateNabError(parsedNabResponseUrl))
        // return false, as NAB transact didn't success
        return {
          isNABSuccess: false,
        }
      }

      dispatch(initiateNabSuccess(parsedNabResponseUrl))
      return {
        isNABSuccess: true,
      }
    })
    .catch(() => {
      dispatch(initiateNabError())
      return {
        isNABSuccess: false,
      }
    })

  return axiosPromise
}

export const processNabTransact =
  (formData: Object, callback: Function) => (dispatch: Function, getState: Function) => {
    const {
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()

    const isOneTimePayment = formData.id === ONE_OFF_PAYMENT
    const isCPOneTimePayment = formData.id === CP_ONE_OFF_PAYMENT

    if (!isCPOneTimePayment) {
      toast(
        isOneTimePayment
          ? TOAST_TITLE_CREDITCARD_PAYMENT_TRIGGERED
          : TOAST_TITLE_SAVE_CARD_TRIGGERED,
        {
          toastId: TOAST_ID_CAPTURE_CREDITCARD,
          autoClose: false,
        }
      )
    }

    const refId = formData.receiptNo || formData.instrumentNo || formData.bancsPolicyNo

    let nabConfigApiParams = `referenceId=${refId}&portal=Payment`
    if (isOneTimePayment) {
      nabConfigApiParams = `referenceId=${refId}&portal=Payment&oneTimePaymentAmount=${Number(
        formData.amount
      ).toFixed(2)}`
    } else if (isCPOneTimePayment) {
      const refIdCP = `${formData.selectedPolicyBancsPolicyId}_${moment().format('YYYYMMDDHHmmss')}`
      nabConfigApiParams = `referenceId=${refIdCP}&portal=customer&oneTimePaymentAmount=${Number(
        formData.amount
      ).toFixed(2)}`
    }

    // dispatch the action to call the api.
    dispatch({
      type: SITECORE_API,
      name: FETCH_NAB_CONFIG,
      verb: 'GET',
      route: `${SITECORE_QUOTEAPPLY_BASE}/Fingerprint/Store?${nabConfigApiParams}`,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      callback: (err, dataReceived) => {
        if (dataReceived && dataReceived.data) {
          dispatch(initiateNabTransact(dataReceived.data, formData)).then(result => {
            if (result.isNABSuccess) {
              if (!isCPOneTimePayment) {
                toast(
                  isOneTimePayment
                    ? TOAST_TITLE_CREDITCARD_PAYMENT_SUCCESS
                    : TOAST_TITLE_SAVE_CARD_SUCCESS,
                  {
                    toastId: TOAST_ID_CAPTURE_CREDITCARD,
                  }
                )
              }
              callback(true)
            } else if (!isCPOneTimePayment) {
              toast.error(
                isOneTimePayment
                  ? TOAST_TITLE_CREDITCARD_PAYMENT_ERROR
                  : TOAST_TITLE_SAVE_CARD_ERROR,
                {
                  toastId: TOAST_ID_CAPTURE_CREDITCARD,
                }
              )
            } else if (isCPOneTimePayment) {
              callback(false)
            }
          })
        }
      },
    })
  }

/**
 * updateTransaction
 * this is used when doing one of payment process
 *
 * @param {Object} formPayload
 * @param {Function} callback
 */
export const updateTransaction =
  (formPayload: Object, callback: Function) => (dispatch: Function, getState: Function) => {
    const {
      nabPayment: { nabData },
    } = getState()

    dispatch({
      type: EXPERIENCE_API,
      name: UPDATE_NAB_TRANSACT,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_TRANSACT_BASE}`,
      data: {
        paymentType: 'PAY',
        receiptNo: formPayload.receiptNo,
        suspenseNo: '',
        paymentAmount: Number(formPayload.amount),
        transactionReference: formPayload.receiptNo,
        bankTransactionId: pathOr('0', 'txnid', nabData),
      },
      callback: (err, dataReceived) => {
        if (typeof callback === 'function') {
          callback(err, dataReceived)
        }
      },
    })
  }

/**
 * updateSaveCardTransaction
 * this is used when doing save card process
 *
 * @param {Object} formPayload
 * @param {Function} callback
 */
export const updateSaveCardTransaction =
  (formPayload: Object, callback: Function) => (dispatch: Function, getState: Function) => {
    const {
      nabPayment: { nabData },
    } = getState()

    const cardType = getCreditType(formPayload.cardNumber)

    dispatch({
      type: EXPERIENCE_API,
      name: UPDATE_SAVE_CARD_TRANSACT,
      verb: 'PUT',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_SAVE_CARD_TRANSACT_BASE}${formPayload.instrumentNo}`,
      data: {
        paymentInstruction: {
          creditCardDetails: {
            tokenNumber: pathOr(0, 'token', nabData),
            panID: pathOr('', 'pan', nabData),
            cardExpiryDate: formPayload.expiryDate,
            ccAmendmentReason: '',
            cardOwnerName: formPayload.fullName,
            cardType: cardType && cardType.isVisa ? 'VI' : 'MC',
          },
        },
      },
      callback: (err, dataReceived) => {
        if (typeof callback === 'function') {
          callback(err, dataReceived)
        }
      },
    })
  }
