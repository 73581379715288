import { space } from '../../../styles'

const styles = {
  wrap: {
    position: 'relative',
    marginTop: space(9),
    marginBottom: space(9),
  },
}
export default styles
