import { setColumns, fontSize, fontWeights, colours, space } from '../../../../styles'

const styles = {
  main: {
    color: colours.black,
  },
  base: {
    padding: space(3),
    width: 400,
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
  },
  title: {
    width: `calc(50% - ${space(1, true)})`,
  },
  postalAddressCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  halfColumn: {
    width: `calc(50% - ${space(1, true)})`,
  },
  caption: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.normal,
  },
  message: {
    fontSize: fontSize.xs,
    marginBottom: space(4),
  },
}
export default styles
