// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space } = Variables
const styles = {
  wrap: {
    position: 'relative',
    marginTop: space(9),
    marginBottom: space(9),
  },
}

export default styles
