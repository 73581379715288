// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const {
  space,
  colours,
  fontSize,
  fontWeights,
  fontFamily: { charlie, sourceSans },
} = Variables
const padding = {
  padding: space(3),
}
const styles = {
  benefitHeading: {
    fontFamily: charlie,
    fontSize: fontSize.md,
    lineHeight: space(4, true),
    color: colours.lightBlack,
    fontWeight: fontWeights.semiBold,
  },
  policyHeading: {
    color: colours.tertiaryOne,
    fontWeight: fontWeights.semiBold,
  },
  policyNoElement: {
    fontFamily: sourceSans,
    color: colours.tertiaryOne,
  },
  benefitText: {
    color: colours.tertiaryOne,
    overflow: 'hidden',
    ...padding,
  },
  amountInsuredHeading: {
    fontSize: fontSize.xxs,
    lineHeight: space(2, true),
  },
  amountInsured: {
    fontSize: fontSize.md,
    lineHeight: space(4, true),
    fontWeight: fontWeights.bold,
    color: colours.lightBlack,
  },
  benefitHeaderContainer: {
    ...padding,
  },
}

export default styles
