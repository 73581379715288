// @flow
import React from 'react'

import CustomerBeneficiaries from '../../../organisms/CustomerBeneficiaries'

type CustomerBeneficiariesProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const CustomerBeneficiary = ({ fields }: CustomerBeneficiariesProps) => (
  <CustomerBeneficiaries fields={fields} />
)

export default CustomerBeneficiary
