// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
// styles
import styles from './benefits.styles'

import { Card } from '../../../molecules/CardChildren'

// utils
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

const AmmountInsuredWrapper = styled('div')()
const BenefitHeading = styled('div')(styles.benefitHeading)
const PolicyHeading = styled('span')(styles.policyHeading)
const PolicyNoElement = styled('span')(styles.policyNoElement)
const BenefitText = styled('div')(styles.benefitText)
const AmountInsuredHeading = styled('div')(styles.amountInsuredHeading)
const AmountInsured = styled('div')(styles.amountInsured)
const BenefitHeaderContainer = styled('div')(styles.benefitHeaderContainer)

const Benefits = React.memo(props => {
  const {
    fields,
    benefit: { policyId, benefitAssured, description, sumAssured },
  } = props

  const {
    cpViewCoverDetailsPolicyHeading,
    cpViewCoverDetailsAmountInsuredHeading,
    cpViewCoverDetailsLivesAssuredHeading,
  } = reduceAuthorableFields(fields)

  return (
    <Fragment>
      <Card
        noFooterBorder
        noFooterReverse
        footer={
          <AmmountInsuredWrapper>
            <AmountInsuredHeading>{cpViewCoverDetailsAmountInsuredHeading}</AmountInsuredHeading>
            <AmountInsured>{dollarAmountWithCommasAndTwoDecimal(sumAssured)}</AmountInsured>
          </AmmountInsuredWrapper>
        }
      >
        <BenefitHeaderContainer>
          <BenefitHeading>{description && description.benefitHeading}</BenefitHeading>
          <div>
            <PolicyNoElement>
              <PolicyHeading>{cpViewCoverDetailsPolicyHeading}</PolicyHeading>
              {policyId}
            </PolicyNoElement>
          </div>
          <PolicyNoElement>
            <PolicyHeading>{cpViewCoverDetailsLivesAssuredHeading} </PolicyHeading>
            {benefitAssured.map(benefitAssuredName => (
              <Fragment key={benefitAssuredName}>
                {benefitAssured.length > 1 ? <div>{benefitAssuredName}</div> : benefitAssuredName}
              </Fragment>
            ))}
          </PolicyNoElement>
        </BenefitHeaderContainer>
        <div>
          <BenefitText>{description && description.description}</BenefitText>
        </div>
      </Card>
    </Fragment>
  )
})
export default Benefits

const { shape, object, number, string, arrayOf, oneOfType } = PropTypes
Benefits.propTypes = {
  fields: shape({
    cpViewCoverDetailsPolicyHeading: oneOfType([object, string]),
    cpViewCoverDetailsAmountInsuredHeading: oneOfType([object, string]),
    cpViewCoverDetailsLivesAssuredHeading: oneOfType([object, string]),
  }),
  benefit: shape({
    policyId: string,
    benefitAssured: arrayOf(string),
    description: object,
    sumAssured: number,
  }),
}
