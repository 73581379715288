import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontFamily } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(4),
  },
  heading: {
    marginBottom: space(2),
    fontFamily: fontFamily.sourceSans,
    svg: {
      marginRight: space(1),
    },
  },
  dataList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: space(1, true),
    '> dt': {
      fontWeight: fontWeights.semiBold,
    },
    dd: {
      span: {
        marginRight: space(1, true),
      },
    },
  },
}

export default styles
