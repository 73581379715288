// @flow
import { space, mediaQueries } from '../../../styles'

const styles = {
  offset: {
    marginTop: -space(3),
    [mediaQueries.md]: {
      marginTop: -space(7),
    },
  },
  wrap: {
    position: 'relative',
    marginBottom: space(24),
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
  },
}

export default styles
