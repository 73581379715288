import React from 'react'
import styled from '@emotion/styled'

// styles

// components
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { Heading } from '@mlcl-digital/mlcl-design'
import styles from './needHelp.styles'

// types
import { NeedHelpFields } from '../../../../types/components/PremiumCalculator'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
import {
  PREMIUM_CALCULATOR_QUOTE_PREVIEW,
  PREMIUM_CALCULATOR_SUBMIT,
} from '../../../../constants/premiumCalculator'
import { createEvent } from '../../../../utils/telemetry'

const NeedHelpContainer = styled('div')(styles.needHelpContainer)

type NeedHelpProps = {
  fields?: NeedHelpFields
  activeComponent: string
}

const NeedHelp = ({ fields, activeComponent }: NeedHelpProps) => {
  const handleEmailClick = () => {
    const componentMapping: Record<string, string> = {
      [PREMIUM_CALCULATOR_QUOTE_PREVIEW]: 'PC-PQHelpEmailCTA',
      [PREMIUM_CALCULATOR_SUBMIT]: 'PC-ROHelpEmailCTA',
    }
    const event = createEvent({
      GA: {
        category: componentMapping[activeComponent],
        action: 'Email CTA',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator help email CTA',
        },
      },
    })
    event.end()
  }
  const handlePhoneClick = () => {
    const componentMapping: Record<string, string> = {
      [PREMIUM_CALCULATOR_QUOTE_PREVIEW]: 'PC-PQHelpPhoneCTA',
      [PREMIUM_CALCULATOR_SUBMIT]: 'PC-ROHelpPhoneCTA',
    }
    const event = createEvent({
      GA: {
        category: componentMapping[activeComponent],
        action: 'Phone CTA',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator help phone CTA',
        },
      },
    })
    event.end()
  }
  return (
    <NeedHelpContainer>
      <Heading variant="h2" size="large">
        {renderTextField(fields?.NeedHelpHeading)}
      </Heading>
      <dl>
        <dt>
          <FontAwesomeIcon iconName={['far', 'phone']} />
          <span>{renderTextField(fields?.PhoneNumber)}</span>
        </dt>
        <dd>
          <a onClick={handlePhoneClick} href={`tel:${fields?.ContactPhone?.value || ''}`}>
            {renderTextField(fields?.ContactPhone)}
          </a>
        </dd>
        <dt />
        <dd>{renderTextField(fields?.ContactTime)}</dd>
        <dt>
          <FontAwesomeIcon iconName={['far', 'envelope']} />
          <span>{renderTextField(fields?.Email)}</span>
        </dt>
        <dd>
          <a
            rel="noopener noreferrer"
            target="_blank"
            onClick={handleEmailClick}
            href={`mailto:${fields?.ContactEmail?.value || ''}`}
          >
            {renderTextField(fields?.ContactEmail)}
          </a>
        </dd>
      </dl>
    </NeedHelpContainer>
  )
}

export default NeedHelp
