// @flow

import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'

/* eslint-disable no-restricted-globals */

import {
  string,
  object,
  arrayOf,
  bool,
  array,
  shape,
  oneOfType,
  objectOf,
  number,
} from 'prop-types'

import 'core-js/es7/array'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Select, Table, Variables, SectionHeader } from '@mlcl-digital/mlcl-design'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import AssociatedFeesTable from './TableFooter'
import styles from './includedCover.styles'
import { getDiscountVal, orderIPBenefits } from '../../../../utils/extendedQuoteUtils'

// constants
import {
  HEALTHY_LIVING_DISCOUNT,
  MULTI_COVER_DISCOUNT,
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../../../../constants/policies'
import { NEW_STEPPED_LABEL, PREMIUM_STYLE_STEPPED } from '../../../../constants/benefit'

// selectors
import { getConfig } from '../../../../selectors/common.selectors'

// components
import BenefitLoadings from '../../../molecules/BenefitLoadings'

// utils
import { sortByKey } from '../../../../utils/sortingUtils'
import { isFeatureEnabled } from '../../../../utils/featureToggling'
import { Exclusions } from '../../../molecules/Exclusions'
import { islegacySystemProductCodeGreatorThanFive } from '../../../../utils/commonUtils'

const Benefit = styled('div')(styles.benefit)
const BenefitName = styled('span')(styles.benefitName)
const AssociatedBenefit = styled('div')(styles.associatedBenefit)
const Badge = styled('span')(styles.badge)
const ParentBenefit = styled('span')(styles.parentBenefit)
const BenefitWithLinking = styled('div')(styles.benefitWithLinking)
const AppliedDiscount = styled('div')(styles.appliedDiscount)
const ChildName = styled('div')(styles.childName)
const StyledSelect = styled(Select)(styles.select)

const renderCell = ({ key, value }: Object, associatedBenefits) => (
  <Benefit>
    <BenefitWithLinking>
      <BenefitName>{value}</BenefitName>
    </BenefitWithLinking>
    {associatedBenefits.map(associatedBenefit => (
      <AssociatedBenefit>
        <BenefitName>{associatedBenefit[key]}</BenefitName>
      </AssociatedBenefit>
    ))}
  </Benefit>
)

const columns = (labels, legacySystemProductCode) => [
  {
    Header: labels.includedCoverBenefit,
    id: 'benefits',
    accessor: ({
      benefitAssured,
      parentCover,
      benefit,
      benefitInstanceNo,
      campaignDetail,
      associatedBenefits,
      childRelatedPartyList,
    }) => ({
      benefitAssured,
      parentCover,
      benefit,
      benefitInstanceNo,
      campaignDetail,
      associatedBenefits,
      childRelatedPartyList,
    }),
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: {
        benefit,
        benefitAssured,
        parentCover = null,
        benefitInstanceNo = null,
        campaignDetail = [],
        associatedBenefits = [],
        childRelatedPartyList,
      },
      // eslint-disable-next-line react/prop-types
      row: {
        original: { discounts },
      },
    }) => {
      const {
        includedCoversLoadingLabel,
        detailsLinkedToLabel,
        healthyLivingDiscountApplied,
        multiCoverDiscountApplied,
      } = labels

      // Provided the feature is enabled, show MCD only for Series 6 policy
      // when the cover has a MCD discount which is non zero.
      const mcdDiscount = getDiscountVal(MULTI_COVER_DISCOUNT, discounts)
      const showMCDDiscount =
        islegacySystemProductCodeGreatorThanFive(legacySystemProductCode) && mcdDiscount > 0

      return (
        <Benefit key={benefit}>
          <BenefitWithLinking>
            <BenefitName>
              {benefit} {benefitInstanceNo}
            </BenefitName>
            {parentCover && (
              <>
                <Badge>{detailsLinkedToLabel}</Badge>
                <ParentBenefit>{parentCover}</ParentBenefit>
              </>
            )}
            {Boolean(
              campaignDetail.find(({ campaignCode }) => campaignCode === HEALTHY_LIVING_DISCOUNT)
            ) && <AppliedDiscount>{healthyLivingDiscountApplied}</AppliedDiscount>}
            {showMCDDiscount && <AppliedDiscount>{multiCoverDiscountApplied}</AppliedDiscount>}
          </BenefitWithLinking>
          {childRelatedPartyList.map(name => (
            <ChildName key={name}>{name}</ChildName>
          ))}
          {associatedBenefits.map(associatedBenefit => (
            <AssociatedBenefit>
              <BenefitName>
                {associatedBenefit.benefit} {associatedBenefit.benefitInstanceNo}
              </BenefitName>
            </AssociatedBenefit>
          ))}
          <BenefitLoadings
            benefitAssured={benefitAssured}
            loadingLabel={includedCoversLoadingLabel}
          />
        </Benefit>
      )
    },
    disableSortBy: true,
    styleOverrides: {
      cell: {
        fontWeight: Variables.fontWeights.semiBold,
        minWidth: '40%',
      },
      headerCell: {
        minWidth: '38%',
      },
    },
  },
  {
    Header: labels.includedCoverPaymentStructure,
    id: 'paymentStructure',
    accessor: ({ paymentStructure, associatedBenefits }) => ({
      paymentStructure,
      associatedBenefits,
    }),
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { paymentStructure, associatedBenefits = [] },
    }) => renderCell({ key: 'paymentStructure', value: paymentStructure }, associatedBenefits),
    disableSortBy: true,
  },
  {
    Header: labels.includedCoverWaitingBenefitPeriod,
    id: 'waitingBenefitPeriod',
    accessor: ({ waitingBenefitPeriod, associatedBenefits }) => ({
      waitingBenefitPeriod,
      associatedBenefits,
    }),
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { waitingBenefitPeriod, associatedBenefits = [] },
    }) =>
      renderCell({ key: 'waitingBenefitPeriod', value: waitingBenefitPeriod }, associatedBenefits),
    sortType: sortByKey('waitingBenefitPeriod'),
    styleOverrides: {
      headerCell: {
        wordBreak: 'normal',
      },
    },
  },
  {
    Header: labels.includedCoverCpiStatus,
    id: 'cpiStatus',
    accessor: ({ cpiStatus, associatedBenefits }) => ({
      cpiStatus,
      associatedBenefits,
    }),
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { cpiStatus, associatedBenefits = [] },
    }) => renderCell({ key: 'cpiStatus', value: cpiStatus }, associatedBenefits),
    disableSortBy: true,
  },
  {
    Header: labels.includedCoverSumInsured,
    id: 'sumInsured',
    accessor: ({ sumInsured, associatedBenefits }) => ({
      sumInsured,
      associatedBenefits,
    }),
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { sumInsured, associatedBenefits = [] },
    }) => renderCell({ key: 'sumInsured', value: sumInsured }, associatedBenefits),
    disableSortBy: true,
  },
  {
    Header: labels.includedCoverPremium,
    id: 'premium',
    accessor: ({ premium, associatedBenefits }) => ({
      premium,
      associatedBenefits,
    }),
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { premium, associatedBenefits = [] },
    }) => renderCell({ key: 'premium', value: premium }, associatedBenefits),
    disableSortBy: true,
    styleOverrides: {
      cell: {
        justifyContent: 'flex-end',
      },
      headerCell: {
        justifyContent: 'flex-end',
      },
    },
  },
]

export const IncludedCoverComponent = memo(props => {
  const {
    policyFees,
    covers,
    lifeAssured,
    exclusions,
    fields,
    legacySystemProductCode,
    productId,
  } = props

  const {
    cpCustomerPolicyDetailsIncludedBenefitsStampDutyRowHeading,
    cpHealthyLivingDiscountRowHeading,
    cpHealthyLivingDiscountTooltip,
    cpHealthyLivingDiscountApplied,
    cpMultiCoverDiscountHeading,
    cpMultiCoverDiscountTooltip,
    cpMultiCoverDiscountApplied,
    cpCustomerPolicyDetailsIncludedBenefitsTaxRebateRowHeading,
    cpCustomerPolicyDetailsIncludedBenefitsPolicyFeeRowHeading,
    cpCustomerPolicyDetailsIncludedBenefitsPremiumRowHeading,
    cpCustomerPolicyDetailsIncludedBenefitsCoverColumnHeading,
    cpCustomerPolicyDetailsIncludedBenefitsPaymentStructureColumnHeading,
    cpCustomerPolicyDetailsIncludedBenefitsWaitingPeriodColumnHeading,
    cpCustomerPolicyDetailsIncludedBenefitsSumInsuredColumnHeading,
    cpCustomerPolicyDetailsExclusionsHeading,
    cpCustomerPolicyDetailsExclusionsSubHeading,
    cpCustomerPolicyDetailsIncludedBenefitsHeading,
    cpCustomerPolicyDetailsIncludedBenefitsSubHeading,
    cpCustomerPolicyDetailsIncludedCoversLoadingLabel,
    cpCustomerPolicyDetailsIncludedCoversLoadingForField,
    cpCustomerPolicyDetailsIncludedBenefitsCoverLinkedTo,
  } = reduceAuthorableFields(fields)
  const labels = {
    includedCoverStampDuty: cpCustomerPolicyDetailsIncludedBenefitsStampDutyRowHeading,
    includedCoverHealthyLivingDiscount: cpHealthyLivingDiscountRowHeading,
    includedCoverHealthyLivingDiscountTooltip: cpHealthyLivingDiscountTooltip,
    multiCoverDiscount: cpMultiCoverDiscountApplied,
    multiCoverDiscountHeading: cpMultiCoverDiscountHeading,
    multiCoverDiscountTooltip: cpMultiCoverDiscountTooltip,
    healthyLivingDiscountApplied: cpHealthyLivingDiscountApplied,
    multiCoverDiscountApplied: cpMultiCoverDiscountApplied,
    includedCoverTaxRebate: cpCustomerPolicyDetailsIncludedBenefitsTaxRebateRowHeading,
    includedCoverPolicyFee: cpCustomerPolicyDetailsIncludedBenefitsPolicyFeeRowHeading,
    includedCoverBenefit: cpCustomerPolicyDetailsIncludedBenefitsCoverColumnHeading,
    // eslint-disable-next-line max-len
    includedCoverPaymentStructure:
      cpCustomerPolicyDetailsIncludedBenefitsPaymentStructureColumnHeading,
    // eslint-disable-next-line max-len
    includedCoverWaitingBenefitPeriod:
      cpCustomerPolicyDetailsIncludedBenefitsWaitingPeriodColumnHeading,
    includedCoverCpiStatus: 'CPI Status',
    includedCoverSumInsured: cpCustomerPolicyDetailsIncludedBenefitsSumInsuredColumnHeading,
    includedCoverPremium: cpCustomerPolicyDetailsIncludedBenefitsPremiumRowHeading,
    selectLifeAssuredLabel: 'Select life assured',
    exclusionsHeading: cpCustomerPolicyDetailsExclusionsHeading,
    exclusionsSubheading: cpCustomerPolicyDetailsExclusionsSubHeading,
    sectionHeading: cpCustomerPolicyDetailsIncludedBenefitsHeading,
    sectionSubheading: cpCustomerPolicyDetailsIncludedBenefitsSubHeading,
    includedCoversLoadingLabel: cpCustomerPolicyDetailsIncludedCoversLoadingLabel,
    includedCoversLoadingForField: cpCustomerPolicyDetailsIncludedCoversLoadingForField,
    detailsLinkedToLabel: cpCustomerPolicyDetailsIncludedBenefitsCoverLinkedTo,
  }

  const [selectedLifeInsured = { label: '', value: '' }, setLifeInsured] = useState(
    lifeAssured && lifeAssured[0]
  )

  const benefitList = useSelector(state => get(state.masterList, 'data.benefitList', []))
  const config = useSelector(getConfig)
  const isCaliRelabel = isFeatureEnabled('CaliRelabel', config.FEATURES)

  useEffect(() => {
    if (lifeAssured) {
      setLifeInsured(lifeAssured[0])
    }
  }, [covers])

  const {
    policyFee,
    policyStampDuty,
    policyHealthyLivingDiscount,
    policyTaxRebate,
    policyPremium,
    policyPaymentfrequency,
  } = policyFees

  const coversForLA =
    covers
      .flat()
      .filter(cover =>
        get(cover, 'benefitAssured', '').some(
          BA => get(BA, 'bancsCustomerNo', '') === selectedLifeInsured.value || cover.isChildBenefit
        )
      )
      .map(c => ({
        ...c,
        ...(isCaliRelabel &&
        [POLICY_PRODUCT_CODE_SUPER, POLICY_PRODUCT_CODE_NON_SUPER].includes(productId) &&
        c.paymentStructure === PREMIUM_STYLE_STEPPED
          ? { paymentStructure: NEW_STEPPED_LABEL }
          : {}),
      })) || []

  const finalCoversForLA = orderIPBenefits(true, coversForLA, benefitList)

  const exclusionsForLifeAssured = exclusions.map(exclusionList =>
    exclusionList.filter(
      exclusion => exclusion.lifeAssured === selectedLifeInsured.value || exclusion.isForChild
    )
  )

  const {
    includedCoverStampDuty,
    includedCoverHealthyLivingDiscount,
    includedCoverHealthyLivingDiscountTooltip,
    multiCoverDiscountHeading,
    multiCoverDiscountTooltip,
    includedCoverTaxRebate,
    includedCoverPolicyFee,
    sectionHeading,
    sectionSubheading,
    exclusionsHeading,
    exclusionsSubheading,
    selectLifeAssuredLabel,
  } = labels
  const COLUMNS = columns(labels, legacySystemProductCode)
  // Included cover table footer data

  // For 'Associated fees section', show MCD from any of the covers
  // where there is a non zero value when its a Series 6 policy
  const getNonZeroMCDDiscountInAnyCover = () => {
    let aNonZeroMCDDiscount = null
    finalCoversForLA?.forEach(({ discounts }) => {
      const discountVal = getDiscountVal(MULTI_COVER_DISCOUNT, discounts)
      if (discountVal > 0) aNonZeroMCDDiscount = discountVal
    })
    return aNonZeroMCDDiscount
  }

  // TODO:: Remove Feature enable flag once released in production
  const mcdDiscountInPolicy = getNonZeroMCDDiscountInAnyCover()
  const shouldShowMCD =
    islegacySystemProductCodeGreatorThanFive(legacySystemProductCode) && !!mcdDiscountInPolicy

  const footerData = [
    [includedCoverPolicyFee, policyFee],
    [includedCoverStampDuty, policyStampDuty],
    [includedCoverTaxRebate, policyTaxRebate],
    ...(policyHealthyLivingDiscount > 0
      ? [
          [
            includedCoverHealthyLivingDiscount,
            `${policyHealthyLivingDiscount}%`,
            includedCoverHealthyLivingDiscountTooltip,
          ],
        ]
      : []),
    ...(shouldShowMCD
      ? [[multiCoverDiscountHeading, `${mcdDiscountInPolicy}%`, multiCoverDiscountTooltip]]
      : []),
    [policyPaymentfrequency, policyPremium],
  ]

  const changeHandler = ({ value }) => setLifeInsured(value)

  const overrideTableStyle = {
    headerCell: {
      minWidth: 'none',
    },
    cell: {
      minWidth: 'none',
    },
    footer: {
      padding: 0,
    },
  }
  return (
    <>
      {lifeAssured.length > 1 && (
        <StyledSelect
          label={selectLifeAssuredLabel}
          changeHandler={changeHandler}
          value={selectedLifeInsured}
          options={lifeAssured}
          name={selectLifeAssuredLabel}
        />
      )}
      {selectedLifeInsured && (
        <>
          <SectionHeader
            heading={`${sectionHeading} ${selectedLifeInsured.label}`}
            subHeading={sectionSubheading}
          />
          <Table
            classes="table-row"
            data={finalCoversForLA}
            columns={COLUMNS}
            pageSize={1000}
            defaultSorted={[]}
            styleOverrides={overrideTableStyle}
          />
          <SectionHeader heading="Associated Fees" />
          <AssociatedFeesTable footerData={footerData} />
          <Exclusions
            heading={`${exclusionsHeading} ${selectedLifeInsured.label}`}
            subHeading={exclusionsSubheading}
            exclusions={exclusionsForLifeAssured}
          />
        </>
      )}
    </>
  )
})

IncludedCoverComponent.propTypes = {
  fields: objectOf(shape({ value: string })).isRequired,
  covers: oneOfType([array, arrayOf(object)]).isRequired,
  lifeAssured: arrayOf(
    shape({
      fullName: string,
      label: string,
      value: string,
      dateOfBirth: string,
      gender: string,
      smokerStatus: string,
      isAnyBenefitsInForce: oneOfType([bool, array]),
      tfn: string,
      bancsCustomerNo: string,
      hasRelatedParty: bool,
    })
  ).isRequired,
  exclusions: oneOfType([array, arrayOf(object)]).isRequired,
  policyFees: shape({
    policyFee: string,
    policyStampDuty: string,
    policyHealthyLivingDiscount: number,
    policyTaxRebate: string,
    policyPaymentfrequency: string,
    policyPremium: string,
  }).isRequired,
  legacySystemProductCode: string.isRequired,
}

export default IncludedCoverComponent
