// @flow
import React, { Fragment, PureComponent } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../actions'

// components
import Modal from '../../../molecules/Modal'
import Button from '../../../atoms/Button'

// styles
import styles from './informationModal.styles'

const ConfirmBtn = styled(Button)(styles.button)

type valueType = { value: string }

type PercentageSplitProps = {
  // flag to open/close modal
  isModalOpen: Boolean,
  // list of actions
  actions: Array<Function>,
  // value: to be displayed in the body
  body: valueType,
  // value: to be displayed in the title
  title: valueType,
  // value: to be displayed in the button
  button: valueType,
  // value: to use RichText instead of value if RichText
  isBodyRichText?: Boolean,
}

export class InformationModal extends PureComponent<PercentageSplitProps> {
  handleClose = () => {
    const {
      actions: { toggleBeneficiaryInfoModal },
    } = this.props
    toggleBeneficiaryInfoModal(false)
  }

  render() {
    const { isModalOpen, body, title, button, isBodyRichText } = this.props
    const { value: bodyValue } = body
    const { value: titleValue } = title
    const { value: buttonValue } = button
    return (
      <Fragment>
        <Modal isOpen={isModalOpen} onClose={this.handleClose} title={titleValue}>
          {isBodyRichText ? <RichText field={body} /> : bodyValue}
          <ConfirmBtn type="secondary" onClick={this.handleClose}>
            {buttonValue}
          </ConfirmBtn>
        </Modal>
      </Fragment>
    )
  }
}
InformationModal.defaultProps = {
  isBodyRichText: false,
}
export const mapStateToProps = ({ customerBeneficiary: { isModalOpen } }) => ({
  isModalOpen,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InformationModal)
