import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontSize, colours } = Variables
export default {
  section: ({ removeBottomPadding }) => ({
    padding: space(3),
    paddingBottom: removeBottomPadding ? 0 : space(3),
  }),
  title: {
    fontSize: fontSize.xxs,
    color: colours.tertiaryTwo,
    marginBottom: space(1),
  },
  table: {
    rowGap: space(1),
    background: colours.tertiaryFive,
    gridTemplateColumns: '1fr 1fr',
  },
  exception: ({ danger }) => ({
    padding: space(3),
    textAlign: 'center',
    color: colours.tertiaryTwo,
    ...(danger && {
      color: colours.functionalDangerDark,
      backgroundColor: colours.functionalDangerLight,
    }),
  }),
  chips: {
    '& > div': {
      marginRight: space(1),
    },
  },
}
