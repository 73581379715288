import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, colours } = Variables
const styles = {
  buttonBar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: space(9),
    marginTop: space(9),
    '& > *': {
      marginRight: space(2),
      '&:first-child': {
        marginRight: 0,
      },
    },
  },
  wrap: {
    padding: `0 ${space(3, true)}`,
    [mediaQueries.lg]: {
      padding: '0',
    },
  },
  timeline: {
    background: colours.tertiaryFive,
    marginBottom: space(5),
  },
}
export default styles
