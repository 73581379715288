// @flow
/* eslint-disable max-len */
import React, { Fragment, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { A11yLabel, Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// component.
import InputComponent from '../../../../atoms/Input'
import HeadingComponent from '../../../../atoms/Heading'
import Button from '../../../../atoms/Button'

// styles.
import styles from './otpForm.styles'

// schema.
import SCHEMA, { FORM_ID, otpFieldName } from './otpform.schema'

import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { NAVIGATION_ONLY_LOGO } from '../../../../../constants/navigation'
import { errorCheck } from '../../../../../utils/formUtils'
import { space } from '../../../../../styles'

export const Form = styled('form')(styles.base)
const FormCaption = styled(HeadingComponent)(styles.caption)
const Message = styled('p')(styles.message)
const ResendOTPLink = styled('p')(styles.resendLink)
const ResendOTPContainer = styled('div')(styles.resendOTPContainer)
const ErrorMsg = styled('p')(styles.error)
const OTPconfirmation = styled('p')(styles.otpConfirmation)
const Spinner = styled(Loader)(styles.spinner)

type OTPFormPropTypes = {
  fields: object,
  actions: object,
  form: object,
  myLink: object,
  contactIdentity: string,
}

export const OTPForm = React.memo((props: OTPFormPropTypes) => {
  const { fields, actions, myLink } = props
  const {
    personalStatementOTPPageCaption,
    personalStatementOTPFormLabel,
    personalStatementOTPFormInputPlaceholder,
    personalStatementOTPFormResendOtpText,
    personalStatementOTPFormResendOtpAlternativeText,
    personalStatementOTPFormNextButton,
    myLinkInvalidOTPErrorMessage,
    myLinkOTPSuccessMessage,
  } = fields

  const {
    changeNavigationType,
    formInit,
    formUpdateField,
    formValidate,
    formSubmit,
    formSubmitComplete,
    resetMylinkAuthDetail,
    myLinkSubmitOtp,
    resendOTP,
    setMylinkError,
    formResetField,
  } = actions

  const { authError, factorId, stateToken, contactIdentity, reSendSuccess, authLoading } = myLink
  const schema = SCHEMA(fields)
  const hasFormValidationError = !!get(props, 'form.fields.otp.error.error', '')
  const enteredOTP = get(props, `form.fields.${otpFieldName}.value`, '')

  useEffect(() => {
    const { form } = props
    changeNavigationType(NAVIGATION_ONLY_LOGO)
    if (!form) formInit(FORM_ID, schema, {})
    resetMylinkAuthDetail(true)
  }, [])

  const handleChange = useCallback(({ value, name }) => {
    if (authError || reSendSuccess) {
      setMylinkError('')
    }
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition, null),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }, [])

  const resetOtpField = () => {
    formResetField(FORM_ID, otpFieldName)
  }

  const submitHandler = useCallback(
    event => {
      event.preventDefault()
      formSubmit(FORM_ID, schema, () => {
        const data = { factorId, stateToken, passCode: enteredOTP }
        myLinkSubmitOtp(data, resetOtpField)
      })
      formSubmitComplete(FORM_ID)
    },
    [enteredOTP]
  )

  const formHead = useMemo(
    () => (
      <Fragment>
        <FormCaption size={3}>{renderTextField(personalStatementOTPPageCaption)}</FormCaption>
        <Message>{personalStatementOTPFormLabel.value.replace('$0', contactIdentity)}</Message>
        <A11yLabel>{renderTextField(personalStatementOTPFormInputPlaceholder)}</A11yLabel>
      </Fragment>
    ),
    [contactIdentity]
  )

  const formFooter = useMemo(() => {
    if (authLoading) {
      return (
        <Button type="secondary">
          <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
        </Button>
      )
    }
    return (
      <Button onClick={submitHandler}>{renderTextField(personalStatementOTPFormNextButton)}</Button>
    )
  }, [enteredOTP, authLoading])

  const handleResendOTP = useCallback(() => {
    const data = { factorId, stateToken }
    resendOTP(data)
  }, [factorId, stateToken])

  const renderResendOTP = useMemo(
    () => (
      <Fragment>
        {reSendSuccess && (
          <OTPconfirmation>{renderTextField(myLinkOTPSuccessMessage)}</OTPconfirmation>
        )}
        <ResendOTPContainer>
          <ResendOTPLink onClick={handleResendOTP}>
            {renderTextField(personalStatementOTPFormResendOtpText)}
          </ResendOTPLink>
          {renderTextField(personalStatementOTPFormResendOtpAlternativeText)}
        </ResendOTPContainer>
      </Fragment>
    ),
    [reSendSuccess, factorId, stateToken]
  )

  const errorCaption = useMemo(() => {
    if (hasFormValidationError) {
      return <ErrorMsg>{renderTextField(myLinkInvalidOTPErrorMessage)}</ErrorMsg>
    }
    if (authError) {
      return <ErrorMsg>{authError}</ErrorMsg>
    }
    return null
  }, [hasFormValidationError, authError])

  if (!contactIdentity && !authError) {
    return <Loader spinnerSize={Number(space(10))} borderSize={Number(space(0.5))} />
  }

  return (
    <Fragment>
      {formHead}
      <Form id={FORM_ID} aria-labelledby={FORM_ID} onSubmit={submitHandler}>
        <InputComponent
          htmlFor={otpFieldName}
          name={otpFieldName}
          placeholder={personalStatementOTPFormInputPlaceholder.value}
          caption={(hasFormValidationError || authError) && errorCaption}
          value={enteredOTP}
          captionWithIcon={false}
          changeHandler={handleChange}
          error={hasFormValidationError}
        />
        {renderResendOTP}
        {formFooter}
      </Form>
    </Fragment>
  )
})

export const mapStateToProps = ({ forms, myLink }) => ({
  form: forms[FORM_ID],
  myLink,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(OTPForm)
