// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, fontSize, space } = Variables
const styles = {
  wrapper: {
    marginBottom: space(17, true),
  },
  exclusionsElement: {
    background: colours.tertiarySix,
    padding: `${space(2, true)}`,
    color: colours.tertiaryTwo,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    borderBottom: `1px solid ${colours.tertiaryThree}`,
    marginBottom: 0,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  benefitHeading: {
    margin: `${space(5, true)} 0 ${space(1, true)}`,
  },
}

export default styles
