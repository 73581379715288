import {
  required,
  mobilePhone,
  phone,
  phoneInputs,
  getFieldValueFromForm,
  regexTest,
  // @ts-expect-error file not in typescript
} from '../../../../utils/formUtils'
import { Fields } from '../../../../types/components/PremiumCalculatorSubmit'
import { DEFAULT_PHONE_CODE } from '../../../../constants/contactDetails'

export const FORM_ID = 'premiumCalculatorContactDetails'
const schema = ({ fields }: { fields: Fields }) => ({
  preferredContactNumberCode: {
    condition: required,
  },
  preferredContactNumber: {
    condition: [
      { condition: required, errorMsg: fields.MobileNumberRequiredError?.value },
      {
        condition: (value: string, formFields: { [key: string]: { value: string } }) => {
          const phoneCodeValue = getFieldValueFromForm('preferredContactNumberCode', {
            fields: formFields,
          }) as string
          const phoneRegex = phoneCodeValue === DEFAULT_PHONE_CODE ? mobilePhone : phone
          return !regexTest(phoneRegex, value)
        },
        errorMsg: fields.MobileNumberInvalidError?.value,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.MobileNumberInvalidError?.value,
  },
  preferredContactTime: {
    condition: required,
    errorMsg: fields.ContactTimeRequiredError?.value,
  },
})
export default schema
