// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontSize, colours } = Variables

const styles = {
  benefitLoadings: {
    fontSize: fontSize.xxs,
    lineHeight: space(2, true),
    color: colours.tertiaryTwo,
  },
}

export default styles
