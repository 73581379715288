// @flow
import React, { useEffect } from 'react'

// redux.
import { useSelector, useDispatch } from 'react-redux'
import { objectOf, string } from 'prop-types'
import {
  ErrorState,
  PageWrap,
  SectionHeader as SectionHeaderComponent,
} from '@mlcl-digital/mlcl-design'
import { actionCreators } from '../../../actions'

// selectors
import {
  getAllPolicyBancsPolicyIds,
  checkIfAllPoliciesLoaded,
  haveAnyPoliciesFailed,
  hasSummaryLoaded,
} from '../../../selectors/common'
import {
  shouldCustomerDocumentsPageShowLoader,
  shouldShowCertificateOfCurrency,
} from '../../../selectors/customerDocuments'

// constants
import { THEME_LIGHT } from '../../../constants/themes'

// components
import CertificateOfCurrencyComponent from '../../molecules/CustomerCertificateOfCurrency'
import CorrespondenceHistoryComponent from '../../molecules/CorrespondenceHistory'
import CustomerPortalLifeCycle from '../../molecules/CustomerPortalLifeCycle'
import Header from '../../molecules/PageHeader'

export const CustomerDocuments = props => {
  const {
    fields: {
      cpDocumentsHeadingText,
      cpDocumentsSubHeadingText,
      cpDownloadDocumentText,
      cpDocumentsBannerImageUrl,
      cpDocumentsBannerImageAlt,
      downloadDocumentCertificateOfCurrencyNotApplicableText,
    },
    fields,
  } = props

  const dispatch = useDispatch()

  const bancsPolicyIds = useSelector(getAllPolicyBancsPolicyIds)
  const haveAllPoliciesLoaded = useSelector(checkIfAllPoliciesLoaded)
  const shouldPageShowLoader = useSelector(shouldCustomerDocumentsPageShowLoader)
  const showCertificateOfCurrency = useSelector(shouldShowCertificateOfCurrency)
  const policiesFailed = useSelector(haveAnyPoliciesFailed)
  const summaryLoaded = useSelector(hasSummaryLoaded)

  useEffect(() => {
    if (summaryLoaded && haveAllPoliciesLoaded) {
      dispatch(
        actionCreators.getCorrespondenceHistory({
          externalPolicyNumber: bancsPolicyIds,
          channel: 'Outbound',
        })
      )
      dispatch(
        actionCreators.fetchBatchRelatedParty(
          'beneficiaries',
          'lifeAssured',
          'payers',
          'policyOwners',
          'iet',
          'agents'
        )
      )
    }
  }, [haveAllPoliciesLoaded])

  const analytics = {
    category: 'Downloading certificate of currency - documents tab',
    action: 'CP - download certificate of currency',
  }
  const analyticsCorrespondence = {
    category: 'Downloading past correspondence - documents tab - {docSubType}',
    action: 'Downloading past correspondence - documents tab - {docSubType}',
  }

  return (
    <>
      <Header
        theme={THEME_LIGHT}
        heading={{ value: cpDocumentsHeadingText }}
        subHeading={{ value: cpDocumentsSubHeadingText }}
        bigImage
        imageSrc={cpDocumentsBannerImageUrl}
        imageAlt={cpDocumentsBannerImageAlt}
      />
      <CustomerPortalLifeCycle loadingConstraints={[shouldPageShowLoader]}>
        <PageWrap>
          <SectionHeaderComponent
            heading={
              !showCertificateOfCurrency && !shouldPageShowLoader
                ? downloadDocumentCertificateOfCurrencyNotApplicableText
                : cpDownloadDocumentText
            }
          />
          {policiesFailed ? (
            <ErrorState showBackground={false} />
          ) : (
            showCertificateOfCurrency && (
              <CertificateOfCurrencyComponent fields={fields} analytics={analytics} />
            )
          )}
          <CorrespondenceHistoryComponent
            fields={fields}
            analytics={analyticsCorrespondence}
            pageSize={20}
          />
        </PageWrap>
      </CustomerPortalLifeCycle>
    </>
  )
}

CustomerDocuments.propTypes = {
  fields: objectOf(string),
}

export default CustomerDocuments
