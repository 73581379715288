/* eslint-disable no-underscore-dangle */
import {
  CUSTOMER_POLICY_SELF_LOADING,
  CUSTOMER_POLICY_SELF_FAILED,
  CUSTOMER_POLICY_SELF_SUCCESS,
  CUSTOMER_POLICY_SUPPORTING_DATA_LOADING,
  CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS,
  CUSTOMER_POLICY_SUPPORTING_DATA_FAILED,
  SETUP_CUSTOMER_POLICY_STATUS_MAP,
} from '../actions/types/customerPolicyStatus'
import { CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS } from '../actions/types/customerPolicies'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'
import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_LOADED,
  EXP_CUSTOMER_STATUS_FAILED,
} from '../constants/apis'

export const initialState = {}

const stateMap = {
  [CUSTOMER_POLICY_SELF_LOADING]: EXP_CUSTOMER_STATUS_LOADING,
  [CUSTOMER_POLICY_SELF_FAILED]: EXP_CUSTOMER_STATUS_FAILED,
  [CUSTOMER_POLICY_SELF_SUCCESS]: EXP_CUSTOMER_STATUS_LOADED,
  [CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS]: EXP_CUSTOMER_STATUS_LOADED,
  [CUSTOMER_POLICY_SUPPORTING_DATA_LOADING]: EXP_CUSTOMER_STATUS_LOADING,
  [CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS]: EXP_CUSTOMER_STATUS_LOADED,
  [CUSTOMER_POLICY_SUPPORTING_DATA_FAILED]: EXP_CUSTOMER_STATUS_FAILED,
}

const customerPolicyStatus = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_CUSTOMER_POLICY_STATUS_MAP: {
      return action.payload.reduce(
        (newState, policy) => ({
          ...newState,
          [policy.bancsPolicyNo]: {
            policyNo: policy.bancsPolicyNo,
            statuses: {
              self: {
                url: `${policy._links.self.href}?alterationCheckRequired=Y&returnBalance=Y`,
                status: EXP_CUSTOMER_STATUS_UNLOADED,
              },
              paymentHistory: {
                url: policy._links.paymentHistory.href,
                status: EXP_CUSTOMER_STATUS_UNLOADED,
              },
              paymentInstrument: {
                url: policy._links.paymentInstrument.href,
                status: EXP_CUSTOMER_STATUS_UNLOADED,
              },
              workItems: {
                url: policy._links.workItems.href,
                status: EXP_CUSTOMER_STATUS_UNLOADED,
              },
            },
          },
        }),
        state
      )
    }
    case CUSTOMER_POLICY_SELF_FAILED:
    case CUSTOMER_POLICY_SELF_SUCCESS:
    case CUSTOMER_POLICY_SELF_LOADING:
    case CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS: {
      return action.payload.reduce(
        (newState, policy) => ({
          ...newState,
          [policy.policyNo]: {
            ...newState[policy.policyNo],
            statuses: {
              ...newState[policy.policyNo].statuses,
              self: {
                ...newState[policy.policyNo].statuses.self,
                status: stateMap[action.type],
              },
            },
          },
        }),
        state
      )
    }

    case CUSTOMER_POLICY_SUPPORTING_DATA_LOADING:
    case CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS:
    case CUSTOMER_POLICY_SUPPORTING_DATA_FAILED: {
      return action.payload.reduce(
        (newState, policy) => ({
          ...newState,
          [policy.policyNo]: {
            ...newState[policy.policyNo],
            statuses: {
              ...newState[policy.policyNo].statuses,
              [policy.type]: {
                ...newState[policy.policyNo].statuses[policy.type],
                status: stateMap[action.type],
              },
            },
          },
        }),
        state
      )
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default customerPolicyStatus
