import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { Notification } from '@mlcl-digital/mlcl-design'
// @ts-expect-error non-ts-code
import { actionCreators } from '../../../../actions'
import {
  getAddedAuthorityName,
  getRemovedAuthorityName,
} from '../../../../selectors/letterOfAuthority'
import { Store } from '../../../../types/store'

const Message = styled('span')({ fontWeight: 'normal', lineHeight: '40px' })

const LetterOfAuthoritySuccessBanner = () => {
  const dispatch = useDispatch()
  const [shouldShowBanner, toggleBanner] = useState(false)
  const hasSuccessfullyCreatedLOA = useSelector(
    (state: Store) => state.letterOfAuthority.createSuccess
  )
  const hasSuccessfullyRemovedLOA = useSelector(
    (state: Store) => state.letterOfAuthority.removeSuccess
  )
  const authorityName = useSelector(getAddedAuthorityName)
  const removedAuthorityName = useSelector(getRemovedAuthorityName)
  useEffect(() => {
    if (hasSuccessfullyCreatedLOA || hasSuccessfullyRemovedLOA) {
      toggleBanner(true)
    } else {
      toggleBanner(false)
    }
  }, [hasSuccessfullyCreatedLOA, hasSuccessfullyRemovedLOA])

  const handleClose = () => {
    const { resetLOA } = actionCreators
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(resetLOA())
    toggleBanner(false)
  }

  return (
    shouldShowBanner && (
      <div>
        <Notification variant="success" closeHandler={handleClose}>
          Success <br />
          <Message>
            You have {hasSuccessfullyCreatedLOA ? 'sucessfully added a new' : 'removed'} authority:
          </Message>{' '}
          {hasSuccessfullyCreatedLOA ? authorityName : removedAuthorityName}
        </Notification>
      </div>
    )
  )
}

export default LetterOfAuthoritySuccessBanner
