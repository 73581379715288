/* eslint-disable no-unused-vars */
import {
  colours,
  containerUtils,
  fontFamily,
  fontSize,
  mediaQueries,
  fontWeights,
  shadows,
  space,
  zIndex,
} from '../../../../../styles'

const linkSpacing = {
  paddingTop: space(2),
  paddingBottom: space(2),
  display: 'flex',
  alignItems: 'center',
}

const styles = {
  logoImage: theme => ({
    marginBottom: 0,
  }),
  overlay: {
    zIndex: zIndex.mainNavigation - 1,
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  header:
    theme =>
    ({ isScrolling }) => ({
      fontWeight: fontWeights.bold,
      backgroundColor: colours.white,
      paddingTop: space(2),
      position: 'fixed',
      left: 0,
      right: 0,
      boxShadow: isScrolling ? shadows.mid : 'none',
      zIndex: zIndex.mainNavigation,

      '& + *': {
        paddingTop: space(8),

        [mediaQueries.md]: {
          paddingTop: space(11),
        },
      },

      [mediaQueries.md]: {
        padding: `0 0 0 ${space(3, true)}`,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
      },
    }),
  logoContainer: theme => ({
    ...containerUtils.innerPaddingHorizontal,
    alignSelf: 'flex-start',
    marginBottom: space(2),
    display: 'flex',
    justifyContent: 'space-between',

    [mediaQueries.md]: {
      marginBottom: 0,
      marginRight: space(1),
      padding: 0,
      alignSelf: 'center',

      [mediaQueries.lg]: {
        marginRight: space(4),
      },
    },
  }),
  nav: theme => props => ({
    display: props.isOpen ? 'block' : 'none',
    color: colours.darkGrey,
    [mediaQueries.md]: {
      display: 'block',
    },
  }),
  item:
    theme =>
    ({ showOnSmall }) => ({
      display: 'block',

      [mediaQueries.md]: {
        border: 0,
        color: colours.darkGreen,
        display: showOnSmall && 'none',
        fontFamily: fontFamily.sourceSans,
        minWidth: space(20),
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    }),

  list: theme => ({
    fontFamily: fontFamily.charlie,

    '> li:last-child': {
      borderBottomWidth: 1,
      borderBottomColor: colours.lightGrey,
      borderBottomStyle: 'solid',

      [mediaQueries.md]: {
        borderBottomWidth: 0,
      },
    },

    [mediaQueries.md]: {
      display: 'flex',
      borderBottomWidth: 0,
    },
  }),
  itemLink:
    theme =>
    ({ showOnSmall }) => ({
      [mediaQueries.md]: {
        paddingLeft: space(2),
        paddingRight: space(2),
        ...linkSpacing,
        display: showOnSmall ? 'none' : 'flex',

        '& > *': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      [mediaQueries.lg]: {
        paddingLeft: space(2.5),
        paddingRight: space(2.5),
      },
    }),

  itemLinkShort: theme => ({
    backgroundColor: colours.mediumGreen,
    color: colours.white,
    minWidth: space(16, true),
    marginLeft: space(2),
    textAlign: 'center',

    '&:hover': {
      backgroundColor: colours.darkGreen,
    },
    '& a': {
      paddingTop: space(2),
      paddingBottom: space(2),
      minWidth: space(17, true),
      color: colours.white,
    },
  }),

  navSubSection: theme => ({
    display: 'none',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.md]: {
      display: 'flex',
    },
  }),

  profileIcon: theme => ({
    color: 'currentColor',
    marginRight: space(1),
  }),

  profileNameText: theme => ({
    display: 'inline-flex',
    alignItems: 'baseline',
  }),

  profileDropdpwn: theme => ({
    width: '64%',
    minWwidth: 120,
    maxWidth: 208,
  }),

  quickAction: theme => ({
    paddingTop: space(2),
    paddingBottom: space(2),
  }),

  quickActionButton: theme => ({
    paddingLeft: space(2),
    paddingRight: space(2),
  }),

  helpLink: theme => ({
    color: colours.darkGreen,
  }),
}

export default styles
