import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

// styles

// components
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { Heading } from '@mlcl-digital/mlcl-design'
import styles from './sidePanel.styles'
import { Store } from '../../../../types/store'
import { NeedHelpFields } from '../../../../types/components/PremiumCalculator'
import NeedHelp from '../NeedHelp'
import {
  PREMIUM_CALCULATOR_QUOTE_PREVIEW,
  PREMIUM_CALCULATOR_SUBMIT,
} from '../../../../constants/premiumCalculator'

const SideContainerHeading = styled('div')(styles.sideContainerHeading)

type SidePanelProps = {
  heading?: string
  children?: string | React.ReactNode
  fields?: NeedHelpFields
}

const SidePanel = ({ heading, children, fields }: SidePanelProps) => {
  const timelineState = useSelector((state: Store) => state.timelineWithComponents)
  return (
    <div>
      {children && (
        <div>
          {heading && (
            <SideContainerHeading>
              <FontAwesomeIcon iconName={['far', 'circle-info']} />
              <Heading variant="h2" size="large">
                {heading}
              </Heading>
            </SideContainerHeading>
          )}
          {children}
        </div>
      )}
      {timelineState &&
        [PREMIUM_CALCULATOR_QUOTE_PREVIEW, PREMIUM_CALCULATOR_SUBMIT].includes(
          timelineState.activeComponent
        ) && <NeedHelp activeComponent={timelineState.activeComponent} fields={fields} />}
    </div>
  )
}

export default SidePanel
