// @flow
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// actions
import { actionCreators } from '../../../actions'

import Checkbox from '../../atoms/Checkbox'

type CheckboxGroupProps = {
  // list of checkbox to be render
  optionsData: Array<Object>,
  // Redux actions
  actions: Object<Object>,
  // Redux forms
  forms: Object<Object>,
  // Redux formId
  formId: string,
  // handle checkbix change
  handleCheckBoxChange: Function,
  // trigger form update
  updateReduxForm: Function,
  // enable/disable select all button
  hasSelectAll: Boolean,
  // label for select all button
  selectAllLabel: string,
  hasSelectedAll: Boolean,
}

type CheckboxGroupState = {
  // options to render checkbox list
  options: Array<Object>,
  // is select all checked
  isSelectAllChecked: boolean,
}

export class CheckboxGroup extends Component<CheckboxGroupProps, CheckboxGroupState> {
  state = {
    options: [],
    isSelectAllChecked: false,
  }

  componentDidMount() {
    const { optionsData, actions, forms, formId, hasSelectedAll } = this.props
    const { formUpdateField } = actions

    if (forms && forms[formId]) {
      formUpdateField(formId, 'initialContact', {})
    }

    if (hasSelectedAll) {
      this.setState(() => ({
        isSelectAllChecked: true,
      }))
    }

    this.setState({ options: optionsData })
  }

  handleChange = ({ name, value }) => {
    const { options } = this.state
    const { handleCheckBoxChange } = this.props
    const updateFormArr = options.map(item => {
      if (item.type === 'selectAll') {
        return { ...item, checked: false }
      }
      if (item.name.toLowerCase() === name.toLowerCase()) {
        return { ...item, checked: value }
      }

      return item
    })

    this.setState({ options: updateFormArr })
    handleCheckBoxChange({ name, value })
  }

  handleSelectAllChange = ({ name, value }) => {
    const { options } = this.state
    const { updateReduxForm } = this.props
    const updateFormArr = options.map(item => {
      if (item.name.toLowerCase() === name.toLowerCase()) {
        return { ...item, checked: value }
      }
      return { ...item, checked: false }
    })
    let updateReduxData = []
    if (value) {
      updateReduxData = options.reduce((acc, ele) => {
        acc.push(ele.label)
        return acc
      }, [])
    }

    this.setState({ options: updateFormArr, isSelectAllChecked: value })
    updateReduxForm(updateReduxData)
  }

  renderCheckboxGroup = optionsData =>
    optionsData.map(({ name, label, checked }) => (
      <Checkbox
        key={name}
        name={name}
        text={label}
        direction="left"
        htmlFor={name}
        onChangeHandler={this.handleChange}
        variant="box"
        checked={checked}
      />
    ))

  render() {
    const { options, isSelectAllChecked } = this.state
    const { hasSelectAll, selectAllLabel } = this.props

    return (
      <Fragment>
        {hasSelectAll && (
          <Checkbox
            key={selectAllLabel}
            name={selectAllLabel}
            text={selectAllLabel}
            direction="left"
            htmlFor={selectAllLabel}
            onChangeHandler={this.handleSelectAllChange}
            variant="box"
            checked={isSelectAllChecked}
          />
        )}
        {this.renderCheckboxGroup(options)}
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms }) => ({
  form: forms,
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxGroup)
