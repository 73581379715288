// @flow
import React, { Component, Fragment } from 'react'
import PropTypes, { number, objectOf, oneOfType, shape, string } from 'prop-types'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Notification } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../utils/telemetry'

// components
import Input from '../../../atoms/Input'
import Button from '../../../atoms/Button'
import Modal from '../../../molecules/Modal'

// constants
import {
  ACTION_UPDATE,
  ACTION_REMOVE,
  ACTION_ADD,
  TOTAL_PERCENTAGE,
  THREE_BENEFICIARIES,
  SIX_BENEFICIARIES,
  PERCENTAGE_REGEX,
} from '../../../../constants/customerBeneficiary'

import styles from './percentageAdjustment.styles'

// utils
import { getPartyName } from '../../../../utils/contactUtils'

const PercAdjustmentPanel = styled('div')(styles.flexContainer)
const PercentageField = styled('div')(styles.percentageInput)
const BrowseLink = styled(Button)(styles.browseLink)
const BeneficiaryItem = styled('div')(styles.flexItem)
const BeneficiaryName = styled('div')(styles.beneficiaryName)
const NotificationWrapper = styled(Notification)(styles.notification)
const ConfirmButton = styled(Button)(styles.confirmChangeBtn)

const REGEX = new RegExp(PERCENTAGE_REGEX)

class PercentageAdjustment extends Component<PercentageAdjustmentProps> {
  constructor(props) {
    super()
    this.state = {
      showErrorNotification: false,
      benificiaryEntities: props.beneficiaries || [],
    }
  }

  componentWillReceiveProps(props) {
    const { beneficiaries } = props
    this.setState({ benificiaryEntities: beneficiaries })
  }

  setShowErrorNotification = value => {
    this.setState({ showErrorNotification: value })
  }

  handleAdjustmentModalClose = () => {
    const { beneficiaries, actions } = this.props
    this.setShowErrorNotification(false)
    actions.showPercentageAdjustmentModal(false)
    this.setState({
      benificiaryEntities: this.getAutoAdjustedEntities(beneficiaries),
    })
    actions.updateBeneficiariesFormReset()
  }

  getBeneficiaryCount = allBeneficiaries =>
    allBeneficiaries.filter(
      beneficiary => beneficiary.action === ACTION_UPDATE || beneficiary.action === ACTION_ADD
    ).length

  showAutoAdjust = () => {
    const { benificiaryEntities } = this.state
    const count = this.getBeneficiaryCount(benificiaryEntities)
    return count > 1 && count !== THREE_BENEFICIARIES && count !== SIX_BENEFICIARIES
  }

  showConfirmIsDisable = () => {
    const { beneficiaries } = this.props
    const { benificiaryEntities } = this.state
    const count = this.getBeneficiaryCount(benificiaryEntities)
    let isDisable = false

    if (count !== 1) {
      isDisable = !benificiaryEntities.filter(
        benificiaryEntity =>
          !beneficiaries.some(
            beneficiary =>
              beneficiary.percentageAllocation.toString() ===
                benificiaryEntity.percentageAllocation.toString() &&
              beneficiary.bancsCustomerNo === benificiaryEntity.bancsCustomerNo
          )
      ).length
    }

    return isDisable
  }

  getAutoAdjustedEntities = beneficiariesToAdjust => {
    const count = this.getBeneficiaryCount(beneficiariesToAdjust)
    const equalAllocations = (TOTAL_PERCENTAGE / count).toString()
    const autoAdjustedEntities = beneficiariesToAdjust.map(entity => ({
      ...entity,
      percentageAllocation: entity.action === ACTION_REMOVE ? '0' : equalAllocations,
    }))
    return autoAdjustedEntities
  }

  autoAdjustAllocations = () => {
    this.setShowErrorNotification(false)
    this.setState(prevState => ({
      benificiaryEntities: this.getAutoAdjustedEntities(prevState.benificiaryEntities),
    }))
  }

  handleValueChange = (beneficiary, event) => {
    this.setShowErrorNotification(false)
    this.setState(prevState => ({
      benificiaryEntities: prevState.benificiaryEntities.map(item => ({
        ...item,
        ...(item.bancsCustomerNo === beneficiary.bancsCustomerNo && {
          percentageAllocation: event.value.toString(),
          fieldError: !REGEX.test(event.value),
        }),
      })),
    }))
  }

  handleChangeConfirm = () => {
    const { actions, bancsPolicyNo, isBeneficiaryFormAdd } = this.props
    const { benificiaryEntities } = this.state
    const sum = benificiaryEntities
      .filter(
        benificiary => benificiary.action === ACTION_UPDATE || benificiary.action === ACTION_ADD
      )
      .reduce((accum, benificiary) => accum + Number(benificiary.percentageAllocation), 0)
    if (sum !== TOTAL_PERCENTAGE) {
      this.setShowErrorNotification(true)
    } else {
      this.setShowErrorNotification(false)
      actions.showPercentageAdjustmentModal(false)
      actions.updateBeneficiariesAction({ benificiaryEntities, bancsPolicyNo })
    }
    // analytics
    let category = ''
    const action = 'select'
    if (isBeneficiaryFormAdd) {
      category = 'Customer selects Add Beneficiary-Add beneficiary-Confirm button'
    } else if (benificiaryEntities.some(benificiary => benificiary.action === ACTION_REMOVE)) {
      category = 'Customer select Beneficiary-Remove beneficiary-Confirm'
    } else {
      category = 'Customer select Beneficiary-Adjust percentage split-Confirm'
    }
    const tagEvent = createEvent({
      GA: { category, action },
      Splunk: {
        attributes: {
          'workflow.name': category,
        },
      },
    })
    tagEvent.end()
  }

  renderInputs = () => {
    const { fields } = this.props
    const { benificiaryEntities } = this.state
    const {
      cpClientPolicyDetailsBeneficiaryPercentageLabel,
      cpClientPolicyDetailsBeneficiaryAdjustErrorLabel,
    } = fields

    return benificiaryEntities
      .filter(
        beneficiary => beneficiary.action === ACTION_UPDATE || beneficiary.action === ACTION_ADD
      )
      .map((beneficiary, index) => (
        <BeneficiaryItem key={get(beneficiary, 'bancsCustomerNo', 'none')}>
          <BeneficiaryName>
            {get(
              beneficiary,
              'beneficiaryName',
              // FIXME: Fallsbacks to original shape of
              // relatedParty exclusively for ADD beneficiaries
              getPartyName(get(beneficiary, 'relatedParty', {}))
            )}
          </BeneficiaryName>
          <PercentageField>
            <Input
              htmlFor={`pecentage-${index}`}
              name={`pecentage-${index}`}
              label={cpClientPolicyDetailsBeneficiaryPercentageLabel.value}
              error={beneficiary.fieldError}
              value={beneficiary.percentageAllocation}
              caption={
                beneficiary.fieldError && cpClientPolicyDetailsBeneficiaryAdjustErrorLabel.value
              }
              changeHandler={event => this.handleValueChange(beneficiary, event)}
            />
          </PercentageField>
        </BeneficiaryItem>
      ))
  }

  render() {
    const { fields, isPercentageAllocationOpen } = this.props
    const { showErrorNotification } = this.state
    const {
      cpClientPolicyDetailsBeneficiaryAdjustLabel,
      cpClientPolicyDetailsBeneficiaryPercentageEqualLabel,
      cpClientPolicyDetailsBeneficiaryConfirmButtonLabel,
      cpPercentageAdjustmentNotificationErrorText,
    } = fields

    return (
      <Fragment>
        <Modal
          isOpen={isPercentageAllocationOpen}
          title={cpClientPolicyDetailsBeneficiaryAdjustLabel.value}
          onClose={this.handleAdjustmentModalClose}
        >
          {this.showAutoAdjust() && (
            <BrowseLink type="tertiary" onClick={() => this.autoAdjustAllocations()}>
              {cpClientPolicyDetailsBeneficiaryPercentageEqualLabel.value}
            </BrowseLink>
          )}
          <PercAdjustmentPanel>{this.renderInputs()}</PercAdjustmentPanel>
          {showErrorNotification ? (
            <NotificationWrapper variant="error">
              {cpPercentageAdjustmentNotificationErrorText.value}
            </NotificationWrapper>
          ) : null}
          <ConfirmButton
            type="secondary"
            onClick={this.handleChangeConfirm}
            disabled={this.showConfirmIsDisable()}
          >
            <Text field={cpClientPolicyDetailsBeneficiaryConfirmButtonLabel} />
          </ConfirmButton>
        </Modal>
      </Fragment>
    )
  }
}
PercentageAdjustment.propTypes = {
  beneficiaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPercentageAllocationOpen: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    showPercentageAdjustmentModal: PropTypes.func,
    updateBeneficiariesFormReset: PropTypes.func,
    updateBeneficiariesAction: PropTypes.func,
  }).isRequired,
  fields: objectOf(
    shape({
      value: oneOfType([string, number]),
    })
  ).isRequired,
}

export const PercentageAdjustmentContainer = props => {
  const customerBeneficiary = useSelector(state => state.customerBeneficiary)
  const newProps = {
    ...props,
    beneficiaries: customerBeneficiary.beneficiariesForFormUpdate,
    isPercentageAllocationOpen: customerBeneficiary.isPercentageAllocationOpen,
    bancsPolicyNo: customerBeneficiary.currentBancsPolicyNoForUpdate,
    toAdd: customerBeneficiary.addingNewBeneficiary,
    isBeneficiaryFormAdd: customerBeneficiary.isBeneficiaryFormAdd,
  }
  return <PercentageAdjustment {...newProps} />
}
