// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../../../actions'

// atoms
import Input from '../../../../../../../atoms/Input'

// molecules
import AbnLookup from '../../../../../../../molecules/AbnLookup'

// schema.
import custRolloverPaymentSchema, { FORM_ID } from './rolloverPayment.schema'

// utils.
import { reduceAuthorableFields } from '../../../../../../../../utils/sitecoreUtils'
import { errorCheck } from '../../../../../../../../utils/formUtils'

// styles.
import styles from './rolloverPayment.styles'

const Form = styled('form')(styles.root)
const MembershipNumber = styled(Input)(styles.membershipNumber)
const Wrap = styled('div')(styles.wrap)
const FundName = styled(Input)(styles.halfWidth)
const ProductName = styled(Input)(styles.halfWidth)
const UsiNumber = styled(Input)(styles.halfWidth)
const AbnNumber = styled(AbnLookup)(styles.halfWidth)

type RolloverProps = {
  // redux actions
  actions: {
    formInit: Function,
  },
  // Sitecore fields
  fields: Object<Object>,
  // Redux form
  form: Object<Object>,
  // abn number data
  abnLookup: Object,
  // hanlde next button
  nextButton: Object,
}

export class RolloverPayment extends Component<RolloverProps> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit },
      fields,
      form,
    } = props
    const SCHEMA = custRolloverPaymentSchema({ fields })
    this.state = {
      SCHEMA,
    }
    if (!form) {
      formInit(FORM_ID, SCHEMA)
    }
  }

  handleChange = ({ name, value }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state

    const data = {
      value,
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  render() {
    const {
      fields,
      form,
      nextButton,
      abnLookup,
      abnLookup: { isLoading: isValidatingABN },
    } = this.props

    const { SCHEMA } = this.state

    const {
      cpEditPolicyPaymentDetailRolloverMembershipNum,
      cpEditPolicyPaymentDetailRolloverMembershipNumPlaceholder,
      cpEditPolicyPaymentDetailRolloverFundName,
      cpEditPolicyPaymentDetailRolloverFundNamePlaceholder,
      cpEditPolicyPaymentDetailRolloverProductName,
      cpEditPolicyPaymentDetailRolloverProductNamePlaceholder,
      cpEditPolicyPaymentDetailRolloverUSI,
      cpEditPolicyPaymentDetailRolloverUSIPlaceholder,
      cpEditPolicyPaymentDetailRolloverABN,
      cpEditPolicyPaymentDetailRolloverABNPlaceholder,
      cpEditPolicyPaymentDetailRolloverValidatingABNMsg,
      cpEditPolicyPaymentDetailRolloverCancelledABNErrorMsg,
    } = reduceAuthorableFields(fields)
    if (!form) return null

    const { usi, membershipNumber, fundName, productName, abn } = form.fields

    return (
      <Fragment>
        <Form id={FORM_ID} aria-labelledby="rolloverPaymentMethod">
          <Wrap>
            <MembershipNumber
              htmlFor={SCHEMA.membershipNumber.name}
              name={SCHEMA.membershipNumber.name}
              label={cpEditPolicyPaymentDetailRolloverMembershipNum}
              placeholder={cpEditPolicyPaymentDetailRolloverMembershipNumPlaceholder}
              changeHandler={this.handleChange}
              value={membershipNumber.value}
              error={membershipNumber.error.error}
              caption={
                membershipNumber.error.error
                  ? membershipNumber.error.errorMsg
                  : SCHEMA.membershipNumber.tooltip
              }
            />
            <FundName
              htmlFor="fundName"
              name={SCHEMA.fundName.name}
              label={cpEditPolicyPaymentDetailRolloverFundName}
              placeholder={cpEditPolicyPaymentDetailRolloverFundNamePlaceholder}
              value={fundName.value}
              changeHandler={this.handleChange}
              error={fundName.error.error}
              caption={fundName.error.error ? fundName.error.errorMsg : SCHEMA.fundName.tooltip}
            />
          </Wrap>
          <Wrap>
            <ProductName
              htmlFor="productName"
              name={SCHEMA.productName.name}
              label={cpEditPolicyPaymentDetailRolloverProductName}
              placeholder={cpEditPolicyPaymentDetailRolloverProductNamePlaceholder}
              changeHandler={this.handleChange}
              value={productName.value}
              error={productName.error.error}
              caption={
                productName.error.error ? productName.error.errorMsg : SCHEMA.productName.tooltip
              }
            />
            <UsiNumber
              htmlFor="usi"
              name={SCHEMA.usi.name}
              label={cpEditPolicyPaymentDetailRolloverUSI}
              placeholder={cpEditPolicyPaymentDetailRolloverUSIPlaceholder}
              changeHandler={this.handleChange}
              value={usi.value}
              error={usi.error.error}
              caption={usi.error.error ? usi.error.errorMsg : SCHEMA.usi.tooltip}
            />
          </Wrap>
          <AbnNumber
            htmlFor="abn"
            name={SCHEMA.abn.name}
            label={cpEditPolicyPaymentDetailRolloverABN}
            placeholder={cpEditPolicyPaymentDetailRolloverABNPlaceholder}
            changeHandler={this.handleChange}
            value={abn.value}
            error={abnLookup.error || abn.error.error}
            caption={
              (isValidatingABN && cpEditPolicyPaymentDetailRolloverValidatingABNMsg) ||
              (abnLookup.error || abn.error.error
                ? cpEditPolicyPaymentDetailRolloverCancelledABNErrorMsg
                : SCHEMA.abn.tooltip)
            }
          />
        </Form>
        {nextButton}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms, fundsLookUp, masterList, abnLookup }) => ({
  form: forms[FORM_ID],
  fundsLookUp,
  abnLookup,
  masterList,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RolloverPayment)
