// @flow
import get from 'lodash/get'
import { toast } from 'react-toastify'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

// types
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  BENEFICIARY_INFO_MODAL,
  BENEFICIARY_UPDATE,
  RELATIONSHIPS_UPDATE,
  PAYMENT_UPDATE,
  CUSTOMER_POLICY_BENEFICIARY_DETAILS_DONT_RETRY,
} from '../types/customerBeneficiary'

import { fetchPolicy } from './customerPolicies'

import { ACTION_ADD } from '../../constants/customerBeneficiary'

export const resetRetryFetchPolicyDetails = () => dispatch =>
  dispatch({ type: CUSTOMER_POLICY_BENEFICIARY_DETAILS_DONT_RETRY })

export const toggleBeneficiaryInfoModal = showModal => ({
  type: BENEFICIARY_INFO_MODAL,
  payload: {
    showModal,
  },
})

export const formatFormPayloadToRequestPayload = beneficiaryEntities => {
  const isAddAction = beneficiaryEntities.some(item => item.action === ACTION_ADD)
  const appendRelatedParty = benificiaryItem =>
    (isAddAction && benificiaryItem.action === ACTION_ADD) || !isAddAction
  return beneficiaryEntities.map(item => {
    // Stripping out unwanted data from relatedParty. Should be handled better. MM
    const { hasRelatedParty, retryCount, loadingState, ...otherCustomerData } = get(
      item,
      'customerData',
      {}
    )
    const hasNewDataForExisting =
      (item.customerData && item.relatedParty) || (!item.customerData && item.relatedParty)
    return {
      action: item.action,
      bancsCustomerNo: item.bancsCustomerNo,
      percentageAllocation: parseInt(item.percentageAllocation, 10),
      ...(appendRelatedParty(item) && {
        relatedParty: {
          ...(hasNewDataForExisting ? item.relatedParty : otherCustomerData),
        },
      }),
      relationship: item.relationship,
      roleCode: item.roleCode,
      role: item.role,
      status: item.status,
      type: item.type,
      startDate: item.startDate,
      expiryDate: item.expiryDate,
      beneficiaryRelationshipType: item.beneficiaryRelationshipType,
    }
  })
}

const failureToastBeneficiaires = () => {
  toast('Unable To Submit Your Request', {
    autoClose: false,
    toastId: '123aa',
    type: toast.TYPE.ERROR,
  })
}
export const updateBeneficiariesAction = data => dispatch => {
  const { benificiaryEntities, bancsPolicyNo } = data

  dispatch({
    type: EXPERIENCE_API,
    name: BENEFICIARY_UPDATE,
    verb: 'POST',
    data: {
      requestedBy: 'OWR',
      beneficiaries: formatFormPayloadToRequestPayload(benificiaryEntities),
    },
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/${bancsPolicyNo}/beneficiaries`,
    callback: (error, dataRecieved) => {
      if (error) {
        failureToastBeneficiaires()
      } else {
        toast('Request Submitted', {
          autoClose: true,
          toastId: '123aab',
          type: toast.TYPE.SUCCESS,
        })
        dispatch(fetchPolicy(bancsPolicyNo))
      }
      return dataRecieved
    },
    failureCallback: () => {
      failureToastBeneficiaires()
    },
  })
}

export const updateBeneficiariesFormForUpdate = ({
  benificiaryEntities,
  toAdd = false,
  bancsPolicyNo,
}) => ({
  type: 'BENEFICIARY_FORM_UPDATE',
  payload: {
    beneficiaries: benificiaryEntities,
    bancsCustomerNo: toAdd,
    bancsPolicyNo,
  },
})

export const showPercentageAdjustmentModal = (showModal = false) => ({
  type: 'BENEFICIARY_SHOW_ADJUSTMENT_MODAL',
  payload: showModal,
})

export const updateBeneficiariesFormReset = () => ({
  type: 'BENEFICIARY_FORM_RESET',
})

export const showBeneficiaryForm = (showForm, data = {}) => ({
  type: 'SHOW_BENEFICIARY_FORM',
  payload: {
    showBeneficiaryForm: showForm,
    ...data,
  },
})

export const updateRelationshipsForCustomerBeneficiaries = data => dispatch => {
  dispatch({
    type: RELATIONSHIPS_UPDATE,
    payload: { data },
  })
}

export const updatePaymentInstructions = (data, bancsPolicyNo, callback) => ({
  type: EXPERIENCE_API,
  name: PAYMENT_UPDATE,
  verb: 'POST',
  data: {
    paymentInstruction: {
      ...data,
    },
  },
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/${bancsPolicyNo}/paymentInstruction`,
  callback: (error, dataRecieved) => {
    if (callback) {
      callback(error, dataRecieved)
    }
    return dataRecieved
  },
})
