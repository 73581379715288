import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { space } = Variables as VariablesType

const styles = {
  downloadIcon: {
    marginRight: space(1),
  },
  buttonsContainer: {
    display: 'flex',
    a: {
      marginLeft: space(3),
    },
  },
}

export default styles
