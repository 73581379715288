// @flow
import React from 'react'

// redux.
import { connect } from 'react-redux'

// components.
import MainNav from './components/MainNavigation'
import CustNav from './components/CustomerNavigation'

// constants.
import {
  NAVIGATION_MAIN,
  NAVIGATION_DISABLED,
  NAVIGATION_CUSTOMER,
  NAVIGATION_ONLY_LOGO,
  NAVIGATION_CUSTOMER_PERSONAL_STATEMENT,
  NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION,
} from '../../../constants/navigation'

type NavigationProps = {
  // Sitecore fields
  fields: Object<Object>,
  // sitecore rendering
  rendering: Object,
  // user updated data of Quote
  navigationType: string,
}

const Navigation = (props: NavigationProps) => {
  const { fields, navigationType, rendering, params } = props
  const excludeSecondaryNavItems = ['beneficiaries']
  switch (navigationType) {
    case NAVIGATION_DISABLED:
      return null
    case NAVIGATION_CUSTOMER:
      return (
        <MainNav
          fields={fields}
          excludeSecondaryNavItems={excludeSecondaryNavItems}
          rendering={rendering}
          params={params}
        />
      )
    case NAVIGATION_ONLY_LOGO: {
      return <MainNav hideNavigationItems fields={fields} rendering={rendering} />
    }
    case NAVIGATION_CUSTOMER_PERSONAL_STATEMENT: {
      return <CustNav fields={fields} disableGoToDashboard />
    }
    case NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION: {
      return <CustNav fields={fields} showQuickLinkAction={false} disableGoToDashboard />
    }
    case NAVIGATION_MAIN: {
      return <MainNav fields={fields} rendering={rendering} params={params} />
    }
    default: {
      return <MainNav fields={fields} rendering={rendering} params={params} />
    }
  }
}

const mapStateToProps = ({ navigation: { navigationType } }) => ({
  navigationType,
})

export default connect(mapStateToProps)(Navigation)
