import { colours, space, fontWeights, mediaQueries } from '../../../../../styles'

const lastColumnTextAlignment = {
  textAlign: 'right',
}
const lastColumnTextAlignmentMobile = {
  textAlign: 'right',
}
const styles = {
  table: {
    width: '100%',
    background: colours.white,
    color: colours.black,
    tr: {
      borderTop: `1px solid ${colours.lightestGrey}`,
      '&:first-child': {
        borderTop: 'none',
      },
    },
    'th, td': {
      '&:last-child': lastColumnTextAlignmentMobile,
    },

    [mediaQueries.md]: {
      'th, td': {
        '&:last-child': lastColumnTextAlignment,
      },
    },
  },
  thead: {
    color: colours.mediumDarkGrey,
    opacity: 0.5,
    th: {
      fontWeight: fontWeights.semiBold,
      padding: `${space(3, true)} ${space(5, true)}`,
      textAlign: 'left',
    },
  },
  tbody: {
    td: {
      padding: `${space(4, true)} ${space(5, true)}`,
      '&:first-child': {
        fontWeight: fontWeights.semiBold,
      },
    },
    tr: {
      '&:nth-child(odd)': {
        background: colours.offWhite,
      },
    },
  },
  tfoot: {
    td: {
      padding: `${space(2, true)}`,
    },
  },
  infoIcon: {
    color: colours.lightBlack,
  },
  infoIconContainer: {
    display: 'inline-flex',
    paddingLeft: space(1),
  },
  tooltip: {
    width: space(35, true),
  },
}

export const styleOverrides = {
  feature: {
    color: colours.black,
    fontWeight: fontWeights.semiBold,
  },
  childName: {
    fontWeight: fontWeights.normal,
  },
  pdsMessage: {
    color: colours.lightGreen,
    fontWeight: fontWeights.semiBold,
    paddingRight: space(0.5),
  },
  arrowRightIcon: {
    color: colours.lightGreen,
  },
  pdsLink: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}
export default styles
