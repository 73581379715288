// @flow
import React from 'react'

// components.
import CPHeaderComponent from '../../../organisms/CPHeader'

type CPHeaderProps = {
  // Sitecore fields.
  fields: Object<Object>,
  // Params
  params: Object,
}

const CPHeader = ({ fields, params }: CPHeaderProps) => (
  <CPHeaderComponent fields={fields} params={params} />
)

export default CPHeader
