import React from 'react'
import styled from '@emotion/styled'
import { Chip } from '@mlcl-digital/mlcl-design'
import moment from 'moment'
import PropTypes from 'prop-types'
import { dollarAmountWithCommasAndTwoDecimal } from '../../../utils/formUtils'

// Components
import { CardTable, CardCell, Card as NewCard } from '../CardChildren'

// Constants
import {
  POLICY_OUT_OF_FORCE,
  POLICY_LAPSE_TOO_LONG,
  POLICY_PAYMENT_INVALID,
  POLICY_LAPSE_STATUS,
} from '../../../constants/payments'
import {
  POLICY_PREFIX,
  POLICY_CANCELLED,
  PAYMENT_OVERDUE,
  LIFE_ASSURED,
  BENEFITS,
  PREMIUM_SUFFIX,
  OUTSTANDING_BALANCE,
  NEXT_PAYMENT_DUE,
} from '../../../constants/cards'

// Utils
import { renderTextField } from '../../../utils/sitecoreUtils'

import styles from './CustomerOneOffPaymentCard.styles'

const Section = styled('div')(styles.section)
const Title = styled('div')(styles.title)
const Table = styled(CardTable)(styles.table)
const Exception = styled('div')(styles.exception)
const ChipWrap = styled('div')(styles.chips)

export const PolicyException = props => {
  const { reason, fields } = props
  const { LapsedReason, OutOfForceReason, InvalidPaymentReason, PolicyRevivableLapsedMessage } =
    fields
  const contentMap = {
    [POLICY_LAPSE_TOO_LONG]: LapsedReason,
    [POLICY_OUT_OF_FORCE]: OutOfForceReason,
    [POLICY_PAYMENT_INVALID]: InvalidPaymentReason,
    [POLICY_LAPSE_STATUS]: PolicyRevivableLapsedMessage,
  }
  return (
    <Exception danger={reason === POLICY_LAPSE_STATUS}>
      {renderTextField(contentMap[reason])}
    </Exception>
  )
}

export const StatusChip = ({ status, invalidReason, balance }) => {
  const isPolicyCancelled = invalidReason === POLICY_OUT_OF_FORCE || status === POLICY_LAPSE_STATUS
  const paymentOverDue = balance > 0

  return (
    (isPolicyCancelled || paymentOverDue) && (
      <Chip variant={isPolicyCancelled ? 'danger' : 'warning'}>
        {isPolicyCancelled ? POLICY_CANCELLED : PAYMENT_OVERDUE}
      </Chip>
    )
  )
}

const CustomerOneOffPaymentCard = ({ policy, fields }) => (
  <NewCard
    header={
      <>
        <ChipWrap>
          <Chip variant={policy.policyType === 'Inside Super' ? 'default' : 'important'}>
            {policy.policyType}
          </Chip>
          <StatusChip
            status={policy.status}
            invalidReason={policy.inValidReason}
            balance={policy.outstandingBalance}
          />
        </ChipWrap>
        <div>
          <span>
            {POLICY_PREFIX} {policy.policyId}
          </span>
        </div>
        <div>{policy.policyName}</div>
      </>
    }
    borderless
  >
    <div>
      <Section removeBottomPadding={policy.benefits.length > 0}>
        <Title>{LIFE_ASSURED}</Title>
        {policy.lifeAssured.join(', ')}
      </Section>
      {policy.benefits.length > 0 && (
        <Section>
          <Title>{BENEFITS}</Title>
          {policy.benefits.join(', ')}
        </Section>
      )}
      <Table>
        <CardCell>
          {policy.policyPremiumFrequency}
          {PREMIUM_SUFFIX}
        </CardCell>
        <CardCell>{policy.premium}</CardCell>
        <CardCell danger={policy.outstandingBalance > 0}>{OUTSTANDING_BALANCE}</CardCell>
        <CardCell danger={policy.outstandingBalance > 0}>
          {dollarAmountWithCommasAndTwoDecimal(policy.outstandingBalance)}
        </CardCell>
        <CardCell>{NEXT_PAYMENT_DUE}</CardCell>
        <CardCell>
          {policy.nextPremiumDueDate === 'N/A'
            ? policy.nextPremiumDueDate
            : moment(policy.nextPremiumDueDate, 'YYYY-MM-DD').format('Do MMM YYYY')}
        </CardCell>
      </Table>
      {policy.inValidReason && <PolicyException reason={policy.inValidReason} fields={fields} />}
    </div>
  </NewCard>
)

export default CustomerOneOffPaymentCard

CustomerOneOffPaymentCard.propTypes = {
  policy: PropTypes.shape({
    policyType: PropTypes.string,
    status: PropTypes.string,
    inValidReason: PropTypes.string,
    outstandingBalance: PropTypes.number,
    policyId: PropTypes.string,
    policyName: PropTypes.string,
    lifeAssured: PropTypes.arrayOf(PropTypes.string),
    benefits: PropTypes.arrayOf(PropTypes.string),
    policyPremiumFrequency: PropTypes.string,
    premium: PropTypes.string,
    nextPremiumDueDate: PropTypes.string,
  }).isRequired,
  fields: PropTypes.shape({
    LapsedReason: PropTypes.object,
    OutOfForceReason: PropTypes.object,
    InvalidPaymentReason: PropTypes.object,
  }).isRequired,
}

PolicyException.propTypes = {
  reason: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    LapsedReason: PropTypes.object,
    OutOfForceReason: PropTypes.object,
    InvalidPaymentReason: PropTypes.object,
  }).isRequired,
}
