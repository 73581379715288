// @flow
import React from 'react'
import CustomerDashBoardContainer from '../../../organisms/CustomerDashboard'

type CustomerDashboardProps = {
  fields: Object,
  // Params
  params: Object,
}

const CustomerDashboard = ({ fields, params }: CustomerDashboardProps) => (
  <CustomerDashBoardContainer fields={fields} params={params} />
)

export default CustomerDashboard
