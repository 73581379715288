// @flow
import React from 'react'
import PremiumCalculatorQuotePreviewComp from '../../../organisms/PremiumCalculator/PremiumCalculatorQuotePreview'

type PremiumCalculatorQuotePreviewProps = {
  fields: Object,
}

const PremiumCalculatorQuotePreview = ({ fields }: PremiumCalculatorQuotePreviewProps) => (
  <PremiumCalculatorQuotePreviewComp fields={fields} />
)

export default PremiumCalculatorQuotePreview
