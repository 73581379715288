// @flow
import React from 'react'
import PaymentsOneOffPaymentContainer from '../../../organisms/CustomerOneOffPayment'

type PaymentsOneOffPaymentProps = {
  fields: Object,
  rendering: Object,
}

const PaymentsOneOffPayment = ({ fields, rendering }: PaymentsOneOffPaymentProps) => (
  <PaymentsOneOffPaymentContainer fields={fields} rendering={rendering} />
)

export default PaymentsOneOffPayment
