export const FETCH_NAB_CONFIG = 'FETCH_NAB_CONFIG'
export const FETCH_NAB_CONFIG_INIT = 'FETCH_NAB_CONFIG_INIT'
export const FETCH_NAB_CONFIG_SUCCESS = 'FETCH_NAB_CONFIG_SUCCESS'
export const FETCH_NAB_CONFIG_ERROR = 'FETCH_NAB_CONFIG_ERROR'
export const FETCH_NAB_CONFIG_FAILURE = 'FETCH_NAB_CONFIG_FAILURE'

export const INITIATE_NAB_TRANSACT = 'INITIATE_NAB_TRANSACT'
export const INITIATE_NAB_TRANSACT_SUCCESS = 'INITIATE_NAB_TRANSACT_SUCCESS'
export const INITIATE_NAB_TRANSACT_ERROR = 'INITIATE_NAB_TRANSACT_ERROR'
export const INITIATE_NAB_TRANSACT_FAILURE = 'INITIATE_NAB_TRANSACT_FAILURE'

export const UPDATE_NAB_TRANSACT = 'UPDATE_NAB_TRANSACT'
export const UPDATE_NAB_TRANSACT_SUCCESS = 'UPDATE_NAB_TRANSACT_SUCCESS'
export const UPDATE_NAB_TRANSACT_ERROR = 'UPDATE_NAB_TRANSACT_ERROR'
export const UPDATE_NAB_TRANSACT_FAILURE = 'UPDATE_NAB_TRANSACT_FAILURE'

export const UPDATE_SAVE_CARD_TRANSACT = 'UPDATE_SAVE_CARD_TRANSACT'
export const UPDATE_SAVE_CARD_TRANSACT_SUCCESS = 'UPDATE_SAVE_CARD_TRANSACT_SUCCESS'
export const UPDATE_SAVE_CARD_TRANSACT_ERROR = 'UPDATE_SAVE_CARD_TRANSACT_ERROR'
export const UPDATE_SAVE_CARD_TRANSACT_FAILURE = 'UPDATE_SAVE_CARD_TRANSACT_FAILURE'

export const RESET_NAB_DATA = 'RESET_NAB_DATA'
