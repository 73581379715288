import {
  LOA_REQUEST_INIT,
  LOA_REQUEST_SUCCESS,
  LOA_REQUEST_FAILURE,
  LOA_REQUEST_RESET,
  LOA_REMOVE_SUCCESS,
} from '../actions/types/letterOfAuthority'

export const initialState = {
  isLoading: false,
  hasError: false,
  data: [],
  createSuccess: false,
  removeSuccess: false,
}

const letterOfAuthority = (
  state: object = initialState,
  action: { type: string; payload?: object; error: boolean }
) => {
  switch (action.type) {
    case LOA_REQUEST_INIT: {
      return {
        ...state,
        isLoading: true,
        hasError: false,
        createSuccess: false,
        removeSuccess: false,
      }
    }
    case LOA_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...(!action.payload && { createSuccess: true }),
        ...(action.payload && { data: action.payload }),
      }
    }
    case LOA_REQUEST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    }
    case LOA_REQUEST_RESET: {
      return {
        ...state,
        isLoading: false,
        hasError: false,
        createSuccess: false,
        removeSuccess: false,
      }
    }
    case LOA_REMOVE_SUCCESS: {
      return {
        ...state,
        removeSuccess: true,
      }
    }
    default: {
      return state
    }
  }
}

export default letterOfAuthority
