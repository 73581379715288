// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Input from '../../../../../../../atoms/Input'

import styles from './directDebit.styles'

import { actionCreators } from '../../../../../../../../actions'
import { errorCheck } from '../../../../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../../../../utils/sitecoreUtils'
import custDirectDebitSchema, { FORM_ID } from './directDebit.schema'

const AccountName = styled(Input)(styles.accountName)
const DrawDate = styled(Input)(styles.drawDate)
const DirectDebitInput = styled(Input)(styles.directDebitInput)
const DirectDebitContainer = styled('div')(styles.directDebitContainer)

type Props = {
  actions: Object,
  fields: Object,
  form: Object,
  nextButton: Object,
  fileUploadInfo: { isModalOpen: Boolean },
  paymentInstruction: Object,
  bsbLookup: Object,
}
type State = {
  paymentDrawDayOptions: Array<Object>,
  SCHEMA: Object,
  bsbField: string,
}

export class DirectDebit extends Component<Props, State> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit, formResetField },
      fields,
      form,
    } = props

    const SCHEMA = custDirectDebitSchema({ fields })
    // initializing form
    if (!form) {
      formInit(FORM_ID, SCHEMA)
    }

    formResetField(FORM_ID, ['accountName', 'accountNumber', 'directDebitBsb'])
    this.state = {
      SCHEMA,
      bsbField: '',
    }
  }

  componentWillReceiveProps(nextProps: Object) {
    const { bsbLookup } = this.props
    // error in bsb lookup
    if (
      bsbLookup &&
      nextProps.bsbLookup.isError !== bsbLookup.isError &&
      nextProps.bsbLookup.isError
    ) {
      const {
        fields: { cpEditPaymentDirectDebitPaymentBSBError, cpEditPaymentValidatingBsbError },
      } = this.props
      const errorMsg =
        get(nextProps, 'bsbLookup.data.errorCode', false) === 404
          ? cpEditPaymentValidatingBsbError.value
          : cpEditPaymentDirectDebitPaymentBSBError.value
      this.updateBsbField('', true, errorMsg)
    }

    if (
      bsbLookup &&
      !nextProps.bsbLookup.isFetching &&
      bsbLookup.isFetching &&
      !nextProps.bsbLookup.isError
    ) {
      const { bsbField } = this.state
      this.updateBsbField(bsbField, false, '')
    }
  }

  handleBsbChange = ({ value }) => {
    // @FIXME: need to determine if param assignment is neccessary
    // eslint-disable-next-line no-param-reassign
    value = value.split('-').join('')
    const formattedVal = value.length ? value.match(/.{1,3}/g).join('-') : ''
    this.setState({
      bsbField: formattedVal,
    })
  }

  /**
   * Handles BSB field blur event
   * @param event - event object
   */
  handleBsbFieldBlur = (event: Object, name: string) => {
    const {
      actions: { validateBsb },
      fields,
    } = this.props
    const { cpEditPaymentDirectDebitPaymentBSBError } = reduceAuthorableFields(fields)
    const {
      target: { value },
    } = event
    if (value) {
      let bsbValue = value
      if (value[3] === ' ') {
        bsbValue = `${value.substring(0, 3)}-${value.substring(4)}`
      } else if (value[3] && value[3] !== '-') {
        bsbValue = `${value.substring(0, 3)}-${value.substring(3)}`
      }

      // action for api call to validate BSB code
      validateBsb({ name, value: bsbValue })
    }
    this.updateBsbField('', true, cpEditPaymentDirectDebitPaymentBSBError)
  }

  updateBsbField = (value, error, errorMsg) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state
    const data = {
      value,
      error: {
        error,
        errorMsg,
      },
    }
    formUpdateField(FORM_ID, 'directDebitBsb', data)
    formValidate(FORM_ID, SCHEMA)
  }

  // Handles changes in form fields
  handleFieldChange = ({ name, value }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state

    const data = {
      value,
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  render() {
    const { form, fields, nextButton, paymentInstruction, bsbLookup } = this.props
    const { SCHEMA, bsbField } = this.state

    const {
      cpEditPaymentDirectDebitPaymentBSBLabel,
      cpEditPaymentDirectDebitPaymentBSBPlaceholder,
      cpEditPaymentDirectDebitPaymentAccountNumberLabel,
      cpEditPaymentDirectDebitPaymentAccountNumberPlaceholder,
      cpEditPaymentDirectDebitPaymentAccountNameLabel,
      cpEditPaymentDirectDebitPaymentAccountNamePlaceholder,
      cpEditPaymentValidatingBsbLabel,
    } = reduceAuthorableFields(fields)
    if (!form) {
      return null
    }
    const { collectionDay } = paymentInstruction
    const { directDebitBsb, accountName, accountNumber } = form.fields

    return (
      <div>
        <form aria-labelledby="DirectDebitForm">
          <DirectDebitContainer>
            <DirectDebitInput
              htmlFor="directDebitBsb"
              name="directDebitBsb"
              changeHandler={this.handleBsbChange}
              error={directDebitBsb.error.error}
              caption={
                directDebitBsb.error.error &&
                (bsbLookup && bsbLookup.isFetching
                  ? cpEditPaymentValidatingBsbLabel
                  : directDebitBsb.error.errorMsg)
              }
              value={bsbField}
              label={cpEditPaymentDirectDebitPaymentBSBLabel}
              placeholder={cpEditPaymentDirectDebitPaymentBSBPlaceholder}
              blurHandler={this.handleBsbFieldBlur}
            />
            <DirectDebitInput
              htmlFor="accountNumber"
              name="accountNumber"
              changeHandler={this.handleFieldChange}
              error={accountNumber.error.error}
              caption={
                accountNumber.error.error
                  ? accountNumber.error.errorMsg
                  : SCHEMA.accountNumber.tooltip
              }
              value={accountNumber.value}
              label={cpEditPaymentDirectDebitPaymentAccountNumberLabel}
              placeholder={cpEditPaymentDirectDebitPaymentAccountNumberPlaceholder}
            />
          </DirectDebitContainer>
          <DirectDebitContainer>
            <AccountName
              htmlFor="accountName"
              label={cpEditPaymentDirectDebitPaymentAccountNameLabel}
              name="accountName"
              placeholder={cpEditPaymentDirectDebitPaymentAccountNamePlaceholder}
              changeHandler={this.handleFieldChange}
              error={accountName.error.error}
              caption={
                accountName.error.error ? accountName.error.errorMsg : SCHEMA.accountName.tooltip
              }
              value={accountName.value}
            />
            <DrawDate
              htmlFor="paymentDrawDate"
              name="paymentDrawDate"
              value={collectionDay}
              disabled
            />
          </DirectDebitContainer>
          {nextButton}
        </form>
      </div>
    )
  }
}

export const mapStateToProps = ({ forms, captureDirectDebit, bsbLookup }) => ({
  form: forms[FORM_ID],
  captureDirectDebit,
  bsbLookup: bsbLookup.directDebitBsb,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DirectDebit)
