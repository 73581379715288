import React, { useCallback } from 'react'
import { object, objectOf } from 'prop-types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

// Selectors
import {
  getActivePolicy,
  getFileUploadFormName,
} from '../../../../../../selectors/customerPaymentDetails'

// Molecules
import FileUpload from '../../../../../molecules/FileUpload'

// Utils
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { browserOrigin } from '../../../../../../utils/browserUtils'
import { getDocumentTypeDetails } from '../../../../../../utils/fileUpload'

const FileUploadModal = ({ fields, fileUploadMeta }) => {
  const policyNo = useSelector(state => getActivePolicy(state))
  const formName = useSelector(state => getFileUploadFormName(state))
  const documentTypes = useSelector(state => get(state.masterList, 'data.documentTypes', []))

  const {
    paymentEditConfirmButton,
    customerEditUploadBenneficiaryFormMaxFileCount,
    customerEditUploadBenneficiaryFormMaxFileSize,
    customerEditUploadBenneficiaryFormMaxFileSizeError,
    customerEditUploadBenneficiaryFormWrongMimeType,
    customerEditUploadBenneficiaryFormUploading,
    customerEditUploadBenneficiaryFormUploadSuccess,
    customerEditUploadBenneficiaryFormUploadFailure,
    customerEditUploadBenneficiaryFormWorkItemFailure,
    customerEditUploadBenneficiaryFormWorkItemSuccess,
    customerEditUploadBenneficiaryFormFileRemoveLabel,
    customerEditUploadBenneficiaryFormNoFileError,
    customerEditUploadBenneficiaryFormWrongFileNameError,
  } = reduceAuthorableFields(fields)

  const data = useCallback(() => {
    const {
      customerEditPaymentDetailsHeadingLabel,
      customerEditPaymentDetailsSubHeadingLabel,
      ...otherFields
    } = reduceAuthorableFields(fields)

    const documentEntity = getDocumentTypeDetails(otherFields[formName], documentTypes)[0]

    return {
      modalHeading: customerEditPaymentDetailsHeadingLabel,
      documentName: get(documentEntity, 'documentTypeName', ''),
      documentPath: {
        value: `<a href="${
          browserOrigin() + get(documentEntity, 'link', '')
        }" target=_blank>Download</a>`,
      },
      docSubTypeCode: get(documentEntity, 'documentCode', ''),
      modalSubHeading: customerEditPaymentDetailsSubHeadingLabel,
      workType: get(documentEntity, 'workType', ''),
    }
  }, [documentTypes])

  const { modalHeading, modalSubHeading, documentName, documentPath, docSubTypeCode, workType } =
    data()

  const createWorkItemRequest = {
    workTypeCode: workType,
    bancsPolicyNo: policyNo,
  }

  if (fileUploadMeta) {
    createWorkItemRequest.comment = isEmpty(fileUploadMeta) ? '' : JSON.stringify(fileUploadMeta)
  }
  return (
    <FileUpload
      formDownloadMeta={{
        documentName,
        documentPath,
        docSubTypeCode,
      }}
      modalMeta={{
        modalHeading,
        modalSubHeading,
        modalConfirmButton: paymentEditConfirmButton,
      }}
      dropzoneMeta={{
        maxFileNumber: customerEditUploadBenneficiaryFormMaxFileCount,
        maxFileSize: customerEditUploadBenneficiaryFormMaxFileSize,
      }}
      fileUploadMeta={{
        maxFileSizeError: customerEditUploadBenneficiaryFormMaxFileSizeError,
        wrongFileType: customerEditUploadBenneficiaryFormWrongMimeType,
        fileUploading: customerEditUploadBenneficiaryFormUploading,
        fileUploadSuccess: customerEditUploadBenneficiaryFormUploadSuccess,
        fileUploadFailure: customerEditUploadBenneficiaryFormUploadFailure,
        createWorkItemError: customerEditUploadBenneficiaryFormWorkItemFailure,
        createWorkItemSuccess: customerEditUploadBenneficiaryFormWorkItemSuccess,
        fileRemoveButtonLabel: customerEditUploadBenneficiaryFormFileRemoveLabel,
        noFileUploadedError: customerEditUploadBenneficiaryFormNoFileError,
        wrongFileNameError: customerEditUploadBenneficiaryFormWrongFileNameError,
      }}
      createWorkItemRequest={createWorkItemRequest}
      workItemRequired
      uploadDocumentRequest={{
        docSubTypeCode,
        workType,
      }}
    />
  )
}

FileUploadModal.propTypes = {
  fields: objectOf(object).isRequired,
  fileUploadMeta: objectOf(object).isRequired,
}

export default FileUploadModal
