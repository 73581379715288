import React from 'react'
import styled from '@emotion/styled'
// components
import { Heading } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import Policy from '../Policy'

// types
import { CoverGroupsProp } from '../../../../../types/components/PremiumCalculator'

// styles
import styles from './coverGroups.styles'

const StyledHeading = styled(Heading)(styles.heading)
const CoverHeader = styled('div')(styles.coverHeader)

const CoverGroups = ({ premiumCalculateQuoteData, fields }: CoverGroupsProp) => (
  <div>
    {premiumCalculateQuoteData.map(({ coverGroupName, coverGroup }) => (
      <div key={coverGroupName}>
        <CoverHeader>
          <StyledHeading variant="h2" size="medium">
            <Icon iconName={['far', 'umbrella-simple']} />
            {coverGroupName}
          </StyledHeading>
        </CoverHeader>
        {coverGroup.map(({ policy, covers }) => (
          <Policy fields={fields} policy={policy} covers={covers} />
        ))}
      </div>
    ))}
  </div>
)

export default CoverGroups
