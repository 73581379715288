// flow
import get from 'lodash/get'
import moment from 'moment'

// Constants
import { BENEFICIARY_TYPE_NON_BINDING_CODE } from '../constants/forms'
import {
  COUNTRY_CODE,
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../constants/policies'
import {
  ACTION_ADD,
  ACTION_UPDATE,
  ACTION_REMOVE,
  newBeneficiary,
  RELATIONSHIP_LEGAL_REP,
} from '../constants/customerBeneficiary'

// Utils
import { getFullAddressWithState, isPartyTypeOrg, determineDistrict } from './contactUtils'
import { DATE_FORMAT, CLIENT_SIDE_DATE_FORMAT } from './quoteUtils'

export const initBeneficiaryForm = (dataForInput, partyType, lifeAssured) => {
  const beneficiaryRelationshipType = isPartyTypeOrg(partyType)
    ? RELATIONSHIP_LEGAL_REP
    : get(dataForInput, 'relationshipType', '')
  const title = get(dataForInput, 'customerData.title', '')
  const firstName = get(dataForInput, 'customerData.firstName', '')
  const lastName = get(dataForInput, 'customerData.lastName', '')
  const dob = get(dataForInput, 'customerData.dateOfBirth', '')
  const dateOfBirth = dob !== '' ? moment(dob).format(CLIENT_SIDE_DATE_FORMAT) : ''
  const residentialAddress = getFullAddressWithState(
    get(dataForInput, 'customerData.contactMethods.addresses', '')
  )
  const residentialAddressHouseNo = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].houseNo',
    ''
  )
  const residentialAddressPONo = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].district',
    ''
  )
  const residentialAddressStreet = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].street',
    ''
  )
  const residentialAddressLocality = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].locality',
    ''
  )
  const residentialAddressState = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].state',
    ''
  )
  const residentialAddressCountry = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].country',
    COUNTRY_CODE
  )
  const residentialAddressPostCode = get(
    dataForInput,
    'customerData.contactMethods.addresses[0].postCode',
    ''
  )

  let businessName = ''
  if (isPartyTypeOrg(partyType)) {
    const laFirstName = get(lifeAssured, 'firstName', '')
    const laLastName = get(lifeAssured, 'lastName', '')
    businessName = get(
      dataForInput,
      'customerData.businessName',
      `Estate Trust of ${laFirstName} ${laLastName}`
    )
  }

  return {
    useAsPostalAddress: true,
    title,
    lastName,
    firstName,
    dateOfBirth,
    beneficiaryRelationshipType,
    residentialAddress,
    residentialAddressHouseNo,
    residentialAddressPONo,
    residentialAddressStreet,
    residentialAddressLocality,
    residentialAddressState,
    residentialAddressCountry,
    residentialAddressPostCode,
    businessName,
  }
}

export const updateActionInBeneficiary = relationships =>
  relationships.map(relation => ({
    ...relation,
    action: ACTION_UPDATE,
    relationship: relation.relationshipType,
    type: get(relation, 'bindingType', BENEFICIARY_TYPE_NON_BINDING_CODE),
  }))

export const formatBeneficiaryForm = (
  form = null,
  bancsCustomerNo,
  relationships,
  action,
  partyType
) => {
  const relationshipsWithActions = updateActionInBeneficiary(relationships)
  const indexOfBeneficiary =
    action !== ACTION_ADD
      ? relationships.findIndex(relation => relation.bancsCustomerNo === bancsCustomerNo)
      : undefined
  if (action === ACTION_REMOVE) {
    relationshipsWithActions[indexOfBeneficiary] = {
      ...relationshipsWithActions[indexOfBeneficiary],
      action: ACTION_REMOVE,
      type: BENEFICIARY_TYPE_NON_BINDING_CODE,
    }
  } else {
    const {
      title,
      firstName,
      lastName,
      dateOfBirth,
      beneficiaryRelationshipType,
      residentialAddressHouseNo,
      residentialAddressPONo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      businessName,
      postalType,
      postalNumber,
    } = form.fields

    const beneficiaryToUpdate =
      action === ACTION_ADD
        ? { ...newBeneficiary }
        : { ...relationshipsWithActions[indexOfBeneficiary] }
    const benefitTitle = title.value

    const addressList = get(beneficiaryToUpdate, 'customerData.contactMethods.addresses', [])
    const existingDistrictValue = get(
      addressList.find(({ preferred }) => preferred === 'Y'),
      'district',
      ''
    )
    const addressPayload = {
      houseNo: residentialAddressHouseNo.value,
      street: postalType && postalType.value === 'PO BOX' ? '' : residentialAddressStreet.value,
      locality: residentialAddressLocality.value,
      state: residentialAddressState.value,
      country: residentialAddressCountry.value.value || COUNTRY_CODE,
      postCode: residentialAddressPostCode.value.toString(),
      addressType: 'S',
      district:
        residentialAddressPONo.value ||
        determineDistrict(postalType, postalNumber, existingDistrictValue),
    }

    const benefRelation = beneficiaryRelationshipType.value
    const finalPayload = {
      ...beneficiaryToUpdate,
      percentageAllocation:
        relationshipsWithActions.length === 0 ? '100' : beneficiaryToUpdate.percentageAllocation,
      type: BENEFICIARY_TYPE_NON_BINDING_CODE,
      relationship: typeof benefRelation === 'object' ? benefRelation.value : benefRelation,
      relatedParty: {
        ...get(beneficiaryToUpdate, 'relatedParty', {}),
        ...(isPartyTypeOrg(partyType)
          ? {
              partyType: POLICY_PARTY_TYPE_BUSINESS,
              businessName: businessName.value,
            }
          : {
              partyType: POLICY_PARTY_TYPE_INDIVIDUAL,
              title: typeof benefitTitle === 'object' ? benefitTitle.value : benefitTitle,
              firstName: firstName.value,
              lastName: lastName.value,
              ...(dateOfBirth &&
                dateOfBirth.value && {
                  dateOfBirth: moment(dateOfBirth.value, CLIENT_SIDE_DATE_FORMAT).format(
                    DATE_FORMAT
                  ),
                }),
            }),
        contactMethods: {
          ...get(beneficiaryToUpdate, 'relatedParty.contactMethods', {}),
          addresses: addressList.length
            ? addressList.map(address => ({
                ...address,
                ...(address.preferred === 'Y' && {
                  ...addressPayload,
                }),
              }))
            : [
                {
                  ...addressPayload,
                  preferred: 'Y',
                },
              ],
        },
      },
    }

    if (action === ACTION_ADD) {
      relationshipsWithActions.push(finalPayload)
    } else {
      relationshipsWithActions[indexOfBeneficiary] = finalPayload
    }
  }

  return relationshipsWithActions
}
