import { Variables } from '@mlcl-digital/mlcl-design'

const { fontSize, fontWeights, fontFamily, shadows, colours, space } = Variables

const styles = {
  modalSubHeading: {
    padding: '0 0 2rem 0',
    fontSize: fontSize.xs,
    fontWeight: fontWeights.normal,
    fontFamily: fontFamily.SOURCE_SANS_PRO,
  },
  policyPayerWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  policyPayerOption: {
    width: '49%',
    fontSize: fontSize.sm,
    boxShadow: shadows.low,
    textAlign: 'center',
    border: `1px solid ${colours.lightestGrey}`,
    padding: `${space(7.5, true)} ${space(2.75, true)}`,
    fontWeight: fontWeights.normal,
    fontFamily: fontFamily.SOURCE_SANS_PRO,
    background: colours.white,
    '&:hover, &:focus': {
      outline: 'none',
    },
  },
}
export default styles
