// @flow
/* eslint-disable max-len */
import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// component.
import HeadingComponent from '../../../../atoms/Heading'
import LockIcon from '../../../../atoms/Icons/IconLock32'

// styles.
import styles from './unAuthenticated.styles'

import { renderTextField } from '../../../../../utils/sitecoreUtils'

const Main = styled('div')(styles.main)
const CompletedIcon = styled('div')(styles.completedIcon)
const Caption = styled(HeadingComponent)(styles.caption)
const Message = styled('p')(styles.message)
const ExploreLink = styled('div')(styles.exploreButton)

type UnAuthenticatedTypes = {
  fields: {
    unAuthenticatedUserPageTitle: string,
    unAuthenticatedUserPageSubtitle: string,
    unAuthenticatedUserPageExploreButton: string,
  },
}

export const UnAuthenticated = ({ fields }: UnAuthenticatedTypes) => {
  const {
    unAuthenticatedUserPageTitle,
    unAuthenticatedUserPageSubtitle,
    unAuthenticatedUserPageExploreButton,
    ExploreMLCLink,
  } = fields

  return (
    <Main>
      <CompletedIcon>
        <LockIcon />
      </CompletedIcon>
      <Caption size={3}>{renderTextField(unAuthenticatedUserPageTitle)}</Caption>
      <Message>{renderTextField(unAuthenticatedUserPageSubtitle, true)}</Message>
      <ExploreLink>
        <a href={get(ExploreMLCLink, 'value.href', '')}>
          {renderTextField(unAuthenticatedUserPageExploreButton)}
        </a>
      </ExploreLink>
    </Main>
  )
}

export default UnAuthenticated
