import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontSize } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(3),
  },
  panelHeading: {
    background: colours.mlcAquaLight,
    color: colours.tertiaryOne,
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    padding: `${space(2, true)} ${space(3, true)}`,
  },
  detailsSection: {
    color: colours.tertiaryOne,
    padding: `${space(2, true)} ${space(3, true)} 0`,
    '& p': {
      marginTop: space(1),
    },
    '& span': {
      fontWeight: fontWeights.semiBold,
    },
  },
  buttonContainer: {
    marginTop: space(4),
    button: {
      marginRight: space(2.5),
    },
  },
  notification: {
    margin: `${space(3, true)} 0`,
    fontWeight: fontWeights.light,
    display: 'flex',
    border: 0,
    div: {
      margin: `${space(0.5, true)} 0 0 ${space(1, true)}`,
      p: {
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    svg: {
      fontSize: fontSize.md,
    },
    span: {
      fontWeight: fontWeights.semiBold,
    },
  },
  resetButton: ({ disabled }: { disabled: boolean }) =>
    disabled && {
      color: colours.tertiaryThree,
      background: 'transparent',
    },
}

export default styles
