// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// components
import { Text } from '@sitecore-jss/sitecore-jss-react'

// styles
import styles from './adviserInfoCard.styles'

// utils.
import {
  getFullName,
  getWorkPhone,
  getEmail,
  getFullAddressWithState,
} from '../../../../utils/contactUtils'

// const
import { PARTY_TYPE_PERSON } from '../../../../constants/adviser'
import { POLICY_MEMBER_IDENTIFIER_CUSTOMER } from '../../../../constants/policies'

type AdviserInfoCardPropTypes = {
  advisor: {
    contactMethods: {
      addresses: Array,
      emails: Array,
      phones: Array,
    },
    employment: {
      occupation: string,
    },
  },
  fields: Object,
  policyIds: Array,
  showRemoveModal: Function,
  isRemoveAdviserButtonRendered: Boolean,
}

const AgentName = styled('div')(styles.label)
const Divider = styled('hr')(styles.divider)
const AdviserDetailsLabel = styled('div')(styles.adviserDetails)
const InfoWrapper = styled('div')(styles.wrapperInfo)
const InfoLabel = styled('div')(styles.infoLabel)
const InfoValue = styled('div')(styles.infoValue)
const PolicyIdsContainer = styled('div')(styles.idsContainer)
const PolicyIdValue = styled('div')(styles.idValue)
const CardHeader = styled('div')(styles.cardHeader)
const HeaderLeft = styled('div')(styles.headerLeft)
const HeaderRight = styled('div')(styles.headerRight)
export const RemoveLink = styled('div')(styles.removeLink)

const getBankCustomerNo = adviser =>
  adviser.identifiers.find(id => id.type === POLICY_MEMBER_IDENTIFIER_CUSTOMER).value

const renderPolicyIds = policyMeta =>
  Object.values(policyMeta).map(({ policyId }) => (
    <PolicyIdValue key={policyId}>{policyId}</PolicyIdValue>
  ))

const AdviserInfoCard = ({
  advisor,
  fields,
  policyIds,
  showRemoveModal,
  isRemoveAdviserButtonRendered,
}: AdviserInfoCardPropTypes) => {
  const {
    adviserDetailsLabel,
    adviserPhoneLabel,
    adviserEmailLabel,
    adviserAddressLabel,
    adviserPoliciesServicingLabel,
    adviserPoliciesRemoveLabel,
  } = fields

  const showRemoveConfirm = () => {
    showRemoveModal(getBankCustomerNo(advisor), policyIds)
  }
  const contactNumber = getWorkPhone(advisor.contactMethods.phones)
  const phone = contactNumber.number ? (
    <a href={`tel:+${contactNumber.idc} ${contactNumber.number}`}>
      +{contactNumber.idc} {contactNumber.number}
    </a>
  ) : (
    '-'
  )
  return (
    <Fragment>
      <CardHeader>
        <HeaderLeft>
          {advisor.partyType === PARTY_TYPE_PERSON ? (
            <AgentName>{getFullName(advisor)}</AgentName>
          ) : (
            <AgentName>{advisor.businessName}</AgentName>
          )}
        </HeaderLeft>
        {isRemoveAdviserButtonRendered && (
          <HeaderRight>
            <RemoveLink onClick={showRemoveConfirm}>{adviserPoliciesRemoveLabel.value}</RemoveLink>
          </HeaderRight>
        )}
      </CardHeader>

      <Divider />
      <AdviserDetailsLabel>
        <Text field={adviserDetailsLabel} />
      </AdviserDetailsLabel>
      <InfoWrapper>
        <InfoLabel>
          <Text field={adviserPhoneLabel} />
        </InfoLabel>
        <InfoValue>{phone}</InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoLabel>
          <Text field={adviserEmailLabel} />
        </InfoLabel>
        <InfoValue> {getEmail(advisor.contactMethods.emails)}</InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoLabel>
          <Text field={adviserAddressLabel} />
        </InfoLabel>
        <InfoValue>{getFullAddressWithState(advisor.contactMethods.addresses)}</InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoLabel>
          <Text field={adviserPoliciesServicingLabel} />
        </InfoLabel>
        <PolicyIdsContainer>{renderPolicyIds(advisor.policyRolesMap)}</PolicyIdsContainer>
      </InfoWrapper>
    </Fragment>
  )
}

export default AdviserInfoCard
