import { createSelector } from 'reselect'
import get from 'lodash/get'
import {
  checkIfAllPoliciesLoaded,
  getCustomerPolicies,
  getMasterList,
  getCustomerRelationships,
  getCustomerPolicySummaryStatus,
} from './common'
import { getInforceCustomerPoliciesCardData } from './customerPolicies'
// @ts-expect-error file not in typescript
import { checkIfValidBenefitStatusCode, getChildBenefitStatus } from '../utils/policyUtils'
import { EXP_CUSTOMER_STATUS_LOADING, EXP_CUSTOMER_STATUS_FAILED } from '../constants/apis'

// Types
import { Store } from '../types/store'
import { CustomerRelationship, FormattedCustomerRelationship } from '../types/customerRelationship'
import { CustomerPolicyCardData } from '../types/ClientPolicies'

const rolesMap: Record<string, string> = {
  SA: 'Agent',
  LA: 'Life Assured',
  PYR: 'Payer',
  OWR: 'Policy Owner',
  IET: 'Scheme',
  LB: 'Life beneficiary',
}

const getCorrespondenceHistoryStatus = (state: Store) => state.correspondenceHistory.isSending

const BLACKLIST_BENEFITS_COC = ['PHII', 'WVRI', 'WVRA', 'PHIA']

export const shouldCustomerDocumentsPageShowLoader = createSelector(
  [
    getCorrespondenceHistoryStatus,
    checkIfAllPoliciesLoaded,
    getCustomerPolicySummaryStatus,
    getCustomerRelationships,
  ],
  (isCorrespondenceAPILoading, haveAllPoliciesLoaded, summaryStatus, relationships) =>
    (isCorrespondenceAPILoading ||
      !haveAllPoliciesLoaded ||
      summaryStatus === EXP_CUSTOMER_STATUS_LOADING ||
      relationships.some(
        (relationship: CustomerRelationship) => relationship.loadingState === 'LOADING'
      )) &&
    summaryStatus !== EXP_CUSTOMER_STATUS_FAILED
)

export const shouldShowCertificateOfCurrency = createSelector(
  [getInforceCustomerPoliciesCardData],
  (policies: CustomerPolicyCardData[]) => {
    if (policies.length === 1 && policies.some(policy => policy.status === 'IP Inforce')) {
      return false
    }
    return policies.length > 0
  }
)

export const getCertificateOfCurrencyTiles = createSelector(
  [getInforceCustomerPoliciesCardData],
  (policies: CustomerPolicyCardData[]) =>
    policies.map(policy => ({
      policyNo: policy.subHeadingText,
      bancsPolicyNo: policy.bancsPolicyNo,
      // We only want to show life assured with valid / inforce benefits
      lifeAssured: policy.policyPersonnel.filter(
        personnel => personnel.indexOf('(Out of force)') === -1
      ),
    }))
)

export const getCertificateOfCurrencyRequestPayload = createSelector(
  [
    getInforceCustomerPoliciesCardData,
    getCustomerPolicies,
    getMasterList,
    getCustomerRelationships,
  ],
  (
    inforcePolicies: CustomerPolicyCardData[],
    policyStore,
    { benefitStatus },
    customerRelationshipsStore
  ) => {
    const inforceBancPolicyNos = inforcePolicies.map(policy => policy.bancsPolicyNo)
    const policies = policyStore.filter(policy => inforceBancPolicyNos.includes(policy.policyNo))
    return policies.reduce((payload, policy) => {
      const relationshipsBancsCustomerNos = [
        ...new Set([
          ...(policy.lifeAssured || []),
          ...(policy.payers || []),
          ...(policy.policyOwners || []),
          ...(policy.agents || []),
          ...(policy.iet || []),
          ...(policy.beneficiaries || []),
        ]),
      ]
      const relationships = customerRelationshipsStore.filter(relationship =>
        relationshipsBancsCustomerNos.includes(relationship.bancsCustomerNo)
      )
      const formattedRelationships = relationships.reduce(
        (formattedArray: FormattedCustomerRelationship[], relationship) => {
          const policyObject = relationship.policyRolesMap[policy.policyNo] || {}
          const array = [...new Set(policyObject.roles)].map(role => ({
            bancsCustomerNo: relationship.bancsCustomerNo,
            expiryDate: relationship.expiryDate,
            preferredTelephoneRef: relationship.preferredTelephoneRef,
            startDate: relationship.startDate,
            status: relationship.status,
            ...policyObject.policyMeta,
            role: rolesMap[role],
            roleCode: role,
            relatedParty: {
              identifiers: [
                { type: 'BANCS_CUSTOMER_NO', value: relationship.bancsCustomerNo },
                { type: 'PARTY_NO', value: relationship.partyNo },
              ],
              contactMethods: relationship.contactMethods,
              title: relationship.title,
              firstName: relationship.firstName,
              lastName: relationship.lastName,
              middleName: relationship.middleName,
              isSmoker: relationship.smokerStatus,
              gender: relationship.gender,
              businessName: relationship.businessName,
              partyType: relationship.partyType,
              dateOfBirth: relationship.dateOfBirth,
              abnNumber: relationship.abnNumber,
            },
          }))
          return formattedArray.concat(array)
        },
        []
      )
      const benefits = policy.benefits
        .filter(
          benefit =>
            checkIfValidBenefitStatusCode(benefit.benefitStatusCode, benefitStatus) &&
            !BLACKLIST_BENEFITS_COC.includes(benefit.benefitStatusCode)
        )
        .map(benefit => ({
          ...benefit,
          benefitAssured: benefit.benefitAssuredLegacy,
          stampDuty: benefit.stampDuty !== undefined ? benefit.stampDuty.toString() : undefined,
          ...(benefit.linkedChildBenefitReference && {
            linkedChildBenefitReference: benefit.linkedChildBenefitReference.map(reference => ({
              ...reference,
              benefitStatus: getChildBenefitStatus(reference, policyStore),
            })),
          }),
        }))

      return {
        ...payload,
        [policy.policyNo]: {
          productId: policy.productId,
          policyName: policy.policyName,
          legacyProductCode: policy.legacySystemProductCode,
          productClass: policy.productClass,
          bancsPolicyNo: policy.policyNo,
          identifiers: [
            {
              type: 'POLICY_ID',
              value: policy.policyId,
            },
            {
              type: 'BANCS_POLICY_NO',
              value: policy.policyNo,
            },
          ],
          startDate: policy.startDate,
          firstCollectionDate:
            policy.paymentInstrument && policy.paymentInstrument.firstCollectionDate,
          nextCollectionDate:
            policy.paymentInstrument && policy.paymentInstrument.nextCollectionDate,
          status: policy.status,
          premiumPaymentMethod:
            policy.premiumPaymentMethod ||
            (policy.paymentInstrument && policy?.paymentDetails?.paymentInstrument),
          jurisdiction: policy.jurisdiction,
          paymentHistory: {
            ...policy.paymentHistory,
            totalRecords: get(policy.paymentHistory, 'history', []).length,
          },
          benefits,
          ageAdjustmentFlag: policy.ageAdjustmentFlag || 'N',
          relationships: formattedRelationships,
          policyPremium: policy.policyPremium,
          policyExpiry: policy.policyExpiry,
          policyFee: policy.policyFee,
          stampDuty: policy.stampDuty,
          taxRebate: policy.taxRebate,
        },
      }
    }, {})
  }
)
