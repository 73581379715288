import React, { useState } from 'react'
import propTypes from 'prop-types'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { Heading, Chip, Icons, Button, Loader } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../../utils/telemetry'
import { getReceiptDetails } from '../../../../../selectors/customerOneTimePayment'
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../../utils/formUtils'
import { downloadDocument } from '../../../../../utils/downloadDocumentUtils'
import { generateCorrelationID } from '../../../../../utils/commonUtils'
import { DOC_TYPE_PAYMENT_RECEIPT } from '../../../../../constants/documentTypes'
import {
  TRANSACTION_REFERENCE,
  AMOUNT_PAID,
  DATE,
  PAYMENT_METHOD,
  DOWNLOAD_RECEIPT,
} from '../../../../../constants/customerPaymentDetails'
import styles from './paymentReceipt.styles'
import { TOAST_ID_DOWNLOAD_PAYMENT_RECEIPT_ERROR } from '../../../../../constants/toast'

const { IconVisa, IconMasterCard, IconDownload16 } = Icons
const Container = styled('div')(styles.container)
const Header = styled(Heading)()
const Text = styled('p')(styles.text)
const BoldText = styled('span')(styles.bold)
const MethodContainer = styled('div')(styles.methodContainer)
const DownloadIcon = styled(IconDownload16)(styles.downloadIcon)
const DownloadDisclaimerContainer = styled('div')(styles.downloadReceipt)

const CCIcons = {
  VI: <IconVisa />,
  MC: <IconMasterCard />,
}

const PaymentReceipt = props => {
  const receiptDetails = useSelector(getReceiptDetails)
  const config = useSelector(state => state.config)
  const {
    txnid,
    selectedPolicyBancsPolicyId,
    amount,
    date,
    pan,
    expirydate,
    policyType,
    cardType,
    shouldShowReinstatementMessage,
    shouldShowPolicyNotFullyPaid,
  } = receiptDetails
  const {
    fields: {
      paymentSuccessful,
      policyReinstatementMessage,
      processingTimeMessage,
      PolicyNotFullyPaidMessage,
      DownloadReceiptDisclaimer,
    },
  } = props

  const [receiptLoader, setReceiptLoader] = useState(false)

  const handleDocumentDownload = () => {
    setReceiptLoader(true)
    const downloadCompletedCallback = () => {
      setReceiptLoader(false)
    }

    const requestPayload = {
      docType: DOC_TYPE_PAYMENT_RECEIPT,
      correlationID: generateCorrelationID(),
      receipt: {
        amount: dollarAmountWithCommasAndTwoDecimal(amount), // Formatted dollar value
        cardType: cardType === 'MC' ? 'MS' : cardType, // Value will either be VI or MS "date":
        date, // String of date of submission formatted
        expiryDate: expirydate, // Card expiry date e.g. 05/25
        pan, // Pan id of card e.g. "XXXX XXXX XXXX X111"
        policyType, // Used to render the inside super outside super chip.
        selectedPolicy: selectedPolicyBancsPolicyId, // Policy ID "
        shouldShowReinstatementMessage, // If set to true, render the reinstatement message
        shouldShowPolicyNotFullyPaidMessage: shouldShowPolicyNotFullyPaid,
        txnid, // reference id
      },
    }
    const toastMessage = {
      error: TOAST_ID_DOWNLOAD_PAYMENT_RECEIPT_ERROR,
    }

    const tagEvent = createEvent({
      GA: {
        category: 'Make a payment enhancement',
        action: 'Download receipt',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Make a payment enhancement - Download receipt',
        },
      },
    })
    tagEvent.end()
    downloadDocument(requestPayload, null, null, config, downloadCompletedCallback, {
      toastMessage,
    })
  }

  return (
    <>
      <Container>
        <Header variant="h1" size="large">
          {renderTextField(paymentSuccessful)}
          {shouldShowReinstatementMessage && (
            <>
              ,{' '}
              <span data-testid="reinstatement">
                {renderTextField(policyReinstatementMessage, true)}
              </span>
            </>
          )}
        </Header>
        {shouldShowPolicyNotFullyPaid && (
          <Text>{renderTextField(PolicyNotFullyPaidMessage, true)}</Text>
        )}
        <Text>
          {TRANSACTION_REFERENCE} {txnid}
        </Text>
        <Text>{renderTextField(processingTimeMessage, true)}</Text>
      </Container>
      <Container smallBorder>
        <Chip variant="important">{policyType}</Chip>
        <Header variant="h2" size="medium">
          {selectedPolicyBancsPolicyId}
        </Header>
        <Text>
          {AMOUNT_PAID} <br /> <BoldText>{dollarAmountWithCommasAndTwoDecimal(amount)}</BoldText>
        </Text>
        <Text>
          {DATE} <br /> <BoldText>{date}</BoldText>
        </Text>
        <Text noMargin>{PAYMENT_METHOD}</Text>
        <MethodContainer>
          <div>{pan}</div>
          <div>{expirydate}</div>
          <div>{CCIcons[cardType]}</div>
        </MethodContainer>
        {receiptLoader ? (
          <Loader />
        ) : (
          <Button variant="tertiary" size="small" onClick={handleDocumentDownload}>
            <DownloadIcon />
            {DOWNLOAD_RECEIPT}
          </Button>
        )}
      </Container>
      <DownloadDisclaimerContainer>
        <p>{renderTextField(DownloadReceiptDisclaimer)}</p>
      </DownloadDisclaimerContainer>
    </>
  )
}

PaymentReceipt.propTypes = {
  fields: propTypes.shape({
    paymentSuccessful: propTypes.object,
    policyReinstatementMessage: propTypes.object,
    processingTimeMessage: propTypes.object,
  }).isRequired,
}

export default PaymentReceipt
