// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../../actions'
import {
  checkIfAllPoliciesLoaded,
  checkIfAllRelationshipsHaveRelatedParty,
  hasSummaryLoaded,
  checkIfAllWorkItemsLoaded,
  getIsRelatedPartyLoadedOrLoading,
} from '../../../selectors/common'
import { getAlterations } from '../../../selectors/common.selectors'
import { getPolicies } from '../../../selectors/customerDashboard'
import { getIsAllAltsRules } from '../../../selectors/alterations'
import { getBancsCustomerNumber } from '../../../utils/cookieUtils'
import { getEscalationDueDate } from '../../../utils/escalations'

// TODO (MVP2):: Move the Policy Anniversary Card and all related API calls here.
// Move the creation of policy into seperate component
const CustomerPolicyAnniversary = () => {
  const haveAllPoliciesLoaded = useSelector(checkIfAllPoliciesLoaded)
  const haveAllworkItemsLoaded = useSelector(checkIfAllWorkItemsLoaded)
  const summaryLoaded = useSelector(hasSummaryLoaded)
  const allRelationshipsHaveRelatedParty = useSelector(checkIfAllRelationshipsHaveRelatedParty)
  const policies = useSelector(getPolicies)
  const alterations = useSelector(getAlterations)
  const isAllAltsRules = useSelector(getIsAllAltsRules)
  const isRelatedPartyLoadedOrLoading = useSelector(getIsRelatedPartyLoadedOrLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (summaryLoaded && haveAllPoliciesLoaded && !isRelatedPartyLoadedOrLoading) {
      dispatch(
        actionCreators.fetchBatchRelatedParty(
          'beneficiaries',
          'lifeAssured',
          'payers',
          'policyOwners',
          'iet',
          'agents'
        )
      )
    }
  }, [haveAllPoliciesLoaded])

  useEffect(() => {
    if (
      summaryLoaded &&
      haveAllPoliciesLoaded &&
      allRelationshipsHaveRelatedParty &&
      haveAllworkItemsLoaded &&
      !alterations.policies.length
    ) {
      dispatch(
        actionCreators.setAlterationsPolicies({
          bancsCustomerNo: getBancsCustomerNumber(),
          policies,
        })
      )
    }
  }, [allRelationshipsHaveRelatedParty, haveAllworkItemsLoaded])

  useEffect(() => {
    if (summaryLoaded && !alterations.rules) {
      dispatch(actionCreators.getAlterationRules(true))
    }
  }, [summaryLoaded])

  useEffect(() => {
    if (alterations.policies.length && !alterations.isEscalationAPIDone) {
      let madeEscalationAPICall = false
      // make escalations api call
      alterations.policies.forEach(policyData => {
        const { policy } = policyData
        if (policy) {
          const escalationDueDate = getEscalationDueDate(policy)
          if (escalationDueDate) {
            dispatch(
              actionCreators.getEscalation({
                bancsPolicyNo: policyData.bancsPolicyNo,
                escalationDueDate,
              })
            )
            madeEscalationAPICall = true
          }
        }
      })
      // If escalations API call is not made, directly call rules API
      if (!madeEscalationAPICall) {
        // make rules API call
        dispatch(actionCreators.getAlterationRules())
      }
      dispatch(actionCreators.setIsEscalationAPIDone(true))

      // product rules api call
      const { policies: altsPolicies = [] } = alterations
      const uniqueProductIds = Array.from(
        new Set(altsPolicies.map(policy => policy.policy.productId))
      )
      uniqueProductIds.map(productId => dispatch(actionCreators.getProductRules({ productId })))
    }
  }, [alterations.policies])

  // after escalation api calls response make rules api call
  useEffect(() => {
    if (alterations.escalationAPICount === 0 && !isAllAltsRules) {
      dispatch(actionCreators.getAlterationRules())
    }
  }, [alterations.escalationAPICount])

  return <></>
}

export default CustomerPolicyAnniversary
