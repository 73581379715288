import get from 'lodash/get'
import {
  required,
  accountName,
  accountNo,
  accountNumberWithNotAllZero,
} from '../../../../../../../../utils/formUtils'

export const FORM_ID = 'directDebit'

const custDirectDebitSchema = ({ fields }) => ({
  accountName: {
    condition: [
      {
        condition: required,
        errorMsg: get(fields, 'cpEditPaymentDirectDebitPaymentAccountNameError.value', ''),
      },
      {
        condition: accountName,
        errorMsg: get(fields, 'cpEditPaymentDirectDebitPaymentInvalidAccountNameError.value', ''),
      },
    ],
    tooltip: '',
  },
  accountNumber: {
    condition: [
      {
        condition: required,
        errorMsg: get(fields, 'cpEditPaymentDirectDebitPaymentAccountNumberError.value', ''),
      },
      {
        condition: accountNo,
        errorMsg: get(fields, 'cpEditPaymentDirectDebitPaymentInvalidAccountNumberError.value', ''),
      },
      {
        condition: accountNumberWithNotAllZero,
        errorMsg: get(fields, 'cpEditPaymentDirectDebitPaymentAccountNumberError.value', ''),
      },
    ],
    tooltip: '',
  },
  directDebitBsb: {
    condition: required,
    errorMsg: get(fields, 'cpEditPaymentDirectDebitPaymentBSBError.value', ''),
    tooltip: '',
  },
})

export default custDirectDebitSchema
