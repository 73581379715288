// @flow
import {
  colours,
  fontSize,
  space,
  mediaQueries,
  fontWeights,
  coloursWithOpacity,
} from '../../../../styles'

const styles = {
  label: {
    marginBottom: space(0.5),
    color: colours.darkestGrey,
    fontSize: fontSize.md,
    lineHeight: fontSize.lg,
    fontWeight: fontWeights.semiBold,
    display: 'block',
  },
  sublabel: {
    marginBottom: space(2),
    color: colours.mediumGrey,
    fontSize: fontSize.sm,
    lineHeight: fontSize.lg,
    fontWeight: fontWeights.semiBold,
    display: 'block',
  },
  divider: {
    display: 'block',
    height: '1px',
    border: 0,
    borderTop: '1px solid ',
    borderColor: colours.lightestGrey,
    margin: '1em 0',
    padding: 0,
  },
  adviserDetails: {
    marginTop: space(3),
    marginBottom: space(3),
    fontWeight: fontWeights.semiBold,
    color: colours.lightBlack,
  },
  wrapperInfo: {
    border: 0,
    marginBottom: space(2.5),
    display: 'grid',
    gridColumnGap: space(0.2, true),
    gridTemplateColumns: '1fr',
    color: colours.mediumDarkGrey,

    [mediaQueries.md]: {
      gridTemplateColumns: '15% 1fr',
    },
  },
  infoLabel: {
    color: coloursWithOpacity(colours.mediumDarkGrey, 0.4),
  },
  infoValue: {
    display: 'inline-block',
    color: colours.mediumDarkGrey,
    border: 0,
  },
  idsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  idValue: {
    color: colours.mediumDarkGrey,
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: space(4, true),
    paddingBottom: space(3, true),

    [mediaQueries.md]: {
      flexDirection: 'row',
      paddingTop: space(6, true),
      paddingBottom: space(4, true),
    },
  },
  headerLeft: {},
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [mediaQueries.md]: {
      justifyContent: 'center',
    },
  },
  removeLink: {
    fontWeight: 'bold',
    color: colours.mediumGreen,
    marginRight: space(2),
    '&:hover': {
      color: colours.lightGreen,
      cursor: 'pointer',
    },
  },
}

export default styles
