// @flow
import React from 'react'
import styled from '@emotion/styled'

import { Table, ErrorState, Label, SectionHeader } from '@mlcl-digital/mlcl-design'

import { sortDate } from '../../../../../utils/sortingUtils'

// utils
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'

// styles.
import styles from './paymentHistory.styles'

type fieldType = {
  value: string,
}

type PaymentHistoryProps = {
  // fields from sitecore
  fields: {
    cpPaymentHistoryDate: fieldType,
    cpPaymentHistoryAmount: fieldType,
    cpPaymentHistoryPaymentType: fieldType,
    cpPaymentHistoryPolicy: fieldType,
    cpPaymentHistoryHeading: fieldType,
    cpPaymentHistoryFetchError: fieldType,
    cpPaymentHistoryNoRecordMessage: fieldType,
  },
  paymentHistory: Array<Object>,
  hasPaymentHistoryFailed: Bool,
}

const Wrapper = styled('div')(styles.wrapper)
const HeaderWrapper = styled('div')(styles.headerWrapper)
const ActivityMessage1Label = styled(Label)(styles.label)
const CorrespondenceHeading = styled(SectionHeader)(styles.sectionHeader)

const columns = fields => [
  {
    Header: fields.cpPaymentHistoryDate,
    id: 'date',
    accessor: 'date',
    sortType: sortDate('date', 'policyId'),
  },
  {
    Header: fields.cpPaymentHistoryPolicy,
    id: 'policyId',
    accessor: 'policyId',
    disableSortBy: true,
  },
  {
    Header: fields.cpPaymentHistoryPaymentType,
    id: 'paymentType',
    accessor: 'paymentMethod',
    disableSortBy: true,
  },
  {
    Header: fields.cpPaymentHistoryAmount,
    id: 'amount',
    styleOverrides: {
      cell: {
        fontWeight: 'bolder',
        justifyContent: 'flex-end',
      },
      headerCell: {
        fontWeight: 'bolder',
        justifyContent: 'flex-end',
      },
    },
    accessor: 'amount',
    disableSortBy: true,
  },
]

const PaymentHistory = ({
  fields,
  paymentHistory,
  hasPaymentHistoryFailed,
}: PaymentHistoryProps) => {
  const {
    cpPaymentHistoryHeading,
    cpPaymentHistoryFetchError,
    cpPaymentHistoryNoRecordMessage,
    AppActivityMessage1,
  } = fields
  const reducedFields = reduceAuthorableFields(fields)

  const COLUMNS = columns(reducedFields)

  return (
    <Wrapper>
      <HeaderWrapper>
        <CorrespondenceHeading
          heading={renderTextField(cpPaymentHistoryHeading)}
          SectionMarginBottom={
            hasPaymentHistoryFailed || (paymentHistory && paymentHistory.length === 0)
          }
        />
        {paymentHistory && paymentHistory.length > 0 && (
          <>
            <ActivityMessage1Label size="lg">
              {renderTextField(AppActivityMessage1, true)}
            </ActivityMessage1Label>
          </>
        )}
      </HeaderWrapper>
      {hasPaymentHistoryFailed ? (
        <ErrorState showBackground={false} message={renderTextField(cpPaymentHistoryFetchError)} />
      ) : (
        <Table
          defaultSorted={[
            {
              id: 'date',
              desc: true,
            },
          ]}
          classes="table-row"
          data={paymentHistory || []}
          columns={COLUMNS}
          onSortedChange={() => {}}
          noDataCopy={renderTextField(cpPaymentHistoryNoRecordMessage)}
        />
      )}
    </Wrapper>
  )
}

export default PaymentHistory
