// @flow
import {
  CUSTOMER_DETAILS_FETCH,
  CUSTOMER_DETAILS_FETCH_SUCCESS,
  CUSTOMER_DETAILS_FETCH_ERROR,
  CUSTOMER_DETAILS_FETCH_FAILURE,
  CUSTOMER_CONTACT_DETAILS_UPDATE,
  CUSTOMER_CONTACT_DETAILS_UPDATE_SUCCESS,
  CUSTOMER_CONTACT_DETAILS_UPDATE_ERROR,
  CUSTOMER_CONTACT_DETAILS_UPDATE_FAILURE,
  CUSTOMER_CONTACT_DETAILS_UPDATE_RESET,
} from '../actions/types/customerPersonalDetails'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  details: {},
  isLoading: false,
  isSubmitting: false,
  hasFetchError: false,
  hasUpdateError: false,
}

const customer = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_FETCH:
    case CUSTOMER_CONTACT_DETAILS_UPDATE: {
      return {
        ...state,
        isSubmitting: false,
        isLoading: true,
        hasFetchError: false,
        hasUpdateError: false,
      }
    }
    case CUSTOMER_DETAILS_FETCH_SUCCESS:
    case CUSTOMER_CONTACT_DETAILS_UPDATE_SUCCESS: {
      const {
        payload: {
          businessData: { member },
        },
      } = action
      return {
        ...state,
        details: member,
        isSubmitting: false,
        isLoading: false,
      }
    }
    case CUSTOMER_DETAILS_FETCH_ERROR:
    case CUSTOMER_DETAILS_FETCH_FAILURE:
    case CUSTOMER_CONTACT_DETAILS_UPDATE_ERROR:
    case CUSTOMER_CONTACT_DETAILS_UPDATE_FAILURE: {
      return {
        ...state,
        details: { ...state.details },
        isSubmitting: false,
        isLoading: false,
        hasFetchError: true,
        hasUpdateError: true,
      }
    }

    case CUSTOMER_CONTACT_DETAILS_UPDATE_RESET: {
      return {
        ...state,
        hasUpdateError: false,
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default customer
