import { colours, space, fontSize, fontWeights, mediaQueries } from '../../../../../styles'

const styles = {
  accordion: {
    marginBottom: space(2),
    background: colours.white,
    padding: `${space(3, true)}`,

    [mediaQueries.md]: {
      padding: `${space(3, true)} ${space(5, true)}`,
    },
  },
  label: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    margin: 0,
    display: 'flex',
    alignItems: 'center',

    [mediaQueries.md]: {
      fontSize: fontSize.md,
    },
  },
  status(checked) {
    let dynamicAttr = {
      backgroundColor: colours.lightestGrey,
    }
    if (checked === true) {
      dynamicAttr = {
        backgroundColor: colours.lightGreen,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& svg': {
          color: colours.white,
        },
      }
    }
    return {
      display: 'inline-block',
      width: space(2.5, true),
      height: space(2.5, true),
      borderRadius: '100%',
      marginRight: space(2, true),

      [mediaQueries.md]: {
        width: space(3, true),
        height: space(3, true),
        marginRight: space(3, true),
      },
      ...dynamicAttr,
    }
  },
  accordionIcon: {
    '& svg': {
      color: colours.black,
    },
  },
  children(disableScrollContent, resetOverflow) {
    let dynamicAttr = {
      maxHeight: space(60, true),
    }
    if (disableScrollContent) {
      dynamicAttr = { maxHeight: 'auto' }
    }
    let overflowAttr
    if (!resetOverflow) overflowAttr = { overflowY: 'auto' }
    return {
      color: colours.black,
      margin: `${space(4, true)} 0 0`,
      padding: `${space(4, true)} 0`,
      borderColor: colours.lightGrey,
      borderStyle: 'solid',
      borderWidth: '1px 0 0 0',

      '& h3': {
        fontSize: fontSize.sm,
        fontWeight: fontWeights.bold,
        marginBottom: space(3, true),
      },
      '& p': {
        marginBottom: space(3, true),
      },
      '& ul': {
        marginBottom: space(3, true),
      },
      ...dynamicAttr,
      ...overflowAttr,
    }
  },
  continueSection: {
    borderTop: `1px solid ${colours.lightGrey}`,
    paddingTop: space(4, true),
  },
}

export default styles
