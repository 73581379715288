import { space, colours } from '../../../../styles'

const styles = {
  title: {
    width: `calc(50% - ${space(1, true)})`,
  },
  divName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameInput: transparent => ({
    flexBasis: '48%',
    [transparent && 'input']: {
      color: colours.black,
      fontSize: 'inherit',
    },
  }),
}

export default styles
