// @flow

// eslint-disable-next-line max-len
import { SET_POLICY_FOR_PAYMENT_DETAILS_MODAL } from '../actions/creators/paymentDetailsActivePolicy'

const paymentDetailsActivePolicy = (
  state: Object = null,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case SET_POLICY_FOR_PAYMENT_DETAILS_MODAL:
      return action.payload
    default:
      return state
  }
}
export default paymentDetailsActivePolicy
