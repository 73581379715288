import React, { useEffect, useRef, useCallback } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'

// components
import { Heading, RadioGroup, Radio } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import List from '@mlcl-digital/mlcl-design/lib/base/List'
import InputPhone from '../../../molecules/InputPhone'
import SectionWrapper from '../SectionWrapper'
import SidePanel from '../SidePanel'
import PCSubmitModalWithDownloadQuote from '../Modals/PCSubmitModalWithDownloadQuote'
import PCSubmitModal from '../Modals/PCSubmitModal'

// types
import { PremiumCalculatorSubmitProps } from '../../../../types/components/PremiumCalculatorSubmit'
import { FormError } from '../../../../types/forms'

// selectors
import { getPremiumCalculatorSubmitData } from '../../../../selectors/premiumCalculatorSubmit'
// @ts-expect-error file not in typescript
import { getAltsProductRules } from '../../../../selectors/alterations'
import {
  getForm,
  makeAltsLifeInsuredNameAndPartyNo,
  getCreateQuote,
  getConfig,
  getAlterations,
} from '../../../../selectors/common.selectors'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { errorCheck, getValue } from '../../../../utils/formUtils'
// @ts-expect-error file not in typescript
import { downloadDocument } from '../../../../utils/downloadDocumentUtils'
// @ts-expect-error file not in typescript
import { generateCorrelationID } from '../../../../utils/commonUtils'
import { createEvent } from '../../../../utils/telemetry'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// schema
import schema, { FORM_ID } from './premiumCalculatorSubmit.schema'

// constants
import { DEFAULT_PHONE_CODE } from '../../../../constants/contactDetails'
import {
  PREFERRED_CONTACT_TIME_AM,
  PREFERRED_CONTACT_TIME_PM,
  PREFERRED_CONTACT_TIME_ANY,
} from '../../../../constants/forms'
import { DOC_TYPE_ALTERATION_PC } from '../../../../constants/documentTypes'
import { PC_SUBMIT, PC_SUBMIT_WITH_DOWNLOAD_QUOTE } from '../../../../constants/modal'
import { MAX_LIMIT_ERROR_CODE } from '../../../../constants/premiumCalculator'

// styles
import styles from './premiumCalculatorSubmit.styles'

const Wrapper = styled('div')(styles.wrapper)
const DownloadIcon = styled(Icon)(styles.downloadIcon)
const QuoteSection = styled('div')(styles.quoteSection)
const PolicyList = styled('ul')(styles.policyList)
const DownloadButton = styled(Button)(styles.downloadButton)
const FormInputPhone = styled(InputPhone)(styles.formInputPhone)
const SubmitButton = styled(Button)(styles.submitButton)
const BackButton = styled(Button)(styles.backButton)
const PreferredTimeFieldSet = styled('fieldset')(styles.preferredTimeFieldSet)
const StyledHeading = styled(Heading)(styles.heading)
const Disclaimer = styled('div')(styles.disclaimer)
const QuoteDescription = styled('div')(styles.quoteDescription)

const PremiumCalculatorSubmit = ({ fields }: PremiumCalculatorSubmitProps) => {
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const SCHEMA = useRef(schema({ fields })).current
  const { policies } = useSelector(getPremiumCalculatorSubmitData)
  const form = useSelector(state => getForm(state, FORM_ID))
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const productRules = useSelector(getAltsProductRules)
  const {
    preferredContactNumber: preferredContactNumberQuote,
    preferredContactTime: preferredContactTimeQuote,
    quotes,
    activeIndex,
    isDownloadQuoteInProgress,
    quoteCustomerNo,
  } = useSelector(getCreateQuote)
  const config = useSelector(getConfig)
  const alterationsState = useSelector(getAlterations)

  const handleExternalLinksClick: EventListenerOrEventListenerObject = (event: Event) => {
    const target = event.target as HTMLElement
    const eleID = target.id

    // Check if the clicked element has a specific ID
    if (['mlcTelLink', 'mlcWebLink'].includes(eleID)) {
      const linkMap: Record<
        string,
        {
          action: string
          category: string
        }
      > = {
        mlcTelLink: {
          action: 'Phone CTA',
          category: 'PC-ROPhoneCTA',
        },
        mlcWebLink: {
          action: 'Website Link',
          category: 'PC-ROMLCLwebsitelink',
        },
      }
      // Prevent the default behavior of the link
      event.preventDefault()

      // Execute your custom GA code
      const tagEvent = createEvent({
        GA: { category: linkMap[eleID].category, action: linkMap[eleID].action },
        Splunk: {
          attributes: {
            'workflow.name': `Customer clicks ${linkMap[eleID].action}`,
          },
        },
      })
      tagEvent.end()

      // Navigate to the external site after executing your code
      window.open((target as HTMLAnchorElement).href, '_blank')
    }
  }

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'PC-ROPageload',
        action: 'Next steps page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator next steps page load',
        },
      },
    })
    event.end()

    // Access the current value of the ref and attach event listener
    const container = containerRef?.current
    if (container) {
      container.addEventListener('click', handleExternalLinksClick)
    }

    if (!form) {
      const data = {
        preferredContactNumberCode:
          preferredContactNumberQuote?.split(' ')?.[0]?.slice(1) || DEFAULT_PHONE_CODE,
        preferredContactNumber: preferredContactNumberQuote?.split(' ')?.[1] || '',
        preferredContactTime: preferredContactTimeQuote || '',
      }
      dispatch(actionCreators.formInit(FORM_ID, SCHEMA, data))
    }
    dispatch(actionCreators.initModal(PC_SUBMIT))
    dispatch(actionCreators.initModal(PC_SUBMIT_WITH_DOWNLOAD_QUOTE))

    // Remove event listener when component unmounts
    return () => {
      if (container) {
        container.removeEventListener('click', handleExternalLinksClick)
      }
    }
  }, [])

  const handleFieldChange = useCallback(
    ({ value, name }: { value: string; name: string }) => {
      const schemaForField =
        SCHEMA[
          name as 'preferredContactNumberCode' | 'preferredContactNumber' | 'preferredContactTime'
        ]
      if ('onChangeCondition' in schemaForField) {
        const charactersCheck = errorCheck(
          value,
          schemaForField.onChangeCondition,
          schemaForField.errorMsg
        ) as FormError
        if (charactersCheck.error) return
      }
      const data = {
        error: errorCheck(
          value,
          schemaForField.condition,
          'errorMsg' in schemaForField && schemaForField.errorMsg,
          form && form.fields
        ) as FormError,
        value,
      }
      dispatch(actionCreators.formUpdateField(FORM_ID, name, data))
      dispatch(actionCreators.formValidate(FORM_ID, SCHEMA))
    },
    [form]
  )

  const handleChange = useCallback(
    ({ value, name }: { value: string; name: string }) => {
      handleFieldChange({ value, name })
    },
    [form]
  )

  const handleChangeDropdown = useCallback(
    ({ value, name }: { value: { label: string; value: string }; name: string }) => {
      handleFieldChange({ value: value.value, name })
    },
    []
  )

  const handleBack = useCallback(() => {
    const event = createEvent({
      GA: {
        category: 'PC-ROBack',
        action: 'Next steps page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator reach out page back clicked',
        },
      },
    })
    event.end()
    dispatch(actionCreators.timelinePrevState())
  }, [])

  const handleDownloadClick = useCallback(() => {
    const event = createEvent({
      GA: {
        category: 'PC-RODownloadQuote',
        action: 'Download quote - quote screen',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator reach out page download quote clicked',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.updateDownloadQuoteProgress(true))
    const correlationID = generateCorrelationID()
    if (!alterationsState.quoteURN) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.setAltsQuoteURN(correlationID))
    }
    downloadDocument(
      {
        docType: DOC_TYPE_ALTERATION_PC,
        quote: quotes[activeIndex],
        correlationID,
        lifeInsuredFirstName: firstName,
        lifeInsuredLastName: lastName,
        isUploadToRecm: !alterationsState.isAltsQuoteDownloaded,
        returnData: true,
        quoteCustomerNo,
        quoteURN: alterationsState.quoteURN || correlationID,
      },
      null,
      null,
      config,
      (err: { status: { code: string } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(actionCreators.updateDownloadQuoteProgress(false))
        if (!err) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsAltsQuoteDowloaded())
        } else if (err.status?.code === MAX_LIMIT_ERROR_CODE) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsMaxDownloadLimitModal(true))
        }
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator reach out page downloading quote',
              error: !!err,
            },
          },
        })
        apiEvent.end()
      }
    )
  }, [quotes, alterationsState])

  const triggerSaveQuote = ({
    preferredContactNumberCodeField,
    preferredContactNumberField,
    preferredContactTime,
  }: {
    preferredContactNumberCodeField: string
    preferredContactNumberField: string
    preferredContactTime: string
  }) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.updateDetailsForContact({
        preferredContactNumber: `+${preferredContactNumberCodeField} ${preferredContactNumberField}`,
        preferredContactTime,
      })
    )
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.saveQuote(
        (error: object | false) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsSubmissionInProgress(false))
          if (!error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            dispatch(actionCreators.setIsAltsQuoteSubmitted())
            if (alterationsState.isAltsQuoteDownloaded) {
              dispatch(actionCreators.showModal(PC_SUBMIT))
            } else {
              dispatch(actionCreators.showModal(PC_SUBMIT_WITH_DOWNLOAD_QUOTE))
            }
          }
          const savingQuoteEvent = createEvent({
            Splunk: {
              attributes: {
                'workflow.name': 'Premium calculator submission',
                error: !!error,
                'error.message': error ? 'Error in saving quote' : '',
              },
            },
          })
          savingQuoteEvent.end()
        },
        'id-1',
        `${firstName} ${lastName}`,
        firstName,
        lastName,
        productRules
      )
    )
  }

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      const event = createEvent({
        GA: {
          category: 'PC-ROSubmit',
          action: 'Submit',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Premium calculator reach out submit',
          },
        },
      })
      event.end()
      dispatch(
        actionCreators.formSubmit(
          FORM_ID,
          SCHEMA,
          ({
            preferredContactNumberCode: preferredContactNumberCodeField,
            preferredContactNumber: preferredContactNumberField,
            preferredContactTime,
          }: Record<string, string>) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            dispatch(actionCreators.setIsSubmissionInProgress())
            if (alterationsState.isAltsQuoteDownloaded) {
              triggerSaveQuote({
                preferredContactNumberCodeField,
                preferredContactNumberField,
                preferredContactTime,
              })
            } else {
              /*
              Download API call is used to save quote pdf in RECM and then save quote API call is
              done to create case in salesforce
            */
              const correlationID = generateCorrelationID()
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              dispatch(actionCreators.setAltsQuoteURN(correlationID))
              downloadDocument(
                {
                  docType: DOC_TYPE_ALTERATION_PC,
                  quote: quotes[activeIndex],
                  correlationID,
                  lifeInsuredFirstName: firstName,
                  lifeInsuredLastName: lastName,
                  isUploadToRecm: true,
                  returnData: false,
                  quoteCustomerNo,
                  quoteURN: correlationID,
                },
                null,
                null,
                config,
                (err: object) => {
                  if (!err) {
                    triggerSaveQuote({
                      preferredContactNumberCodeField,
                      preferredContactNumberField,
                      preferredContactTime,
                    })
                  } else {
                    dispatch(actionCreators.setIsSubmissionInProgress(false))
                    const errorEvent = createEvent({
                      Splunk: {
                        attributes: {
                          'workflow.name': 'Premium calculator submission',
                          error: true,
                          'error.message': 'Error in getting quote pdf',
                        },
                      },
                    })
                    errorEvent.end()
                  }
                },
                { isOnlyGetDocument: true }
              )
            }
          }
        )
      )
    },
    [alterationsState.isAltsQuoteDownloaded]
  )

  const { fields: formFields } = form || {}
  const preferredContactNumberCode = formFields?.preferredContactNumberCode
  const preferredContactNumber = formFields?.preferredContactNumber
  const preferredContactTime = formFields?.preferredContactTime

  const selectContactNumberCodeData = {
    placeholder: fields.CountryCode?.value,
    disabled: alterationsState.isAltsQuoteSubmitted,
    label: fields.CountryCode?.value,
    value: getValue(preferredContactNumberCode) as string,
    name: 'preferredContactNumberCode',
    changeHandler: handleChangeDropdown,
    error: preferredContactNumberCode?.error?.error,
    caption:
      preferredContactNumberCode?.error?.error && preferredContactNumberCode?.error?.errorMsg,
  }
  const preferredContactNumberData = {
    name: 'preferredContactNumber',
    htmlFor: 'preferredContactNumber',
    label: fields.MobileNumber?.value,
    changeHandler: handleChange,
    disabled: alterationsState.isAltsQuoteSubmitted,
    error: preferredContactNumber?.error?.error,
    caption: preferredContactNumber?.error?.error && preferredContactNumber?.error?.errorMsg,
    value: preferredContactNumber?.value,
    placeholder: fields.MobileNumberPlaceholder?.value,
  }

  return (
    <div ref={containerRef}>
      <Wrapper>
        <p>{renderTextField(fields.Description, true)}</p>
      </Wrapper>
      <SectionWrapper>
        <div>
          <QuoteSection>
            <div>
              <StyledHeading variant="h3" size="MD">
                {renderTextField(fields.QuoteHeading)}
              </StyledHeading>
              <QuoteDescription>{renderTextField(fields.QuoteDescription, true)}</QuoteDescription>
              <PolicyList>
                {policies.map(policy => (
                  <li>
                    <p>{fields.Policy?.value?.replace('##', policy.policyNo)} </p>
                    <p>
                      <span>{renderTextField(fields.Premium)}</span>
                      <span>
                        {policy.premium} {policy.premiumFrequency}
                      </span>
                    </p>
                  </li>
                ))}
              </PolicyList>
              <DownloadButton
                variant="secondaryWithTheme"
                onClick={handleDownloadClick}
                isLoading={isDownloadQuoteInProgress}
              >
                <DownloadIcon iconName={['far', 'download']} />
                {renderTextField(fields.DownloadQuote)}
              </DownloadButton>
            </div>
            <div>
              <StyledHeading variant="h3" size="MD">
                {renderTextField(fields.ContactHeading)}
              </StyledHeading>
              <form>
                <FormInputPhone
                  selectPhoneCode={selectContactNumberCodeData}
                  inputPhoneNumber={preferredContactNumberData}
                />
                <PreferredTimeFieldSet>
                  <legend>{renderTextField(fields.PreferredContactTime)}</legend>
                  <RadioGroup
                    type="inline"
                    error={
                      preferredContactTime?.error?.error && preferredContactTime?.error?.errorMsg
                    }
                  >
                    <Radio
                      text={fields.ContactTimeAM?.value}
                      checked={preferredContactTime?.value === PREFERRED_CONTACT_TIME_AM}
                      htmlFor="contactTimeAM"
                      name="preferredContactTime"
                      value={PREFERRED_CONTACT_TIME_AM}
                      disabled={alterationsState.isAltsQuoteSubmitted}
                      handleOnChange={(value: string) =>
                        handleChange({ value, name: 'preferredContactTime' })
                      }
                      styleOverrides={styles.preferredTimeRadio}
                    />
                    <Radio
                      text={fields.ContactTimePM?.value}
                      checked={preferredContactTime?.value === PREFERRED_CONTACT_TIME_PM}
                      htmlFor="contactTimePM"
                      name="preferredContactTime"
                      value={PREFERRED_CONTACT_TIME_PM}
                      disabled={alterationsState.isAltsQuoteSubmitted}
                      handleOnChange={(value: string) =>
                        handleChange({ value, name: 'preferredContactTime' })
                      }
                      styleOverrides={styles.preferredTimeRadio}
                    />
                    <Radio
                      text={fields.ContactTimeAny.value}
                      checked={preferredContactTime?.value === PREFERRED_CONTACT_TIME_ANY}
                      htmlFor="contactTimeAny"
                      name="preferredContactTime"
                      value={PREFERRED_CONTACT_TIME_ANY}
                      disabled={alterationsState.isAltsQuoteSubmitted}
                      handleOnChange={(value: string) =>
                        handleChange({ value, name: 'preferredContactTime' })
                      }
                      styleOverrides={styles.preferredTimeRadio}
                    />
                  </RadioGroup>
                </PreferredTimeFieldSet>
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  isLoading={alterationsState.isSubmissionInProgress}
                  disabled={alterationsState.isAltsQuoteSubmitted}
                >
                  {renderTextField(fields.Submit)}
                </SubmitButton>
              </form>
            </div>
          </QuoteSection>
          <List
            variant="number"
            heading={fields.ExpectNextHeading?.value}
            headingProps={{ variant: 'h3' }}
            listItems={fields.ExpectNextSteps?.map(listItem =>
              renderTextField(listItem?.fields?.Description?.value, true)
            )}
          />
          <Disclaimer>
            <div>{renderTextField(fields.Disclaimer)}</div>
            {renderTextField(fields.DisclaimerContent, true)}
          </Disclaimer>
          <BackButton variant="secondaryWithTheme" onClick={handleBack}>
            {renderTextField(fields.BackButton)}
          </BackButton>
        </div>
        <SidePanel fields={fields} />
      </SectionWrapper>
      <PCSubmitModalWithDownloadQuote fields={fields} />
      <PCSubmitModal fields={fields} />
    </div>
  )
}

export default PremiumCalculatorSubmit
