export const CUSTOMER_POLICY_BENEFICIARY_DETAILS = 'CUSTOMER_POLICY_BENEFICIARY_DETAILS'
export const CUSTOMER_POLICY_BENEFICIARY_DETAILS_INIT = 'CUSTOMER_POLICY_BENEFICIARY_DETAILS_INIT'
export const CUSTOMER_POLICY_BENEFICIARY_DETAILS_ERROR = 'CUSTOMER_POLICY_BENEFICIARY_DETAILS_ERROR'

export const CUSTOMER_POLICY_BENEFICIARY_DETAILS_SUCCESS =
  'CUSTOMER_POLICY_BENEFICIARY_DETAILS_SUCCESS'

export const CUSTOMER_POLICY_BENEFICIARY_DETAILS_DONT_RETRY =
  'CUSTOMER_POLICY_BENEFICIARY_DETAILS_DONT_RETRY'

export const CUSTOMER_POLICY_BENEFICIARY_DETAILS_FAILURE =
  'CUSTOMER_POLICY_BENEFICIARY_DETAILS_FAILURE'

export const BENEFICIARY_INFO_MODAL = 'BENEFICIARY_INFO_MODAL'

export const BENEFICIARY_UPDATE = 'BENEFICIARY_UPDATE'
export const BENEFICIARY_UPDATE_INIT = 'BENEFICIARY_UPDATE_INIT'
export const BENEFICIARY_UPDATE_SUCCESS = 'BENEFICIARY_UPDATE_SUCCESS'
export const BENEFICIARY_UPDATE_ERROR = 'BENEFICIARY_UPDATE_ERROR'
export const BENEFICIARY_UPDATE_FAILURE = 'BENEFICIARY_UPDATE_FAILURE'
export const RELATIONSHIPS_UPDATE = 'RELATIONSHIPS_UPDATE'
export const RELATIONSHIPS_UPDATE_SUCCESS = 'RELATIONSHIPS_UPDATE_SUCCESS'
export const RELATIONSHIPS_UPDATE_ERROR = 'RELATIONSHIPS_UPDATE_ERROR'
export const RELATIONSHIPS_UPDATE_FAILURE = 'RELATIONSHIPS_UPDATE_FAILURE'
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE'
