import { space, colours, fontWeights, fontSize, mediaQueries } from '../../../../styles'

const styles = {
  select: {
    maxWidth: space(50, true),
  },
  individualDayWrapper: {
    maxWidth: space(70, true),
    marginTop: space(3, true),
  },
  contactWrapper: {
    marginTop: space(3, true),
  },
  contactRadioGroup: isSingleCard => ({
    margin: 0,
    maxWidth: space(58, true),
    [mediaQueries.md]: {
      maxWidth: isSingleCard ? space(29, true) : space(58, true),
    },
  }),
  errorMessage: {
    color: colours.red,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: space(1) / 4,
      fontWeight: fontWeights.bold,
    },
  },

  intialContatcTimeWrap: {
    marginTop: space(5, true),
  },

  initialContactDaysWrap: {
    marginTop: space(5, true),
    maxWidth: space(100, true),
  },
  caption: {
    fontSize: fontSize.md,
    marginBottom: space(3),
  },
  message: {
    fontSize: fontSize.xs,
    marginBottom: space(3),
  },
  disclaimerText: {
    color: colours.mediumGrey,
  },
}

export default styles
