// @flow
import React from 'react'
import PremiumCalculatorSubmitComp from '../../../organisms/PremiumCalculator/PremiumCalculatorSubmit'

type PremiumCalculatorSubmitProps = {
  fields: Object,
}
const PremiumCalculatorSubmit = ({ fields }: PremiumCalculatorSubmitProps) => (
  <PremiumCalculatorSubmitComp fields={fields} />
)

export default PremiumCalculatorSubmit
