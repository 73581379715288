// @flow
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// styles
import styles from './benefitLoadings.styles'

// utils
import { getBenefitLoadingsDescription } from '../../../utils/commonUtils'

const BenefitLoadings = styled('span')(styles.benefitLoadings)

const BenefitLoadingsComponent = ({ benefitAssured, loadingLabel }) =>
  benefitAssured.map(({ loadings = [] }) =>
    loadings.map(loading => {
      const { value, reasonDescription } = getBenefitLoadingsDescription(loading)
      return (
        <BenefitLoadings
          key={loading.loadingType}
        >{`${loadingLabel}: ${value} for ${reasonDescription}`}</BenefitLoadings>
      )
    })
  )

const { shape, arrayOf } = PropTypes
BenefitLoadingsComponent.defaultProps = {
  benefitAssured: [],
}
BenefitLoadingsComponent.propTypes = {
  benefitAssured: arrayOf(
    shape({
      loadings: arrayOf(
        shape({
          loadingType: PropTypes.string,
        })
      ),
    })
  ),
}

export default BenefitLoadingsComponent
