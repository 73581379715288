import {
  optional,
  required,
  date,
  email,
  firstNameLastName,
  phone,
  mobilePhone,
  regexTest,
  // @ts-expect-error non-ts-code
} from '../../../utils/formUtils'
import { phoneCodeAustralia } from '../../../constants/customerPersonalDetails'
import { FormFields } from '../../../types/forms'
import {
  FORM_ADDRESS_VALIDATION_ERRORS,
  FORM_PERSON_VALIDATION_ERRORS,
  FORM_COMPANY_VALIDATION_ERRORS,
  FORM_CONTACT_DETAILS_VALIDATION_ERRORS,
} from '../../../constants/forms'

export const FORM_ID = 'addAuthority'

export const addressFields = {
  address: {
    condition: optional,
  },
  postalNumber: {
    condition: optional,
  },
  postalType: {
    condition: optional,
  },
  country: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.countryRequired,
  },
  houseNo: {
    condition: optional,
  },
  locality: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.localityRequired,
  },
  postCode: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.postcodeRequired,
  },
  state: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.stateRequired,
  },
  street: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.streetRequired,
  },
}

export const secondaryAddressFields = {
  postalAddress: {
    condition: optional,
  },
  postalPostalNumber: {
    condition: optional,
  },
  postalPostalType: {
    condition: optional,
  },
  postalCountry: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.countryRequired,
  },
  postalHouseNo: {
    condition: optional,
  },
  postalLocality: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.localityRequired,
  },
  postalPostCode: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.postcodeRequired,
  },
  postalState: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.stateRequired,
  },
  postalStreet: {
    condition: required,
    errorMsg: FORM_ADDRESS_VALIDATION_ERRORS.streetRequired,
  },
}

const schema = (isIndividual: boolean, hasSecondaryAddress?: boolean) => ({
  duration: {
    condition: optional,
  },
  ...(isIndividual && {
    title: {
      condition: required,
      errorMsg: FORM_PERSON_VALIDATION_ERRORS.titleRequired,
    },
    firstName: {
      condition: [
        {
          condition: required,
          errorMsg: FORM_PERSON_VALIDATION_ERRORS.firstNameRequired,
        },
        {
          condition: firstNameLastName,
          errorMsg: FORM_PERSON_VALIDATION_ERRORS.firstNameRequired,
        },
      ],
    },
    middleName: {
      condition: optional,
    },
    lastName: {
      condition: [
        {
          condition: required,
          errorMsg: FORM_PERSON_VALIDATION_ERRORS.lastNameRequired,
        },
        {
          condition: firstNameLastName,
          errorMsg: FORM_PERSON_VALIDATION_ERRORS.lastNameRequired,
        },
      ],
    },
    dateOfBirth: {
      condition: [
        { condition: required, errorMsg: FORM_PERSON_VALIDATION_ERRORS.dateOfBirthRequired },
        { condition: date, errorMsg: FORM_PERSON_VALIDATION_ERRORS.invalidDateOfBirth },
      ],
    },
    email: {
      condition: [
        { condition: required, errorMsg: FORM_CONTACT_DETAILS_VALIDATION_ERRORS.emailRequired },
        { condition: email, errorMsg: FORM_CONTACT_DETAILS_VALIDATION_ERRORS.invalidEmail },
      ],
    },
    phoneCode: {
      condition: required,
    },
    mobilePhone: {
      condition: [
        {
          condition: required,
          errorMsg: FORM_CONTACT_DETAILS_VALIDATION_ERRORS.mobilePhoneRequired,
        },
        {
          condition: (value: string, formFields: FormFields) => {
            const idc = formFields.phoneCode?.value
            const regex = idc === phoneCodeAustralia ? mobilePhone : phone
            return !regexTest(regex, value)
          },
          errorMsg: FORM_CONTACT_DETAILS_VALIDATION_ERRORS.invalidMobilePhone,
        },
      ],
    },
  }),
  ...(!isIndividual && {
    companyName: {
      condition: required,
      errorMsg: FORM_COMPANY_VALIDATION_ERRORS.companyNameRequired,
    },
    abn: {
      condition: required,
      errorMsg: FORM_COMPANY_VALIDATION_ERRORS.companyABNRequired,
    },
  }),
  ...addressFields,
  ...(hasSecondaryAddress && secondaryAddressFields),
})

export default schema
