import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Notification, SelectableCardGroup, SectionHeader } from '@mlcl-digital/mlcl-design'
import PropTypes from 'prop-types'
import { createEvent } from '../../../../../utils/telemetry'
import {
  checkIfAllRelationshipsHaveRelatedParty,
  checkIfAllWorkItemsLoaded,
} from '../../../../../selectors/common'
import { getPolicies } from '../../../../../selectors/customerDashboard'

// Actions
import { actionCreators } from '../../../../../actions'

// Selectors
import {
  getPolicyPaymentDetails,
  getAnniversaryCalls,
} from '../../../../../selectors/customerOneTimePayment'
import { shouldMakeAPaymentPageShowLoader } from '../../../../../selectors/customerPaymentDetails'
import { getIsAllAltsRules } from '../../../../../selectors/alterations'

// Components
import OneOfPaymentCard from '../../../../molecules/CustomerOneOfPaymentCard'

// Utils
import { reduceAuthorableFields, renderTextField } from '../../../../../utils/sitecoreUtils'
import { getBancsCustomerNumber } from '../../../../../utils/cookieUtils'

// Constants
import { SELECT_POLICY } from '../../../../../constants/buttons'
import {
  POLICY_OUT_OF_FORCE,
  POLICY_LAPSE_TOO_LONG,
  POLICY_PAYMENT_INVALID,
} from '../../../../../constants/payments'

const PolicySelection = props => {
  const { setPolicy, fields } = props

  const [showProcessingNotification, setShowProcessingNotification] = useState(true)
  const [showAnnivessaryWarningNotification, setShowAnnivessaryWarningNotification] =
    useState(false)
  const [anniversaryCallsFired, setAnniverssaryCallsFired] = useState(false)
  const dispatch = useDispatch()

  const { PaymentWarning, PolicyPremiumWarning } = fields
  const { Header } = reduceAuthorableFields(fields)

  const customerPolicies = useSelector(getPolicyPaymentDetails)
  const anniversaryCalls = useSelector(getAnniversaryCalls)
  const isLoading = useSelector(shouldMakeAPaymentPageShowLoader)
  const policies = useSelector(getPolicies)
  const hasFullRules = useSelector(getIsAllAltsRules)
  const hasAlterationPolicies = useSelector(state => state.alterations.policies)
  const hasAllWorkItemsLoaded = useSelector(checkIfAllWorkItemsLoaded)
  const hasAllRelationShipsLoaded = useSelector(checkIfAllRelationshipsHaveRelatedParty)

  const groupData = customerPolicies.map(policy => ({
    key: policy.policyId,
    value: policy.policyId,
    disabled: [POLICY_LAPSE_TOO_LONG, POLICY_OUT_OF_FORCE, POLICY_PAYMENT_INVALID].includes(
      policy.inValidReason
    ),
  }))

  const handlePolicyChange = item => {
    const tagEvent = createEvent({
      GA: {
        category: 'Make a payment enhancement',
        action: 'Select policy',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Make a payment enhancement - Select policy',
        },
      },
    })
    tagEvent.end()
    setPolicy(item.value)
  }

  // If a policy is within an anniverssary the outstanding balance is incorrect
  // We need to do a new call with the effectiveDate as a query string to get the
  // correct outstanding balance
  useEffect(() => {
    const dates = anniversaryCalls.map(call => call.effectiveDate)
    if (
      dates.length > 0 &&
      !dates.includes(null) &&
      !isLoading &&
      hasFullRules &&
      !anniversaryCallsFired
    ) {
      const callsToMake = anniversaryCalls.filter(call => call.anniversary === 'Within')
      const preAnniversaryFlags = anniversaryCalls.filter(
        call => call.anniversary === 'Pre' && !call.isPolicyCancelled
      )
      if (callsToMake.length > 0) {
        setAnniverssaryCallsFired(true)
        callsToMake.forEach(call =>
          dispatch(
            actionCreators.fetchFutureOutstandingBalance(call.bancsPolicyNo, call.effectiveDate)
          )
        )
      }
      // Show a warning if the anniversary is coming up of a policy and it is not cancelled.
      if (preAnniversaryFlags.length > 0) {
        setShowAnnivessaryWarningNotification(true)
      }
    }
  }, [anniversaryCalls, hasFullRules, isLoading])

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        actionCreators.fetchBatchRelatedParty(
          'beneficiaries',
          'lifeAssured',
          'payers',
          'policyOwners',
          'iet',
          'agents'
        )
      )
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoading && hasAllWorkItemsLoaded && hasAllRelationShipsLoaded) {
      dispatch(
        actionCreators.setAlterationsPolicies({
          bancsCustomerNo: getBancsCustomerNumber(),
          policies,
        })
      )
    }
  }, [isLoading, hasAllWorkItemsLoaded, hasAllRelationShipsLoaded])

  useEffect(() => {
    if (hasAlterationPolicies.length && !hasFullRules) {
      dispatch(actionCreators.getAlterationRules())
    }
  }, [hasAlterationPolicies.length])

  return (
    <div>
      {showProcessingNotification && (
        <Notification variant="warning" closeHandler={() => setShowProcessingNotification(false)}>
          {renderTextField(PaymentWarning)}
        </Notification>
      )}
      {showAnnivessaryWarningNotification && (
        <Notification
          variant="error"
          closeHandler={() => setShowAnnivessaryWarningNotification(false)}
        >
          {renderTextField(PolicyPremiumWarning)}
        </Notification>
      )}
      <SectionHeader heading={Header} />
      {!isLoading && hasAllRelationShipsLoaded && hasAllWorkItemsLoaded && hasFullRules && (
        <SelectableCardGroup
          data={groupData}
          selectButtonLabel={SELECT_POLICY}
          onChange={handlePolicyChange}
        >
          {customerPolicies.map(policy => (
            <OneOfPaymentCard key={policy.policyId} policy={policy} fields={fields} />
          ))}
        </SelectableCardGroup>
      )}
    </div>
  )
}

PolicySelection.propTypes = {
  setPolicy: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default PolicySelection
