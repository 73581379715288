import {
  CUSTOMER_SUMMARY_LOADING,
  CUSTOMER_SUMMARY_FAILED,
  CUSTOMER_SUMMARY_SUCCESS,
} from '../actions/types/customerPolicies'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_LOADED,
  EXP_CUSTOMER_STATUS_FAILED,
} from '../constants/apis'

export const initialState = {
  isLoading: EXP_CUSTOMER_STATUS_UNLOADED,
}

const customerPolicyStatus = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_SUMMARY_LOADING: {
      return {
        isLoading: EXP_CUSTOMER_STATUS_LOADING,
      }
    }
    case CUSTOMER_SUMMARY_FAILED: {
      return {
        isLoading: EXP_CUSTOMER_STATUS_FAILED,
      }
    }
    case CUSTOMER_SUMMARY_SUCCESS: {
      return {
        isLoading: EXP_CUSTOMER_STATUS_LOADED,
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default customerPolicyStatus
