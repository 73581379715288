// @flow
import { space } from '../../../styles'

const style = {
  wrap: {
    position: 'relative',
    marginTop: space(9),
    marginBottom: space(9),
  },
}

export default style
