import get from 'lodash/get'
// helpers.
import {
  required,
  numeric,
  validateCreditCardNumber,
  validateMasterOrVisaNumber,
  firstNameLastName,
  validateFutureDate,
  isNotFormattedDate,
} from '../../../../../../../../utils/formUtils'

export const FORM_ID = 'custCreditCardPayment'

const custCreditCardSchema = ({ fields }) => ({
  cardHolderName: {
    condition: [
      {
        condition: required,
        errorMsg: get(fields, 'cpEditPaymentCreditCardAccountNameErrorMsg.value', ''),
      },
      { condition: firstNameLastName },
    ],
  },
  cardNumber: {
    condition: [
      {
        condition: required,
        errorMsg: get(fields, 'cpEditPaymentCreditCardAccountNumberErrorMsg.value', ''),
      },
      {
        condition: numeric,
        errorMsg: get(fields, 'cpEditPaymentCreditCardAccountNumberValidErrorMsg.value', ''),
      },
      {
        condition: validateCreditCardNumber,
        errorMsg: get(fields, 'cpEditPaymentCreditCardAccountNumberMasterVisaErrorMsg.value', ''),
      },
      {
        condition: validateMasterOrVisaNumber,
        errorMsg: get(fields, 'cpEditPaymentCreditCardAccountNumberMasterVisaErrorMsg.value', ''),
      },
    ],
    name: 'cardNumber',
    tooltip: '',
  },
  expiryDate: {
    condition: [
      {
        condition: required,
        errorMsg: get(fields, 'cpEditPaymentCreditCardExpiryDateErrorMsg.value', ''),
      },
      {
        condition: isNotFormattedDate,
        errorMsg: get(fields, 'cpEditPaymentCreditCardExpiryDateValidErrorMsg.value', ''),
      },
      {
        condition: validateFutureDate(),
        errorMsg: get(fields, 'cpEditPaymentCreditCardExpiryDateValidErrorMsg.value', ''),
      },
    ],
    tooltip: '',
  },
  cardType: {
    condition: [
      {
        condition: required,
      },
    ],
  },
})

export default custCreditCardSchema
