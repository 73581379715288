// @flow
import React from 'react'
import CustomerDocumentsContainer from '../../../organisms/CustomerDocuments'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

type CustomerDocumentsProps = {
  fields: Object,
}

const CustomerDocuments = ({ fields }: CustomerDocumentsProps) => (
  <CustomerDocumentsContainer fields={reduceAuthorableFields(fields)} />
)

export default CustomerDocuments
