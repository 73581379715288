// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components
import Button from '../../../../atoms/Button'
import ModalCard from '../../../PersonalStatementModal/components/ModalCard'
import Modal from '../../../../molecules/Modal'

// styles
import styles from './psChangeModal.styles'

// constants
import {
  SAVE_DRAFT_APPLICATION,
  ADV_F2F,
  TELE,
  WIP_MYLINK,
  MYLINK,
  REGISTER_MY_LINK,
} from '../../../../../constants/application'
import { QUOTE_STATUS_APPLICATION_STAGE } from '../../../../../constants/policies'
import history from '../../../../../utils/browserHistory'
import {
  CUSTOMER_PERSONAL_STATEMENT_METHOD_PAGE,
  CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE,
  CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE,
} from '../../../../../constants/personalStatement'
import { getScope } from '../../../../../utils/cookieUtils'
import { SCOPE_TYPE_TELE } from '../../../../../constants/okta'
import { isFeatureEnabledForCP } from '../../../../../utils/commonUtils'

const ButtonContainer = styled('div')(styles.buttonContainer)
const ModalDescription = styled('p')(styles.modalDescription)

type PSChangeModalProps = {
  fields: Object,
  actions: Object,
  createQuote: Object,
  handleUWCloseModal: Function,
  featureControlSwitch: Array<Object>,
}

export class PSChangeModal extends Component<PSChangeModalProps> {
  constructor(props) {
    const {
      createQuote: { activeIndex, quotes },
    } = props
    const method = get(quotes, `[${activeIndex}].underwritingDetails.underwritingMethod`, '')
    super(props)
    this.state = {
      openChangeModalOnlineToPhone: method === MYLINK,
      openChangeModalPhoneToOnline: method === TELE,
      openConfirmModalPhoneToOnline: false,
      openConfirmModalOverThePhone: false,
      openConfirmModalSendToAdviser: false,
    }
  }

  componentWillMount() {
    const {
      actions: { getMasterList },
    } = this.props
    getMasterList()
  }

  closeChangeModalOnlineToPhone = () => {
    const { handleUWCloseModal } = this.props
    this.setState({ openChangeModalOnlineToPhone: false })
    handleUWCloseModal()
  }

  closeChangeModalPhoneToOnline = () => {
    const { handleUWCloseModal } = this.props
    this.setState({ openChangeModalPhoneToOnline: false })
    handleUWCloseModal()
  }

  closeConfirmModalPhoneToOnline = () => {
    const { handleUWCloseModal } = this.props
    this.setState({ openConfirmModalPhoneToOnline: false })
    handleUWCloseModal()
  }

  closeConfirmModalOverThePhone = () => {
    const { handleUWCloseModal } = this.props
    this.setState({ openConfirmModalOverThePhone: false })
    handleUWCloseModal()
  }

  closeConfirmModalSendToAdviser = () => {
    const { handleUWCloseModal } = this.props
    this.setState({ openConfirmModalSendToAdviser: false })
    handleUWCloseModal()
  }

  handleSendToAdviser = () => {
    this.setState({
      openChangeModalPhoneToOnline: false,
      openChangeModalOnlineToPhone: false,
      openConfirmModalSendToAdviser: true,
    })
  }

  handleOverThePhone = () => {
    this.setState({ openChangeModalOnlineToPhone: false, openConfirmModalOverThePhone: true })
  }

  handleCompleteOnline = () => {
    this.setState({ openChangeModalPhoneToOnline: false, openConfirmModalPhoneToOnline: true })
  }

  moveBackToMethod = () => {
    const {
      createQuote: { activeIndex, quotes },
    } = this.props
    const method = get(quotes, `[${activeIndex}].underwritingDetails.underwritingMethod`, '')
    if (getScope() === SCOPE_TYPE_TELE && method === TELE) {
      history.push(CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE)
    } else {
      history.push(CUSTOMER_PERSONAL_STATEMENT_METHOD_PAGE)
    }
  }

  saveUWMethodToOnline = () => {
    const {
      handleUWCloseModal,
      actions,
      createQuote: { isTeleFromAdviser },
    } = this.props
    const { updateQuoteStatus, saveQuote, updateIsTeleFromAdviser } = actions
    this.setState({ openConfirmModalPhoneToOnline: false })
    handleUWCloseModal()
    const quoteStatus = {
      actionName: isTeleFromAdviser ? REGISTER_MY_LINK : WIP_MYLINK,
      quoteType: QUOTE_STATUS_APPLICATION_STAGE,
      underwritingMethod: MYLINK,
    }
    updateIsTeleFromAdviser(false)
    updateQuoteStatus(quoteStatus)
    saveQuote()
    this.moveBackToMethod()
  }

  saveUWMethodToOverThePhone = () => {
    const { handleUWCloseModal, actions } = this.props
    const { updateQuoteStatus } = actions
    this.setState({ openConfirmModalOverThePhone: false })
    handleUWCloseModal()
    const quoteStatus = {
      actionName: TELE,
      quoteType: QUOTE_STATUS_APPLICATION_STAGE,
      underwritingMethod: TELE,
    }
    updateQuoteStatus(quoteStatus)
    this.moveBackToMethod()
  }

  saveUWMethodToYourAdviser = () => {
    const { handleUWCloseModal, actions } = this.props
    const { updateQuoteStatus, saveQuote, updateIsTeleFromAdviser } = actions
    this.setState({ openConfirmModalSendToAdviser: false })
    handleUWCloseModal()
    const quoteStatus = {
      actionName: SAVE_DRAFT_APPLICATION,
      quoteType: QUOTE_STATUS_APPLICATION_STAGE,
      underwritingMethod: ADV_F2F,
    }
    updateIsTeleFromAdviser(false)
    updateQuoteStatus(quoteStatus)
    saveQuote(err => {
      if (!err) {
        if (getScope() === SCOPE_TYPE_TELE) {
          history.push(CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE)
        } else {
          history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
        }
      }
    })
  }

  render() {
    const {
      openChangeModalOnlineToPhone,
      openChangeModalPhoneToOnline,
      openConfirmModalPhoneToOnline,
      openConfirmModalOverThePhone,
      openConfirmModalSendToAdviser,
    } = this.state
    const { fields, featureControlSwitch } = this.props
    const {
      changeMethodOnlinetoPhoneModalTitle,
      changeMethodOnlinetoPhoneModalDescription,
      sendBackToAdviserButtonLabel,
      sendBackToAdviserButtonDescription,
      completeOverPhoneButtonLabel,
      completeOverPhoneButtonDescription,
      changeMethodPhoneToOnlineModalTitle,
      changeMethodPhoneToOnlineModalDescription,
      completeOnlineButtonLabel,
      completeOnlineButtonDescription,
      changeMethodToOnlineModalTitle,
      changeMethodToOnlineModalDescription,
      changeMethodToOnlineModalButtonLabel,
      changeMethodToOverThePhoneModalTitle,
      changeMethodToOverThePhoneModalDescription,
      changeMethodToOverThePhoneButtonLabel,
      sendToYourAdviserModalTitle,
      sendToYourAdviseModalDescription,
      sendToYourAdviseButtonLabel,
    } = fields
    const isOverthePhoneEnabled = isFeatureEnabledForCP(
      featureControlSwitch,
      'enableTeleUnderwritingForCustomerPersonalStatement'
    )
    return (
      <Fragment>
        {/* Change Method Online to Phone  */}
        <Modal
          isOpen={openChangeModalOnlineToPhone}
          onClose={this.closeChangeModalOnlineToPhone}
          title={changeMethodOnlinetoPhoneModalTitle}
          shouldFocusCloseButton
        >
          <ModalDescription>{changeMethodOnlinetoPhoneModalDescription}</ModalDescription>
          <ButtonContainer>
            <ModalCard
              handleClickEvent={this.handleSendToAdviser}
              buttonTitleText={sendBackToAdviserButtonLabel}
              buttonDescriptionText={sendBackToAdviserButtonDescription}
            />
            {isOverthePhoneEnabled && (
              <ModalCard
                handleClickEvent={this.handleOverThePhone}
                buttonTitleText={completeOverPhoneButtonLabel}
                buttonDescriptionText={completeOverPhoneButtonDescription}
              />
            )}
          </ButtonContainer>
        </Modal>

        {/* Change Method Phone to Online  */}
        <Modal
          isOpen={openChangeModalPhoneToOnline}
          onClose={this.closeChangeModalPhoneToOnline}
          title={changeMethodPhoneToOnlineModalTitle}
          shouldFocusCloseButton
        >
          <ModalDescription>{changeMethodPhoneToOnlineModalDescription}</ModalDescription>
          <ButtonContainer>
            <ModalCard
              handleClickEvent={this.handleSendToAdviser}
              buttonTitleText={sendBackToAdviserButtonLabel}
              buttonDescriptionText={sendBackToAdviserButtonDescription}
            />
            <ModalCard
              handleClickEvent={this.handleCompleteOnline}
              buttonTitleText={completeOnlineButtonLabel}
              buttonDescriptionText={completeOnlineButtonDescription}
            />
          </ButtonContainer>
        </Modal>

        {/* Confirm Modal - Change method to online */}
        <Modal
          isOpen={openConfirmModalPhoneToOnline}
          onClose={this.closeConfirmModalPhoneToOnline}
          title={changeMethodToOnlineModalTitle}
          shouldFocusCloseButton
        >
          <ModalDescription>{changeMethodToOnlineModalDescription}</ModalDescription>
          <Button type="secondary" onClick={this.saveUWMethodToOnline}>
            {changeMethodToOnlineModalButtonLabel}
          </Button>
        </Modal>

        {/* Confirm Modal - Change method to over the phone */}
        <Modal
          isOpen={openConfirmModalOverThePhone}
          onClose={this.closeConfirmModalOverThePhone}
          title={changeMethodToOverThePhoneModalTitle}
          shouldFocusCloseButton
        >
          <ModalDescription>{changeMethodToOverThePhoneModalDescription}</ModalDescription>
          <Button type="secondary" onClick={this.saveUWMethodToOverThePhone}>
            {changeMethodToOverThePhoneButtonLabel}
          </Button>
        </Modal>

        {/* Confirm Modal -  Send to your adviser for complete */}
        <Modal
          isOpen={openConfirmModalSendToAdviser}
          onClose={this.closeConfirmModalSendToAdviser}
          title={sendToYourAdviserModalTitle}
          shouldFocusCloseButton
        >
          <ModalDescription>{sendToYourAdviseModalDescription}</ModalDescription>
          <Button type="secondary" onClick={this.saveUWMethodToYourAdviser}>
            {sendToYourAdviseButtonLabel}
          </Button>
        </Modal>
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ createQuote, masterList }) => ({
  createQuote,
  featureControlSwitch: get(masterList, 'data.featureControlSwitch', []),
})

export default connect(mapStateToProps, mapDispatchToProps)(PSChangeModal)
