import {
  colours,
  space,
  fontSize,
  mediaQueries,
  buttonPadding,
  fontFamily,
  fontWeights,
} from '../../../../styles'

const styles = {
  main(centerAlignedItems) {
    return {
      color: colours.black,
      backgroundColor: colours.white,
      padding: space(4),
      marginBottom: space(4),
      ...(centerAlignedItems && { textAlign: 'center' }),
    }
  },
  progress: borderBottom => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...(borderBottom && {
      borderBottom: `1px solid ${colours.lightGrey}`,
      marginBottom: space(6),
      paddingBottom: space(4),
    }),

    [mediaQueries.md]: {
      flexDirection: 'row',
    },
  }),
  bar: (active, percentage, type) => ({
    borderBottom: `8px solid ${colours.mediumGrey}`,
    color: colours.mediumGrey,
    borderColor: type === 'lightest' ? colours.lightGrey : colours.mediumGrey,
    flexBasis: percentage,
    paddingBottom: space(2),
    fontWeight: 'bold',
    ...(active && {
      borderColor: type === 'dark' ? colours.mediumGreen : colours.lightGreen,
      color: colours.mediumGreen,
    }),
  }),
  method: {
    color: colours.mediumGrey,
  },
  changeMethod: {},
  completedIcon: {
    minHeight: space(25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  caption: {
    fontSize: fontSize.md,
  },
  message: {
    marginBottom: space(4),
    marginTop: space(3),
  },
  continueButton: {
    height: 'auto',
  },
  exploreButton: () => ({
    height: 48,
    paddingTop: buttonPadding.sm.vertical,
    paddingBottom: buttonPadding.sm.vertical,
    paddingLeft: buttonPadding.sm.horizontal,
    paddingRight: buttonPadding.sm.horizontal,
    textAlign: 'center',
    border: 'none',

    justifyContent: 'center',
    display: 'inline-flex',
    alignItems: 'center',

    backgroundColor: colours.mediumGreen,
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    fontFamily: fontFamily.charlie,
    fontWeight: fontWeights.normal,
    '&:hover, &:focus': {
      backgroundColor: colours.darkGreen,
    },
    '> a': {
      textDecoration: 'none',
      color: colours.white,
    },
  }),
}
export default styles
