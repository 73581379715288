// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import queryString from 'query-string'
import get from 'lodash/get'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components
import OTPFormComponent from './components/OTPForm'

import UnAuthenticatedComponent from './components/UnAuthenticated'

// styles.
import styles from './completionMethod.styles'

import { STEP_OTP, STEP_UNAUTHORISED } from '../../../constants/completionMethod'

// utils
import history from '../../../utils/browserHistory'
import { space } from '../../../styles'
import { NAVIGATION_ONLY_LOGO } from '../../../constants/navigation'
import { CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE } from '../../../constants/personalStatement'
import { OKTA_SESSION_INACTIVE } from '../../../constants/okta'

const Wrapper = styled('div')(styles.main)

type CompletionMethodPropTypes = {
  fields: object,
  actions: object,
  hasPersonalStatementCompleted: boolean,
  hasFactorMissing: boolean,
}

export class CompletionMethod extends Component<CompletionMethodPropTypes> {
  constructor(props) {
    super(props)
    this.queryParams = queryString.parse(get(history, 'location.search', ''))
    this.state = {
      view: this.queryParams && this.queryParams.quoteCollectionId ? STEP_OTP : STEP_UNAUTHORISED,
    }
  }

  componentWillMount() {
    const { hasPersonalStatementCompleted } = this.props
    if (hasPersonalStatementCompleted) {
      history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
    }
  }

  componentDidMount() {
    const { actions } = this.props
    const { myLinkPrimaryAuth, changeNavigationType, clearMyLinkOktaSession } = actions
    const quoteCollectionId = get(this.queryParams, 'quoteCollectionId')
    changeNavigationType(NAVIGATION_ONLY_LOGO)
    if (quoteCollectionId) {
      clearMyLinkOktaSession(quoteCollectionId)
        .then(response => {
          // is previous session deleted and inactive
          if (
            response.status === 204 ||
            (response.data && response.data.status === OKTA_SESSION_INACTIVE)
          ) {
            myLinkPrimaryAuth({
              quoteCollectionId,
            })
            this.changeView(STEP_OTP)
          } else {
            this.changeView(STEP_UNAUTHORISED)
          }
        })
        .catch(() => {
          myLinkPrimaryAuth({
            quoteCollectionId,
          })
          this.changeView(STEP_OTP)
        })
    } else {
      this.changeView(STEP_UNAUTHORISED)
    }
  }

  changeView = view => {
    this.setState(() => ({
      view,
    }))
  }

  renderLayout() {
    const { fields, hasFactorMissing } = this.props
    const { view } = this.state

    if (hasFactorMissing) return <UnAuthenticatedComponent fields={fields} />

    switch (view) {
      case STEP_OTP: {
        return <OTPFormComponent fields={fields} query={this.queryParams} />
      }

      case STEP_UNAUTHORISED:
        return <UnAuthenticatedComponent fields={fields} />
      default:
        return <Loader spinnerSize={Number(space(10))} borderSize={Number(space(0.5))} />
    }
  }

  render() {
    return (
      <Fragment>
        <Wrapper>{this.renderLayout()}</Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ myLink: { hasCompleted, hasFactorMissing } }) => ({
  hasPersonalStatementCompleted: hasCompleted,
  hasFactorMissing,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompletionMethod)
