import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Chip, Loader } from '@mlcl-digital/mlcl-design'
import { Card, CardContainer, CardTable as Table, CardCell as Cell } from '../CardChildren'

const LifeInsureds = ({ lifeAssured, fields, isAdvisorPortal }) => {
  const { policyDetailsLifeInsuredDetailsHeading, policyDetailsLifeInsuredDateOfBirthHeading } =
    fields
  return (
    <CardContainer data-testid="policy-details-overview-life-insured">
      {lifeAssured.map(
        (
          {
            fullName,
            dob,
            isAnyBenefitsInForce,
            smokerStatus,
            tfn,
            hasRelatedParty,
            bancsCustomerNo,
            fullAddress,
          },
          index
        ) => (
          <Card
            data-testid={`policy-details-overview-life-insured-${index}`}
            key={bancsCustomerNo || index}
            header={
              <Fragment>
                {hasRelatedParty === false && <Loader />}
                {isAnyBenefitsInForce ? (
                  <Chip>{policyDetailsLifeInsuredDetailsHeading}</Chip>
                ) : (
                  <Chip variant="important">Out of force</Chip>
                )}
                <div>
                  <span>{fullName}</span>
                </div>
              </Fragment>
            }
            noFooterBorder
          >
            <Table data-testid={`policy-details-overview-life-insured-details-${index}`}>
              <Cell>{policyDetailsLifeInsuredDateOfBirthHeading}</Cell>
              <Cell>{dob || '-'}</Cell>
              <Cell>Address</Cell>
              <Cell>{fullAddress || '-'}</Cell>
              <Cell>Smoker status</Cell>
              <Cell>{smokerStatus || '-'}</Cell>
              {isAdvisorPortal && (
                <>
                  <Cell>TFN</Cell>
                  <Cell>{tfn}</Cell>
                </>
              )}
            </Table>
          </Card>
        )
      )}
    </CardContainer>
  )
}
LifeInsureds.propTypes = {
  lifeAssured: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.shape({
    policyDetailsLifeInsuredDetailsHeading: PropTypes.string,
    policyDetailsLifeInsuredDateOfBirthHeading: PropTypes.string,
    policyDetailsLifeInsuredPhoneHeading: PropTypes.string,
    policyDetailsLifeInsuredEmailHeading: PropTypes.string,
    policyDetailsLifeInsuredAddressHeading: PropTypes.string,
  }),
  isAdvisorPortal: PropTypes.bool,
}

LifeInsureds.defaultProps = {
  lifeAssured: [],
  fields: {},
  isAdvisorPortal: false,
}

export default LifeInsureds
