// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

import { responsiveTileStyle } from '../KnowledgeBase/styles'

const { space, colours, fontSize, fontWeights, mediaQueries, coloursWithOpacity, fontFamily } =
  Variables

const styles = {
  benefitsPanel: {
    padding: `0 0 ${space(3, true)} 0`,

    [mediaQueries.md]: {
      padding: `0 0 ${space(4, true)} 0`,
    },
  },
  benefitsHeaderContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    [mediaQueries.md]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  pageWrapWrapper: {
    '> div:first-child > div:first-child': {
      padding: 40,
      marginTop: 0,
      '> div': {
        marginTop: 0,
      },
      '> div > img': {
        display: 'block',
      },
    },
  },
  benefitsHeader: {
    color: coloursWithOpacity(colours.lightBlack, 0.7),
    marginBottom: space(1, true),

    [mediaQueries.md]: {
      marginBottom: 'auto',
    },
  },
  advisernopolicyinforce: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    color: colours.Black,
  },
  advisernopolicyinforceText: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.normal,
    color: colours.Black,
  },
  button: {
    textAlign: 'right',
    fontSize: fontSize.xs,
    fontWeight: fontWeights.bold,
    color: colours.mediumGreen,
    borderWidth: 2,
    textDecoration: 'none',

    [mediaQueries.md]: {
      fontSize: fontSize.sm,
    },
  },
  findAdvisorLink: {
    textAlign: 'right',
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    color: colours.mediumGreen,
    textDecoration: 'none',

    [mediaQueries.md]: {
      fontSize: fontSize.sm,
    },
  },

  benefitsContainer: {
    display: 'grid',
    gridRowGap: space(3, true),
    marginTop: space(3, true),
    [mediaQueries.lg]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: space(3, true),
    },
  },
  advisorContainer: {
    display: 'grid',
    gridRowGap: space(3, true),
    [mediaQueries.lg]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: space(3, true),
    },
  },
  sectionHeader: {
    marginTop: '-200px',
  },
  articleCardWrapper: {
    display: 'grid',
    gridGap: space(2),
    marginBottom: space(17),
    ...responsiveTileStyle,
  },
  errorStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '500px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  modalContent: {
    color: colours.tertiaryOne,
    fontWeight: '400',
  },
  premiumCalculatorLink: {
    marginRight: space(2.5),
    marginBottom: space(2),
    // Spacing between text and loader
    '> div': {
      marginLeft: space(1),
    },
    [mediaQueries.md]: {
      marginBottom: 0,
    },
  },
  assessYourNeeds: {
    display: 'flex',
    color: colours.tertiaryOne,
    border: `1px solid ${colours.tertiaryThree}`,
    background: colours.tertiaryFive,
    '> div:first-of-type': {
      flexBasis: '54%',
      background: colours.tertiarySix,
      padding: space(7.5),
      h4: {
        color: colours.mlcAquaAccessible,
        fontFamily: fontFamily.sourceSans,
      },
      p: {
        padding: `${space(1.5, true)} 0`,
        div: {
          display: 'inline',
        },
        a: {
          padding: `0 ${space(0.5, true)}`,
          color: colours.mlcAquaAccessible,
          textDecoration: 'none',
          letterSpacing: 'normal',
        },
      },
    },
  },
  accessNeedsImageWrapper: {
    flexBasis: '46%',
    display: 'flex',
    alignItems: 'center',
    img: {
      marginBottom: 0,
    },
  },
}

export default styles
