// @flow
import React, { Fragment, PureComponent } from 'react'
import { object, arrayOf } from 'prop-types'
import styled from '@emotion/styled'
import { SectionHeader } from '@mlcl-digital/mlcl-design'

// styles
import styles from './exclusions.styles'

const Wrapper = styled('div')(styles.wrapper)
const BenefitHeading = styled('h4')(styles.benefitHeading)
const ExclusionsElement = styled('p')(styles.exclusionsElement)

type ExclusionsProps = {
  // Heading
  heading: String,
  // Sub Heading
  subHeading: String,
  // Exclusion object
  exclusions?: Array<Object>,
}

class ExclusionsComponent extends PureComponent<ExclusionsProps> {
  render() {
    const { heading, subHeading, exclusions } = this.props

    const shouldExclusionsSectionRender = exclusions.flatMap(
      exclusionList =>
        exclusionList &&
        exclusionList.some(
          exclusion => exclusion.exclusionPara && !exclusion.exclusionPara.includes('<?xml')
        )
    )
    return (
      shouldExclusionsSectionRender.length > 0 && (
        <>
          <SectionHeader heading={heading} subHeading={subHeading} />
          <Wrapper>
            {exclusions.map(benefitExclusions => {
              const exclusionsToRender = benefitExclusions.filter(
                exclusion => exclusion.exclusionPara && !exclusion.exclusionPara.includes('<?xml')
              )

              return (
                exclusionsToRender.length > 0 && (
                  <Fragment key={benefitExclusions[0].referenceNo}>
                    <BenefitHeading>{benefitExclusions[0].benefitHeading}</BenefitHeading>
                    {exclusionsToRender.map(exclusion => (
                      <ExclusionsElement key={exclusion && exclusion.referenceNo}>
                        {exclusion && exclusion.exclusionPara}
                      </ExclusionsElement>
                    ))}
                  </Fragment>
                )
              )
            })}
          </Wrapper>
        </>
      )
    )
  }
}

ExclusionsComponent.defaultProps = {
  exclusions: arrayOf(object).isRequired,
}

export default ExclusionsComponent

export { ExclusionsComponent as Exclusions }
