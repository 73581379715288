// @flow
import React, { Component, type ComponentType, Fragment } from 'react'

// components.
import GenericFallback from './GenericFallback'

export type ErrorInfo = {
  /* the key that react uses to pass the call stack into. */
  componentStack: string,
}

type ErrorBoundaryProps = {
  /* children to usually render when no errors are encountered. */
  children?: any,
  /* the fallback ui for when an error is encountered below the boundary. */
  fallbackComponent?: ComponentType<any>,
  /* an optional function you can call when the tree fails to render correctly. */
  onError?: (error: Error, info: ErrorInfo) => void,
  /* used to customise the display of the fallback ui. */
  type?: 'default' | 'tab' | 'page',
}

type ErrorBoundaryState = {
  /* if an error has been thrown within this components tree. */
  hasError: boolean,
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    children: null,
    fallbackComponent: GenericFallback,
    onError: () => {},
    type: 'default',
  }

  state = {
    hasError: false,
  }

  // this is called before commit phase, before the dom is updated.
  static getDerivedStateFromError() {
    // update state so the next render will show the fallback ui.
    return { hasError: true }
  }

  // this is called during the commit phase, when the dom has been updated.
  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props
    if (typeof onError === 'function') onError(error, info)
  }

  render() {
    const { children, fallbackComponent: FallbackComponent, ...rest } = this.props
    const { hasError } = this.state

    return hasError ? <FallbackComponent {...rest} /> : <Fragment>{children}</Fragment>
  }
}

export default ErrorBoundary
