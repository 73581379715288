// @flow
import React, { Fragment, PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { createEvent } from '../../../../utils/telemetry'

// components
import Modal from '../../../molecules/Modal'
import AddBeneficiary from '../AddBeneficiary'

// utils
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { PolicyType } from '../../../../selectors/customerBeneficiaries'

// styles
import styles from './selectPolicyModal.styles'

const PolicyContainer = styled.div(styles.policyContainer)
export const PolicyWrapper = styled.div(styles.policyWrapper)
const Policy = styled.div(styles.policy)
const LifeInsured = styled.div(styles.lifeInsured)
const PolicyDetails = styled.div(styles.policyDetails)
const MessageWrapper = styled.div(styles.messageWrapper)

class SelectPolicyModal extends PureComponent<SelectPolicyModalProps> {
  constructor(props) {
    super(props)
    this.state = {
      isPoliciesModelOpen: false,
      isBindNonBindModalOpen: false,
      bancsPolicyNo: '',
      beneficiaries: [],
    }
    this.handlePolicyClick = this.handlePolicyClick.bind(this)
  }

  onSubmit = () => {
    const tagEvent = createEvent({
      GA: { category: 'Customer selects Add Beneficiary', action: 'select' },
      Splunk: {
        attributes: {
          'workflow.name': 'Customer selects Add Beneficiary',
        },
      },
    })
    tagEvent.end()
    this.setState({ isPoliciesModelOpen: true })
  }

  handleClose = () => {
    this.setState({ isPoliciesModelOpen: false })
  }

  handleBindNonBindModalClose = () => {
    this.setState({ isBindNonBindModalOpen: false })
  }

  renderPoliciesTab = (policy, policyIndex) => {
    const { fields } = this.props
    const { cpPolicyModalLifeInsuredText } = fields
    return (
      <PolicyWrapper
        key={`${policy.policyId}-${policy.productId}`}
        data-locator={`beneficiary-policy-${policyIndex}`}
        onClick={() =>
          this.handlePolicyClick(
            policy.productId,
            policy.bancsPolicyNo,
            policy.beneficiariesData,
            policy.lifeAssuredDetails,
            policy.formType,
            policy.authorisedToEditBeneficiary
          )
        }
      >
        <Policy>
          <div>{policy.policyName}</div>
        </Policy>
        <LifeInsured>
          <PolicyDetails>
            {renderTextField(cpPolicyModalLifeInsuredText)} {policy.policyId}
          </PolicyDetails>
        </LifeInsured>
      </PolicyWrapper>
    )
  }

  handlePolicyClick(
    productId,
    bancsPolicyNo,
    beneficiaries = [],
    lifeAssured,
    formType,
    isAuthorised
  ) {
    const {
      actions: { toggleFileUploadModal, showBeneficiaryForm },
    } = this.props

    if (formType === 'binding' || !isAuthorised) {
      toggleFileUploadModal(true, bancsPolicyNo, productId)
      this.setState({ isPoliciesModelOpen: false })
    } else if (formType === 'nonbinding') {
      showBeneficiaryForm(true, {
        lifeAssured,
        bancsPolicyNo,
        toAdd: true,
        beneficiaries,
      })
      this.setState({ beneficiaries, lifeAssured })
      this.handleClose()
    } else if (formType === 'both') {
      this.setState({
        isPoliciesModelOpen: false,
        isBindNonBindModalOpen: true,
        beneficiaries,
        lifeAssured,
      })
    }
    this.setState({ bancsPolicyNo, productId })
  }

  render() {
    const {
      bancsPolicyNo,
      beneficiaries,
      isPoliciesModelOpen,
      isBindNonBindModalOpen,
      lifeAssured,
      productId,
    } = this.state

    const { policies = [], fields } = this.props

    const {
      cpCustomerPolicyModalTitle,
      cpCustomerPolicyModalSubTitle,
      cpCustomerPolicyModalSinglePolicySubTitle,
      cpCustomerBindNonBindModalTitle,
      cpCustomerBindNonBindModalSubTitle,
      cpCustomerBindNonBindAddBeneficiarySubText,
    } = fields

    const { cpCustomerAddBeneficiary, cpClientPolicyDetailsBeneficiaryDelayMessage } =
      reduceAuthorableFields(fields)

    const { cpCustomerPolicyModalTitle: clientPolicyModalTitleSuffix } = reduceAuthorableFields({
      cpCustomerPolicyModalTitle,
    })

    const { cpCustomerBindNonBindModalTitle: cpCustomerBindNonBindModalTitleSuffix } =
      reduceAuthorableFields({
        cpCustomerBindNonBindModalTitle,
      })

    const { cpCustomerPolicyModalSubTitle: cpCustomerPolicyModalSubTitleSuffix } =
      reduceAuthorableFields({
        cpCustomerPolicyModalSubTitle,
      })

    const {
      cpCustomerPolicyModalSinglePolicySubTitle: cpCustomerPolicyModalSinglePolicySubTitleSuffix,
    } = reduceAuthorableFields({
      cpCustomerPolicyModalSinglePolicySubTitle,
    })

    const { cpCustomerBindNonBindModalSubTitle: cpCustomerBindNonBindModalSubTitleSuffix } =
      reduceAuthorableFields({
        cpCustomerBindNonBindModalSubTitle,
      })

    return (
      <Fragment>
        <AddBeneficiary
          handleClick={this.onSubmit}
          addBeneficiaryLabel={cpCustomerAddBeneficiary}
        />
        <Modal
          isOpen={isPoliciesModelOpen}
          onClose={this.handleClose}
          title={clientPolicyModalTitleSuffix}
          isDynamic
        >
          {policies && (
            <p>
              {policies.length > 1
                ? cpCustomerPolicyModalSubTitleSuffix
                : cpCustomerPolicyModalSinglePolicySubTitleSuffix}
            </p>
          )}
          <PolicyContainer>
            {policies.map((policy, index) => this.renderPoliciesTab(policy, index))}
          </PolicyContainer>
        </Modal>

        <Modal
          isOpen={isBindNonBindModalOpen}
          onClose={this.handleBindNonBindModalClose}
          title={cpCustomerBindNonBindModalTitleSuffix}
          isDynamic
        >
          {<p>{cpCustomerBindNonBindModalSubTitleSuffix}</p>}
          <PolicyContainer>
            <PolicyWrapper
              data-locator="binding"
              data-testid="binding"
              onClick={() => {
                const {
                  actions: { toggleFileUploadModal },
                } = this.props

                toggleFileUploadModal(true, bancsPolicyNo, productId)
                this.setState({
                  isPoliciesModelOpen: false,
                  isBindNonBindModalOpen: false,
                })
              }}
            >
              <Policy>
                <div>Binding</div>
              </Policy>
              <LifeInsured>
                {renderTextField(cpCustomerBindNonBindAddBeneficiarySubText)}
              </LifeInsured>
            </PolicyWrapper>
            <PolicyWrapper
              data-locator="non-binding"
              data-testid="non-binding"
              onClick={() => {
                const {
                  actions: { showBeneficiaryForm },
                } = this.props
                this.handleBindNonBindModalClose()
                showBeneficiaryForm(true, {
                  lifeAssured,
                  bancsPolicyNo,
                  toAdd: true,
                  beneficiaries,
                })
              }}
            >
              <Policy>
                <div>Non-Binding</div>
              </Policy>
              <LifeInsured>
                {renderTextField(cpCustomerBindNonBindAddBeneficiarySubText)}
              </LifeInsured>
            </PolicyWrapper>
          </PolicyContainer>
          <MessageWrapper>{cpClientPolicyDetailsBeneficiaryDelayMessage}</MessageWrapper>
        </Modal>
      </Fragment>
    )
  }
}

SelectPolicyModal.propTypes = {
  actions: PropTypes.shape({
    showBeneficiaryForm: PropTypes.func,
    toggleFileUploadModal: PropTypes.func,
  }).isRequired,
  fields: PropTypes.shape({
    cpCustomerPolicyModalTitle: PropTypes.object,
    cpCustomerBindNonBindModalTitle: PropTypes.object,
    cpCustomerPolicyModalSubTitle: PropTypes.object,
    cpCustomerBindNonBindModalSubTitle: PropTypes.object,
    cpCustomerBindNonBindAddBeneficiarySubText: PropTypes.object,
    cpPolicyModalLifeInsuredText: PropTypes.object,
    cpCustomerAddBeneficiary: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryDelayMessage: PropTypes.object,
  }).isRequired,
  policies: PropTypes.arrayOf(PolicyType).isRequired,
}

export default SelectPolicyModal
