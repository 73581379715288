// @flow
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'

// design.
import { Tooltip, Icons } from '@mlcl-digital/mlcl-design'

import { renderTextField } from '../../../../../utils/sitecoreUtils'

import styles from './table.styles'

const { IconInfo16 } = Icons

const StyledTable = styled('table')(styles.table)
const StyledTFoot = styled('tfoot')(styles.tfoot)
const InfoIconContainer = styled('div')(styles.infoIconContainer)
const TooltipRow = styled(Tooltip)(styles.tooltip)
const InfoIcon = styled(IconInfo16)(styles.infoIcon)

type TableProps = {
  footerData: Array<Array<string | Object>>,
}
class TableFooter extends PureComponent<TableProps> {
  render() {
    const { footerData = [] } = this.props

    return (
      <StyledTable>
        <StyledTFoot>
          {footerData.map(row => (
            <tr key={`${row[0]}-${row[1]}`}>
              <td colSpan="5">{row[0]}</td>
              <td>
                {row[1]}
                {row[2] && (
                  <InfoIconContainer>
                    <div data-tip data-for="toolTip">
                      <InfoIcon />
                    </div>
                    <TooltipRow id="toolTip" place="left">
                      {renderTextField(row[2], true)}
                    </TooltipRow>
                  </InfoIconContainer>
                )}
              </td>
            </tr>
          ))}
        </StyledTFoot>
      </StyledTable>
    )
  }
}

export default TableFooter
