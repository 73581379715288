import { fontSize, colours, space } from '../../../../../../../../styles'

const styles = {
  label: {
    display: 'block',
    fontSize: fontSize.xs,
    label: {
      fontSize: fontSize.xs,
      color: colours.darkGrey,
      a: {
        color: colours.mediumGreen,
        textDecoration: 'none',
      },
    },
  },
  paymentFrequency: {
    color: colours.black,
  },
  creditCardInput: {
    input: {
      border: 'none',
    },
  },
  directDebitContainer: {
    display: 'flex',
  },
  directDebitInput: {
    width: '50%',
    '&:nth-child(even)': { paddingLeft: space(1, true) },
    '&:nth-child(odd)': { padding: '0' },
  },
  accountName: {
    width: '49%',
  },
  noteLink: {
    color: colours.mediumGreen,
    textDecoration: 'none',
    marginLeft: space(1),
  },
  drawDate: {
    width: '49',
    marginLeft: space(3),
    input: {
      border: 0,
    },
  },
}

export default styles
