// @flow
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Chip, Button, Variables, ErrorState, Loader } from '@mlcl-digital/mlcl-design'

// components
import DeleteBeneficiariesModel from '../DeleteBeneficiary'
import CustomerBeneficiaryModal from '../InformationModal'

import { IconInfo16 } from '../../../atoms/Icons'

import { CardDivide, CardTable, CardCell, Card as NewCard } from '../../../molecules/CardChildren'

// utilitis
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { PolicyType } from '../../../../selectors/customerBeneficiaries'

const { fontSize, colours, space } = Variables
const StyledHeader = styled.div({
  '.title': {
    fontSize: fontSize.xs,
  },
})
const StyledInfoButton = styled(Button)({
  display: 'contents',
})
const StyledRemoveButton = styled(Button)({
  color: Variables.colours.functionalDangerDark,
})

const CardTableDiv = styled(CardTable)(({ isPaddingRequired }) => ({
  ...(!isPaddingRequired && {
    padding: '0px',
  }),
}))

const IconInfo = styled(IconInfo16)({
  color: colours.tertiaryOne,
  display: 'inline-block',
  transform: 'translateX(0)',
  transition: 'transform .35s ease',
  borderRadius: '50%',
  marginRight: space(2),
  padding: space(1),
  height: 32,
  width: 32,
})
class Beneficiaries extends Component {
  constructor() {
    super()
    this.state = {
      removeQuoteModalOpen: false,
      beneficiaryToRemove: {},
    }
  }

  handleInfoModal = () => {
    const { actions } = this.props
    actions.toggleBeneficiaryInfoModal(true)
  }

  displayRemoveModal = value => {
    this.setState({
      removeQuoteModalOpen: value,
    })
  }

  removeBeneficiary = beneficiary => {
    const { policy } = this.props
    const { formType } = policy
    if (formType === 'binding') {
      this.showBindingUploadForm()
    } else {
      this.setState({
        removeQuoteModalOpen: true,
        beneficiaryToRemove: beneficiary,
      })
    }
  }

  primaryActionInvocation = beneficiary => {
    const { policy, actions } = this.props
    const { beneficiariesData, bancsPolicyNo, authorisedToEditBeneficiary, lifeAssured } = policy
    if (authorisedToEditBeneficiary) {
      actions.showBeneficiaryForm(true, {
        dataForInput: beneficiary,
        lifeAssured,
        bancsPolicyNo,
        toAdd: false,
        beneficiaries: beneficiariesData,
      })
    } else {
      this.showBindingUploadForm()
    }
  }

  retryClickHandler = bancsCustomerNo => {
    const {
      actions: { fetchRelatedParty },
    } = this.props
    fetchRelatedParty(bancsCustomerNo, 'LB')
  }

  showBindingUploadForm() {
    const {
      actions: { toggleFileUploadModal },
      policy,
    } = this.props
    const { productId, bancsPolicyNo } = policy
    toggleFileUploadModal(true, bancsPolicyNo, productId)
  }

  renderBeneficiaryInfoCard = () => {
    const { policy, fields } = this.props
    const { beneficiariesData, policyName, policyId } = policy

    const {
      cpClientPolicyDetailsBeneficiaryRemoveLabel,
      cpClientPolicyDetailsBeneficiaryRelationshipLabel,
      cpClientPolicyDetailsBeneficiaryPercentageAllocationLabel,
      cpClientPolicyDetailsAddressEditLabel,
      cpClientPolicyDetailsBeneficiaryDateOfBirthLabel,
      cpClientPolicyDetailsBeneficiaryAddressLabel,
    } = reduceAuthorableFields(fields)

    return beneficiariesData.map(beneficiary => {
      const {
        bindingTypeValue,
        bancsCustomerNo,
        beneficiaryName,
        percentageAllocationValue,
        dateOfBirth,
        address,
        canShowAddress,
        hasRelatedParty,
        loadingState,
        relationshipTypeLabel,
        isLegalRepresentive,
        relatedPartyStillPending,
      } = beneficiary

      const key = `${beneficiaryName}-${policyName}-${policyId}-${bancsCustomerNo}`
      const isTableContainData =
        (canShowAddress && (dateOfBirth || address)) ||
        loadingState === 'FAILED' ||
        loadingState === 'UNLOADED' ||
        relatedPartyStillPending
      return (
        <NewCard
          key={key}
          noFooterReverse
          header={
            <StyledHeader>
              <Chip variant="default">{bindingTypeValue}</Chip>
              <div className="title">
                {beneficiaryName ||
                  (hasRelatedParty && '-') ||
                  (loadingState !== 'FAILED' && <Loader />)}
              </div>
              <div className="title">
                {`${policyName} - ${policyId}`}
                <StyledInfoButton variant="tertiary" size="xsmall" onClick={this.handleInfoModal}>
                  <IconInfo />
                </StyledInfoButton>
              </div>
            </StyledHeader>
          }
          footer={
            loadingState !== 'FAILED' &&
            !relatedPartyStillPending && (
              <>
                {canShowAddress && (address || !isLegalRepresentive) && (
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => this.primaryActionInvocation(beneficiary)}
                  >
                    {cpClientPolicyDetailsAddressEditLabel}
                  </Button>
                )}
                <StyledRemoveButton
                  variant="tertiary"
                  onClick={() => this.removeBeneficiary(beneficiary)}
                >
                  {cpClientPolicyDetailsBeneficiaryRemoveLabel}
                </StyledRemoveButton>
              </>
            )
          }
        >
          <CardDivide>
            <div>
              {cpClientPolicyDetailsBeneficiaryRelationshipLabel}
              <br />
              {relationshipTypeLabel || '-'}
            </div>
            <div>
              {cpClientPolicyDetailsBeneficiaryPercentageAllocationLabel}
              <br />
              {percentageAllocationValue}
            </div>
          </CardDivide>
          <CardTableDiv isPaddingRequired={isTableContainData}>
            {canShowAddress && (dateOfBirth || address) && (
              <>
                {dateOfBirth && (
                  <>
                    <CardCell>{cpClientPolicyDetailsBeneficiaryDateOfBirthLabel}</CardCell>
                    <CardCell>{dateOfBirth}</CardCell>
                  </>
                )}
                {address && (
                  <>
                    <CardCell>{cpClientPolicyDetailsBeneficiaryAddressLabel}</CardCell>
                    <CardCell>{address}</CardCell>
                  </>
                )}
              </>
            )}
            {loadingState === 'FAILED' && (
              <CardCell style={{ gridColumn: '1 / span 2' }}>
                <ErrorState
                  message="There was an issue loading personal details. Please try again later"
                  showBackground={false}
                  retryFunction={() => this.retryClickHandler(bancsCustomerNo)}
                />
              </CardCell>
            )}
            {loadingState === 'UNLOADED' ||
              (loadingState === 'LOADING' && (
                <CardCell style={{ gridColumn: '1 / span 2' }}>
                  <Loader />
                </CardCell>
              ))}
            {relatedPartyStillPending && (
              <CardCell style={{ gridColumn: '1 / span 2' }}>
                <ErrorState
                  message="Your beneficiary's details are being finalised in our system"
                  emptyState
                  showBackground={false}
                />
              </CardCell>
            )}
          </CardTableDiv>
        </NewCard>
      )
    })
  }

  render() {
    const { fields, actions, policy } = this.props
    const { bancsPolicyNo, productId, beneficiariesData } = policy
    const { removeQuoteModalOpen, beneficiaryToRemove } = this.state

    return (
      <Fragment>
        {this.renderBeneficiaryInfoCard()}
        <CustomerBeneficiaryModal
          actions={actions}
          button={fields.cpCustomerBeneficiaryModalButton}
          title={fields.cpCustomerBeneficiaryModalTitle}
          body={fields.cpCustomerBeneficiaryModalBody}
          isBodyRichText
        />
        <DeleteBeneficiariesModel
          fields={fields}
          beneficiaries={beneficiariesData}
          beneficiaryToRemove={beneficiaryToRemove}
          bancsPolicyNo={bancsPolicyNo}
          productId={productId}
          authorisedToEditBeneficiary={policy.authorisedToEditBeneficiary}
          actions={actions}
          displayRemoveModal={this.displayRemoveModal}
          removeQuoteModalOpen={removeQuoteModalOpen}
        />
      </Fragment>
    )
  }
}

Beneficiaries.propTypes = {
  actions: PropTypes.shape({
    toggleBeneficiaryInfoModal: PropTypes.func,
    showBeneficiaryForm: PropTypes.func,
    fetchRelatedParty: PropTypes.func,
  }).isRequired,
  fields: PropTypes.shape({
    cpClientPolicyDetailsBeneficiaryRemoveLabel: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryRelationshipLabel: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryPercentageAllocationLabel: PropTypes.object,
    cpClientPolicyDetailsAddressEditLabel: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryDateOfBirthLabel: PropTypes.object,
    cpClientPolicyDetailsBeneficiaryAddressLabel: PropTypes.object,
    cpCustomerBeneficiaryModalTitle: PropTypes.object,
    cpCustomerBeneficiaryModalBody: PropTypes.object,
    cpCustomerBeneficiaryModalButton: PropTypes.object,
  }).isRequired,
  policy: PolicyType.isRequired,
}

export default Beneficiaries
