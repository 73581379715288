// @flow
// @FIXME: need to better manage sitecore fields when used as validation
import React, { Component, Fragment } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { Input, Select } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../utils/telemetry'

// Components
import Button from '../../../atoms/Button'
import AddressLookUp from '../../../molecules/AddressLookUp'
import Modal from '../../../molecules/Modal'

import {
  getCustomerBeneficiaryOptions,
  isManualResidential as isManualResidentialSelector,
} from '../../../../selectors/customerBeneficiaries'

// schema.
import SCHEMA, { FORM_ID } from './BeneficiaryForm.schema'

// styles
import styles from './BeneficiaryForm.styles'
import { space } from '../../../../styles'

// utils
import { ignoreColumns } from '../../../../utils/stylesUtils'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { errorCheck, generateFieldsFromData, getValue } from '../../../../utils/formUtils'
import {
  formatBeneficiaryForm,
  initBeneficiaryForm,
} from '../../../../utils/customerBeneficiaryUtils'

// Constants
import {
  ACTION_ADD,
  ACTION_UPDATE,
  newBeneficiary,
} from '../../../../constants/customerBeneficiary'
import { BENEFICIARY_PARTY_TYPE } from '../../../../constants/forms'
import {
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../../../../constants/policies'

const NameContainer = styled('div')(styles.divName)
const NameInput = styled(Input)(({ transparent }) => styles.nameInput(transparent))
const SelectComponent = styled(Select)(styles.title)
const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const Form = styled('form')(styles)
const MessageWrapper = styled.div({ marginTop: space(3, true) })

type BeneficiaryFormProps = {
  actions: Object,
  fields: Object,
  form: Object,
  dataForInput: Object,
  beneficiaries: Object,
  toAdd: boolean,
  isManualResidential: boolean,
  lifeAssured: Object,
  relationshipOptions: Array<Object>,
  bancsPolicyNo: String,
}

export class BeneficiaryForm extends Component<BeneficiaryFormProps> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)
    const { dataForInput, toAdd } = this.props
    this.state = {
      partyType: get(dataForInput, 'partyType', POLICY_PARTY_TYPE_INDIVIDUAL),
      disable: !toAdd && true,
      oldFormFields: undefined,
    }
  }

  componentWillMount() {
    this.initForm()
  }

  componentDidUpdate(prevProps) {
    const { form, toAdd } = this.props
    const { oldFormFields } = this.state
    if (!toAdd) {
      // storing the intial form data in state for comparing
      if (!oldFormFields && form) {
        this.setState({
          oldFormFields: form.fields,
        })
      }
      // Comparing the updated form with intial form
      if (form && prevProps.form && prevProps.form.fields !== form.fields && oldFormFields) {
        if (!isEqual(form.fields, oldFormFields)) {
          this.setState({
            disable: false,
          })
        } else {
          this.setState({
            disable: true,
          })
        }
      }
    }
  }

  addressChange = address => {
    const { partyType } = this.state
    const { name, data, value } = address
    const { actions, isManualResidential, fields } = this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        isPartyTypeOrg: partyType === POLICY_PARTY_TYPE_BUSINESS,
      },
      fields
    )
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  getLabelFromValue = (options, value) => {
    const selected = options ? options.find(option => option.value === value) : undefined
    return selected ? selected.label : ''
  }

  handleChange = ({ value, name }) => {
    const { partyType } = this.state
    const { actions, isManualResidential, fields } = this.props
    const { formUpdateField, formValidate } = actions
    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        isPartyTypeOrg: partyType === POLICY_PARTY_TYPE_BUSINESS,
      },
      fields
    )
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handlePartyTypeChange = ({ value }) => {
    const partyType = getValue(value)
    this.setState({ partyType }, () => {
      this.initForm()
    })
  }

  addressToggleHandler =
    () =>
    (isManual: boolean): void => {
      const {
        actions: { formResetField },
      } = this.props
      if (isManual) {
        formResetField(FORM_ID)
      }
    }

  onSubmit = () => {
    const { partyType } = this.state
    const {
      actions,
      isManualResidential,
      dataForInput,
      beneficiaries,
      toAdd,
      fields,
      bancsPolicyNo,
      form,
    } = this.props
    const isPartyTypeOrg = partyType === POLICY_PARTY_TYPE_BUSINESS
    const {
      formSubmit,
      formSubmitComplete,
      updateRelationshipsForCustomerBeneficiaries,
      updateBeneficiariesFormForUpdate,
      showPercentageAdjustmentModal,
      showBeneficiaryForm,
      updateBeneficiariesAction,
    } = actions
    const isPOManual = Boolean(getValue(get(form, 'fields.residentialAddressPONo', '')))
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isPartyTypeOrg, toAdd, isPOManual },
      fields
    )

    formSubmit(FORM_ID, schema, (data, updatedForm) => {
      formSubmitComplete(FORM_ID)
      if (updatedForm.isValid) {
        const dataToSend = formatBeneficiaryForm(
          updatedForm,
          toAdd ? null : dataForInput.bancsCustomerNo,
          beneficiaries,
          toAdd ? ACTION_ADD : ACTION_UPDATE,
          partyType
        )
        updateRelationshipsForCustomerBeneficiaries(dataToSend)
        showBeneficiaryForm(false)
        if (toAdd) {
          updateBeneficiariesFormForUpdate({
            benificiaryEntities: dataToSend,
            toAdd,
            bancsPolicyNo,
          })
          showPercentageAdjustmentModal(true)
        } else {
          const tagEvent = createEvent({
            GA: {
              category: 'Customer select Beneficiary Edit Address Save button',
              action: 'Select',
            },
            Splunk: {
              attributes: {
                'workflow.name': 'Customer select Beneficiary Edit Address Save button',
              },
            },
          })
          tagEvent.end()
          // If update beneficiary address, send request immediately
          updateBeneficiariesAction({ benificiaryEntities: dataToSend, bancsPolicyNo })
        }
      }
    })
  }

  initForm() {
    const { partyType } = this.state
    const isPartyTypeOrg = partyType === POLICY_PARTY_TYPE_BUSINESS
    const { actions, isManualResidential, dataForInput, fields, lifeAssured } = this.props
    const { formInit } = actions
    const schema = this.schemaWithAuthorableFields({ isManualResidential, isPartyTypeOrg }, fields)
    const data = dataForInput || newBeneficiary
    formInit(FORM_ID, schema, initBeneficiaryForm(data, partyType, lifeAssured))
  }

  render() {
    const {
      fields: { heading, ...fields },
      form,
      toAdd,
      isManualResidential,
      relationshipOptions,
    } = this.props

    const { partyType, disable } = this.state

    if (!form) return null

    const {
      title,
      firstName,
      lastName,
      dateOfBirth,
      beneficiaryRelationshipType,
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressPONo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      businessName,
    } = form.fields

    const {
      cpBeneficiaryFormResidentialAddressToggleToManualText,
      cpBeneficiaryFormResidentialAddressFieldLabel,
      cpBeneficiaryFormResidentialAddressFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressFieldError,
      cpBeneficiaryFormResidentialAddressStreetFieldLabel,
      cpBeneficiaryFormResidentialAddressStreetFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressHouseNoFieldLabel,
      cpBeneficiaryFormResidentialAddressHouseNoFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressPONoFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressLocalityFieldLabel,
      cpBeneficiaryFormResidentialAddressLocalityFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressStateFieldLabel,
      cpBeneficiaryFormResidentialAddressStateFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressCountryFieldLabel,
      cpBeneficiaryFormResidentialAddressCountryFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressPostCodeFieldLabel,
      cpBeneficiaryFormResidentialAddressPostCodeFieldPlaceholder,
      cpBeneficiaryFormResidentialAddressToggleToAutoText,
      cpBeneficiaryFormTitleLabel,
      cpBeneficiaryFormFirstNameLabel,
      cpBeneficiaryFormLastNameLabel,
      cpBeneficiaryFormDateOfBirthLabel,
      cpBeneficiaryFormRelationshipTypeLabel,
      cpBeneficiaryFormDateOfBirthPlaceholder,
      cpCustomerAddBeneficiary,
      cpCustomerEditBeneficiary,
    } = reduceAuthorableFields(fields)

    const isPartyTypeOrg = partyType === POLICY_PARTY_TYPE_BUSINESS
    const schema = this.schemaWithAuthorableFields({ isManualResidential, isPartyTypeOrg }, fields)

    return (
      <Fragment>
        {toAdd && (
          <SelectComponent
            label="Type"
            value={partyType}
            name="partyType"
            id="partyType"
            data-locator="partyType"
            changeHandler={this.handlePartyTypeChange}
            options={BENEFICIARY_PARTY_TYPE}
          />
        )}

        <Form id={FORM_ID} aria-labelledby="application-overview" data-locator="beneficary-form">
          {toAdd && partyType === POLICY_PARTY_TYPE_INDIVIDUAL && (
            <Fragment>
              <SelectComponent
                label={cpBeneficiaryFormTitleLabel}
                placeholder={cpBeneficiaryFormTitleLabel}
                value={title.value}
                name="title"
                id="title"
                data-locator="title"
                changeHandler={this.handleChange}
                options={schema.title.options}
                error={title.error.error}
                caption={title.error.error && title.error.errorMsg}
              />
              <NameContainer>
                <NameInput
                  htmlFor="firstName"
                  name="firstName"
                  data-locator="first-name"
                  label={cpBeneficiaryFormFirstNameLabel}
                  placeholder={cpBeneficiaryFormFirstNameLabel}
                  value={firstName.value}
                  changeHandler={this.handleChange}
                  type="text"
                  error={firstName.error.error}
                  caption={firstName.error.error && firstName.error.errorMsg}
                />
                <NameInput
                  htmlFor="lastName"
                  name="lastName"
                  data-locator="last-name"
                  label={cpBeneficiaryFormLastNameLabel}
                  placeholder={cpBeneficiaryFormLastNameLabel}
                  value={lastName.value}
                  changeHandler={this.handleChange}
                  type="text"
                  error={lastName.error.error}
                  caption={lastName.error.error && lastName.error.errorMsg}
                />
              </NameContainer>
              <NameContainer>
                <SelectComponent
                  label={cpBeneficiaryFormRelationshipTypeLabel}
                  placeholder={cpBeneficiaryFormRelationshipTypeLabel}
                  value={beneficiaryRelationshipType.value}
                  name="beneficiaryRelationshipType"
                  id="beneficiaryRelationshipType"
                  data-locator="beneficiary-relationship-type"
                  changeHandler={this.handleChange}
                  options={relationshipOptions}
                  error={beneficiaryRelationshipType.error.error}
                  caption={
                    beneficiaryRelationshipType.error.error &&
                    beneficiaryRelationshipType.error.errorMsg
                  }
                />
                <NameInput
                  htmlFor="dateOfBirth"
                  name="dateOfBirth"
                  data-locator="date-of-birth"
                  label={cpBeneficiaryFormDateOfBirthLabel}
                  changeHandler={this.handleChange}
                  placeholder={cpBeneficiaryFormDateOfBirthPlaceholder}
                  value={dateOfBirth.value}
                  options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                  error={dateOfBirth.error.error}
                  caption={dateOfBirth.error.error && dateOfBirth.error.errorMsg}
                />
              </NameContainer>
            </Fragment>
          )}
          {toAdd && partyType === POLICY_PARTY_TYPE_BUSINESS && (
            <Fragment>
              <NameInput
                htmlFor="businessName"
                name="businessName"
                data-locator="first-name"
                label="Name"
                value={businessName.value}
                changeHandler={this.handleChange}
                disabled
                noBorder
                transparent
              />
              <NameInput
                htmlFor="beneficiaryRelationshipType"
                name="beneficiaryRelationshipType"
                data-locator="beneficiary-relationship-type"
                label={cpBeneficiaryFormRelationshipTypeLabel}
                value={this.getLabelFromValue(
                  relationshipOptions,
                  beneficiaryRelationshipType.value
                )}
                changeHandler={this.handleChange}
                disabled
                noBorder
                transparent
              />
            </Fragment>
          )}
          <FullWidthAddressLookup
            toggleHandler={this.addressToggleHandler}
            name="beneficiaryFormResidentialAddress"
            auto={{
              label: cpBeneficiaryFormResidentialAddressFieldLabel,
              placeholder: cpBeneficiaryFormResidentialAddressFieldPlaceholder,
              toggleLabel: cpBeneficiaryFormResidentialAddressToggleToManualText,
              value: residentialAddress.value,
              selectChangeHandler: this.addressChange,
              addressName: 'residentialAddress',
              addressError: residentialAddress.error.error,
              caption: residentialAddress.error.error && residentialAddress.error.errorMsg,
              addressErrorMessage:
                residentialAddress.error.error && cpBeneficiaryFormResidentialAddressFieldError,
            }}
            manual={{
              streetLabel: cpBeneficiaryFormResidentialAddressStreetFieldLabel,
              streetPlaceholder: cpBeneficiaryFormResidentialAddressStreetFieldPlaceholder,
              streetValue: residentialAddressStreet.value,
              streetRequiredErrorMessage: residentialAddressStreet.error.errorMsg,
              streetError: residentialAddressStreet.error.error,
              caption:
                residentialAddressStreet.error.error && residentialAddressStreet.error.errorMsg,
              streetName: 'residentialAddressStreet',
              houseNoError: residentialAddressHouseNo.error.error,
              houseNoRequiredErrorMessage: residentialAddressHouseNo.error.errorMsg,
              houseNoLabel: cpBeneficiaryFormResidentialAddressHouseNoFieldLabel,
              houseNoPlaceholder: cpBeneficiaryFormResidentialAddressHouseNoFieldPlaceholder,
              houseNoValue: residentialAddressHouseNo.value,
              houseNoName: 'residentialAddressHouseNo',
              postOfficeNoPlaceholder: cpBeneficiaryFormResidentialAddressPONoFieldPlaceholder,
              postOfficeNoValue: residentialAddressPONo.value,
              postOfficeNoError: residentialAddressPONo.error.error,
              postOfficeNoRequiredErrorMessage: get(
                residentialAddressPONo,
                'error.errorMsg.value',
                ''
              ),
              postOfficeNoName: 'residentialAddressPONo',
              localityLabel: cpBeneficiaryFormResidentialAddressLocalityFieldLabel,
              localityPlaceholder: cpBeneficiaryFormResidentialAddressLocalityFieldPlaceholder,
              localityLabelValue: residentialAddressLocality.value,
              localityError: residentialAddressLocality.error.error,
              localityRequiredErrorMessage: residentialAddressLocality.error.errorMsg,
              localityName: 'residentialAddressLocality',
              stateLabel: cpBeneficiaryFormResidentialAddressStateFieldLabel,
              statePlaceholder: cpBeneficiaryFormResidentialAddressStateFieldPlaceholder,
              stateOptions: schema.residentialAddressState.options,
              stateValue: residentialAddressState.value,
              stateError: residentialAddressState.error.error,
              stateRequiredErrorMessage: residentialAddressState.error.errorMsg,
              stateName: 'residentialAddressState',
              countryLabel: cpBeneficiaryFormResidentialAddressCountryFieldLabel,
              countryPlaceholder: cpBeneficiaryFormResidentialAddressCountryFieldPlaceholder,
              countryValue: residentialAddressCountry.value,
              countryError: residentialAddressCountry.error.error,
              countryRequiredErrorMessage: residentialAddressCountry.error.errorMsg,
              countryName: 'residentialAddressCountry',
              postCodeLabel: cpBeneficiaryFormResidentialAddressPostCodeFieldLabel,
              postCodePlaceholder: cpBeneficiaryFormResidentialAddressPostCodeFieldPlaceholder,
              postCodeValue: residentialAddressPostCode.value,
              postCodeError: residentialAddressPostCode.error.error,
              postCodeRequiredErrorMessage: residentialAddressPostCode.error.errorMsg,
              postCodeName: 'residentialAddressPostCode',
              manualToggleLabel: cpBeneficiaryFormResidentialAddressToggleToAutoText,
              inputEntryHandler: this.handleChange,
            }}
          />
          <Button type="secondary" onClick={this.onSubmit} disabled={disable}>
            {toAdd ? cpCustomerAddBeneficiary : cpCustomerEditBeneficiary}
          </Button>
        </Form>
      </Fragment>
    )
  }
}

export const BeneficiaryFormContainer = props => {
  const otherProps = {
    relationshipOptions: useSelector(getCustomerBeneficiaryOptions),
    isManualResidential: useSelector(isManualResidentialSelector),
    form: useSelector(state => state.forms[FORM_ID]),
    showForm: useSelector(state => state.customerBeneficiary.showBeneficiaryForm),
    dataForInput: useSelector(state => state.customerBeneficiary.currentDataForInputForBeneficiary),
    beneficiaries: useSelector(state => state.customerBeneficiary.currentBeneficiariesForInput),
    toAdd: useSelector(state => state.customerBeneficiary.isBeneficiaryFormAdd),
    bancsPolicyNo: useSelector(state => state.customerBeneficiary.currentBancsPolicyNoForBenForm),
    lifeAssured: useSelector(state => state.customerBeneficiary.lifeAssuredDetailsForFormEdit),
  }
  const { fields, actions } = props

  const {
    cpEditNonBindingBeneficairyModalTitle,
    cpAddNonBindingBeneficairyModalTitle,
    cpClientPolicyDetailsBeneficiaryDelayMessage,
  } = reduceAuthorableFields(fields)

  const onCloseModal = () => {
    const { showBeneficiaryForm, deleteForms } = actions
    showBeneficiaryForm(false)
    deleteForms([FORM_ID])
  }
  const modalTitle = otherProps.toAdd
    ? cpAddNonBindingBeneficairyModalTitle
    : cpEditNonBindingBeneficairyModalTitle
  return (
    <Modal isOpen={otherProps.showForm} title={modalTitle} onClose={onCloseModal}>
      <BeneficiaryForm {...props} {...otherProps} />
      {otherProps.toAdd && (
        <MessageWrapper>{cpClientPolicyDetailsBeneficiaryDelayMessage}</MessageWrapper>
      )}
    </Modal>
  )
}
