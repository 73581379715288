import { space, colours, fontWeights } from '../../../../styles'

const styles = {
  select: {
    maxWidth: space(50),
    marginTop: space(4),
  },
  individualDayWrapper: {
    maxWidth: space(70),
    marginTop: space(4),
  },
  mainWrapper: {
    marginTop: space(-5),
  },
  contactWrapper: {
    marginTop: space(5),
  },

  contactRadioGroup: {
    margin: 0,
    maxWidth: space(96),
  },
  errorMessage: {
    color: colours.red,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: space(1) / 4,
      fontWeight: fontWeights.bold,
    },
  },
  wrapper: {
    width: '90%',
    backgroundColor: colours.white,
    padding: `${space(8, true)} ${space(5, true)} ${space(5, true)}`,
  },

  intitalContactHeading: {
    marginBottom: space(5),
  },

  intialContatcTimeWrap: {
    marginTop: space(6),
  },

  methodCompletionSubHeading: {
    marginBottom: space(4),
  },

  initialContactDaysWrap: {
    marginTop: space(5),
    maxWidth: space(100),
  },
  policy: {
    marginTop: `${space(3, true)}`,
  },
}

export default styles
