// @flow
import React, { useEffect, Fragment } from 'react'
import { string, object, arrayOf, oneOfType, objectOf, func } from 'prop-types'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components
import YourAdviserDetails from './YourAdviserDetails'
import Header from '../../molecules/PageHeader'
import { CustomerPortalLifeCycleComponent } from '../../molecules/CustomerPortalLifeCycle'
import {
  checkIfAllPoliciesLoaded,
  hasCustomerAdviserDetailsFinishedLoading,
  getCustomerAdvisorList,
  hasCustomerAdviserDetailsFailed,
} from '../../../selectors/common'
import { checkIfEveryPolicyFailed } from '../../../selectors/customerPolicies'
// style
import styles from './youradviser.styles'

// constants
import { THEME_LIGHT } from '../../../constants/themes'
import { RENDER_REMOVE_ADVISOR_BUTTON } from '../../../constants/featureControlSwitch'

// utils.
import { getDummyIDsFromMaster } from '../../../utils/adviserUtils'
import { isFeatureEnabledForCP } from '../../../utils/commonUtils'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'

const Wrap = styled(PageWrap)(styles.wrap, styles.offset, styles.tableRow)

export const CustomerYourAdviser = props => {
  const {
    fields,
    customerRelationships,
    dummyIds,
    featureControlSwitch,
    actions: { cpRemoveAdvisor, cpGetAdviserDetails, fetchRelatedParty },
    fields: { heading, content },
  } = props
  const actions = { cpRemoveAdvisor, cpGetAdviserDetails }

  const isRemoveAdviserButtonRendered = isFeatureEnabledForCP(
    featureControlSwitch,
    RENDER_REMOVE_ADVISOR_BUTTON
  )

  const { adviserBannerImageUrl, adviserBannerImageAlt } = reduceAuthorableFields(fields)
  const adviserArray = useSelector(getCustomerAdvisorList)

  const filteredadviserArray = adviserArray.filter(
    adviser => adviser && dummyIds.indexOf(adviser) === -1
  )

  const readyToFetchRelatedParty = useSelector(checkIfAllPoliciesLoaded)

  const hasAdvisorLoader = useSelector(hasCustomerAdviserDetailsFinishedLoading)
  const hasAdvisorFailed = useSelector(hasCustomerAdviserDetailsFailed)
  const haveAllPoliciesFailed = useSelector(checkIfEveryPolicyFailed)

  useEffect(() => {
    if (readyToFetchRelatedParty) {
      filteredadviserArray.forEach(adviser => {
        if (!customerRelationships[adviser].hasRelatedParty) {
          fetchRelatedParty(adviser)
        }
      })
    }
  }, [readyToFetchRelatedParty])
  return (
    <Fragment>
      <Header
        theme={THEME_LIGHT}
        heading={{ value: heading.value }}
        subHeading={{ value: content.value }}
        imageSrc={adviserBannerImageUrl}
        imageAlt={adviserBannerImageAlt}
      />
      <Wrap>
        <CustomerPortalLifeCycleComponent
          loadingConstraints={[!readyToFetchRelatedParty, !hasAdvisorLoader]}
          failureConstraints={[hasAdvisorFailed, haveAllPoliciesFailed]}
        >
          <YourAdviserDetails
            fields={fields}
            customerRelationships={customerRelationships}
            adviserList={filteredadviserArray}
            actions={actions}
            isRemoveAdviserButtonRendered={isRemoveAdviserButtonRendered}
          />
        </CustomerPortalLifeCycleComponent>
      </Wrap>
    </Fragment>
  )
}

CustomerYourAdviser.propTypes = {
  fields: objectOf(oneOfType([object, string])).isRequired,
  actions: objectOf(func).isRequired,
  customerRelationships: oneOfType([object]).isRequired,
  dummyIds: arrayOf(string).isRequired,
  featureControlSwitch: arrayOf(object).isRequired,
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ masterList, customerRelationships }) => ({
  customerRelationships,
  dummyIds: getDummyIDsFromMaster(masterList),
  featureControlSwitch: get(masterList, 'data.featureControlSwitch', []),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerYourAdviser))
