// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, colours } = Variables

const styles = {
  documentsSectionWrap: {
    margin: `${space(5, true)} 0`,
    [mediaQueries.md]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${space(2, true)} 0`,
    },
    '& >p': {
      marginBottom: space(5),
      [mediaQueries.md]: {
        marginBottom: 0,
        color: colours.tertiaryOne,
        flexBasis: '60%',
      },
    },
    '& >a': {
      width: '100%',
      [mediaQueries.md]: {
        width: 'auto',
      },
    },
  },
}

export default styles
