import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

// components
import { Heading, PageWrap, Modal } from '@mlcl-digital/mlcl-design'
import ActionLink from '@mlcl-digital/mlcl-design/lib/base/ActionLink'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
// @ts-expect-error file not in typescript
import Header from '../../../molecules/PageHeader'
// @ts-expect-error file not in typescript
import ScrollToTop from '../../../atoms/ScrollToTop'
import SubmitMaxLimitModal from '../Modals/SubmitMaxLimitModal'
import DownloadMaxLimitModal from '../Modals/DownloadMaxLimitModal'
// @ts-expect-error file not in typescript
import { CalculateQuoteErrorModal } from '../../../molecules/CalculateQuoteErrorModal'

// constants
import { THEME_LIGHT } from '../../../../constants/themes'
import { DOC_TYPE_ALTERATION_PC } from '../../../../constants/documentTypes'
import { NAV_LOGOUT_ITEM_ID, NAV_CHANGE_PASSWORD_ITEM_ID } from '../../../../constants/sitecore'
import { OKTA_CHANGE_PASSWORD_MODAL } from '../../../../actions/types/okta'

// utils
// @ts-expect-error file not in typescript
import history from '../../../../utils/browserHistory'
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { downloadDocument } from '../../../../utils/downloadDocumentUtils'
// @ts-expect-error file not in typescript
import { generateCorrelationID, shouldRenderNativeLink } from '../../../../utils/commonUtils'
// @ts-expect-error file not in typescript
import { logout } from '../../../../utils/logoutUtils'
// @ts-expect-error file not in typescript
import { isBrowser } from '../../../../utils/browserUtils'
import { createEvent } from '../../../../utils/telemetry'

// selectors
import {
  getTimeline,
  getAlterations,
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
  getConfig,
} from '../../../../selectors/common.selectors'
// @ts-expect-error file not in typescript
import { getIsAnyPolicyAltered } from '../../../../selectors/createQuote'
import {
  getAltsCalculateErrorModalData,
  // @ts-expect-error file not in typescript
} from '../../../../selectors/alterations'

// types
import { AltsPremiumCalculatorProps } from '../../../../types/components/PremiumCalculator'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// styles
import styles from './altsPremiumCalculator.styles'
import {
  PREMIUM_CALCULATOR_QUOTE,
  PREMIUM_CALCULATOR_QUOTE_PREVIEW,
  PREMIUM_CALCULATOR_SUBMIT,
  MAX_LIMIT_ERROR_CODE,
} from '../../../../constants/premiumCalculator'
import { isErrorOnAlteredQuote } from '../../../../selectors/premiumCalculatorQuote'

const ComponentWrapper = styled('div')(styles.componentWrapper)
const BackToHomeCTA = styled(ActionLink)(styles.backToHomeCTA)
const StyledHeading = styled(Heading)(styles.heading)
const ConfirmLeaveModalBtnContainer = styled('div')(styles.confirmLeaveModalBtnContainer)
const DownloadIcon = styled(Icon)(styles.downloadIcon)

const AltsPremiumCalculator = ({ fields, rendering }: AltsPremiumCalculatorProps) => {
  const dispatch = useDispatch()
  const timelineWithComponentsState = useSelector(getTimeline)
  const alterationsState = useSelector(getAlterations)
  const isAnyPolicyAltered = useSelector(getIsAnyPolicyAltered)
  const { activeIndex, quotes, isDownloadQuoteInProgress, quoteCustomerNo } =
    useSelector(getCreateQuote)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const isErrorOnQuote = useSelector(isErrorOnAlteredQuote)
  const config = useSelector(getConfig)
  const formattedError = useSelector(getAltsCalculateErrorModalData)
  const handleBackToHome = useCallback(() => {
    const componentMapping: Record<string, string> = {
      [PREMIUM_CALCULATOR_QUOTE]: 'PC-Backtohome',
      [PREMIUM_CALCULATOR_QUOTE_PREVIEW]: 'PC-PQBacktohome',
      [PREMIUM_CALCULATOR_SUBMIT]: 'PC-ROBacktohome',
    }
    const event = createEvent({
      GA: {
        category: componentMapping[timelineWithComponentsState.activeComponent],
        action: 'Back to home',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator back to home',
        },
      },
    })
    event.end()
    if (isAnyPolicyAltered && !alterationsState.isAltsQuoteDownloaded) {
      dispatch(actionCreators.setIsConfirmLeaveModal(true, fields.BackToHomeLink?.value?.href))
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      history.push(fields.BackToHomeLink?.value?.href)
    }
  }, [isAnyPolicyAltered, alterationsState.isAltsQuoteDownloaded, timelineWithComponentsState])

  const confirmLeaveModalPreviousAction = useCallback(() => {
    const { confirmLeaveModalRedirectURL, confirmLeaveModalItemID } = alterationsState
    if (confirmLeaveModalRedirectURL) {
      shouldRenderNativeLink(confirmLeaveModalRedirectURL)
        ? isBrowser() && window.open(confirmLeaveModalRedirectURL, '_blank')
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          history.push(confirmLeaveModalRedirectURL)
    } else if (confirmLeaveModalItemID === NAV_CHANGE_PASSWORD_ITEM_ID) {
      dispatch(actionCreators.showModal(OKTA_CHANGE_PASSWORD_MODAL))
    } else if (confirmLeaveModalItemID === NAV_LOGOUT_ITEM_ID) {
      logout({
        oktaSignOutAction: () => {
          dispatch(actionCreators.signOut())
        },
        currentRoute: history?.location?.pathname ?? '/',
      })
    }
  }, [alterationsState, history])
  // modal close(X) button
  const handleConfirmLeaveModalClose = () => {
    dispatch(actionCreators.setIsConfirmLeaveModal(false))
  }
  // leave without downloading quote
  const handleConfirmLeaveModalCancel = () => {
    handleConfirmLeaveModalClose()
    confirmLeaveModalPreviousAction()
  }
  // download quote and leave
  const handleConfirmLeaveModalAccept = useCallback(() => {
    const componentMapping: Record<string, string> = {
      [PREMIUM_CALCULATOR_QUOTE]: 'PC-DownloadQuotePopup',
      [PREMIUM_CALCULATOR_QUOTE_PREVIEW]: 'PC-PQReminderpopupDownloadQuote',
      [PREMIUM_CALCULATOR_SUBMIT]: 'PC-ROReminderpopup',
    }
    const event = createEvent({
      GA: {
        category: componentMapping[timelineWithComponentsState.activeComponent],
        action: 'Reminder popup window download quote',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator reminder popup window download quote clicked',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.updateDownloadQuoteProgress(true))
    const correlationID = generateCorrelationID()
    if (!alterationsState.quoteURN) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.setAltsQuoteURN(correlationID))
    }
    downloadDocument(
      {
        docType: DOC_TYPE_ALTERATION_PC,
        quote: quotes[activeIndex],
        correlationID,
        lifeInsuredFirstName: firstName,
        lifeInsuredLastName: lastName,
        isUploadToRecm: !alterationsState.isAltsQuoteDownloaded,
        returnData: true,
        quoteCustomerNo,
        quoteURN: alterationsState.quoteURN || correlationID,
      },
      null,
      null,
      config,
      (err: { status: { code: string } }) => {
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator reminder popup downloading quote',
              error: !!err,
            },
          },
        })
        apiEvent.end()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(actionCreators.updateDownloadQuoteProgress(false))
        if (!err) {
          handleConfirmLeaveModalClose()
          confirmLeaveModalPreviousAction()
        } else if (err.status?.code === MAX_LIMIT_ERROR_CODE) {
          handleConfirmLeaveModalClose()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          dispatch(actionCreators.setIsMaxDownloadLimitModal(true))
        }
      }
    )
  }, [quotes, alterationsState])

  const handleClose = useCallback(() => {
    dispatch(actionCreators.setIsAltsCalculateErrorModal(false))
  }, [])

  return (
    <ScrollToTop>
      <Header
        theme={THEME_LIGHT}
        heading={fields.HeaderHeading}
        subComponent={renderTextField(fields.HeaderDescription, true)}
        bigImage
        imageSrc={fields.HeaderImageUrl?.value?.src}
        imageAlt={fields.HeaderImageUrl?.value?.alt}
      />
      <ComponentWrapper>
        <PageWrap>
          <BackToHomeCTA
            onClick={handleBackToHome}
            label={fields.BackToHomeLink?.value?.text}
            iconName={['far', 'chevron-left']}
          />
          <StyledHeading variant="h2" size="large">
            {renderTextField(fields.Heading)}
          </StyledHeading>
        </PageWrap>
        <Placeholder name="header-bar" rendering={rendering} variant="horizontal" />
        <PageWrap>
          <Placeholder name={timelineWithComponentsState.activeComponent} rendering={rendering} />
        </PageWrap>
        <Modal
          isOpen={alterationsState.isConfirmLeaveModal}
          onClose={handleConfirmLeaveModalClose}
          title={fields.ConfirmLeaveModalHeading}
          footer={
            <ConfirmLeaveModalBtnContainer>
              <Button
                disabled={isErrorOnQuote}
                onClick={handleConfirmLeaveModalAccept}
                isLoading={isDownloadQuoteInProgress}
              >
                <DownloadIcon iconName={['far', 'download']} />
                {renderTextField(fields.ConfirmLeaveModalAcceptBtn)}
              </Button>
              <Button variant="link" onClick={handleConfirmLeaveModalCancel}>
                {renderTextField(fields.ConfirmLeaveModalCancelBtn)}
              </Button>
            </ConfirmLeaveModalBtnContainer>
          }
        >
          {renderTextField(fields.ConfirmLeaveModalDescription)}
        </Modal>
        <SubmitMaxLimitModal fields={fields} />
        <DownloadMaxLimitModal fields={fields} />
        <CalculateQuoteErrorModal
          formattedError={formattedError}
          fields={fields}
          isOpen={alterationsState.isAltsCalculateErrorModal}
          handleModalClose={handleClose}
          modalCTA={fields.ErrorCTA}
        />
      </ComponentWrapper>
    </ScrollToTop>
  )
}

export default AltsPremiumCalculator
