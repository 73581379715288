// @flow
import { colours, space } from '../../../../../styles'

const styles = {
  wrapper: {
    marginBottom: space(17, true),
  },
  headerWrapper: {
    marginBottom: space(5, true),
  },
  card: {
    background: colours.white,
    padding: `${space(3, true)} ${space(4, true)} ${space(3, true)} ${space(5, true)}`,
    display: 'flex',
    borderBottom: `1px solid ${colours.lightGrey}`,
    flexDirection: 'row',
  },
  label: {
    marginBottom: space(5, true),
  },
  sectionHeader: ({ SectionMarginBottom = true }) => ({
    marginBottom: space(SectionMarginBottom ? 5 : 1),
  }),
}

export default styles
