// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../../../actions'

// atoms.
import Input from '../../../../../../../atoms/Input'

// styles.
import styles from './masterKey.styles'

// schema.
import custMasterKeyPaymentSchema, { FORM_ID } from './masterKey.schema'

// helpers.
import { errorCheck } from '../../../../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../../../../utils/sitecoreUtils'

type MasterKeyProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  // Sitecore authorable fields
  fields: Object,
  // hanlde next button
  nextButton: Object,
}

const AccountNumber = styled(Input)(styles.halfWidth)

const Form = styled('form')(styles.base)

export class MasterKeyPayment extends Component<MasterKeyProps> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit },
      fields,
      form,
    } = props
    const SCHEMA = custMasterKeyPaymentSchema({ fields })
    this.state = {
      SCHEMA,
    }
    if (!form) {
      formInit(FORM_ID, SCHEMA)
    }
  }

  handleChange = ({ name, value }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state

    const data = {
      value,
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  render() {
    const { fields, form, nextButton } = this.props
    if (!form) return null
    const { accountNumber } = form.fields
    const { SCHEMA } = this.state
    const {
      cpEditPaymentMasterkeyAccountNumberLabel,
      cpEditPaymentMasterkeyAccountNumberPlaceholder,
    } = reduceAuthorableFields(fields)
    return (
      <Fragment>
        <Form id={FORM_ID} aria-labelledby="MasterKeyMethod">
          <AccountNumber
            htmlFor="masterkeyAccount"
            name={SCHEMA.accountNumber.name}
            label={cpEditPaymentMasterkeyAccountNumberLabel}
            placeholder={cpEditPaymentMasterkeyAccountNumberPlaceholder}
            changeHandler={this.handleChange}
            error={accountNumber.error.error}
            caption={
              accountNumber.error.error
                ? accountNumber.error.errorMsg
                : SCHEMA.accountNumber.tooltip
            }
            value={accountNumber.value}
            captionWithIcon
          />
        </Form>
        {nextButton}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms }) => ({
  form: forms[FORM_ID],
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterKeyPayment)
