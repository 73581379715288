// @flow
import React from 'react'
import styled from '@emotion/styled'
import Proptypes from 'prop-types'
import { Card } from '../CardChildren'

// styles.
import styles from './articleCard.styles'

// utils
import history from '../../../utils/browserHistory'

const CardWrapper = styled('div')(styles.articleCardWrapper)
const Footer = styled('div')(styles.articleFooter)
const FooterText = styled('div')(styles.articleFooterText)
const ArticleImage = styled('img')(styles.articleImage)

export const ArticleCard = props => {
  const { title, articleId, handleSetActiveArticle, imageSource, link } = props

  return (
    <Card
      onClick={() => {
        history.push(link)
        handleSetActiveArticle(articleId)
      }}
      data-testid={`article-card-${articleId}`}
    >
      <CardWrapper style={{ padding: 0 }}>
        <ArticleImage src={imageSource} alt="image" />
      </CardWrapper>
      <Footer>
        <FooterText>{title}</FooterText>
      </Footer>
    </Card>
  )
}

const { string, func } = Proptypes
ArticleCard.propTypes = {
  // image URL for the article
  imageSource: string.isRequired,
  // the title of the article is displayed in the footer
  title: string.isRequired,
  // save article id to redux store
  handleSetActiveArticle: func,
  // artilce id
  articleId: string.isRequired,
  // Link to knowledgebase article
  link: string.isRequired,
}

ArticleCard.defaultProps = {
  handleSetActiveArticle: () => {},
}

export default ArticleCard
