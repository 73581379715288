// @flow
/* eslint-disable max-len */
import React, { PureComponent, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../actions'

// component.
import Layout from '../../../molecules/PersonalStatementLayout'
import PSChangeModalComponent from '../../../organisms/CompletionMethod/components/PSChangeModal'
import HeadingComponent from '../../../atoms/Heading'
import Button from '../../../atoms/Button'
import ThumbsUpIcon from '../../../atoms/Icons/IconThumbsUp32'
import LockIcon from '../../../atoms/Icons/IconLock32'

// styles.
import styles from './personalStatementStatus.styles'

import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import {
  NAVIGATION_CUSTOMER_PERSONAL_STATEMENT,
  NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION,
  NAVIGATION_ONLY_LOGO,
} from '../../../../constants/navigation'
import { TELE, URE_BY_ADVISER, URE_BY_CUSTOMER } from '../../../../constants/application'
import history from '../../../../utils/browserHistory'
import { CUSTOMER_PERSONAL_STATEMENT_UNDERWRITING_PAGE } from '../../../../constants/personalStatement'
import { BIG_T_BOOK } from '../../../../constants/workItems'
import { SCOPE_TYPE_TELE } from '../../../../constants/okta'
import { getScope } from '../../../../utils/cookieUtils'
import { getUREAssignedParty } from '../../../../utils/quoteUtils'

const Wrapper = styled('div')(({ centerAlignedItems }) => styles.main(centerAlignedItems))
const Container = styled('div')(({ borderBottom }) => styles.progress(borderBottom))
const Bar = styled('div')(({ active, percentage, type }) => styles.bar(active, percentage, type))
const Method = styled('div')(styles.method)
const ChangeMethod = styled(Button)(styles.changeMethod)
const ContinueButton = styled(Button)(styles.continueButton)
const Caption = styled(HeadingComponent)(styles.caption)
const Message = styled('p')(styles.message)
const CompletedIcon = styled('div')(styles.completedIcon)
const ExploreLink = styled('div')(styles.exploreButton)

type PSStatusPropTypes = {
  fields: Object,
  actions: object,
  createQuote: object,
  isOpenUWChangeModal: boolean,
  hasPersonalStatementCompleted: boolean,
}

type ProgressBarTypes = {
  displayText: string,
  active: boolean,
  percentage: string,
  type: string,
}

export const PrgressBar = ({ displayText, ...otherProps }: ProgressBarTypes) => (
  <Bar {...otherProps}>{displayText}</Bar>
)

export class PSStatus extends PureComponent<PSStatusPropTypes> {
  constructor(props) {
    super(props)
    const {
      fields: { psWelecomeTextPrefix },
      createQuote,
    } = this.props
    const lifeInsuredDetail = get(
      createQuote,
      `quotes[${createQuote.activeIndex}].policyStructure[0].relationships`,
      [{ role: [] }]
    ).find(relation => relation.role.includes('LA'))
    let userName = ''
    if (lifeInsuredDetail) {
      userName = get(lifeInsuredDetail, 'relatedParty.firstName', '')
    }
    this.pageTitle = userName && {
      value: `${get(psWelecomeTextPrefix, 'value', '')} ${userName}`,
    }
  }

  componentDidMount() {
    const {
      actions: { changeNavigationType, checkQuoteActionStatus },
      hasPersonalStatementCompleted,
      createQuote,
      createQuote: { quoteCollectionId },
    } = this.props
    if (quoteCollectionId) {
      checkQuoteActionStatus(quoteCollectionId)
    }
    if (hasPersonalStatementCompleted || getScope() === SCOPE_TYPE_TELE) {
      changeNavigationType(NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION)
    } else if (getUREAssignedParty(createQuote) === URE_BY_ADVISER) {
      changeNavigationType(NAVIGATION_ONLY_LOGO)
    } else {
      changeNavigationType(NAVIGATION_CUSTOMER_PERSONAL_STATEMENT)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      createQuote,
      actions: { changeNavigationType },
    } = nextProps
    const { createQuote: oldCreateQuote } = this.props
    if (
      !(getUREAssignedParty(oldCreateQuote) === URE_BY_ADVISER) &&
      getUREAssignedParty(createQuote) === URE_BY_ADVISER
    ) {
      changeNavigationType(NAVIGATION_ONLY_LOGO)
    }
  }

  getAppointment() {
    const {
      fields: {
        statusDaysDelimiter,
        statusLastDayDelimiter,
        statusLastDayPreposition,
        statusTimeSeparator,
      },
      createQuote: { workTypeHistory },
    } = this.props
    let appointmentDeatils = ''

    if (workTypeHistory.length) {
      const { preferredDays, preferredCallAfterTime, preferredCallBeforeTime } =
        workTypeHistory.find(({ type }) => type === BIG_T_BOOK)

      const daysLastIndex = preferredDays.length - 1
      let daysDetail = ''
      if (preferredDays.length > 1) {
        daysDetail = `${[...preferredDays]
          .splice(0, daysLastIndex)
          .join(`${statusDaysDelimiter.value} `)} ${statusLastDayDelimiter.value} ${
          preferredDays[daysLastIndex]
        }`
      } else {
        daysDetail = preferredDays.join()
      }

      appointmentDeatils = `${daysDetail} ${statusLastDayPreposition.value} ${moment(
        preferredCallAfterTime,
        'hh'
      ).format('LT')} ${statusTimeSeparator.value} ${moment(preferredCallBeforeTime, 'hh').format(
        'LT'
      )}`
    }

    return appointmentDeatils
  }

  // eslint-disable-next-line react/sort-comp
  renderPersonalStatementInProgressView() {
    const { fields, createQuote } = this.props
    const {
      psMethodText,
      personalStatementMethodOnlineDisplayText,
      telMethodDisplayText,
      psContinueChangeMethodButton,
    } = fields

    const { activeIndex, quotes, isFetchingData } = createQuote
    const method = get(quotes, `[${activeIndex}].underwritingDetails.underwritingMethod`, '')
    let methodDisplayLabel = personalStatementMethodOnlineDisplayText

    if (method === TELE) {
      methodDisplayLabel = telMethodDisplayText
    }
    return (
      <Fragment>
        <Container borderBottom>
          <Method>
            <p>{renderTextField(psMethodText)}</p>
            <Caption size={3}>{renderTextField(methodDisplayLabel)}</Caption>
          </Method>
          {!isFetchingData && (
            <ChangeMethod type="secondary" onClick={this.changeMethodHandler}>
              {renderTextField(psContinueChangeMethodButton)}
            </ChangeMethod>
          )}
        </Container>
        {isFetchingData ? <Loader /> : this.renderContinueButton()}
      </Fragment>
    )
  }

  renderContinueButton() {
    const { fields, createQuote } = this.props
    const { activeIndex, quotes } = createQuote
    const { psContinueButton, statusCallScheduledLabel } = fields
    const method = get(quotes, `[${activeIndex}].underwritingDetails.underwritingMethod`, '')
    return method === TELE ? (
      <Method>
        <p>{renderTextField(statusCallScheduledLabel)}</p>
        <Caption size={3}>{this.getAppointment()}</Caption>
      </Method>
    ) : (
      <ContinueButton onClick={this.initUnderWriting}>
        {renderTextField(psContinueButton)}
      </ContinueButton>
    )
  }

  renderPersonalStatementCompletedView() {
    const {
      fields: {
        statusFinishedCation,
        statusFinishedMessage,
        statusFinishedButtonText,
        statusAssignedToAdviserCaption,
        statusAssignedToAdviserMessage,
        statusAssignedToCustomerCaption,
        statusAssignedToCustomerMessage,
        statusExploreRedirectionURL,
      },
      createQuote,
    } = this.props

    const { activeIndex, quotes } = createQuote
    let caption = statusFinishedCation
    let icon = <ThumbsUpIcon />
    let message = statusFinishedMessage
    if (getUREAssignedParty(createQuote) === URE_BY_ADVISER) {
      icon = <LockIcon />
      caption = statusAssignedToAdviserCaption
      message = statusAssignedToAdviserMessage
    }
    if (getScope() === SCOPE_TYPE_TELE && getUREAssignedParty(createQuote) === URE_BY_CUSTOMER) {
      icon = <LockIcon />
      caption = statusAssignedToCustomerCaption
      message = statusAssignedToCustomerMessage
    }
    return (
      <Fragment>
        <CompletedIcon>{icon}</CompletedIcon>
        <Caption size={3}>{renderTextField(caption)}</Caption>
        <Message>
          {message
            ? `${message.value}
           ${get(quotes, `${activeIndex}.myLinkDetails.adviserTelephoneNumber`, '')}.`
            : null}
        </Message>
        <ExploreLink>
          <a href={statusExploreRedirectionURL.value.href}>
            {renderTextField(statusFinishedButtonText)}
          </a>
        </ExploreLink>
      </Fragment>
    )
  }

  renderBody() {
    const { hasPersonalStatementCompleted, createQuote } = this.props

    if (
      hasPersonalStatementCompleted ||
      getScope() === SCOPE_TYPE_TELE ||
      getUREAssignedParty(createQuote) === URE_BY_ADVISER
    ) {
      return this.renderPersonalStatementCompletedView()
    }
    // If customer assigns to teleunderwriter
    return this.renderPersonalStatementInProgressView()
  }

  renderProgressBar() {
    const {
      hasPersonalStatementCompleted,
      fields: { statusCallBookedInLabel, statusCallTakenLabel, psStartedText, psCompletedText },
      createQuote,
    } = this.props

    const underwritingMethod = get(
      createQuote,
      `quotes[${createQuote.activeIndex}].underwritingDetails.underwritingMethod`,
      ''
    )

    if (getScope() === SCOPE_TYPE_TELE || underwritingMethod === TELE) {
      return (
        <Fragment>
          <PrgressBar
            displayText={renderTextField(statusCallBookedInLabel)}
            active
            type="dark"
            percentage="35%"
          />
          <PrgressBar displayText={renderTextField(statusCallTakenLabel)} percentage="35%" />
          <PrgressBar
            displayText={renderTextField(psCompletedText)}
            type="lightest"
            percentage="30%"
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        <PrgressBar
          displayText={renderTextField(psStartedText)}
          active
          type="dark"
          percentage="40%"
        />
        <PrgressBar
          displayText={renderTextField(psCompletedText)}
          active={hasPersonalStatementCompleted}
          percentage="60%"
        />
      </Fragment>
    )
  }

  initUnderWriting = () => {
    const {
      actions: {
        changePersonalStatementCompletionStatus,
        changeNavigationType,
        checkQuoteActionStatus,
      },
      createQuote: { quoteCollectionId },
    } = this.props

    if (quoteCollectionId) {
      checkQuoteActionStatus(quoteCollectionId, (err, data) => {
        if (!(getUREAssignedParty(data.businessData) === URE_BY_ADVISER)) {
          if (getScope() === SCOPE_TYPE_TELE) {
            changePersonalStatementCompletionStatus(true)
            changeNavigationType(NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION)
          } else {
            history.push(CUSTOMER_PERSONAL_STATEMENT_UNDERWRITING_PAGE)
          }
        }
      })
    }
  }

  handleUWCloseModal = () => {
    const {
      actions: { closeUWMethodChangeModal },
    } = this.props
    closeUWMethodChangeModal()
  }

  changeMethodHandler = () => {
    const {
      actions: { openUWMethodChangeModal, checkQuoteActionStatus },
      createQuote: { quoteCollectionId },
    } = this.props
    checkQuoteActionStatus(quoteCollectionId, () => {
      openUWMethodChangeModal()
    })
  }

  render() {
    const { fields, isOpenUWChangeModal, hasPersonalStatementCompleted, createQuote } = this.props
    return (
      <Layout fields={fields} fillChildBackground={false} pageTitle={this.pageTitle}>
        {!(getUREAssignedParty(createQuote) === URE_BY_ADVISER) && (
          <Wrapper>
            <Container>{this.renderProgressBar()}</Container>
          </Wrapper>
        )}
        <Wrapper
          centerAlignedItems={
            hasPersonalStatementCompleted ||
            getScope() === SCOPE_TYPE_TELE ||
            getUREAssignedParty(createQuote) === URE_BY_ADVISER
          }
        >
          {this.renderBody()}
        </Wrapper>
        {isOpenUWChangeModal && (
          <PSChangeModalComponent
            fields={reduceAuthorableFields(fields)}
            handleUWCloseModal={this.handleUWCloseModal}
          />
        )}
      </Layout>
    )
  }
}

export const mapStateToProps = ({ createQuote, myLink: { showModal, hasCompleted, status } }) => ({
  createQuote,
  hasPersonalStatementCompleted: hasCompleted,
  isOpenUWChangeModal: showModal,
  status,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PSStatus))
