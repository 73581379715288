import { space, colours, fontSize, fontWeights, shadows, mediaQueries } from '../../../../styles'

const style = {
  policyContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: space(2, true),
    gridRowGap: space(2, true),

    [mediaQueries.md]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  policyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${colours.lightGrey}`,
    boxSizing: 'border-box',
    boxShadow: shadows.low,
    padding: `${space(5, true)} ${space(3, true)}`,
    cursor: 'pointer',
  },
  policy: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(1),
    display: 'flex',
    '& :first-child': {
      whiteSpace: 'nowrap',
      marginRight: space(0.5, true),
    },
  },
  lifeInsured: {
    fontSize: fontSize.xs,
    color: colours.mediumGrey,
    display: 'flex',
    '& :first-child': {
      whiteSpace: 'nowrap',
      marginRight: space(0.5, true),
    },
  },
  policyDetails: {
    display: 'inline-block',
  },
}

export default style
