// @flow
import React, { PureComponent, Fragment } from 'react'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FullUreEngineComponent from '../../../organisms/FullUreEngine'

// actions
import { actionCreators } from '../../../../actions'

import '@mlcl-digital/ure/css/style.css'
import { NAVIGATION_CUSTOMER_PERSONAL_STATEMENT } from '../../../../constants/navigation'

import PSChangeModalComponent from '../../../organisms/CompletionMethod/components/PSChangeModal'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { handleUREByOtherParty } from '../../../../utils/quoteUtils'

type EngineProps = {
  fields: Object<Object>,
  actions: Object<Object>,
  isOpenUWChangeModal: Boolean,
  createQuote: Object<Object>,
}

export class Engine extends PureComponent<EngineProps> {
  componentDidMount() {
    const {
      actions: { changeNavigationType },
      createQuote,
    } = this.props
    changeNavigationType(NAVIGATION_CUSTOMER_PERSONAL_STATEMENT)
    // show app has beeen assigned to other party
    handleUREByOtherParty(createQuote)
  }

  handleUWCloseModal = () => {
    const {
      actions: { closeUWMethodChangeModal },
    } = this.props
    closeUWMethodChangeModal()
  }

  render() {
    const { fields, isOpenUWChangeModal } = this.props
    return (
      <Fragment>
        <FullUreEngineComponent isUreFrmCP fields={fields} />
        {isOpenUWChangeModal && (
          <PSChangeModalComponent
            fields={reduceAuthorableFields(fields)}
            handleUWCloseModal={this.handleUWCloseModal}
          />
        )}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ myLink: { showModal }, createQuote }) => ({
  isOpenUWChangeModal: showModal,
  createQuote,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Engine)
