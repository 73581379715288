import { colours, space, fontSize, mediaQueries } from '../../../../../../../../styles'

const styles = {
  offset: {
    marginTop: -space(8),
    [mediaQueries.md]: {
      marginTop: -space(7),
    },
  },
  uploadFileWrapper: {
    width: '50%',
  },
  fullName: {
    width: '50%',
  },
  wrapper: {
    flex: '0 0 100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${colours.lightGrey}`,
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: space(2.5),
  },
  creditCardContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    [mediaQueries.md]: {
      width: '80%',
    },
  },
  creditCardWrap: {
    display: 'flex',
  },
  creditCardNumber: {
    flex: '0 0 60%',
    marginBottom: 0,
  },
  expiryDate: {
    flex: '0 0 20%',
    marginBottom: 0,
  },
  cvcNumber: {
    flex: '0 0 16%',
    marginBottom: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: space(25),
  },
  errorWrap: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    left: space(0),
    bottom: space(0),
  },
  error: {
    fontSize: fontSize.xxs,
    color: colours.red,
    marginBottom: 0,
  },
  icon: {
    color: colours.red,
  },
  creditCardIcon: {
    paddingLeft: space(1, true),
  },
  noteLink: {
    color: colours.mediumGreen,
    textDecoration: 'none',
    marginLeft: space(1),
  },
  drawDate: {
    width: '50%',
    marginLeft: space(3),
    input: {
      border: 0,
    },
  },
}

export default styles
