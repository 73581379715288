// @flow
import React, { Component } from 'react'
import { shape, func, bool, objectOf, object, number, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'
import { Button, PageWrap } from '@mlcl-digital/mlcl-design'
import Timeline from '@mlcl-digital/mlcl-design/lib/base/Timeline'
import { withRouter } from 'react-router-dom'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components.
import Link from '../../atoms/Link'
import Header from '../../molecules/PageHeader'
import PolicySelection from './components/PolicySelection'
import PaymentDetails from './components/PaymentDetails'
import PaymentReceipt from './components/PaymentReceipt'

// constants.
import { THEME_LIGHT } from '../../../constants/themes'
import {
  CONTINUE,
  PROCESS_PAYMENT,
  MAKE_ANOTHER_PAYMENT,
  BACK_TO_DASHBOARD,
} from '../../../constants/buttons'
import { CP_ONE_OFF_PAYMENT } from '../../../constants/payments'

// utils
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { CustomerPortalLifeCycleComponent } from '../../molecules/CustomerPortalLifeCycle'

// styles.
import styles from './customerOneOffPayment.styles'

// selectors
import {
  shouldMakeAPaymentPageShowLoader,
  checkIfSomePaymentInstumentFailed,
} from '../../../selectors/customerPaymentDetails'
import {
  haveAnyPoliciesFailed as checkIfAnyPoliciesHaveFailed,
  hasSummaryLoaded,
} from '../../../selectors/common'

// schema.
import SCHEMA, { FORM_ID } from './components/PaymentDetails/paymentForm.schema'
import { DASHBOARD_ROUTE } from '../../../constants/routes'

const Wrap = styled(PageWrap)(styles.wrap)
const ButtonBar = styled('div')(styles.buttonBar)
const TimelineWrap = styled('div')(styles.timeline)

export class CustomerOneOffPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeline: {
        activeIndex: 0,
        steps: [
          'OneOffPaymentPolicySelection',
          'OneOffPaymentDetailsAndConfirmation',
          'OneOffPaymentReceipt',
        ],
      },
      selectedPolicy: '',
    }
  }

  componentDidMount() {
    const {
      actions: { resetFooterType },
    } = this.props

    // set footer
    resetFooterType()
  }

  setPolicy = policyId => {
    this.setState({ selectedPolicy: policyId })
  }

  onSubmit = (): void => {
    const {
      actions: { formSubmit, processNabTransact },
      fields,
    } = this.props
    const schema = SCHEMA(fields)

    formSubmit(FORM_ID, schema, (data, form) => {
      const {
        timeline: { steps },
      } = this.state
      if (form.isValid) {
        // @FIXME: No sure why we're triggering two events here
        const tagEventOne = createEvent({
          GA: { category: 'Make a payment', action: 'CP - process payment' },
          Splunk: {
            attributes: {
              'workflow.name': 'Make a payment - Submit',
            },
          },
        })
        tagEventOne.end()

        const tagEventTwo = createEvent({
          GA: {
            category: 'Make a payment enhancement',
            action: ' Process payment',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Make a payment enhancement - Process payment',
            },
          },
        })
        tagEventTwo.end()

        processNabTransact({ ...data, id: CP_ONE_OFF_PAYMENT }, success => {
          if (success) {
            this.setState({ timeline: { activeIndex: 2, steps } })
          }
        })
      }
    })
  }

  goBack = () => {
    const {
      timeline: { steps },
    } = this.state
    this.setState({ timeline: { activeIndex: 0, steps } })
  }

  handleHeaderBackButtonClick = () => {
    const {
      timeline: { activeIndex, steps },
    } = this.state
    const { history } = this.props
    if (activeIndex === 0) {
      history.push(DASHBOARD_ROUTE)
    } else {
      this.setState({ timeline: { activeIndex: activeIndex - 1, steps } })
    }
  }

  handlePolicySelectionContinue = () => {
    const {
      timeline: { steps },
    } = this.state

    const tagEvent = createEvent({
      GA: {
        category: 'Make a payment enhancement',
        action: 'Continue',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Make a payment enhancement - Continue',
        },
      },
    })
    tagEvent.end()

    this.setState({ timeline: { activeIndex: 1, steps } })
  }

  handleBackToDashboard = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'Make a payment enhancement',
        action: 'Back to dashboard',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Make a payment enhancement - Back to dashboard',
        },
      },
    })
    tagEvent.end()
  }

  handleMakeAnotherPayment = () => {
    const {
      timeline: { steps },
    } = this.state
    const tagEvent = createEvent({
      GA: {
        category: 'Make a payment enhancement',
        action: 'Make another payment',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Make a payment enhancement - Make another payment',
        },
      },
    })
    tagEvent.end()
    this.setState({ timeline: { activeIndex: 0, steps } })
  }

  render() {
    const {
      fields,
      haveAnyPoliciesFailed,
      isLoading,
      haveAnyPaymentInstrumentsFailed,
      form,
      isProcessingPayment,
    } = this.props

    const {
      timeline: { activeIndex, steps },
      selectedPolicy,
    } = this.state
    const { pageHeading, pageDescription, TimelineStepOne, TimelineStepTwo } =
      reduceAuthorableFields(fields)

    const timelineData = [
      {
        label: {
          value: TimelineStepOne,
        },
      },
      {
        label: {
          value: TimelineStepTwo,
        },
      },
    ]

    const buttons = {
      '0': (
        <>
          <Button
            btnActionType="button"
            disabled={selectedPolicy === ''}
            onClick={this.handlePolicySelectionContinue}
          >
            {CONTINUE}
          </Button>
        </>
      ),
      '1': (
        <>
          <Button
            btnActionType="button"
            disabled={!form || (form && !form.isValid)}
            onClick={this.onSubmit}
            isLoading={isProcessingPayment}
          >
            {PROCESS_PAYMENT}
          </Button>
        </>
      ),
      '2': (
        <>
          <Link variant="primary" to="/dashboard" onClick={this.handleBackToDashboard}>
            {BACK_TO_DASHBOARD}
          </Link>
          <Button
            variant="secondary"
            btnActionType="button"
            onClick={this.handleMakeAnotherPayment}
          >
            {MAKE_ANOTHER_PAYMENT}
          </Button>
        </>
      ),
    }
    const subComponents = {
      OneOffPaymentPolicySelection: <PolicySelection setPolicy={this.setPolicy} fields={fields} />,
      OneOffPaymentDetailsAndConfirmation: (
        <PaymentDetails selectedPolicyId={selectedPolicy} goBack={this.goBack} fields={fields} />
      ),
      OneOffPaymentReceipt: <PaymentReceipt fields={fields} />,
    }

    return (
      <>
        <Header
          theme={THEME_LIGHT}
          heading={{ value: pageHeading }}
          subHeading={{ value: pageDescription }}
          iconText="back"
          backClickHandler={this.handleHeaderBackButtonClick}
        />
        {activeIndex < 2 && (
          <TimelineWrap>
            <Wrap>
              <Timeline data={timelineData} activeIndex={activeIndex} />
            </Wrap>
          </TimelineWrap>
        )}
        <Wrap>
          <CustomerPortalLifeCycleComponent
            loadingConstraints={[isLoading]}
            failureConstraints={[haveAnyPoliciesFailed, haveAnyPaymentInstrumentsFailed]}
          >
            {subComponents[steps[activeIndex]]}
            <ButtonBar>{buttons[activeIndex]}</ButtonBar>
          </CustomerPortalLifeCycleComponent>
        </Wrap>
      </>
    )
  }
}

export const mapStateToProps = state => ({
  isLoading: shouldMakeAPaymentPageShowLoader(state),
  haveAnyPoliciesFailed: checkIfAnyPoliciesHaveFailed(state),
  haveAnyPaymentInstrumentsFailed: checkIfSomePaymentInstumentFailed(state),
  summaryLoaded: hasSummaryLoaded(state),
  form: state.forms[FORM_ID],
  isProcessingPayment: state.nabPayment.isFetchingNabData,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

CustomerOneOffPayment.propTypes = {
  actions: shape({
    resetFooterType: func,
  }).isRequired,
  haveAnyPoliciesFailed: bool.isRequired,
  haveAnyPaymentInstrumentsFailed: bool.isRequired,
  isLoading: bool.isRequired,
  fields: objectOf(object).isRequired,
  form: objectOf(oneOfType([number, string, bool, object])),
  isProcessingPayment: bool.isRequired,
  history: objectOf(oneOfType([number, string, func, object])).isRequired,
}
CustomerOneOffPayment.defaultProps = {
  form: undefined,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerOneOffPayment))
