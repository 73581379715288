import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'

// components
import { PageWrap, Heading, Button } from '@mlcl-digital/mlcl-design'
import List from '@mlcl-digital/mlcl-design/lib/base/List'
import ListWithItemHeading from '@mlcl-digital/mlcl-design/lib/base/ListWithItemHeading'
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import SectionWrapper from '../SectionWrapper'
import SidePanel from '../SidePanel'
// @ts-expect-error file not in typescript
import Link from '../../../atoms/Link'
// @ts-expect-error file not in typescript
import Header from '../../../molecules/PageHeader'
// @ts-expect-error file not in typescript
import ScrollToTop from '../../../atoms/ScrollToTop'

// types
import { LandingPageFieldsType } from '../../../../types/components/PremiumCalculator'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// constants
import { DASHBOARD_ROUTE, PREMIUM_CALCULATOR_ROUTE } from '../../../../constants/routes'
import { ALTERATION_TYPES } from '../../../../constants/alterations'
import { THEME_LIGHT } from '../../../../constants/themes'

// selectors
import {
  getPremiumCalculatorValidationMessage,
  getPremiumCalculatorStartedStatus,
} from '../../../../selectors/premiumCalculator'
import {
  getIsFetchingPolicies,
  getWithInPeriodEffectiveDate,
  getBancsCustomerNoOfLAFromAlterations,
  // @ts-expect-error file not in typescript
} from '../../../../selectors/alterations'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../utils/telemetry'
// @ts-expect-error file not in typescript
import history from '../../../../utils/browserHistory'

// styles
import styles from './landingPage.styles'

const BackToHome = styled('div')(styles.backToHome)
const ErrorMessage = styled('div')(styles.errorMessage)
const ButtonContainer = styled('div')(styles.buttonContainer)
const DescriptionText = styled('p')(styles.descriptionText)
const Disclaimer = styled('div')(styles.disclaimer)
const SidePanelContent = styled('div')(styles.sidePanelContent)
const StepDescription = styled('p')(styles.stepDescription)

type LandingPageProps = {
  fields: LandingPageFieldsType
}

const LandingPage = ({ fields }: LandingPageProps) => {
  const dispatch = useDispatch()

  const validationMessage = useSelector(getPremiumCalculatorValidationMessage)
  const enableGetStarted = useSelector(getPremiumCalculatorStartedStatus)
  const bancsCustomerNoOfLA = useSelector(getBancsCustomerNoOfLAFromAlterations) as string
  const withInPeriodEffectiveDate = useSelector(getWithInPeriodEffectiveDate)
  const isLoadingPoliciesDetail = useSelector(getIsFetchingPolicies)

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'PC-LandingPageload',
        action: 'Landing page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator landing page load',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetTimelineData())
    if (withInPeriodEffectiveDate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(
        actionCreators.getAlterationPolicies(
          bancsCustomerNoOfLA,
          withInPeriodEffectiveDate,
          (err: object) => {
            const apiEvent = createEvent({
              Splunk: {
                attributes: {
                  'workflow.name':
                    'Premium calculator fetching future dated premiums for anniversary',
                  error: !!err,
                },
              },
            })
            apiEvent.end()
          }
        )
      )
    }
  }, [])

  const handleGetStarted = () => {
    const event = createEvent({
      GA: {
        category: 'PC-LandingGetstarted',
        action: 'Get started',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Premium calculator get started',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetAlterationsQuoteError())
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.resetSumInsuredErrors())

    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.createAltsQuote({
        alterationType: ALTERATION_TYPES.DECREASE_RISK,
      })
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push(PREMIUM_CALCULATOR_ROUTE)
  }

  const handleBackClick = (isBackToHome = false) => {
    const action = isBackToHome ? 'Back to home' : 'Back - landing page'
    const event = createEvent({
      GA: {
        category: `PC-LandingBack${(isBackToHome && 'tohome') || ''}`,
        action,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Premium calculator ${action}`,
        },
      },
    })
    event.end()
  }

  return (
    <ScrollToTop>
      <Header
        theme={THEME_LIGHT}
        heading={fields.HeaderHeading}
        subComponent={renderTextField(fields.HeaderDescription, true)}
        bigImage
        imageSrc={fields.HeaderImageUrl?.value?.src}
        imageAlt={fields.HeaderImageUrl?.value?.alt}
      />
      <PageWrap>
        <BackToHome>
          <Link variant="link" onClick={() => handleBackClick(true)} to={DASHBOARD_ROUTE}>
            <FontAwesomeIcon iconName={['far', 'chevron-left']} />
            {renderTextField(fields.BackToHomeButton)}
          </Link>
        </BackToHome>
        {validationMessage && (
          <ErrorMessage>
            <div>
              <FontAwesomeIcon iconName={['far', 'triangle-exclamation']} />
              <span>{validationMessage}</span>
            </div>
          </ErrorMessage>
        )}
        <Heading variant="h2" size="large">
          {renderTextField(fields.Heading)}
        </Heading>
        <DescriptionText>{renderTextField(fields.Description)}</DescriptionText>
        <SectionWrapper>
          <div>
            <div>
              <Heading variant="h3" size="MD">
                {renderTextField(fields.StepSubHeading)}
              </Heading>
              <StepDescription>{renderTextField(fields.StepDescription)}</StepDescription>
              <Heading variant="h4" size="SMD">
                {renderTextField(fields.Steps)}
              </Heading>
              <ListWithItemHeading
                listItems={fields?.PremiumCalculatingSteps?.map(listItem => ({
                  description: listItem?.fields?.Description?.value,
                  iconName: ['far', listItem?.fields?.Icon?.value as 'phone'],
                  heading: listItem?.fields?.Heading?.value,
                }))}
              />
            </div>
            <ListWithItemHeading
              heading={fields?.ThingsSubHeading?.value}
              listItems={fields?.ChangingYourCoverContentSteps?.map(listItem => ({
                description: listItem?.fields?.Description?.value,
                heading: listItem?.fields?.Heading?.value,
              }))}
            />
            <ButtonContainer>
              <Link variant="secondary" onClick={() => handleBackClick(false)} to={DASHBOARD_ROUTE}>
                {renderTextField(fields.BackButton)}
              </Link>
              <Button
                isLoading={isLoadingPoliciesDetail}
                disabled={!enableGetStarted}
                variant={enableGetStarted ? 'primary' : 'secondary'}
                onClick={() => handleGetStarted()}
              >
                {renderTextField(fields.GetStartedButton)}
              </Button>
            </ButtonContainer>
            <Disclaimer>
              <div>{renderTextField(fields.Disclaimer)}</div>
              {renderTextField(fields.DisclaimerContent, true)}
            </Disclaimer>
          </div>
          <SidePanel heading={fields?.SideHeading?.value}>
            <SidePanelContent>
              <List
                variant="bullet"
                listItems={fields?.ThingsConsiderSteps?.map(listItem =>
                  renderTextField(listItem?.fields?.Description?.value, true)
                )}
              />
            </SidePanelContent>
          </SidePanel>
        </SectionWrapper>
      </PageWrap>
    </ScrollToTop>
  )
}

export default LandingPage
