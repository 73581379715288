// @TODO: Taking payment from an existing CC is impossible due
// to limitations in upstream functionality
// I have commented out the code for now as this feature will be delivered at a later date
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import get from 'lodash/get'
import {
  // RadioGroup,
  // Radio,
  Chip,
  Icons,
  Button,
  Input,
  CreditCardInput,
  Checkbox,
  Heading,
  Notification,
  Label,
  SectionHeader,
} from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../../utils/telemetry'

// Redux
import { actionCreators } from '../../../../../actions'

// Utils
import { reduceAuthorableFields, renderTextField } from '../../../../../utils/sitecoreUtils'
import { getEmail } from '../../../../../utils/contactUtils'
import { errorCheck, dollarAmountWithCommasAndTwoDecimal } from '../../../../../utils/formUtils'

// Selectors
import { getPolicyPaymentDetails } from '../../../../../selectors/customerOneTimePayment'
import styles from './paymentDetails.styles'

// schema.
import SCHEMA, { FORM_ID, defaults } from './paymentForm.schema'

// Constants
import {
  TOTAL_DUE,
  MAKE_PARTIAL_PAYMENT,
  CREDIT_CARD_INPUT_LABEL,
  // CHOOSE_PAYMENT_METHOD,
} from '../../../../../constants/customerPaymentDetails'
import { CANCEL, BACK } from '../../../../../constants/buttons'
import { POLICY_PREFIX } from '../../../../../constants/cards'

// Components
const {
  // IconVisa,
  // IconMasterCard,
  IconChevronLeft16,
} = Icons
const PolicyDetails = styled('div')(styles.policyDetails)
const Section = styled('div')(styles.section)
const AltActionButton = styled('button')(styles.altActionButton)
const PartialPaymentSection = styled('div')(styles.partialPaymentSection)
const CreditCardDetailSection = styled('div')(styles.creditCardSection)
const BackButton = styled(Button)(styles.backButton)
const Chevron = styled(IconChevronLeft16)(styles.leftChevronIcon)
const HeaderComponent = styled(SectionHeader)(styles.header)
// const Card = styled('div')(styles.card)
// const ErrorLine = styled('div')(styles.errorLine)

// const STORED_CC = 'stored'
const NEW_CC = 'custom'

const PaymentDetails = props => {
  const { fields, selectedPolicyId, goBack } = props
  const dispatch = useDispatch()
  const { formInit, formValidate, formUpdateField, resetNabData } = actionCreators
  const { PaymentConfirmationHeader, AccountHolderDeclaration } = reduceAuthorableFields(fields)

  const customerPolicies = useSelector(getPolicyPaymentDetails)
  const policy = customerPolicies.find(p => p.policyId === selectedPolicyId)
  // eslint-disable-next-line max-len
  // const { paymentInstrument } = useSelector(state => state.customerPolicies[policy.bancsPolicyNo])
  const customerDetails = useSelector(state => state.customerPersonalDetails.details)
  const formData = useSelector(state => state.forms[FORM_ID])
  const nabData = useSelector(state => state.nabPayment.nabData)

  // const CCDetails = paymentInstrument && paymentInstrument.creditCardDetails

  const [isMakingPartial, changePartialPayment] = useState(false)
  const [isUsingStoredCC] = useState(NEW_CC)
  const [nabError, setNabError] = useState('')

  const totalDue =
    policy.outstandingBalance > 0
      ? `${policy.outstandingBalance.toFixed(2)}`
      : policy.premium.split('$')[1].replace(/,/g, '')

  // const cardIcon = {
  //   VI: <IconVisa />,
  //   MC: <IconMasterCard />,
  // }

  useEffect(() => {
    if (nabData === '') {
      setNabError('Something has gone wrong, please try again later.')
    }
    if (nabData === null) {
      setNabError('')
    }
    if (nabData && typeof nabData === 'object') {
      if (nabData.rescode !== '00') {
        setNabError(nabData.restext)
      }
    }
  }, [nabData])

  useEffect(() => {
    const customerEmail = getEmail(get(customerDetails, 'contactMethods.emails', []))
    const schema = SCHEMA(fields, isUsingStoredCC === NEW_CC)
    dispatch(
      formInit(
        FORM_ID,
        schema,
        defaults(customerEmail, selectedPolicyId, totalDue, isUsingStoredCC, policy.bancsPolicyNo)
      )
    )
    dispatch(formValidate(FORM_ID, schema))
    dispatch(resetNabData())
  }, [])

  const handleChange = ({ value, name }) => {
    const schema = SCHEMA(fields, isUsingStoredCC === NEW_CC)
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }

    dispatch(formUpdateField(FORM_ID, name, data))
    dispatch(formValidate(FORM_ID, schema))
  }

  const handleCreditCardChange = (type, number, date, cvc) => {
    const formFields = ['cardNumber', 'expiryDate', 'cvcNumber']
    const values = [number, date, cvc]
    const schema = SCHEMA(fields, isUsingStoredCC === NEW_CC)
    formFields.forEach((field, index) => {
      const value = values[index]
      const data = {
        error: errorCheck(value, schema[field].condition, schema[field].errorMsg),
        value,
      }
      dispatch(formUpdateField(FORM_ID, field, data))
      dispatch(formValidate(FORM_ID, schema))
    })
  }

  // const handlePaymentTypeChange = value => {
  //   setIsUsingStoredCC(value)
  //   const schema = SCHEMA(fields, value === NEW_CC)
  //   const data = {
  //     error: errorCheck(
  //       value,
  //       schema.existingCardDetails.condition,
  //       schema.existingCardDetails.errorMsg
  //     ),
  //     value,
  //   }

  //   dispatch(formUpdateField(FORM_ID, 'existingCardDetails', data))
  //   dispatch(formValidate(FORM_ID, schema))
  // }

  const handleChangeToPartialPayment = isPartial => {
    if (!isPartial) {
      dispatch(
        formUpdateField(FORM_ID, 'amount', {
          value: totalDue,
          error: false,
        })
      )
    }
    changePartialPayment(isPartial)
    const tagEvent = createEvent({
      GA: {
        category: 'Make a payment enhancement',
        action: `Make a partial payment${!isPartial ? ' (cancel)' : ''}`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Make a payment enhancement - ${!isPartial ? ' (cancel)' : ''}`,
        },
      },
    })
    tagEvent.end()
  }

  const handleDeclarationCHange = data => {
    if (data.value) {
      const tagEvent = createEvent({
        GA: {
          category: 'Make a payment enhancement',
          action: 'Declaration on',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Make a payment enhancement - Declaration on',
          },
        },
      })
      tagEvent.end()
    }
    handleChange({ ...data, value: !data.value ? '' : data.value })
  }

  const fullName = get(formData, 'fields.fullName', {})
  const amount = get(formData, 'fields.amount', {})
  const isAuthorisedAccountHolder = get(formData, 'fields.isAuthorisedAccountHolder', {})
  const cardNumber = get(formData, 'fields.cardNumber', false)

  const cardNumberErrors = get(formData, 'fields.cardNumber.error', false)
  const expiryDateErrors = get(formData, 'fields.expiryDate.error', false)
  const cvcNumberErrors = get(formData, 'fields.cvcNumber.error', false)

  const ccInputHasError = cardNumberErrors.error || expiryDateErrors.error || cvcNumberErrors.error

  const isCCInputValid =
    cardNumber.value && !cardNumberErrors && !expiryDateErrors && !cvcNumberErrors

  // @TODO: Need to determine how error state should be handled. Isn't specified in figma's or story
  const ErrorComponent = (
    <>
      {/* <ErrorLine>{cardNumberErrors.errorMsg}</ErrorLine>
      <ErrorLine>{expiryDateErrors.errorMsg}</ErrorLine> */}
    </>
  )
  return (
    <>
      <BackButton variant="tertiary" size="small" onClick={goBack} data-testid="backButton">
        <Chevron /> {BACK}
      </BackButton>
      <HeaderComponent heading={PaymentConfirmationHeader} />
      <Section>
        <PolicyDetails>
          <div>
            <Chip variant={policy.policyType === 'Inside Super' ? 'default' : 'important'}>
              {policy.policyType}
            </Chip>
            <div>
              {POLICY_PREFIX} {policy.policyId}
            </div>
          </div>
          <div>
            <span>{TOTAL_DUE}</span>
            <span data-testid="totalDue">{dollarAmountWithCommasAndTwoDecimal(totalDue)}</span>
          </div>
        </PolicyDetails>
        {!isMakingPartial && (
          <>
            or{' '}
            <AltActionButton type="button" onClick={() => handleChangeToPartialPayment(true)}>
              {MAKE_PARTIAL_PAYMENT.toLowerCase()}
            </AltActionButton>
          </>
        )}
        {isMakingPartial && (
          <PartialPaymentSection>
            <Heading variant="h4">
              {MAKE_PARTIAL_PAYMENT}{' '}
              <AltActionButton type="button" onClick={() => handleChangeToPartialPayment(false)}>
                {CANCEL}
              </AltActionButton>
            </Heading>
            <Input
              type="text"
              name="amount"
              htmlFor="amount"
              placeholder="amount"
              label="Partial payment amount"
              changeHandler={handleChange}
              value={amount.value}
              error={get(amount, 'error.error')}
              caption={get(amount, 'error.error')}
              testId="amount"
            />
            <Notification variant="warning">
              <span data-testid="partial-payment-warning">
                {renderTextField(
                  policy.status === 'Revivable Lapse'
                    ? fields.lapsedPartialPaymentWarning
                    : fields.partialPaymentWarning
                )}
              </span>
            </Notification>
          </PartialPaymentSection>
        )}
        <CreditCardDetailSection>
          {/* @TODO: update radio label styling */}
          {/* {CCDetails && (
            <>
              <Heading variant="h4">{CHOOSE_PAYMENT_METHOD}</Heading>
              <RadioGroup
                type="block"
                styleOverrides={{
                  inputWrapper: {
                    position: 'relative',
                  },
                }}
              >
                <Radio
                  text={`XXXX XXXX XXXX ${CCDetails.panID} ${CCDetails.cardExpiryDate}`}
                  checked={isUsingStoredCC === 'stored'}
                  htmlFor="radio-one"
                  name="stored"
                  value="stored"
                  handleOnChange={handlePaymentTypeChange}
                  styleOverrides={{
                    wrapper: {
                      position: 'static',
                    },
                  }}
                  selectorId="stored"
                >
                  <Card>{cardIcon[CCDetails.cardType]}</Card>
                </Radio>
                <Radio
                  text="Pay with a different card"
                  checked={isUsingStoredCC === 'custom'}
                  htmlFor="radio-two"
                  name="custom"
                  value="custom"
                  handleOnChange={handlePaymentTypeChange}
                  selectorId="custom"
                />
              </RadioGroup>
            </>
          )} */}
        </CreditCardDetailSection>
        {isUsingStoredCC === 'custom' && (
          <>
            <Input
              type="text"
              name="fullName"
              htmlFor="fullName"
              placeholder="Enter name as it appears on your card"
              label="Name on card"
              changeHandler={handleChange}
              value={fullName.value}
              error={get(fullName, 'error.error')}
              caption={get(fullName, 'error.error')}
              valid={Boolean(fullName.value)}
            />
            <Label htmlFor="creditCard" id="creditCard-label" variant="input">
              {CREDIT_CARD_INPUT_LABEL}
            </Label>

            <CreditCardInput
              onChange={handleCreditCardChange}
              hasError={ccInputHasError}
              ErrorComponent={ErrorComponent}
              isValid={Boolean(isCCInputValid)}
            />
          </>
        )}
      </Section>
      {nabError && (
        <Notification closeHandler={() => setNabError('')} variant="error">
          <span data-testid="nabError">{nabError}</span>
        </Notification>
      )}
      <Checkbox
        text={AccountHolderDeclaration}
        name="isAuthorisedAccountHolder"
        htmlFor="isAuthorisedAccountHolder"
        onChangeHandler={handleDeclarationCHange}
        value="true"
        checked={Boolean(isAuthorisedAccountHolder.value)}
      />
    </>
  )
}

export default PaymentDetails
