// @flow
import {
  FETCH_NAB_CONFIG,
  FETCH_NAB_CONFIG_INIT,
  FETCH_NAB_CONFIG_SUCCESS,
  FETCH_NAB_CONFIG_ERROR,
  FETCH_NAB_CONFIG_FAILURE,
  INITIATE_NAB_TRANSACT,
  INITIATE_NAB_TRANSACT_SUCCESS,
  INITIATE_NAB_TRANSACT_ERROR,
  INITIATE_NAB_TRANSACT_FAILURE,
  UPDATE_NAB_TRANSACT_SUCCESS,
  UPDATE_NAB_TRANSACT_ERROR,
  UPDATE_NAB_TRANSACT_FAILURE,
  UPDATE_SAVE_CARD_TRANSACT_SUCCESS,
  UPDATE_SAVE_CARD_TRANSACT_ERROR,
  UPDATE_SAVE_CARD_TRANSACT_FAILURE,
  RESET_NAB_DATA,
} from '../actions/types/nabPayment'

export const initialState = {
  isFetchingCreditCardInfo: false,
  isCreditCardInfoError: false,
  creditCardInfo: null,
  isFetchingNabData: false,
  isNabDataError: false,
  nabData: null,
  displayCreditCardListing: false,
  isProcessingPayment: false,
}

const nabPayment = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case FETCH_NAB_CONFIG:
    case FETCH_NAB_CONFIG_INIT: {
      return {
        ...state,
        isFetchingCreditCardInfo: true,
        isCreditCardInfoError: false,
        isProcessingPayment: true,
      }
    }
    case FETCH_NAB_CONFIG_SUCCESS: {
      return {
        ...state,
        creditCardInfo: action.payload,
        isFetchingCreditCardInfo: false,
        isCreditCardInfoError: false,
      }
    }
    case FETCH_NAB_CONFIG_ERROR:
    case FETCH_NAB_CONFIG_FAILURE: {
      return {
        ...state,
        isFetchingCreditCardInfo: false,
        isCreditCardInfoError: true,
        isProcessingPayment: false,
      }
    }
    case INITIATE_NAB_TRANSACT: {
      return {
        ...state,
        isFetchingNabData: true,
        isNabDataError: false,
        nabData: null,
      }
    }
    case INITIATE_NAB_TRANSACT_SUCCESS: {
      return {
        ...state,
        nabData: action.payload,
        isFetchingNabData: false,
        isNabDataError: false,
      }
    }
    case INITIATE_NAB_TRANSACT_ERROR:
    case INITIATE_NAB_TRANSACT_FAILURE: {
      return {
        ...state,
        isFetchingNabData: false,
        nabData: action.payload ? action.payload : '',
        isNabDataError: true,
      }
    }
    case RESET_NAB_DATA: {
      return {
        ...state,
        nabData: null,
      }
    }
    case UPDATE_NAB_TRANSACT_ERROR:
    case UPDATE_NAB_TRANSACT_FAILURE:
    case UPDATE_NAB_TRANSACT_SUCCESS:
    case UPDATE_SAVE_CARD_TRANSACT_FAILURE:
    case UPDATE_SAVE_CARD_TRANSACT_ERROR:
    case UPDATE_SAVE_CARD_TRANSACT_SUCCESS: {
      return {
        ...state,
        isProcessingPayment: false,
      }
    }
    default: {
      return state
    }
  }
}

export default nabPayment
