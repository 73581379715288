// helpers.
import { required, numeric } from '../../../../../utils/formUtils'

// field constants.
export const FORM_ID = 'otpVerification'
export const otpFieldName = 'otp'

const SCHEMA = () => ({
  [otpFieldName]: {
    condition: [{ condition: required }, { condition: numeric }],
  },
})

export default SCHEMA
