import { mediaQueries } from '../../../../../../../../styles'

const FULL_WIDTH = {
  width: '100%',
}

const styles = {
  base: {
    position: 'relative',
  },
  halfWidth: {
    ...FULL_WIDTH,
    [mediaQueries.sm]: {
      width: '48%',
    },
  },
}
export default styles
