// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Chip, ErrorState, PageWrap, SectionHeader } from '@mlcl-digital/mlcl-design'
import { Text } from '@sitecore-jss/sitecore-jss-react'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { shape, string, bool, object, arrayOf, array } from 'prop-types'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'
// eslint-disable-next-line max-len
import { getPolicySummary as getPolicySummaryActionHandler } from '../../../actions/creators/customerPolicies'
// selectors
import {
  getInforceCustomerPoliciesCardData,
  getOutOfForceCustomerPoliciesCardData,
  checkIfEveryPolicyFailed,
} from '../../../selectors/customerPolicies'

// styles
import styles from './customerPolicies.styles'

// utils
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'

// components
import CustomerPortalLifeCycle from '../../molecules/CustomerPortalLifeCycle'
import { CardContainer, Card as NewCard } from '../../molecules/CardChildren'
import Header from '../../molecules/PageHeader'
import { PolicyCard } from '../../molecules/PolicyCard'

// constants.
import { POLICY_DETAILS_PATH } from '../../../constants/customer'
import { EXP_CUSTOMER_STATUS_LOADING } from '../../../constants/apis'
import { THEME_LIGHT } from '../../../constants/themes'

const Wrap = styled(PageWrap)(styles.wrap)

export class CustomerPolicies extends Component {
  constructor(props) {
    super(props)
    this.renderPolicyItem = this.renderPolicyItem.bind(this)
    this.renderOutOfPolicyItem = this.renderOutOfPolicyItem.bind(this)
  }

  componentWillMount() {
    const {
      actions: { resetFooterType },
    } = this.props

    const tagEvent = createEvent({
      GA: {
        category: 'Customer views policies',
        action: 'Customer choses to view policies',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Customer views policies - Customer choses to view policies',
        },
      },
    })
    tagEvent.end()

    // set default footer
    resetFooterType()
  }

  primaryAction = (clientId, bancsPolicyNo) => () => {
    const tagEvent = createEvent({
      GA: {
        category: 'Policies in force',
        action: 'View policy',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Policies in force - View policy',
        },
      },
    })
    tagEvent.end()

    history.push(`${POLICY_DETAILS_PATH}?policyId=${bancsPolicyNo}`)
  }

  renderOutOfPolicyItem(policy) {
    const { currentStatus, policyName, policyId } = policy
    const {
      fields: { cpCustomerPolicyDetailsSubHeading },
    } = this.props
    return (
      <NewCard
        key={policyId}
        header={
          <>
            <Chip variant="important">{currentStatus}</Chip>
            <div>
              <span>
                <Text field={cpCustomerPolicyDetailsSubHeading} />
                {policyId}
              </span>
            </div>
            <div>{policyName}</div>
          </>
        }
      />
    )
  }

  renderPolicyItem(policy) {
    const { fields, clientId } = this.props
    const { hasFailed } = policy

    const {
      cpCustomerPolicySectionPaymentMethod,
      cpCustomerPolicySectionAnnualPremium,
      cpCustomerPolicySectionBenefitsHeader,
      cpCustomerPolicySectionSumInsuredHeader,
      cpCustomerPolicySectionLivesInsuredHeader,
      cpCustomerPolicyDetailsSubHeading,
      cpCustomerPolicySectionViewPolicyButton,
    } = reduceAuthorableFields(fields)

    const policyFields = {
      policySectionAnnualPremium: cpCustomerPolicySectionAnnualPremium,
      policySectionSubHeading: cpCustomerPolicyDetailsSubHeading,
      policySectionPaymentMethod: cpCustomerPolicySectionPaymentMethod,
      policySectionBenefitsHeader: cpCustomerPolicySectionBenefitsHeader,
      policySectionSumInsuredHeader: cpCustomerPolicySectionSumInsuredHeader,
      policySectionLivesInsuredHeader: cpCustomerPolicySectionLivesInsuredHeader,
      policySectionViewPolicyButton: cpCustomerPolicySectionViewPolicyButton,
    }

    return hasFailed ? (
      <ErrorState key={policy.subHeadingText} />
    ) : (
      <PolicyCard
        {...policy}
        key={policy.subHeadingText}
        onFooterAction={policy.bancsPolicyNo && this.primaryAction(clientId, policy.bancsPolicyNo)}
        fields={policyFields}
      />
    )
  }

  render() {
    const { fields, inForcePolicies, outOfForcePolicies, pageIsLoading, haveAllPoliciesFailed } =
      this.props
    const {
      cpCustomerPolicyValue,
      cpCustomerPolicyHeading,
      cpPoliciesBannerImageUrl,
      cpPoliciesBannerImageAlt,
      cpPolicySectionHeaderText,
      cpPolicySectionSubHeaderText,
      cpNoPolicySectionSubHeaderText,
      cpOutOfForcePolicyHeaderText,
      cpOutOfForcePolicySubHeaderText,
    } = reduceAuthorableFields(fields)
    const policiesSubHeading =
      inForcePolicies.length > 0 ? cpPolicySectionSubHeaderText : cpNoPolicySectionSubHeaderText
    return (
      <Fragment>
        <Header
          theme={THEME_LIGHT}
          heading={{ value: `${cpCustomerPolicyHeading}` }}
          subHeading={{ value: `${cpCustomerPolicyValue}` }}
          bigImage
          imageSrc={cpPoliciesBannerImageUrl}
          imageAlt={cpPoliciesBannerImageAlt}
        />
        <CustomerPortalLifeCycle failureConstraints={[haveAllPoliciesFailed]}>
          <Wrap>
            <SectionHeader
              heading={cpPolicySectionHeaderText}
              subHeading={!pageIsLoading && policiesSubHeading}
            />
            <CardContainer>{inForcePolicies.map(this.renderPolicyItem)}</CardContainer>
            {outOfForcePolicies.length > 0 && (
              <>
                <SectionHeader
                  heading={cpOutOfForcePolicyHeaderText}
                  subHeading={cpOutOfForcePolicySubHeaderText}
                />
                <CardContainer>{outOfForcePolicies.map(this.renderOutOfPolicyItem)}</CardContainer>
              </>
            )}
          </Wrap>
        </CustomerPortalLifeCycle>
      </Fragment>
    )
  }
}

CustomerPolicies.propTypes = {
  // Sitecore authorable fields.
  fields: shape({
    cpCustomerPolicySectionPaymentMethod: object,
    cpCustomerPolicySectionAnnualPremium: object,
    cpCustomerPolicySectionBenefitsHeader: object,
    cpCustomerPolicySectionSumInsuredHeader: object,
    cpCustomerPolicySectionLivesInsuredHeader: object,
    cpCustomerPolicyDetailsSubHeading: object,
    cpCustomerPolicySectionViewPolicyButton: object,
    cpCustomerPolicyValue: object,
    cpCustomerPolicyHeading: object,
    cpPoliciesBannerImageUrl: object,
    cpPoliciesBannerImageAlt: object,
    cpPolicySectionHeaderText: object,
    cpPolicySectionSubHeaderText: object,
    cpNoPolicySectionSubHeaderText: object,
    cpOutOfForcePolicyHeaderText: object,
    cpOutOfForcePolicySubHeaderText: object,
  }).isRequired,
  pageIsLoading: bool,
  inForcePolicies: arrayOf(
    shape({
      bancsPolicyNo: string,
      collectionFrequency: string,
      subHeadingText: string,
      mainHeadingText: string,
      policyBenefitAmounts: array,
      paymentMethod: undefined,
      policyPremiumValue: string,
      policyType: string,
      policyPersonnel: array,
      isLoading: bool,
      hasFailed: bool,
    })
  ).isRequired,
  outOfForcePolicies: arrayOf(
    shape({
      currentStatus: string,
      policyName: string,
      policyNo: string,
    })
  ).isRequired,
}

CustomerPolicies.defaultProps = {
  pageIsLoading: false,
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actionCreators,
      getPolicySummary: getPolicySummaryActionHandler,
    },
    dispatch
  ),
})

export const mapStateToProps = state => {
  const { navigation, customerPolicySummaryStatus } = state
  return {
    inForcePolicies: getInforceCustomerPoliciesCardData(state),
    outOfForcePolicies: getOutOfForceCustomerPoliciesCardData(state),
    navigation,
    pageIsLoading: Boolean(customerPolicySummaryStatus.isLoading === EXP_CUSTOMER_STATUS_LOADING),
    haveAllPoliciesFailed: checkIfEveryPolicyFailed(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPolicies)
