import get from 'lodash/get'
import {
  CUSTOMER_POLICY_SELF_SUCCESS,
  CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS,
} from '../actions/types/customerPolicyStatus'
import {
  UPDATE_CUSTOMER_POLICY,
  CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS,
} from '../actions/types/customerPolicies'
import { BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {}

const customerPolicies = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_POLICY:
    case CUSTOMER_POLICY_SELF_SUCCESS: {
      return action.payload.reduce(
        (newState, policy) => ({
          ...newState,
          [policy.bancsPolicyNo]: {
            policyId: policy.policyId,
            policyNo: policy.bancsPolicyNo,
            productId: policy.productId,
            policyName: policy.policyName,
            productClass: policy.productClass,
            policyPremium: policy.policyPremium,
            alterationPending: get(policy, 'alterationPending', null),
            outstandingBalance: get(policy, 'outstandingBalance', 0),
            policyPremiumFrequency: get(policy, 'policyPremiumFrequency', null),
            bPayPayInReference: get(policy, 'bPayPayInReference', null),
            status: policy.status,
            policyState: policy.policyState,
            isAssociatedToSuperFund: get(policy, 'policyOwners', []).some(({ bancsCustomerNo }) =>
              [BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(bancsCustomerNo)
            ),
            lifeAssured: policy.lifeAssured.map(({ bancsCustomerNo }) => bancsCustomerNo),
            seoBeneficiaryAttributes: get(policy, 'seoBeneficiaryAttributes', []),
            legacySystemProductCode: get(policy, 'legacySystemProductCode', null),
            startDate: get(policy, 'startDate', null),
            escalationDueDate: get(policy, 'escalationDueDate', null),
            lastAnniversaryDate: get(policy, 'lastAnniversaryDate', null),
            nextAnniversaryDate: get(policy, 'nextAnniversaryDate', null),
            premiumPaymentMethod: get(policy, 'premiumPaymentMethod', null),
            premiumContributionType: get(policy, 'premiumContributionType', null),
            policyFee: get(policy, 'policyFee', 0),
            policyExpiry: get(policy, 'policyExpiry', null),
            ageAdjustmentFlag: get(policy, 'ageAdjustmentFlag', null),
            jurisdiction: get(policy, 'jurisdiction', null),
            stampDuty: get(policy, 'stampDuty', 0),
            isSMSF: get(policy, 'isSMSF', null),
            taxRebate: get(policy, 'taxRebate', 0),
            beneficiaries: [
              ...new Set(
                get(policy, 'beneficiaries', []).map(({ bancsCustomerNo }) => bancsCustomerNo)
              ),
            ],
            payers: get(policy, 'payers', []).map(({ bancsCustomerNo }) => bancsCustomerNo),
            policyOwners: get(policy, 'policyOwners', []).map(
              ({ bancsCustomerNo }) => bancsCustomerNo
            ),
            agents: get(policy, 'agents', []).map(({ bancsCustomerNo }) => bancsCustomerNo),
            iet: get(policy, 'iet', []).map(({ bancsCustomerNo }) => bancsCustomerNo),
            benefits: policy.benefits.map(benefit => ({
              ...benefit,
              status: benefit.benefitStatusCode,
              benefitAssuredLoadings: benefit.benefitAssured.reduce(
                (loadingsObject, benefitAssured) => ({
                  ...loadingsObject,
                  [benefitAssured.bancsCustomerNo]: {
                    loadings: benefitAssured.loadings,
                  },
                }),
                {}
              ),
              benefitAssured: benefit.benefitAssured.map(benefitAssured =>
                benefitAssured.bancsCustomerNo ? benefitAssured.bancsCustomerNo : benefitAssured
              ),
              // Having to add this back in for CoC, there is very little data that the frontend
              // actually consumes from this list however due to API design we
              // have to send it back in certain requests.
              benefitAssuredLegacy:
                typeof benefit.benefitAssured[0] === 'object' ? benefit.benefitAssured : [],
            })),
          },
        }),
        state
      )
    }
    case CUSTOMER_POLICY_SUPPORTING_DATA_SUCCESS: {
      return action.payload.reduce(
        (newState, policy) => ({
          ...newState,
          [policy.policyNo]: {
            ...newState[policy.policyNo],
            [policy.type]: {
              ...policy.response,
            },
          },
        }),
        state
      )
    }
    case CUSTOMER_POLICY_OUTSTANDING_FUTURE_BALANCE_SUCCESS: {
      const policy = get(action.payload[0], 'businessData.policy', {})
      return {
        ...state,
        [policy.bancsPolicyNo]: {
          ...state[policy.bancsPolicyNo],
          futurePremium: get(policy, 'policyPremium', state[policy.bancsPolicyNo].policyPremium),
        },
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default customerPolicies
