// @flow
import React, { Fragment, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import styled from '@emotion/styled'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import { Button, Variables, PageWrap, SectionHeader, TabWrap } from '@mlcl-digital/mlcl-design'
import { shape, object, string } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'
// redux.

import {
  getCustomerPolicyDetails,
  getCustomerPolicyProductId,
  getLifeAssuredForPolicy,
  getPolicyActivity,
  getPolicyCovers,
  getLifeAssuredWithBenefits,
  getExclusions,
  getPolicyFees,
  isPolicyDetailsPageLoading,
  isPolicyActivityLoading,
  hasPolicyDetailsPageFailed as checkIfPolicyDetailsPageFailed,
  getPrimaryPolicyOwner,
} from '../../../selectors/customerPolicyDetails'

import { checkIfAllPoliciesLoaded } from '../../../selectors/common'

// styles
import styles from './customerPolicyDetails.styles'

// components.
import Header from '../../molecules/PageHeader'
import PolicyDetails from './PolicyDetails'
import { PolicyActivity } from './PolicyActivity'
import { IncludedCoverComponent as IncludedCover } from './IncludedCover'
import LifeInsured from '../../molecules/LifeInsuredCardList'
import CustomerPortalLifeCycle from '../../molecules/CustomerPortalLifeCycle'
// util
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import { showBeneficiariesBasedOnAllPolicies } from '../../../utils/policyUtils'
import history from '../../../utils/browserHistory'
import { THEME_LIGHT } from '../../../constants/themes'

const Wrap = styled(PageWrap)(styles.wrap)

const tabs = {
  OVERVIEW: 'Overview',
  POLICY_DETAILS: 'Policy Details',
  POLICY_OVERVIEW: 'Policy Activity',
}

const StyledButton = styled(Button)({
  borderColor: Variables.colours.tertiarySix,
  color: Variables.colours.tertiarySix,
  margin: Variables.space(1),
  '&:hover, :active, :focus': {
    background: Variables.colours.tertiarySix,
    borderColor: Variables.colours.tertiaryOne,
    color: Variables.colours.tertiaryOne,
  },
  ':focus': {
    borderColor: Variables.colours.tertiarySix,
  },
})

const VIEW_BENEFICIARIES_BUTTON_LABEL = 'View beneficiaries'
const VIEW_PAYMENT_DETAILS_BUTTON_LABEL = 'View payment details'

export const CustomerPolicyDetails = props => {
  const {
    fields,
    location: { hash },
  } = props
  const dispatch = useDispatch()
  const { fetchBatchRelatedParty } = actionCreators
  // @TODO: these selectors should me moved to the individual child components that need them. CF
  const policy = useSelector(getCustomerPolicyDetails)
  const productId = useSelector(getCustomerPolicyProductId)
  const policyOwner = useSelector(getPrimaryPolicyOwner)
  const isPoliciesLoaded = useSelector(checkIfAllPoliciesLoaded)
  const lifeAssured = useSelector(getLifeAssuredForPolicy)
  const lifeAssuredWithBenefits = useSelector(getLifeAssuredWithBenefits)
  const policyActivity = useSelector(getPolicyActivity)
  const covers = useSelector(getPolicyCovers)
  const exclusions = useSelector(getExclusions)
  const policyFees = useSelector(getPolicyFees)
  const isPageLoading = useSelector(isPolicyDetailsPageLoading)
  const policyActivityLoadingStatus = useSelector(isPolicyActivityLoading)
  const hasPolicyDetailsPageFailed = useSelector(checkIfPolicyDetailsPageFailed)

  const { policyId } = queryString.parse(history.location.search)

  const [activeTab, setActiveTab] = useState(
    hash ? decodeURIComponent(hash.substring(1)) : tabs.OVERVIEW
  )
  const {
    customerPolicyDetailsLifeInsuredSectionHeader,
    customerPolicyDetailsLifeInsuredSectionSubtitle,
  } = fields

  const {
    cpCustomerPolicyDetailsSubHeading,
    cpPolicyDetailsBannerImageUrl,
    cpPolicyDetailsBannerImageAlt,
    cpCustomerPolicyDetailsLifeInsuredNameHeading,
    cpCustomerPolicyDetailsLifeInsuredAddress,
    cpCustomerPolicyDetailsLifeInsuredDateOfBirthHeading,
    cpCustomerPolicyDetailsLifeInsuredPrimaryContactNumberHeading,
    cpCustomerPolicyDetailsLifeInsuredEmailAddressHeading,
  } = reduceAuthorableFields(fields)

  const showViewBeneficiariesButton =
    !isPoliciesLoaded || (isPoliciesLoaded && showBeneficiariesBasedOnAllPolicies([policy]))
  const { policyName, policyId: policyStorePolicyId, legacySystemProductCode } = policy

  useEffect(() => {
    const tagEvent = createEvent({
      GA: {
        category: 'Viewing policy details',
        action: 'CP  - view policy details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Viewing policy details - View',
        },
      },
    })
    tagEvent.end()
  }, [])

  useEffect(() => {
    if (isPoliciesLoaded) {
      dispatch(fetchBatchRelatedParty('lifeAssured', 'policyOwners'))
    }
  }, [isPoliciesLoaded])

  const tabbedComponents = {
    [tabs.OVERVIEW]: (
      <>
        <PolicyDetails fields={fields} policy={policy} policyOwner={policyOwner} />
        <SectionHeader
          heading={renderTextField(customerPolicyDetailsLifeInsuredSectionHeader)}
          subHeading={renderTextField(customerPolicyDetailsLifeInsuredSectionSubtitle)}
        />
        <LifeInsured
          lifeAssured={lifeAssured}
          fields={{
            policyDetailsLifeInsuredDetailsHeading: cpCustomerPolicyDetailsLifeInsuredNameHeading,
            // eslint-disable-next-line max-len
            policyDetailsLifeInsuredDateOfBirthHeading:
              cpCustomerPolicyDetailsLifeInsuredDateOfBirthHeading,
            // eslint-disable-next-line max-len
            policyDetailsLifeInsuredPhoneHeading:
              cpCustomerPolicyDetailsLifeInsuredPrimaryContactNumberHeading,
            // eslint-disable-next-line max-len
            policyDetailsLifeInsuredEmailHeading:
              cpCustomerPolicyDetailsLifeInsuredEmailAddressHeading,
            policyDetailsLifeInsuredAddressHeading: cpCustomerPolicyDetailsLifeInsuredAddress,
          }}
        />
      </>
    ),
    [tabs.POLICY_DETAILS]: (
      <IncludedCover
        fields={fields}
        covers={covers}
        productId={productId}
        lifeAssured={lifeAssuredWithBenefits}
        exclusions={exclusions}
        policyFees={policyFees}
        legacySystemProductCode={legacySystemProductCode}
      />
    ),
    [tabs.POLICY_OVERVIEW]: (
      <PolicyActivity
        fields={fields}
        policyActivity={policyActivity}
        isPolicyActivityLoading={policyActivityLoadingStatus}
      />
    ),
  }

  return (
    <Fragment>
      <Header
        theme={THEME_LIGHT}
        heading={{
          value: (
            <>
              {policyName}
              <br />
              {policyStorePolicyId}
            </>
          ),
        }}
        subHeading={{ value: `${cpCustomerPolicyDetailsSubHeading}` }}
        imageSrc={cpPolicyDetailsBannerImageUrl}
        imageAlt={cpPolicyDetailsBannerImageAlt}
      >
        {showViewBeneficiariesButton && (
          <StyledButton
            isLoading={!isPoliciesLoaded}
            variant="secondary"
            onClick={() => history.push('/beneficiaries')}
          >
            {VIEW_BENEFICIARIES_BUTTON_LABEL}
          </StyledButton>
        )}
        <StyledButton variant="secondary" onClick={() => history.push('/payment-details')}>
          {VIEW_PAYMENT_DETAILS_BUTTON_LABEL}
        </StyledButton>
      </Header>
      <TabWrap>
        <Tabs>
          {Object.keys(tabs).map((tab, index) => {
            if (tabs[tab] === tabs.POLICY_OVERVIEW && policyActivity.length === 0) {
              return null
            }
            return (
              <Tab
                data-testid={`cp-policy-details-tabs-${tab}`}
                dataLocator={`cp-policy-details-tabs-${tab}`}
                id={tabs[tab]}
                hash={tabs[tab]}
                key={tab}
                index={index}
                selected={activeTab === tabs[tab]}
                clickHandler={() => setActiveTab(tabs[tab])}
              >
                {tabs[tab]}
              </Tab>
            )
          })}
        </Tabs>
      </TabWrap>
      <CustomerPortalLifeCycle
        bancsPolicyNo={policyId}
        loadingConstraints={[isPageLoading]}
        failureConstraints={[hasPolicyDetailsPageFailed]}
      >
        <Wrap>{tabbedComponents[activeTab]}</Wrap>
      </CustomerPortalLifeCycle>
    </Fragment>
  )
}

CustomerPolicyDetails.propTypes = {
  fields: shape({
    cpCustomerPolicyDetailsSubHeading: object,
    cpPolicyDetailsBannerImageUrl: object,
    cpPolicyDetailsBannerImageAlt: object,
    cpCustomerPolicyDetailsLifeInsuredNameHeading: object,
    cpCustomerPolicyDetailsLifeInsuredAddress: object,
    cpCustomerPolicyDetailsLifeInsuredDateOfBirthHeading: object,
    cpCustomerPolicyDetailsLifeInsuredPrimaryContactNumberHeading: object,
    cpCustomerPolicyDetailsLifeInsuredEmailAddressHeading: object,
    cpCustomerPolicyDetailsExclusionsHeading: object,
    cpCustomerPolicyDetailsExclusionsSubHeading: object,
    customerPolicyDetailsLifeInsuredSectionHeader: object,
    customerPolicyDetailsLifeInsuredSectionSubtitle: object,
  }).isRequired,
  location: shape({
    hash: string,
  }).isRequired,
}

export default withRouter(CustomerPolicyDetails)
