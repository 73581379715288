// @flow
import get from 'lodash/get'

import { TITLES, RELATIONSHIP_BENEFICIARY_FORM, STATES } from '../../../../constants/forms'
import {
  date,
  optional,
  required,
  ageRangeCondition,
  datePast,
  isCurrentDate,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../utils/formUtils'

export const FORM_ID: string = 'beneficiaryForm'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema =
  (): Function =>
  (state?: Object, fields): Object => ({
    title: {
      condition: state.isPartyTypeOrg || !state.toAdd ? optional : required,
      options: TITLES,
      errorMsg: fields && fields.cpBeneficiaryFormTitleError.value,
    },
    firstName: {
      condition: state.isPartyTypeOrg || !state.toAdd ? optional : required,
      errorMsg: fields && fields.cpBeneficiaryFormFirstNameError.value,
    },
    lastName: {
      condition: state.isPartyTypeOrg || !state.toAdd ? optional : required,
      errorMsg: fields && fields.cpBeneficiaryFormLastNameError.value,
    },
    dateOfBirth: {
      condition: [
        state.isPartyTypeOrg || !state.toAdd ? optional : required,
        date,
        {
          condition: required,
          errorMsg: fields && fields.cpBeneficiaryFormMissingDateOfBirthError.value,
        },
        {
          condition: datePast,
          errorMsg: fields && fields.cpBeneficiaryFormPastDateOfBirthError.value,
        },
        {
          condition: isCurrentDate,
          errorMsg: fields && fields.cpBeneficiaryFormCurrentDateOfBirthError.value,
        },
        {
          condition: ageRangeCondition(1, 150),
          errorMsg: fields && fields.cpBeneficiaryFormtDateOfBirthRangeError.value,
        },
      ],
      errorMsg: fields && fields.cpBeneficiaryFormMissingDateOfBirthError.value,
      tooltip: '',
    },
    beneficiaryRelationshipType: {
      options: RELATIONSHIP_BENEFICIARY_FORM,
      condition: required,
      errorMsg: fields && fields.beneficiaryFormRelationshipTypeError.value,
    },
    residentialAddress: {
      condition: state.isManualResidential ? optional : required,
      errorMsg: fields && fields.cpBeneficiaryFormResidentialAddressError,
    },
    residentialAddressHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressHouseNoInvalidError.value'),
        },
      ],
    },
    residentialAddressPONo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressHouseNoInvalidError.value'),
        },
      ],
    },
    residentialAddressStreet: {
      condition: [
        !state.isPOManual && state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressStreetFieldError.value'),
            }
          : optional,
        {
          condition: state.isPOManual && state.isManualResidential ? /^$/ : manualAddressRegex,
          errorMsg:
            state.isPOManual && state.isManualResidential
              ? get(
                  fields,
                  'cpBeneficiaryFormResidentialAddressStreetInvalidFieldErrorIfPOBox.value'
                )
              : get(fields, 'cpBeneficiaryFormResidentialAddressStreetInvalidFieldError.value'),
        },
      ],
    },
    residentialAddressLocality: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressLocalityFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            fields,
            'cpBeneficiaryFormResidentialAddressLocalityInvalidFieldError.value'
          ),
        },
      ],
    },
    residentialAddressState: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressStateFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressInvalidStateFieldError.value'),
        },
      ],
      options: STATES,
    },
    residentialAddressCountry: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressCountryFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            fields,
            'cpBeneficiaryFormResidentialAddressCountryInvalidFieldError.value'
          ),
        },
      ],
    },
    residentialAddressPostCode: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'cpBeneficiaryFormResidentialAddressPostCodeFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressPostalCode,
          errorMsg: get(
            fields,
            'cpBeneficiaryFormResidentialAddressPostCodeInvalidFieldError.value'
          ),
        },
      ],
    },
    businessName: {},
  })
export default schema
