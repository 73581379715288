// @flow
import React from 'react'
import CustomerDetaisContainer from '../../../organisms/CustomerPersonalDetails'

type CustomerDetailsProps = {
  fields: Object,
  // sitecore rendering
  rendering: Object,
}

const CustomerDetail = ({ fields, rendering }: CustomerDetailsProps) => (
  <CustomerDetaisContainer fields={fields} rendering={rendering} />
)

export default CustomerDetail
