// @flow
import React from 'react'
import PremiumCalculatorQuoteComp from '../../../organisms/PremiumCalculator/PremiumCalculatorQuote'

type PremiumCalculatorQuoteProps = {
  fields: Object,
}

const PremiumCalculatorQuote = ({ fields }: PremiumCalculatorQuoteProps) => (
  <PremiumCalculatorQuoteComp fields={fields} />
)

export default PremiumCalculatorQuote
