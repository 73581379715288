// @flow
import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import styled from '@emotion/styled'
import { Heading, ErrorState, PageWrap } from '@mlcl-digital/mlcl-design'
import get from 'lodash/get'
// redux.
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'
// constants
import { MINIMUM_AMOUNT_LB_TO_SHOW_SPLIT_MODAL } from '../../../constants/customerBeneficiary'
import { THEME_LIGHT } from '../../../constants/themes'

// components.
import ErrorBoundary, { type ErrorInfo } from '../../molecules/ErrorBoundary'
import styles from './customerBeneficiary.styles'
import PolicyBeneficiaries from './Beneficiaries'
import {
  getFailedPoliciesLoaded,
  getInForcePoliciesBeneficariesMapping,
  getNonBindingPoliciesBeneficariesMapping,
  PolicyType,
  checkForErrorForCorruptedSeoAttributes,
} from '../../../selectors/customerBeneficiaries'
import { checkIfAllPoliciesLoaded } from '../../../selectors/common'
import SelectPoliciesToAddBeneficiary from './SelectPolicyModal'
import { PercentageAdjustmentContainer as PercentageAdjustment } from './PercentageAdjustment'
import { BeneficiaryFormContainer as BeneficiaryForm } from './BeneficiaryForm'
import { BenefeciaryFileUpload } from './FileUpload'
import SelectPoliciesToSplitPercentage from './ShowPolicyCards'
import Header from '../../molecules/PageHeader'
import { CardContainer } from '../../molecules/CardChildren'
import { CustomerPortalLifeCycleComponent } from '../../molecules/CustomerPortalLifeCycle'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'

const Wrap = styled(PageWrap)(styles.wrap)

const ErrorStateContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '24px',
})

const styleErrorState = {
  container: {
    padding: '24px',
  },
}
const PoliciesToRetry = () => {
  const dispatch = useDispatch()

  const policiesToRetry = useSelector(getFailedPoliciesLoaded)
  const retryPolicyLoad = useCallback(
    policyNo => {
      dispatch(actionCreators.fetchPolicy(policyNo))
    },
    [dispatch]
  )

  return policiesToRetry.map(policy => (
    <ErrorStateContainer key={policy.policyId}>
      <ErrorState
        styleOverrides={styleErrorState}
        message={`Policy ${policy.policyId} failed to load. Please try again later`}
        showBackground
        retryFunction={() => retryPolicyLoad(policy.policyNo)}
      />
    </ErrorStateContainer>
  ))
}

export const CustomerBeneficiaries = React.memo(props => {
  const {
    fields,
    actions,
    nonBindingPoliciesToPercentageSplit,
    policiesThatAllowAddingOfBeneficiaries,
    isBeneficiariesApplicable,
    showAddBeneficiariesButton,
    isPoliciesLoaded,
    showSplitPercentageButton,
    policiesBeneficiariesMapping,
    isUpdatingBeneficiary,
    areThereCorruptedSEOBeneficiaries,
  } = props
  const {
    cpHeading,
    cpContent,
    cpBeneficiaryBannerImageUrl,
    cpBeneficiaryBannerImageAlt,
    cpBeneficiariesBeneficiariesNotApplicableMessage,
  } = reduceAuthorableFields(fields)

  return (
    <>
      <Header
        theme={THEME_LIGHT}
        heading={{ value: cpHeading }}
        subHeading={{ value: cpContent }}
        imageSrc={cpBeneficiaryBannerImageUrl}
        imageAlt={cpBeneficiaryBannerImageAlt}
      >
        {showSplitPercentageButton && (
          <SelectPoliciesToSplitPercentage
            fields={fields}
            actions={actions}
            policies={nonBindingPoliciesToPercentageSplit}
          />
        )}
      </Header>
      <CustomerPortalLifeCycleComponent
        loadingConstraints={[!isPoliciesLoaded, isUpdatingBeneficiary]}
        failureConstraints={[areThereCorruptedSEOBeneficiaries]}
      >
        <Wrap>
          <PoliciesToRetry />
          <CardContainer>
            {isBeneficiariesApplicable ? (
              <>
                {policiesBeneficiariesMapping.map(policy => (
                  <PolicyBeneficiaries
                    key={`${policy.policyId}`}
                    policy={policy}
                    fields={fields}
                    actions={actions}
                  />
                ))}
                {showAddBeneficiariesButton && (
                  <SelectPoliciesToAddBeneficiary
                    policies={policiesThatAllowAddingOfBeneficiaries}
                    fields={fields}
                    actions={actions}
                  />
                )}
              </>
            ) : (
              isPoliciesLoaded && (
                <Heading variant="h6" size="xsmall">
                  {cpBeneficiariesBeneficiariesNotApplicableMessage}
                </Heading>
              )
            )}
          </CardContainer>
          <PercentageAdjustment fields={fields} actions={actions} />
          <BenefeciaryFileUpload fields={fields} />
          <BeneficiaryForm fields={fields} actions={actions} />
        </Wrap>
      </CustomerPortalLifeCycleComponent>
    </>
  )
})

CustomerBeneficiaries.propTypes = {
  nonBindingPoliciesToPercentageSplit: PropTypes.arrayOf(PolicyType).isRequired,
  policiesThatAllowAddingOfBeneficiaries: PropTypes.arrayOf(PolicyType).isRequired,
  isBeneficiariesApplicable: PropTypes.bool.isRequired,
  isPoliciesLoaded: PropTypes.bool.isRequired,
  showAddBeneficiariesButton: PropTypes.bool.isRequired,
  showSplitPercentageButton: PropTypes.bool.isRequired,
  policiesBeneficiariesMapping: PropTypes.arrayOf(PolicyType).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  areThereCorruptedSEOBeneficiaries: PropTypes.bool.isRequired,
}

const useActions = actions => {
  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch])
}

const CustomerBeneficiaryContainer = ({ fields }) => {
  const actions = useActions(actionCreators)

  const isPoliciesLoaded = useSelector(checkIfAllPoliciesLoaded)
  const inForcePoliciesBeneficariesMapping = useSelector(getInForcePoliciesBeneficariesMapping)

  const isBeneficiariesApplicable = inForcePoliciesBeneficariesMapping.length > 0

  const nonBindingPoliciesToPercentageSplit = useSelector(getNonBindingPoliciesBeneficariesMapping)
  const beneficiariesData = get(nonBindingPoliciesToPercentageSplit, '[0].beneficiariesData', [])
  // Show the button, when beneficiaries against a policy are greator than 1
  const showSplitPercentageButton =
    nonBindingPoliciesToPercentageSplit.length > 0 &&
    beneficiariesData.length > MINIMUM_AMOUNT_LB_TO_SHOW_SPLIT_MODAL

  const policiesThatAllowAddingOfBeneficiaries = inForcePoliciesBeneficariesMapping.filter(
    policy => policy.canAddBeneficiary
  )
  const showAddBeneficiariesButton = policiesThatAllowAddingOfBeneficiaries.length > 0

  const isUpdatingBeneficiary = useSelector(state => state.customerBeneficiary.isLoading)

  const areThereCorruptedSEOBeneficiaries = useSelector(checkForErrorForCorruptedSeoAttributes)

  const onError = (error: Error, info: ErrorInfo) => {
    // eslint-disable-next-line no-console
    console.log({ error, info })
  }

  const CustomFallbackUi = () => <div>An error was encountered</div>

  useEffect(() => {
    if (isPoliciesLoaded) {
      actions.fetchBatchRelatedParty('beneficiaries')
    }
    actions.resetFooterType()
  }, [isPoliciesLoaded])
  return (
    <ErrorBoundary onError={onError} fallbackComponent={CustomFallbackUi}>
      <CustomerBeneficiaries
        actions={actions}
        isPoliciesLoaded={isPoliciesLoaded}
        isBeneficiariesApplicable={isBeneficiariesApplicable}
        showAddBeneficiariesButton={showAddBeneficiariesButton}
        policiesBeneficiariesMapping={inForcePoliciesBeneficariesMapping}
        nonBindingPoliciesToPercentageSplit={nonBindingPoliciesToPercentageSplit}
        policiesThatAllowAddingOfBeneficiaries={policiesThatAllowAddingOfBeneficiaries}
        showSplitPercentageButton={showSplitPercentageButton}
        fields={fields}
        isUpdatingBeneficiary={isUpdatingBeneficiary}
        areThereCorruptedSEOBeneficiaries={areThereCorruptedSEOBeneficiaries}
      />
    </ErrorBoundary>
  )
}
CustomerBeneficiaryContainer.propTypes = {
  fields: PropTypes.objectOf(
    PropTypes.shape({
      value: oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
}

export default CustomerBeneficiaryContainer
