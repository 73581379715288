/* eslint-disable no-restricted-globals */
// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Table, Chip, Label, SectionHeader } from '@mlcl-digital/mlcl-design'
import propTypes from 'prop-types'
import styles from './policyActivity.styles'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../../utils/sitecoreUtils'
import { sortDate } from '../../../../utils/sortingUtils'

const Wrapper = styled('div')(styles.wrapper)
const ActivityMessage1Label = styled(Label)(styles.label)
const CorrespondenceHeading = styled(SectionHeader)(styles.sectionHeader)

export const PolicyActivity = ({ fields, policyActivity, isPolicyActivityLoading }) => {
  const {
    cpCustomerPolicyDetailsPolicyActivitySubHeading,
    cpCustomerPolicyDetailsPolicyActivityHeading,
    cpCustomerPolicyDetailsPolicyActivityRequestTypeHeading,
    cpCustomerPolicyDetailsPolicyActivityDateCreatedHeading,
    cpCustomerPolicyDetailsPolicyActivityNoDataMessage,
    AppActivityMessage1,
  } = reduceAuthorableFields(fields)
  const COLUMNS = [
    {
      Header: '',
      accessor: 'status',
      disableSortBy: true,
      styleOverrides: styles.status,
    },
    {
      Header: cpCustomerPolicyDetailsPolicyActivityRequestTypeHeading,
      id: 'requestType',
      accessor: 'requestType',
      styleOverrides: styles.requestType,
    },
    {
      Header: cpCustomerPolicyDetailsPolicyActivityDateCreatedHeading,
      id: 'dateCreated',
      styleOverrides: styles.dateCreated,
      accessor: 'dateCreated',
      sortType: sortDate('dateCreated', 'requestType'),
    },
  ]

  const formattedResult = data =>
    data.map(item => ({
      ...item,
      status: <Chip variant={item.status === 'closed' ? 'closed' : 'default'}>{item.status}</Chip>,
    }))

  return (
    <Wrapper>
      <CorrespondenceHeading
        heading={cpCustomerPolicyDetailsPolicyActivityHeading}
        subHeading={cpCustomerPolicyDetailsPolicyActivitySubHeading}
        SectionMarginBottom={!policyActivity || policyActivity.length === 0}
      />
      {policyActivity && policyActivity.length > 0 && (
        <>
          <ActivityMessage1Label size="lg">
            {renderTextField(AppActivityMessage1, true)}
          </ActivityMessage1Label>
        </>
      )}
      <Table
        defaultSorted={[
          {
            id: 'dateCreated',
            desc: true,
          },
        ]}
        classes="table-row"
        data={formattedResult(policyActivity)}
        columns={COLUMNS}
        noDataCopy={cpCustomerPolicyDetailsPolicyActivityNoDataMessage}
        isLoading={isPolicyActivityLoading}
      />
    </Wrapper>
  )
}
const { arrayOf, object, shape, bool } = propTypes
PolicyActivity.propTypes = {
  fields: shape({
    cpCustomerPolicyDetailsPolicyActivitySubHeading: object,
    cpCustomerPolicyDetailsPolicyActivityHeading: object,
    cpCustomerPolicyDetailsPolicyActivityRequestTypeHeading: object,
    cpCustomerPolicyDetailsPolicyActivityDateCreatedHeading: object,
    cpCustomerPolicyDetailsPolicyActivityNoDataMessage: object,
  }).isRequired,
  policyActivity: arrayOf(object).isRequired,
  isPolicyActivityLoading: bool.isRequired,
}

export default PolicyActivity
