import 'core-js/es7/array'
import { UPDATE_RELATIONSHIP_FROM_POLICY } from '../types/customerRelationships'

export const handleRelationshipUpdate = data => dispatch => {
  const {
    lifeAssured = [],
    agents = [],
    payers = [],
    policyOwners = [],
    iet = [],
    beneficiaries = [],
    bancsPolicyNo,
    policyId,
    benefits = [],
  } = data.businessData.policy

  const formattedLifeAssured = lifeAssured.map(LA => ({
    ...LA,
    benefitMeta: benefits
      .flatMap(benefit =>
        benefit.benefitAssured.map(assured => ({
          ...assured,
          type: benefit.type,
          benefitStatus: benefit.benefitStatus,
        }))
      )
      .filter(benefitAssured => benefitAssured.bancsCustomerNo === LA.bancsCustomerNo),
  }))

  dispatch({
    type: UPDATE_RELATIONSHIP_FROM_POLICY,
    payload: [
      ...formattedLifeAssured,
      ...agents,
      ...payers,
      ...policyOwners,
      ...iet,
      ...beneficiaries,
    ].map(relationship => ({ ...relationship, bancsPolicyNo, policyId })),
  })
}
