// @flow
import React from 'react'
import AddAuthorityComponent from '../../../organisms/AddAuthority'

type CustomerDetailsProps = {
  fields: Object,
  // sitecore rendering
  rendering: Object,
}

const AddAuthority = ({ fields, rendering }: CustomerDetailsProps) => (
  <AddAuthorityComponent fields={fields} rendering={rendering} />
)

export default AddAuthority
