import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontWeights, fontSize, fontFamily } = Variables
const styles = {
  tfoot: {
    td: {
      padding: `${space(2, true)} ${space(5, true)}`,
    },
  },
  benefit: {
    display: 'flex',
    flexDirection: 'column',
    color: colours.black,
    fontWeight: fontWeights.semiBold,
  },
  badge: {
    background: colours.lightGreen,
    color: colours.mlcAquaAccessible,
    fontWeight: fontWeights.light,
    padding: `0 ${space(1, true)}`,
    margin: `0 ${space(1, true)}`,
    borderRadius: '3px',
    opacity: '1',
  },
  parentBenefit: {
    opacity: '1',
    color: colours.mlcAquaAccessible,
    fontWeight: fontWeights.light,
  },
  benefitWithLinking: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minHeight: space(6, true),
  },
  appliedDiscount: {
    fontSize: fontSize.xxs,
    lineHeight: space(2.5, true),
    color: colours.tertiaryOne,
    fontWeight: fontWeights.light,
  },
  associatedBenefit: {
    fontWeight: fontWeights.light,
    minHeight: space(9, true),
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    maxWidth: '30%',
  },
  childName: {
    fontWeight: fontWeights.normal,
  },
  infoMessage: {
    fontSize: fontSize.xs,
    color: colours.functionalWarningDark,
  },
  infoIconSRPolicy: {
    color: colours.functionalWarningDark,
  },
  infoMessageSpanPolicy: {
    paddingRight: space(1, true),
    fontSize: fontSize.xxs,
  },
  tooltip: {
    fontFamily: fontFamily.sourceSans,
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.normal,
    lineHeight: 1.3,
  },
  occupationRating: {
    fontSize: fontSize.xxs,
    lineHeight: space(2, true),
    color: colours.tertiaryTwo,
    fontWeight: fontWeights.light,
    margin: `${space(0.2, true)} 0`,
  },
}

export default styles
