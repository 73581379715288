// @flow
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Variables, Button } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../utils/telemetry'

// components
import Modal from '../../../molecules/Modal'

// utils
import { renderTextField, reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { updateActionInBeneficiary } from '../../../../utils/customerBeneficiaryUtils'
import { PolicyType } from '../../../../selectors/customerBeneficiaries'
// styles
import styles from './showPolicyCards.styles'

const { colours, space } = Variables

const PolicyButton = styled(Button)({
  borderColor: colours.tertiarySix,
  color: colours.tertiarySix,
  margin: space(1),
  '&:hover, :active, :focus': {
    background: colours.tertiarySix,
    borderColor: colours.tertiaryOne,
    color: colours.tertiaryOne,
  },
  ':focus': {
    borderColor: colours.tertiarySix,
  },
})
const PolicyContainer = styled.div(styles.policyContainer)
export const PolicyWrapper = styled.div(styles.policyWrapper)
const Policy = styled.div(styles.policy)
const LifeInsured = styled.div(styles.lifeInsured)
const PolicyDetails = styled.div(styles.policyDetails)

class ShowPolicyCards extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isPoliciesModelOpen: false,
    }
  }

  onSubmit = () => {
    this.setState({ isPoliciesModelOpen: true })
    const tagEvent = createEvent({
      GA: { category: 'Customer select Beneficiary Adjust percentage split', action: 'Select' },
      Splunk: {
        attributes: {
          'workflow.name': 'Customer select Beneficiary Adjust percentage split',
        },
      },
    })
    tagEvent.end()
  }

  handleClose = () => {
    this.setState({ isPoliciesModelOpen: false })
  }

  handlePolicyClick = (bancsPolicyNo, beneficiaries) => () => {
    const {
      actions: {
        updateRelationshipsForCustomerBeneficiaries,
        updateBeneficiariesFormForUpdate,
        showPercentageAdjustmentModal,
      },
    } = this.props

    const benefeciariesWithActions = updateActionInBeneficiary(beneficiaries)
    updateBeneficiariesFormForUpdate({
      benificiaryEntities: benefeciariesWithActions,
      bancsPolicyNo,
    })
    showPercentageAdjustmentModal(true)
    updateRelationshipsForCustomerBeneficiaries(benefeciariesWithActions)
    this.handleClose()
  }

  renderPoliciesTab = (policy, policyIndex) => {
    const { fields } = this.props
    const { cpPolicyModalLifeInsuredText } = fields
    const { bancsPolicyNo, policyName, policyId } = policy

    return (
      <PolicyWrapper
        data-locator={`beneficiary-policy-${policyIndex}`}
        onClick={this.handlePolicyClick(bancsPolicyNo, policy.beneficiariesData)}
        key={`${policyId}-${policyIndex}`}
      >
        <Policy>
          <div>{policyName}</div>
        </Policy>
        <LifeInsured>
          <PolicyDetails>
            {renderTextField(cpPolicyModalLifeInsuredText)} {policyId}
          </PolicyDetails>
        </LifeInsured>
      </PolicyWrapper>
    )
  }

  render() {
    const { isPoliciesModelOpen } = this.state

    const {
      policies = [],
      fields: {
        cpCustomerPolicyModalSubTitle,
        cpCustomerPolicyModalSinglePolicySubTitle,
        cpViewPercentageSplitButtonLabel,
      },
      fields,
    } = this.props

    const { cpCustomerPolicyPercentageSplitModalTitle } = reduceAuthorableFields(fields)
    return (
      <Fragment>
        <PolicyButton variant="secondary" onClick={this.onSubmit}>
          {renderTextField(cpViewPercentageSplitButtonLabel)}
        </PolicyButton>

        <Modal
          isOpen={isPoliciesModelOpen}
          onClose={this.handleClose}
          title={cpCustomerPolicyPercentageSplitModalTitle}
        >
          {policies && (
            <p>
              {renderTextField(
                policies.length > 1
                  ? cpCustomerPolicyModalSubTitle
                  : cpCustomerPolicyModalSinglePolicySubTitle
              )}
            </p>
          )}
          <PolicyContainer>
            {policies.map((policy, index) => this.renderPoliciesTab(policy, index))}
          </PolicyContainer>
        </Modal>
      </Fragment>
    )
  }
}

ShowPolicyCards.propTypes = {
  actions: PropTypes.shape({
    updateRelationshipsForCustomerBeneficiaries: PropTypes.func,
    updateBeneficiariesFormForUpdate: PropTypes.func,
    showPercentageAdjustmentModal: PropTypes.func,
  }).isRequired,
  fields: PropTypes.shape({
    cpPolicyModalLifeInsuredText: PropTypes.object,
    cpCustomerPolicyModalSubTitle: PropTypes.object,
    cpCustomerPolicyModalSinglePolicySubTitle: PropTypes.object,
    cpViewPercentageSplitButtonLabel: PropTypes.object,
  }).isRequired,
  policies: PropTypes.arrayOf(PolicyType).isRequired,
}

export default ShowPolicyCards
