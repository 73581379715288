import React from 'react'
import styled from '@emotion/styled'
import { Heading, Chip } from '@mlcl-digital/mlcl-design'
import Cover from '../Cover'
import { PolicyProps } from '../../../../../types/components/PremiumCalculator'
import styles from './policy.styles'

const PolicyHeading = styled(Heading)(styles.heading)

const Policy = ({ fields, policy, covers }: PolicyProps) => (
  <div>
    <PolicyHeading variant="h3" size="xsmall">
      {fields.Policy?.value.replace('##', policy.policyNo)}
      <Chip variant={policy.policyTypeChipVariant}>{policy.policyType}</Chip>
    </PolicyHeading>
    {covers.map(cover => (
      <Cover key={cover.benefitInstanceNo} fields={fields} cover={cover} policy={policy} />
    ))}
  </div>
)

export default Policy
