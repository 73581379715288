// @flow
import React from 'react'
import styled from '@emotion/styled'
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import styles from './accordion.styles'

// icons
import { IconTick16, IconChevronDown32, IconChevronUp32 } from '../../../../atoms/Icons'

// components
import BaseAccordion from '../../../../molecules/Accordion'
import Heading from '../../../../atoms/Heading'
import Button from '../../../../atoms/Button'

// data locators
import {
  LOCATOR_CONTINUE_BUTTON,
  LOCATOR_ACCORDION_CLOSE_BUTTON,
  LOCATOR_ACCORDION_OPEN_BUTTON,
} from '../../personalStatement.locators'

const Wrapper = styled('div')(styles.wrapper)
const AccordionContainer = styled('article')(styles.accordion)
const Label = styled(Heading)(styles.label)
const AccordionIcon = styled('span')(styles.accordionIcon)
const ContinueSection = styled('div')(styles.continueSection)

type AccordionProps = {
  fields: Object,
  children: Node,
  label: string,
  checked: boolean,
  continueHandler: Function,
  isOpen: boolean,
  labelKey: string,
  disableToggleIcon: boolean,
  disableScrollContent: boolean,
  isUpdated?: boolean,
  toggleListener: Function,
  resetOverflow: Boolean,
  disableContinue?: Boolean,
}

const renderLabel = (label, checked = false) => {
  const Status = styled('span')(styles.status(checked))

  return (
    <Label size={3}>
      <Status>{checked ? <IconTick16 /> : null}</Status>
      {label}
    </Label>
  )
}

const renderIcon = type => {
  let Icon = <IconChevronUp32 data-locator={LOCATOR_ACCORDION_CLOSE_BUTTON} />
  if (type === 'open') {
    Icon = <IconChevronDown32 data-locator={LOCATOR_ACCORDION_OPEN_BUTTON} />
  }
  return <AccordionIcon>{Icon}</AccordionIcon>
}

class Accordion extends React.PureComponent<AccordionProps> {
  constructor(props) {
    super(props)
    this.Children = styled('div')(styles.children(props.disableScrollContent, props.resetOverflow))
  }

  render() {
    const {
      fields,
      checked,
      label,
      children,
      continueHandler,
      isOpen,
      labelKey,
      disableToggleIcon,
      isUpdated,
      toggleListener,
      disableContinue,
    } = this.props
    const { Children } = this

    return (
      <Wrapper>
        <AccordionContainer>
          <BaseAccordion
            label={renderLabel(label, checked)}
            openIcon={disableToggleIcon ? null : renderIcon('open')}
            closeIcon={disableToggleIcon ? null : renderIcon('close')}
            isOpen={isOpen}
            labelKey={labelKey}
            isUpdated={isUpdated}
            toggleListener={toggleListener}
          >
            <Children>{children}</Children>
            {!disableContinue && (
              <ContinueSection>
                <Button onClick={continueHandler} data-locator={LOCATOR_CONTINUE_BUTTON}>
                  {renderTextField(fields.personalStatementNextButton)}
                </Button>
              </ContinueSection>
            )}
          </BaseAccordion>
        </AccordionContainer>
      </Wrapper>
    )
  }
}

Accordion.defaultProps = {
  isUpdated: false,
  disableContinue: false,
}

export default Accordion
