// @flow
import React, { useState } from 'react'

// components
import PersonalStatementLayout from '../../../molecules/PersonalStatementLayout'
import CompletionMethodComponent from '../../../organisms/CompletionMethod'

type MethodOfCompletionProps = {
  fields: Object<Object>,
}

const MethodOfCompletion = ({ fields }: MethodOfCompletionProps) => {
  const [isFilledBackground, filledLayoutBackground] = useState(true)
  const [pageTitle, setPageTitle] = useState('')
  const [pageSubTitle, setPageSubTitle] = useState('')

  return (
    <PersonalStatementLayout
      fields={fields}
      fillChildBackground={isFilledBackground}
      pageTitle={pageTitle}
      pageSubTitle={pageSubTitle}
    >
      <CompletionMethodComponent
        fields={fields}
        setBackground={filledLayoutBackground}
        setPageTitle={setPageTitle}
        setPageSubTitle={setPageSubTitle}
      />
    </PersonalStatementLayout>
  )
}

export default MethodOfCompletion
