// @flow
import React, { Fragment } from 'react'
import { object, objectOf, func, shape, string, bool, array } from 'prop-types'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useDispatch, useSelector } from 'react-redux'
import { Chip, Button, ErrorState } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../../utils/telemetry'

// Selectors
// eslint-disable-next-line max-len
import {
  getPoliciesAndPaymentDetails,
  shouldPaymentDetailsPageShowLoader,
} from '../../../../../selectors/customerPaymentDetails'

// Molecules
import {
  Card,
  CardDivide,
  CardTable,
  CardCell,
  CardContainer,
} from '../../../../molecules/CardChildren'

// Actions
import { actionCreators } from '../../../../../actions'

// contants
import {
  CREDIT_CARD_ID,
  DIRECT_DEBIT_ID,
  BPAY,
  CHEQUE,
  MLC_ACC,
  ROLL_OVER_ID,
  NAVIGATOR,
  FUND_PAYMENT_METHODS_IOOF,
} from '../../../../../constants/policies'
import { FORM_ID } from './paymentDetails.schema'

const RenderPaymentDetailsCard = ({
  policy,
  handlePayerModalToggle,
  handleFileModalToggle,
  fields,
}) => {
  const {
    policydetailsPaymentMethodCardNumber,
    cpPolicyPaymentDetailsNameOnCard,
    cpPolicyPaymentDetailsExpiryDate,
    cpPolicyPaymentDetailsAccountName,
    cpPolicyPaymentDetailsAccountNumber,
    cpPolicyPaymentDetailsBSB,
    cpPolicyPaymentDetailsUSI,
    cpPolicyPaymentDetailsFundName,
    cpPolicyPaymentDetailsABN,
    cpPolicyPaymentDetailsProductName,
    cpPolicyPaymentDetailsMembershipNumber,
    cpPolicyPaymentDetailsMasterKeyAccountNumber,
    customerEditPaymentDetailsHeadingLabel,
  } = fields
  const {
    policyType,
    subHeadingText,
    mainHeadingText,
    bancsPolicyNo,
    policyPremiumValue,
    paymentMethod,
    collectionFrequency,
    sourceFundingSystem,
    collectionMethod,
    paymentDetails,
    isSuper,
    shouldShowForm,
    hasFailed,
  } = policy

  const paymenDetailsLabelMap = {
    [CREDIT_CARD_ID]: [
      cpPolicyPaymentDetailsNameOnCard,
      policydetailsPaymentMethodCardNumber,
      cpPolicyPaymentDetailsExpiryDate,
    ],
    [DIRECT_DEBIT_ID]: [
      cpPolicyPaymentDetailsAccountName,
      cpPolicyPaymentDetailsAccountNumber,
      cpPolicyPaymentDetailsBSB,
    ],
    [BPAY]: () => [],
    [CHEQUE]: () => [],
    [ROLL_OVER_ID]: [
      cpPolicyPaymentDetailsUSI,
      cpPolicyPaymentDetailsFundName,
      cpPolicyPaymentDetailsABN,
      cpPolicyPaymentDetailsProductName,
      cpPolicyPaymentDetailsMembershipNumber,
    ],
    [MLC_ACC]: [...FUND_PAYMENT_METHODS_IOOF, NAVIGATOR].includes(sourceFundingSystem)
      ? [cpPolicyPaymentDetailsAccountNumber]
      : [cpPolicyPaymentDetailsMasterKeyAccountNumber],
  }
  const dispatch = useDispatch()
  const { formResetField, setPolicyForPaymentDetailModal } = actionCreators
  const paymentDetailFields = paymenDetailsLabelMap[collectionMethod]

  return hasFailed ? (
    <ErrorState message={`Failed to load payment details for policy ${subHeadingText}`} />
  ) : (
    <Card
      key={`Policy #${subHeadingText}`}
      header={
        <>
          <Chip variant={isSuper ? 'default' : 'important'}>{policyType}</Chip>
          <div>
            <span>{`Policy #${subHeadingText}`}</span>
          </div>
          <div>{mainHeadingText}</div>
        </>
      }
      footer={
        <>
          <Button
            variant="secondary"
            size="small"
            onClick={() => {
              const tagEvent = createEvent({
                GA: {
                  category: 'Policy card',
                  action: 'Edit payment details',
                },
                Splunk: {
                  attributes: {
                    'workflow.name': 'Policy card - Edit payment details',
                  },
                },
              })
              tagEvent.end()
              dispatch(setPolicyForPaymentDetailModal(bancsPolicyNo))
              if (shouldShowForm) {
                handleFileModalToggle()
              } else {
                handlePayerModalToggle()
                dispatch(formResetField(FORM_ID, ['paymentDetails']))
              }
            }}
          >
            <Text field={customerEditPaymentDetailsHeadingLabel} />
          </Button>
        </>
      }
    >
      <CardDivide>
        <div>
          Payment Method
          <br />
          {paymentMethod || '-'}
        </div>
        <div>
          <>
            {collectionFrequency}
            <br />
            {policyPremiumValue}
          </>
        </div>
      </CardDivide>

      {paymentDetails && paymentDetails.length > 0 && (
        <CardTable>
          {paymentDetails.map((value, index) => (
            <Fragment key={`${value}`}>
              <CardCell>
                <Text field={paymentDetailFields[index]} />
              </CardCell>
              <CardCell>{value}</CardCell>
            </Fragment>
          ))}
        </CardTable>
      )}
    </Card>
  )
}

RenderPaymentDetailsCard.propTypes = {
  policy: shape({
    policyType: string,
    subHeadingText: string,
    policyName: string,
    bancsPolicyNo: string,
    policyPremiumValue: string,
    paymentMethod: string,
    collectionFrequency: string,
    sourceFundingSystem: string,
    collectionMethod: string,
    paymentDetails: array,
    isSuper: bool,
    shouldShowForm: bool,
  }).isRequired,
  handlePayerModalToggle: func.isRequired,
  handleFileModalToggle: func.isRequired,
  fields: objectOf(object).isRequired,
}

const PaymentDetails = ({ handlePayerModalToggle, handleFileModalToggle, fields }) => {
  const policies = useSelector(getPoliciesAndPaymentDetails)
  const isLoading = useSelector(shouldPaymentDetailsPageShowLoader)
  return (
    <CardContainer>
      {(isLoading ? [] : policies).map(policy => (
        <RenderPaymentDetailsCard
          key={policy.bancsPolicyNo}
          policy={policy}
          handlePayerModalToggle={handlePayerModalToggle}
          handleFileModalToggle={handleFileModalToggle}
          fields={fields}
        />
      ))}
    </CardContainer>
  )
}

PaymentDetails.propTypes = {
  handlePayerModalToggle: func.isRequired,
  handleFileModalToggle: func.isRequired,
  fields: objectOf(object).isRequired,
}
export default PaymentDetails
