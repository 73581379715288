import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontFamily, pxToRem } = Variables as VariablesType

const anchorStyles = {
  color: colours.mlcAquaAccessible,
  textDecoration: 'none',
}

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(3),
    a: {
      ...anchorStyles,
    },
  },
  downloadIcon: {
    marginRight: space(1),
  },
  quoteSection: {
    background: '#F5FCFC',
    padding: space(5),
    marginBottom: space(5),
  },
  policyList: {
    listStyle: 'none',
    border: `1px solid ${colours.tertiaryFour}`,
    background: colours.tertiarySix,
    padding: space(3),
    marginBottom: space(5),
    marginTop: space(2.5),
    color: colours.tertiaryOne,
    li: {
      marginBottom: space(2.5),
      display: 'flex',
      justifyContent: 'space-between',
      p: {
        marginBottom: 0,
        '&:nth-of-type(2)': {
          'span:first-of-type': {
            marginRight: space(2.5),
          },
          fontWeight: fontWeights.semiBold,
        },
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
  downloadButton: {
    width: '100%',
    marginBottom: space(5),
  },
  formInputPhone: {
    marginTop: space(2.5),
    marginBottom: space(2.5),
    '.mlc__control': {
      background: colours.tertiarySix,
    },
    'input[name="preferredContactNumber"]': {
      background: colours.tertiarySix,
    },
  },
  submitButton: {
    marginTop: space(3),
    width: '100%',
  },
  backButton: {
    minWidth: '150px',
    marginTop: space(4),
  },
  preferredTimeFieldSet: {
    border: 0,
    margin: 0,
    padding: 0,
    legend: {
      color: colours.tertiaryOne,
      fontWeight: fontWeights.semiBold,
      marginBottom: space(1),
    },
  },
  preferredTimeRadio: {
    wrapper: {
      marginRight: space(9),
    },
  },
  heading: {
    fontFamily: fontFamily.sourceSans,
  },
  disclaimer: {
    fontSize: pxToRem(14),
    color: colours.tertiaryTwo,
    marginTop: space(4),
    '> div:first-of-type': {
      fontWeight: fontWeights.semiBold,
      marginBottom: space(2),
    },
    '& a': {
      ...anchorStyles,
    },
  },
  quoteDescription: {
    color: colours.tertiaryOne,
    a: anchorStyles,
  },
}

export default styles
