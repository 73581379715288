// @flow
import React from 'react'

// components.
import CustomerYourAdviser from '../../../organisms/CustomerYourAdviser'

type YourAdviserProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const YourAdviser = ({ fields }: YourAdviserProps) => <CustomerYourAdviser fields={fields} />

export default YourAdviser
