import React, { useCallback, memo, useEffect } from 'react'
import { bool, func, objectOf, object } from 'prop-types'
import styled from '@emotion/styled'
import { Button, Loader } from '@mlcl-digital/mlcl-design'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useSelector, useDispatch } from 'react-redux'
import { createEvent } from '../../../../../../utils/telemetry'

// Selectors
import {
  getPaymentDetailFormType,
  editPaymentMethodRequestMeta,
  getPaymentOptions,
  getActivePaymentInstrument,
  getActivePolicy,
} from '../../../../../../selectors/customerPaymentDetails'

// Redux
import { actionCreators } from '../../../../../../actions'

// Utils
import { errorCheck } from '../../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'

// Constants
import {
  CREDIT_CARD_ID,
  DIRECT_DEBIT_ID,
  ROLL_OVER_ID,
  MASTER_KEY_ACCOUNT_ID,
  WRAPPER_NAVIGATOR_ID,
} from '../../../../../../constants/policies'
// Atoms
import Select from '../../../../../atoms/Select'

// Molecules
import Modal from '../../../../../molecules/Modal'

// Sub components
import CreditCardComponent from './components/CreditCard'
import DirectDebitComponent from './components/DirectDebit'
import RolloverPaymentComponent from './components/RolloverPayment'
import MasterKeyPaymentComponent from './components/MasterKey'

// Schema
import schema, { FORM_ID } from '../../PaymentDetails/paymentDetails.schema'
import custCreditCardSchema, {
  FORM_ID as CREDIT_CARD_FORM,
} from './components/CreditCard/creditCard.schema'

import custDirectDebitSchema, {
  FORM_ID as DIRECT_DEBIT_FORM,
} from './components/DirectDebit/directDebit.schema'

import custRolloverPaymentSchema, {
  FORM_ID as ROLLOVER_PAYMENT_FORM,
} from './components/RolloverPayment/rolloverPayment.schema'

import custMasterKeySchema, {
  FORM_ID as MASTER_KEY_PAYMENT_FORM,
} from './components/MasterKey/masterKey.schema'

import styles from './paymentDetailsModal.styles'

const PaymentType = styled(Select)(styles.paymentType)
const Spinner = styled(Loader)(styles.spinner)
const NextButton = styled(Button)(styles.nextButton)
const Paragraph = styled('p')()
const NoteLink = styled('a')(styles.noteLink)

const paymentDetailsFormMap = fields => ({
  [CREDIT_CARD_ID]: {
    component: CreditCardComponent,
    schema: custCreditCardSchema({ fields }),
    form: CREDIT_CARD_FORM,
  },
  [DIRECT_DEBIT_ID]: {
    component: DirectDebitComponent,
    schema: custDirectDebitSchema({ fields }),
    form: DIRECT_DEBIT_FORM,
  },
  [ROLL_OVER_ID]: {
    component: RolloverPaymentComponent,
    schema: custRolloverPaymentSchema({ fields }),
    form: ROLLOVER_PAYMENT_FORM,
  },
  [WRAPPER_NAVIGATOR_ID]: {
    component: MasterKeyPaymentComponent,
    schema: custMasterKeySchema({ fields }),
    form: MASTER_KEY_PAYMENT_FORM,
  },
  [MASTER_KEY_ACCOUNT_ID]: {
    component: MasterKeyPaymentComponent,
    schema: custMasterKeySchema({ fields }),
    form: MASTER_KEY_PAYMENT_FORM,
  },
})

const LinkToFormPayment = memo(({ fields, handleFileUploadModalToggle, activeBancsPolicyNo }) => {
  const { cpEditPaymentCreditCardPaymentDateNoteLink } = reduceAuthorableFields(fields)
  const { cpEditPaymentCreditCardPaymentDateNote } = fields
  return (
    <Paragraph>
      <Text field={cpEditPaymentCreditCardPaymentDateNote} />
      <NoteLink href="#" onClick={() => handleFileUploadModalToggle(activeBancsPolicyNo)}>
        {cpEditPaymentCreditCardPaymentDateNoteLink}
      </NoteLink>
    </Paragraph>
  )
})

const PaymentDetailsModal = ({
  fields,
  isModalOpen,
  closeModal,
  handleAction,
  handleFileUploadModalToggle,
}) => {
  const dispatch = useDispatch()
  const formType = useSelector(getPaymentDetailFormType)
  const { isFetchingCreditCardInfo, isFetchingNabData } = useSelector(
    state => state.captureCreditCard
  )
  const paymentOptions = useSelector(getPaymentOptions)
  const paymentInstrument = useSelector(getActivePaymentInstrument)
  const creditCardForm = useSelector(state => state.forms[CREDIT_CARD_FORM])
  const editPaymentMethodRequest = useSelector(editPaymentMethodRequestMeta)
  const activeBancsPolicyNo = useSelector(getActivePolicy)

  const {
    cpEditPolicyPaymentDetailPaymentTypePlaceholder,
    cpEditPolicyPaymentDetailModelNextButton,
  } = reduceAuthorableFields(fields)

  const paymentDetailsMeta = paymentDetailsFormMap(fields)[formType] || {}
  const PaymentDetailsComponent = paymentDetailsMeta.component || null

  const disableButton = isFetchingCreditCardInfo || isFetchingNabData

  useEffect(
    () => () => {
      const { formResetField } = actionCreators
      dispatch(formResetField(MASTER_KEY_PAYMENT_FORM, 'accountNumber'))
    },
    []
  )

  const handleNext = useCallback(() => {
    const formID = paymentDetailsMeta.form
    const formSchema = paymentDetailsMeta.schema
    const { fetchCreditCardInfo, formUpdateValidations, formSubmit } = actionCreators

    const tagEvent = createEvent({
      GA: {
        category: 'Payment detail edit modal',
        action: 'CP - edit payment details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Payment detail edit modal - Next',
        },
      },
    })
    tagEvent.end()

    if (formID === CREDIT_CARD_FORM) {
      dispatch(formUpdateValidations(formID, formSchema))
      if (creditCardForm.isValid) {
        // showSuccessToast is for not to show success toast on card detail update,
        // because we are showing success toast in declaration model also it is coming two time
        const showSuccessToast = false
        return dispatch(
          fetchCreditCardInfo(response => {
            if (response && response.captureCardInfoIsSuccess) {
              dispatch(
                formSubmit(formID, formSchema, () => {
                  handleAction(null)
                })
              )
            }
          }, showSuccessToast)
        )
      }
    }

    return dispatch(
      formSubmit(formID, formSchema, () => {
        handleAction(editPaymentMethodRequest)
      })
    )
  }, [paymentDetailsMeta.form, paymentDetailsMeta.schema])

  const handleChange = useCallback(({ value, name }) => {
    const { formUpdateField, formValidate } = actionCreators
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    dispatch(formUpdateField(FORM_ID, name, data))
    dispatch(formValidate(FORM_ID, schema))
  }, [])

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} title="Edit payment details">
      <PaymentType
        data-locator="paymentDetails_dd"
        label="Payment Method"
        value={formType}
        name="paymentDetails"
        id="paymentDetails"
        changeHandler={handleChange}
        placeholder={cpEditPolicyPaymentDetailPaymentTypePlaceholder}
        options={paymentOptions}
      />
      {PaymentDetailsComponent && (
        <div data-testid="payment-details-component">
          <PaymentDetailsComponent
            fields={fields}
            paymentInstruction={paymentInstrument}
            nextButton={
              <div>
                <NextButton variant="secondary" onClick={handleNext} disabled={disableButton}>
                  {isFetchingCreditCardInfo || isFetchingNabData ? (
                    <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
                  ) : (
                    cpEditPolicyPaymentDetailModelNextButton
                  )}
                </NextButton>
              </div>
            }
          />
          {formType && (
            <LinkToFormPayment
              fields={fields}
              handleFileUploadModalToggle={handleFileUploadModalToggle}
              activeBancsPolicyNo={activeBancsPolicyNo}
            />
          )}
        </div>
      )}
    </Modal>
  )
}

PaymentDetailsModal.propTypes = {
  fields: objectOf(object).isRequired,
  isModalOpen: bool.isRequired,
  closeModal: func.isRequired,
  handleAction: func.isRequired,
  handleFileUploadModalToggle: func.isRequired,
}

export default PaymentDetailsModal
