// @flow
import React from 'react'
import CustomerPoliciesContainer from '../../../organisms/CustomerPolicies'

type CustomerPoliciesProps = {
  // Sitecore authorable fields.
  fields: Object,
}

const CustomerPolicies = ({ fields }: CustomerPoliciesProps) => (
  <CustomerPoliciesContainer fields={fields} />
)

export default CustomerPolicies
