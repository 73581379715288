import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontWeights } = Variables
const styles = {
  articleCardWrapper: {
    background: colours.tertiarySix,
    padding: 0,
  },
  articleFooter: {
    background: colours.tertiarySix,
    cursor: 'pointer',
    flexGrow: 1,
    padding: `${space(3, true)} ${space(4, true)}`,
  },
  articleFooterText: {
    fontSize: space(2.5),
    textAlign: 'left',
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
  },
  articleImage: {
    width: space(47, true),
    height: space(24, true),
    maxInlineSize: '100%',
    blockSize: 'auto',
    objectFit: 'cover',
  },
}

export default styles
