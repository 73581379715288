import { createSelector } from 'reselect'
import moment from 'moment'
import {
  PreviewPolicy,
  PreviewQuote,
  PremiumCalculatorQuotePreviewFields,
  Cover as CoverType,
} from '../types/components/PremiumCalculator'
import { Cover } from '../types/createQuote'

// selectors
import { getAlterations, getPolicyStructure } from './common.selectors'

// utils
// @ts-expect-error file not in typescript
import { getPolicyTypeLabel } from '../utils/policyUtils'
import {
  healthyLivingDiscountVal,
  getPolicyHasDiscountAndAmount,
  // @ts-expect-error file not in typescript
} from '../utils/extendedQuoteUtils'
// @ts-expect-error file not in typescript
import { formatCurrency } from '../utils/quoteUtils'
// @ts-expect-error file not in typescript
import { sortResultsWithOrderList } from '../utils/sortingUtils'
import {
  getSectionDetails,
  getOptionDetails,
  getPaymentDetails,
  getExistingAndUpdatedPaymentFrequency,
} from '../utils/premiumCalculator'
// @ts-expect-error file not in typescript
import { getPaymentFrequencyOptions } from '../utils/paymentUtils'

// constants
import {
  INSIDE_SUPER,
  POLICY_BENEFIT_PREMIUM_WAIVER,
  POLICY_BENEFIT_TISO,
  PREMIUM_CALCULATOR_SUB_BENEFITS,
  POLICY_FREQUENCY_ANNUAL,
  PREMIUM_CALCULATOR_BENEFITS_SORT_LIST,
  POLICY_BENEFIT_PTD,
  PREMIUM_CALCULATOR_HIDE_OPTIONS,
  MULTI_COVER_DISCOUNT,
} from '../constants/policies'
import { BENEFITS_SHOWN_AS_OPTIONS } from '../constants/benefit'
import { PAYMENT_FREQUENCY_MULTIPLIER } from '../constants/forms'

import {
  BENEFIT_ALTS_TYPE_REMOVE,
  DECREASED,
  INCREASED,
  REMOVE_BENEFIT,
  SYSTEM_GENERATED_REASON,
} from '../constants/alterations'
import { ROLLOVER_FROM_EXTERNAL_FUND } from '../constants/customerPaymentDetails'

export const getPremiumCalculatorQuotePreviewData = createSelector(
  [
    getPolicyStructure,
    getAlterations,
    (state, fields: PremiumCalculatorQuotePreviewFields) => fields,
  ],
  (policyStructure, alterations, fields) => {
    const premiumCalculatorPreviewQuote: PreviewQuote[] = []
    policyStructure.forEach(policy => {
      const {
        totalPremiumAmount,
        stampDuty,
        rebateAmount,
        policyFeeValue,
        policyNo,
        productClass,
        covers,
        productId,
        paymentFrequency,
        premiumPaymentMethod,
        bancsPolicyNo: internalPolicyNo,
        policyInstanceNo,
      } = policy
      const policyAlteration = policy?.alteration
      const policyType = getPolicyTypeLabel(productClass)
      const filterCovers = covers.filter(({ type }) => !BENEFITS_SHOWN_AS_OPTIONS.includes(type))

      // Adding the premiums of all the covers to be displayed as total Premiums
      const totalPremium = covers
        .filter(({ type }) => POLICY_BENEFIT_PREMIUM_WAIVER !== type)
        .reduce((accumulator, currentValue) => accumulator + +currentValue.premiumAmount, 0)

      // Check if waiver is present in policy
      const waiverBenefit = policy.covers.find(
        benefit => benefit.type === POLICY_BENEFIT_PREMIUM_WAIVER
      )
      const isPolicyAltered =
        Boolean(policyAlteration?.isPolicyAltered) ||
        policyAlteration?.alteredBenefits.filter(
          ({ isSystemGenerated, systemGeneratedReason }) =>
            isSystemGenerated && systemGeneratedReason !== SYSTEM_GENERATED_REASON.NONE
        ).length > 0

      // The waiver premium will be shown as separate line item, filtering before adding premium
      // of other benefits
      // Calculating sum of premiums of benefit for altered (new) section
      const newTotalPremium = covers
        .filter(({ type }) => POLICY_BENEFIT_PREMIUM_WAIVER !== type)
        .reduce((alteredTotalPremium, { type, benefitInstanceNo, premiumAmount }) => {
          const alteredBenefit = policy.alteration?.alteredBenefits?.find(
            benefit =>
              benefit.benefitCode === type &&
              String(benefit.benefitInstanceNo) === String(benefitInstanceNo)
          )

          // if TISO is removed, don't add the premium
          if (alteredBenefit && alteredBenefit.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE) {
            return alteredTotalPremium
          }

          return (
            alteredTotalPremium +
            (alteredBenefit ? +alteredBenefit.newGrossBenefitPremium : +premiumAmount)
          )
        }, 0)

      const { existingPaymentFrequency, updatedPaymentFrequency } =
        getExistingAndUpdatedPaymentFrequency(
          paymentFrequency,
          policyAlteration?.newPremiumPayingFrequency
        )

      let waiverDetails
      if (waiverBenefit) {
        const waiverSelected = !(
          policy.alteration?.alteredBenefits?.find(
            benefit => benefit.benefitCode === POLICY_BENEFIT_PREMIUM_WAIVER
          )?.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
        )
        waiverDetails = {
          waiverName: getOptionDetails(
            fields?.Options,
            POLICY_BENEFIT_PREMIUM_WAIVER,
            POLICY_BENEFIT_PTD,
            productId
          )?.headingText,
          // If waiver is altered then pick value from altered benefit list
          newWaiverPremium: formatCurrency(
            waiverSelected
              ? policy.alteration?.alteredBenefits?.find(
                  benefit => benefit.benefitCode === POLICY_BENEFIT_PREMIUM_WAIVER
                )?.newGrossBenefitPremium ?? waiverBenefit.premiumAmount
              : 0
          ),
          waiverPremium: formatCurrency(waiverBenefit.premiumAmount),
          waiverBenefit,
          waiverSelected,
        }
      }

      const policyData: PreviewPolicy = {
        policyPremium: formatCurrency(totalPremiumAmount),
        stampDuty: formatCurrency(stampDuty),
        taxRebate: formatCurrency(rebateAmount, rebateAmount > 0 ? '-$' : '$'),
        policyFee: formatCurrency(policyFeeValue),
        policyNo,
        policyType,
        policyInstanceNo,
        bancsPolicyNo: internalPolicyNo as string,
        paymentFrequency: existingPaymentFrequency,
        updatedPaymentFrequency,
        productId,
        paymentFrequencyOptions: getPaymentFrequencyOptions(
          premiumPaymentMethod,
          paymentFrequency
        ) as {
          label: string
          value: string
        }[],
        isPaymentFrequencyDisabled: premiumPaymentMethod === ROLLOVER_FROM_EXTERNAL_FUND,
        policyTypeChipVariant: policyType === INSIDE_SUPER ? 'infoLight' : 'important',
        isWaiverPresent: Boolean(waiverBenefit),
        paymentMethod: getPaymentDetails(
          fields,
          premiumPaymentMethod,
          (alterations.policies || []).find(
            ({ bancsPolicyNo }) => bancsPolicyNo === internalPolicyNo
          )?.policy?.paymentDetails
        ),
        ...waiverDetails,
        totalPremium: formatCurrency(totalPremium),
        vivoDiscount: healthyLivingDiscountVal(covers) as number,
        multiCoverDiscount: +getPolicyHasDiscountAndAmount(MULTI_COVER_DISCOUNT, policy)
          ?.discountAmount,
        isPolicyAltered,
        ...(isPolicyAltered && {
          newTotalPremium: formatCurrency(newTotalPremium),
          newPolicyPremium: formatCurrency(policyAlteration.newNetPremium),
          newStampDuty: formatCurrency(policyAlteration.newStampDuty),
          newTaxRebate: formatCurrency(policyAlteration.taxRebate, rebateAmount > 0 ? '-$' : '$'),
          newPolicyFee: formatCurrency(policyAlteration.newPolicyFee),
          saving: formatCurrency(Math.abs(policyAlteration.saving)),
          showSaving: !policyAlteration?.newPremiumPayingFrequency,
          newMultiCoverDiscount:
            policyAlteration.alteredBenefits
              ?.flatMap(({ discounts = [] }) => discounts)
              ?.find(
                discount =>
                  discount?.discountName === MULTI_COVER_DISCOUNT && discount?.discountValue > 0
              )?.discountValue ?? 0,
          hasPremiumIncreased: policyAlteration.saving < 0,
        }),
        // Calculating annual saving, if frequency is other than ANN (annual) or it has changed
        ...(isPolicyAltered &&
          (updatedPaymentFrequency?.value !== POLICY_FREQUENCY_ANNUAL ||
            policyAlteration?.newPremiumPayingFrequency) && {
            annualSaving: formatCurrency(
              Math.abs(policyAlteration.saving) *
                Number(
                  PAYMENT_FREQUENCY_MULTIPLIER.find(
                    frequency => frequency.value === updatedPaymentFrequency?.value
                  )?.label
                )
            ),
          }),
      }

      const coverGroup: Set<string> = new Set([])
      const sortCovers: Cover[] = sortResultsWithOrderList(
        filterCovers,
        'type',
        PREMIUM_CALCULATOR_BENEFITS_SORT_LIST
      )

      const existingCovers: CoverType[] = sortCovers.map((cover: Cover) => {
        const {
          coverAmount,
          coverStyle,
          premiumStyle,
          features,
          tpdDefinition,
          waitingPeriod,
          coverPeriod,
          type,
          benefitInstanceNo,
          benefitCommencementDate,
          premiumAmount,
          childBenefitReferences,
          subBenefits,
        } = cover

        const benefitName = getSectionDetails(fields.Cover, type, productId)?.headingText
        const showBenefitName = !coverGroup.has(benefitName)
        coverGroup.add(benefitName)

        // Checking if benefit is altered
        const alteredBenefit = policy.alteration?.alteredBenefits?.find(
          benefit =>
            benefit.benefitCode === type &&
            String(benefit.benefitInstanceNo) === String(benefitInstanceNo)
        )
        let isBenefitAltered = Boolean(alteredBenefit)

        const modifiedOptions = alteredBenefit?.modifiedOptions || []

        let totalCoverPremiumAmount = Number(premiumAmount)

        // Assign newGrossBenefitPremium is benefit is altered, else assign the initial premium
        let totalCoverAlteredPremiumAmount = Number(
          alteredBenefit?.newGrossBenefitPremium ?? premiumAmount
        )

        const childBenefitTISO = childBenefitReferences?.find(
          childBenefit => childBenefit.childType === POLICY_BENEFIT_TISO
        )

        const benefitAsOptionsList = [] // List for benefit as option for existing section
        const alteredBenefitAsOptionList = [] // List for benefit as option for altered section
        if (childBenefitTISO) {
          const benefitTISO = policy.covers.find(
            benefit =>
              benefit.type === POLICY_BENEFIT_TISO &&
              String(benefit.benefitInstanceNo) === String(childBenefitTISO.childBenefitInstanceNo)
          )
          totalCoverPremiumAmount += Number(benefitTISO?.premiumAmount ?? 0)
          const tisoFeatureDetails = getOptionDetails(
            fields?.Options,
            POLICY_BENEFIT_TISO,
            type,
            productId
          )
          benefitAsOptionsList.push({
            displayedFeatureName: tisoFeatureDetails?.headingText,
            featureName: childBenefitTISO.childType,
          })

          // Check if TISO is altered
          const alteredTISO = policy.alteration?.alteredBenefits?.find(
            benefit =>
              benefit.benefitCode === POLICY_BENEFIT_TISO &&
              String(benefit.benefitInstanceNo) === String(childBenefitTISO?.childBenefitInstanceNo)
          )

          if (alteredTISO) {
            isBenefitAltered = true
            const isTISOSelected = !(alteredTISO.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE)
            if (isTISOSelected) {
              // Adding altered TISO premium to parent
              totalCoverAlteredPremiumAmount += +alteredTISO?.newGrossBenefitPremium

              // Pushing TISO as it's altered because of parent cover but not removed
              alteredBenefitAsOptionList.push({
                displayedFeatureName: tisoFeatureDetails?.headingText,
                featureName: childBenefitTISO.childType,
              })
            }
          } else {
            // Adding TISO premium to parent if TISO is not altered
            totalCoverAlteredPremiumAmount += Number(benefitTISO?.premiumAmount ?? 0)

            alteredBenefitAsOptionList.push({
              displayedFeatureName: tisoFeatureDetails?.headingText,
              featureName: childBenefitTISO.childType,
            })
          }
        }

        // Mapping features to fetch displayedFeatureName from Sitecore field
        const featuresOptions =
          (features &&
            features
              .filter(
                ({ featureApplicable, featureName }) =>
                  featureApplicable === 'Y' &&
                  !PREMIUM_CALCULATOR_HIDE_OPTIONS.includes(featureName)
              )
              .map(({ featureName, duration }) => {
                const featureDetails = getOptionDetails(
                  fields?.Options,
                  featureName,
                  type,
                  productId,
                  duration
                )
                return {
                  displayedFeatureName: featureDetails?.headingText,
                  featureName,
                }
              })) ||
          []

        // Sub benefits as features
        const subBenefitsAsOptionsList =
          subBenefits
            ?.filter(subBenefit =>
              PREMIUM_CALCULATOR_SUB_BENEFITS.includes(subBenefit.subBenefitCode)
            )
            ?.map(subBenefit => {
              const featureDetails = getOptionDetails(
                fields?.Options,
                subBenefit.subBenefitCode,
                type,
                productId
              )
              return {
                featureName: subBenefit.subBenefitCode,
                displayedFeatureName: featureDetails?.headingText,
              }
            }) || []

        // Check if benefit period is updated
        const newCoverPeriod = modifiedOptions.find(
          option => option.optionAlterationType === DECREASED
        )?.benefitPeriod

        // Check if waiting period is updated
        const newWaitingPeriod = modifiedOptions.find(
          option => option.optionAlterationType === INCREASED
        )?.waitingPeriod

        // Use reduce to extract feature names to be removed and filter options
        const newFeatureOptions = (modifiedOptions || []).reduce((acc, option) => {
          if (option?.optionAlterationType === REMOVE_BENEFIT) {
            const featureNameToRemove = option?.feature?.featureName
            return acc.filter(
              feautureOption => feautureOption && feautureOption.featureName !== featureNameToRemove
            )
          }
          return acc
        }, featuresOptions.slice())

        return {
          coverAmount: formatCurrency(coverAmount, '$', 0),
          premiumAmount: formatCurrency(totalCoverPremiumAmount),
          coverStyle,
          showBenefitName,
          premiumStyle,
          type,
          benefitType: getSectionDetails(fields.Type, type, productId)?.headingText,
          benefitName,
          tpdDefinition: getSectionDetails(fields.Definition, tpdDefinition, productId)
            ?.headingText,
          benefitAsOptionsList,
          featuresList: [...featuresOptions, ...benefitAsOptionsList, ...subBenefitsAsOptionsList],
          waitingPeriod,
          coverPeriod,
          newCoverAmount: formatCurrency(alteredBenefit?.newSumInsured || coverAmount, '$', 0),
          newPremiumAmount: formatCurrency(
            totalCoverAlteredPremiumAmount || totalCoverPremiumAmount
          ),
          newCoverPeriod: newCoverPeriod ?? coverPeriod,
          newWaitingPeriod: newWaitingPeriod ?? waitingPeriod,
          newFeaturesList: [
            ...newFeatureOptions,
            ...alteredBenefitAsOptionList,
            ...subBenefitsAsOptionsList,
          ],
          benefitInstanceNo,
          isBenefitAltered,
          paymentFrequency: existingPaymentFrequency,
          updatedPaymentFrequency,
          benefitCommencementDate: moment(benefitCommencementDate).format('DD MMM YYYY'),
        }
      })
      premiumCalculatorPreviewQuote.push({
        policy: policyData,
        covers: existingCovers,
      })
    })
    return premiumCalculatorPreviewQuote
  }
)
