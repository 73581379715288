import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontSize, colours, mediaQueries } = Variables

const styles = {
  wrapper: {
    marginBottom: space(17),
  },
  table: {
    '& th,td': {
      minWidth: 'initial',
      flex: 2,
      '&:nth-of-type(1)': {
        paddingLeft: '24px',
      },
      '&:nth-of-type(3)': {
        [mediaQueries.lg]: {
          flex: 7,
        },
        textAlign: 'right',
        '& > a': {
          marginLeft: 'auto',
        },
      },
    },
  },
  anchor: {
    fontSize: fontSize.xs,
    color: colours.mediumGreen,
    cursor: 'pointer',
  },
  tableFooter: {
    justifyContent: 'flex-end',
  },
  label: {
    marginBottom: space(5, true),
  },
  sectionHeader: ({ SectionMarginBottom = true }) => ({
    marginBottom: space(SectionMarginBottom ? 5 : 1),
  }),
}

export default styles
