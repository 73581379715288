import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { fontSize, fontWeights, space, colours } = Variables as VariablesType

const styles = {
  heading: {
    fontSize: fontSize.xsm,
    padding: space(2),
    border: `1px solid ${colours.lightestGrey}`,
    borderTop: 0,
    margin: 0,
    '> div': {
      fontWeight: fontWeights.light,
      marginLeft: space(1),
    },
  },
}

export default styles
