import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontSize } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(3),
  },
  panelHeading: {
    background: colours.mlcAquaLight,
    color: colours.tertiaryOne,
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    padding: `${space(2, true)} ${space(3, true)}`,
  },
  detailsSection: {
    color: colours.tertiaryOne,
    padding: `${space(2, true)} ${space(3, true)} 0`,
    p: {
      marginTop: space(1),
    },
    span: {
      fontWeight: fontWeights.semiBold,
    },
  },
  buttonContainer: {
    display: 'flex',
    marginTop: space(6),
    svg: {
      marginRight: space(1),
      height: space(2),
      width: space(2),
    },
    button: {
      marginRight: space(2.5),
    },
  },
  sidePanelContent: {
    margin: `${space(2, true)} 0 ${space(3, true)}`,
    li: {
      padding: 0,
    },
  },
}

export default styles
