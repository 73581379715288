import { Variables } from '@mlcl-digital/mlcl-design'

const { mediaQueries, space, colours, fontSize } = Variables
export default {
  policyDetails: {
    background: colours.tertiaryFive,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colours.tertiaryOne,
    padding: `${space(3.5)}px ${space(3)}px`,
    fontSize: fontSize.md,
    alignItems: 'flex-end',
    fontWeight: 600,
    '& span': {
      fontSize: fontSize.xs,
      display: 'block',
      fontWeight: 300,
    },
  },
  section: {
    [mediaQueries.lg]: {
      maxWidth: space(60.75),
    },
    color: colours.tertiaryOne,
  },
  altActionButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    color: colours.mlcAquaAccessible,
    fontWeight: 600,
  },
  creditCardSection: {
    marginTop: space(5),
  },
  partialPaymentSection: {
    marginTop: space(5),
  },
  errorLine: {
    color: colours.functionalDangerMedium,
  },
  backButton: {
    padding: 0,
    marginTop: space(5),
  },
  leftChevronIcon: {
    color: colours.tertiaryOne,
  },
  header: {
    marginTop: space(3),
  },
  card: {
    position: 'absolute',
    right: space(3),
    top: space(3),
  },
}
