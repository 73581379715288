import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { space, fontSize, colours, fontWeights } = Variables as VariablesType
export default {
  wrap: {
    marginTop: space(10),
    marginBottom: space(10),
  },
  formSection: {
    maxWidth: '597px',
  },
  inputSubtitle: {
    fontSize: fontSize.xxs,
    color: colours.tertiaryTwo,
  },
  divider: {
    width: '100%',
    margin: `${space(5, true)} 0`,
    borderBottom: `solid 2px ${colours.tertiaryThree}`,
  },
  datePicker: {
    position: 'relative' as const,
    display: 'inline-block',
    '.react-datepicker__input-container input': {
      padding: `${space(1.5, true)} ${space(2, true)}`,
      cursor: 'pointer',
    },
    '> svg': {
      position: 'absolute' as const,
      right: '8px',
      top: '10px',
    },
  },
  fieldGroup: {
    marginBottom: space(5),
  },
  checkBox: {
    marginBottom: space(5),
  },
  buttons: {
    marginBottom: space(2),
  },
  toggleWrapper: {
    maxWidth: space(66.25),
    '> span': {
      color: colours.tertiaryOne,
    },
  },
  addressHeading: {
    color: colours.tertiaryOne,
    fontWeight: fontWeights.semiBold,
  },
}
