import { space } from '../../../../styles'

const styles = {
  wrapper: {
    marginBottom: space(17, true),
  },
  status: {
    cell: {
      minWidth: 'initial',
      justifyContent: 'center',
      textTransform: 'capitalize',
    },
    headerCell: {
      minWidth: 'initial',
    },
  },
  requestType: {
    cell: {
      flex: 7,
    },
    headerCell: {
      flex: 7,
    },
  },
  dateCreated: {
    cell: {
      justifyContent: 'flex-end',
    },
    headerCell: {
      justifyContent: 'flex-end',
    },
  },
  label: {
    marginBottom: space(5, true),
  },
  sectionHeader: ({ SectionMarginBottom = true }) => ({
    marginBottom: space(SectionMarginBottom ? 5 : 1),
  }),
}

export default styles
