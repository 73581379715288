import { colours, space, mediaQueries } from '../../../../../styles'

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    strong: {
      padding: `${space(1, true)} ${space(6.3, true)}`,
    },
    '& :first-child': {
      marginBottom: space(1, true),
    },

    [mediaQueries.md]: {
      flexDirection: 'row',

      '& :first-child': {
        margin: '0 auto',
      },
    },
  },
  modalDescription: {
    marginBottom: space(5),
    color: colours.black,
  },
}
export default styles
