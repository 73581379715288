import { createSelector } from 'reselect'
// selectors
import { getPolicyStructure } from './common.selectors'
// utils
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
// constants
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../constants/forms'

export const getPremiumCalculatorSubmitData = createSelector(
  [getPolicyStructure],
  policyStructure => ({
    policies: policyStructure.map(policy => {
      const premiumFrequency =
        policy.alteration?.newPremiumPayingFrequency ?? policy.paymentFrequency
      const premiumFrequencyLabel = (
        REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
          frequency => frequency.value === premiumFrequency
        ) || {}
      ).label?.toLowerCase()
      return {
        policyNo: policy.policyNo,
        premium: dollarAmountWithCommasAndTwoDecimal(
          policy?.alteration?.newNetPremium || policy.totalPremiumAmount
        ),
        premiumFrequency: premiumFrequencyLabel,
      }
    }),
  })
)
