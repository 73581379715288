// @flow
import React from 'react'
import { isBrowser } from '../../../../utils/browserUtils'

// components.
import LandingPage from '../../../organisms/PremiumCalculator/LandingPage'

type LandingPageProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const PremiumCalculatorLandingPage = ({ fields }: LandingPageProps) =>
  isBrowser() && <LandingPage fields={fields} />

export default PremiumCalculatorLandingPage
