// @flow
import React from 'react'

// components.
import ContentCardComponent from '../../../organisms/ContentCard'

type ContentCardProps = {
  // Sitecore fields.
  fields: Object<Object>,
  // Params
  params: Object,
}

const ContentCard = ({ fields, params }: ContentCardProps) => (
  <ContentCardComponent fields={fields} params={params} />
)

export default ContentCard
