import React from 'react'
import styled from '@emotion/styled'

// styles
import styles from './sectionWrapper.styles'

const Wrapper = styled('div')(styles.wrapper)

type SectionWrapperProps = {
  children: React.ReactNode
}

const SectionWrapper = ({ children }: SectionWrapperProps) => <Wrapper>{children}</Wrapper>

export default SectionWrapper
