// @flow
import { shadows, colours, fontSize, space, mediaQueries, fontWeights } from '../../../../styles'

const searchHeight = {
  xs: 56,
  sm: 112,
  lg: 332,
}

const styles = {
  wrapper: {
    width: '100%',
    boxShadow: shadows.mid,
    background: colours.white,
    marginBottom: space(4),
    display: 'flex',
    flexDirection: 'row',
    minHeight: searchHeight.lg,
    overflow: 'auto',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: space(1.5),
    paddingBottom: space(1.5),
    marginLeft: '5%',
    marginRight: '5%',
    [mediaQueries.md]: {
      paddingTop: space(3),
      paddingBottom: space(5),
    },
  },
  findAdviserwrapper: {
    width: '100%',
    boxShadow: shadows.mid,
    background: colours.white,
    marginBottom: space(4),
    display: 'flex',
    flexDirection: 'row',
    height: searchHeight.lg,
  },
  findAdviserInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: space(1.5),
    marginLeft: '5%',
    marginRight: '5%',
    [mediaQueries.md]: {
      height: searchHeight.xs,
      paddingTop: space(3),
    },
  },
  label: {
    marginBottom: space(7),
    marginTop: space(7),
    color: colours.darkestGrey,
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    fontWeight: fontWeights.semiBold,
    display: 'block',

    [mediaQueries.md]: {
      fontSize: fontSize.md,
      lineHeight: fontSize.lg,
    },
  },
  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
    fontSize: fontSize.xs,
  },
  confirmAdviserRemovalBtn: {
    margin: `${space(3, true)} 0`,
    color: colours.red,
    borderColor: colours.red,
    '&:hover, &:focus': {
      color: colours.lightRed,
      borderColor: colours.red,
    },
  },
  policyWithNoAdviser: {
    width: '100%',
    boxShadow: shadows.mid,
    background: colours.white,
    marginBottom: space(4),
    display: 'flex',
    flexDirection: 'row',
    height: searchHeight.sm,
  },
  noAdviserMessage: {
    marginBottom: space(7),
    marginTop: space(7),
    marginLeft: space(7),
    color: colours.darkestGrey,
    fontSize: fontSize.sm,
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  },
}

export default styles
